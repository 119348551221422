import OfferPoolList from "../../components/OfferPoolList/OfferPoolList";
import React from "react";
import {
  AppBar,
  LinearProgress,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  OfferPools as IOfferPoolsQuery,
  OfferPools_offerPools,
  OfferPools_offerPools_edges_node,
} from "../../generated/OfferPools";
import OfferPoolsQuery from "../../queries/OfferPoolsQuery";

const OfferPoolListContainer = () => {
  const history = useHistory();
  const classes = useStyles();

  const { data, error, loading } = useQuery<IOfferPoolsQuery>(
    OfferPoolsQuery,
    { variables: { limit: 50, page: 1 } }
  );

  const offerPoolsData = (() => {
    if (data && data.offerPools) {
      return data.offerPools;
    }
    const defaultData: OfferPools_offerPools = {
      __typename: "OfferPoolsConnection",
      edges: [],
      pageInfo: {
        __typename: "PageInfo",
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: null,
      },
      totalCount: 0,
    };
    return defaultData;
  })();
  const onNextPage = () => {};
  const onPreviousPage = () => {};
  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <AppBar color="default" elevation={0} position="static">
        <Tabs
          onChange={(_, value: string) => history.push(value)}
          value={history.location.pathname}
        >
          <Tab label="Account Pools" value="/account-pools" />
          <Tab label="Offer Pools" value="/offer-pools" />
        </Tabs>
      </AppBar>
      <OfferPoolList
        loading={loading}
        offerPools={
          offerPoolsData.edges.map(
            (edge) => edge.node
          ) as OfferPools_offerPools_edges_node[]
        }
        hasNextPage={false}
        hasPreviousPage={false}
        handleSearch={() => {}}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing(),
  },
}));

export default OfferPoolListContainer;
