import { gql } from '@apollo/client';

const ArchiveOfferPoolMutation = gql`
  mutation ArchiveOfferPool (
    $offerPoolId: Int!
  ) {
    archiveOfferPool(
      offerPoolId: $offerPoolId
    ) {
      error {
        errorType
        message
      }
      offerPool {
        id
        archived
      }
    }
  }
`;

export default ArchiveOfferPoolMutation;