import { gql } from "@apollo/client";

const CreateConversionKeyMutation = gql`
  mutation CreateConversionKey(
    $name: String!
  ) {
    createConversionKey(
      input: { name: $name }
    ) {
      conversionKey {
        id
      }
    }
  }
`;

export default CreateConversionKeyMutation;
