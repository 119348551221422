import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import ConfirmationAlert from "../ConfirmationAlert";
import InputBrandSearch from "../InputBrandSearch";
import OfferPoolFormOfferRequirementGroup from "../OfferPoolFormOfferRequirementGroup/OfferPoolFormOfferRequirementGroup";
import { useMutation } from "@apollo/client";
import { Brands_brands_edges_node } from "../../generated/Brands";
import {
  OfferPool_offerPool_offerPool_requirements,
  OfferPool_offerPool_offerPool_requirements_offer
} from "../../generated/OfferPool";
import { OfferPools_offerPools_edges_node_requirements } from "../../generated/OfferPools";
import {
  CreateOfferPool,
  CreateOfferPoolVariables,
} from "../../generated/CreateOfferPool";
import CreateOfferPoolMutation from "../../mutations/CreateOfferPoolMutation";
import {
  UpdateOfferPool,
  UpdateOfferPoolVariables,
} from "../../generated/UpdateOfferPool";
import UpdateOfferPoolMutation from "../../mutations/UpdateOfferPoolMutation";
import {
  OfferPoolRequirementInput,
  OfferPoolRequirementType,
} from "../../generated/globalTypes";
import OfferPoolStatusChip from "../OfferPoolStatusChip/OfferPoolStatusChip";
import { DeactivateOfferPool } from "../../generated/DeactivateOfferPool";
import DeactivateOfferPoolMutation from "../../mutations/DeactivateOfferPoolMutation";
import { ReactivateOfferPool } from "../../generated/ReactivateOfferPool";
import ReactivateOfferPoolMutation from "../../mutations/ReactivateOfferPoolMutation";
import { ArchiveOfferPool } from "../../generated/ArchiveOfferPool";
import ArchiveOfferPoolMutation from "../../mutations/ArchiveOfferPoolMutation";

export interface IOfferPoolRequirement {
  requirementType?: OfferPoolRequirementType;
  requirementValue?: string[];
}

export interface IOfferPoolRequirementsGroup {
  requirements: IOfferPoolRequirement[];
  offer?: OfferPool_offerPool_offerPool_requirements_offer;
}

interface IOfferPoolFormProps {
  type: string;
  onClose: () => void;
  open: boolean;
}

interface IOfferPoolFormCreateProps extends IOfferPoolFormProps {
  id?: undefined;
  name?: undefined;
  active?: boolean;
  archived?: boolean;
  requirements?: OfferPools_offerPools_edges_node_requirements[];
}

interface IOfferPoolFormUpdateProps extends IOfferPoolFormProps {
  id: number;
  name: String;
  active: boolean;
  archived: boolean;
  requirements: OfferPools_offerPools_edges_node_requirements[];
}

export const groupRequirementsByOffer = (
  requirements: OfferPool_offerPool_offerPool_requirements[]
) => {
  if (!requirements || !requirements.length) {
    return [];
  }
  const reducer = (
    groups: IOfferPoolRequirementsGroup[],
    req: OfferPool_offerPool_offerPool_requirements
  ) => {
    const index = groups.findIndex((g) => g?.offer?.id === req.offer.id);
    if (index === -1) {
      groups.push({
        offer: req.offer,
        requirements: [],
      });
    }
    const group = groups.find((g) => g?.offer?.id === req.offer.id);
    if (
      group?.requirements.find((r) => r.requirementType === req.requirementType)
    ) {
      const requirement = group?.requirements.find(
        (r) => r.requirementType === req.requirementType
      );
      requirement?.requirementValue?.push(req.requirementValue);
    } else {
      group?.requirements.push({
        requirementType: req.requirementType,
        requirementValue: [req.requirementValue],
      });
    }
    return groups;
  };
  return requirements.reduce(reducer, [] as IOfferPoolRequirementsGroup[]);
};

const OfferPoolForm = ({
  type,
  onClose,
  open,
  id,
  name,
  active,
  requirements,
}: IOfferPoolFormCreateProps | IOfferPoolFormUpdateProps) => {
  const classes = useClasses();

  // Internal state
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  // Offer data
  const [offerPoolName, setOfferPoolName] = useState<string>(name as string || "");
  const [offerPoolBrand, setOfferPoolBrand] = useState<number|undefined>(
    requirements?.[0]?.offer?.brand?.id as number
  );
  const [offerPoolRequirements, setOfferPoolRequirements] = useState<
    IOfferPoolRequirementsGroup[]
  >(groupRequirementsByOffer(requirements || []));

  const [upsertOfferPool, { loading }] = useMutation<
    CreateOfferPool | UpdateOfferPool
  >(type === "create" ? CreateOfferPoolMutation : UpdateOfferPoolMutation);

  const commonVariables = { offerPoolId: id };
  const [deactivateOfferPool, { loading: deactivateLoading }] = useMutation<
    DeactivateOfferPool
  >(DeactivateOfferPoolMutation, { variables: commonVariables });
  const [reactivateOfferPool, { loading: reactivateLoading }] = useMutation<
    ReactivateOfferPool
  >(ReactivateOfferPoolMutation, { variables: commonVariables });
  const [archiveOfferPool, { loading: archiveLoading }] = useMutation<
    ArchiveOfferPool
  >(ArchiveOfferPoolMutation, { variables: commonVariables });

  const handleOfferRequirementGroupChange = (
    offerPoolGroup: IOfferPoolRequirementsGroup,
    index: number
  ) => {
    setDirty(true);
    const offerPoolGroups = [...offerPoolRequirements];
    offerPoolGroups[index] = offerPoolGroup;
    setOfferPoolRequirements(offerPoolGroups);
  };

  const handleAddOfferRequirementGroup = (
    event: FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setDirty(true);
    setOfferPoolRequirements(() => {
      return [
        ...offerPoolRequirements,
        {
          offer: undefined,
          requirements: [],
        } as IOfferPoolRequirementsGroup,
      ];
    });
  };

  const handleRemoveOfferRequirementGroup = (index: number) => {
    setDirty(true);
    setOfferPoolRequirements(
      [...offerPoolRequirements].filter((_, i) => i !== index)
    );
  };

  const closeAndReset = () => {
    setDirty(false);
    setOfferPoolName('');
    setOfferPoolBrand(undefined);
    setOfferPoolRequirements([]);
    onClose();
  };

  const handleDiscard = () => {
    setConfirmOpen(false);
    closeAndReset();
  };

  const handleClose = () => {
    dirty ? setConfirmOpen(true) : closeAndReset();
  };

  const getRequirements = () => {
    return offerPoolRequirements.reduce(
      (requirements, offerPool: IOfferPoolRequirementsGroup) => {
        const opRequirements = offerPool.requirements.flatMap((requirement) => {
          const requirementType = requirement.requirementType as OfferPoolRequirementType;
          const offerId = offerPool?.offer?.id;
          return (
            requirement.requirementValue?.map(
              (requirementValue) =>
                ({
                  offerId,
                  requirementType,
                  requirementValue,
                } as OfferPoolRequirementInput)
            ) || []
          );
        });
        return [...requirements, ...opRequirements];
      },
      [] as OfferPoolRequirementInput[]
    );
  };

  const getUpsertVariables = () => {
    let variables: CreateOfferPoolVariables | UpdateOfferPoolVariables;
    if (type === "create") {
      variables = {
        name: offerPoolName as string,
        requirements: getRequirements(),
      };
    } else {
      variables = {
        id: id as number,
        name: offerPoolName as string,
        requirements: getRequirements(),
      };
    }
    return variables;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    upsertOfferPool({
      refetchQueries: ["OfferPools"],
      variables: getUpsertVariables(),
    }).then(() => {
      closeAndReset();
    });
  };

  useEffect(() => {
    setOfferPoolName(name as string || "");
    setOfferPoolBrand(requirements?.[0]?.offer?.brand?.id);
    setOfferPoolRequirements(groupRequirementsByOffer(requirements || []));
  }, [id, name, requirements, open]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle className={classes.dialogTitle}>
            {`${type === "create" ? "Create" : "Update"} Offer Pool`}
            {id && <OfferPoolStatusChip offerPoolId={id} active={active} />}
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.formTitle} color="textSecondary">
              Pool Details
            </Typography>
            <TextField
              autoFocus
              id="name"
              label="Offer Pool Name"
              className={classes.formControl}
              fullWidth
              required
              onChange={(event) => {
                setDirty(true);
                setOfferPoolName(event.target.value);
              }}
              value={offerPoolName}
            />
            <InputBrandSearch
              label="Search for brand..."
              className={classes.formControl}
              disabled={false}
              onSelect={(brand: Brands_brands_edges_node | undefined) => {
                setDirty(true);
                setOfferPoolBrand(brand?.id as number);
              }}
            />
          </DialogContent>
          <DialogContent>
            <Typography className={classes.formTitle} color="textSecondary">
              Pool Offers ({offerPoolRequirements.length})
            </Typography>

            <div className={classes.cardOverflowContainer}>
              {offerPoolRequirements.length ? (
                offerPoolRequirements.map(
                  (
                    offerPoolGroup: IOfferPoolRequirementsGroup,
                    index: number
                  ) => (
                    <OfferPoolFormOfferRequirementGroup
                      key={`offer-pool-requirement-group-${offerPoolGroup?.offer?.id}-${index}`}
                      brandId={offerPoolBrand}
                      offer={offerPoolGroup.offer}
                      requirements={offerPoolGroup.requirements}
                      onChange={(newOfferPoolGroup) =>
                        handleOfferRequirementGroupChange(
                          newOfferPoolGroup,
                          index
                        )
                      }
                      onDelete={() =>
                        handleRemoveOfferRequirementGroup(index)
                      }
                    />
                  )
                )
              ) : (
                <Typography color="textSecondary" align="center">
                  No offers added to this pool.
                </Typography>
              )}
            </div>
            <div className={classes.formButton}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleAddOfferRequirementGroup}
              >
                <AddIcon />
                Offer
              </Button>
            </div>
          </DialogContent>
          {type === "update" && (
            <DialogContent>
              <Typography className={classes.formTitle} color="textSecondary">
                Pool Status
              </Typography>
              <div className={classes.formButtons}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="default"
                  onClick={() =>
                    active
                      ? setConfirmDeactivateOpen(true)
                      : reactivateOfferPool()
                  }
                  disabled={
                    deactivateLoading || reactivateLoading || archiveLoading
                  }
                >
                  {active ? "Deactivate" : "Activate"} Pool
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => setConfirmDeleteOpen(true)}
                  disabled={
                    deactivateLoading || reactivateLoading || archiveLoading
                  }
                >
                  Delete
                </Button>
              </div>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={loading}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              {type === "create" ? "Create" : "Update"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        title="Unsaved Changes"
        content="Closing this form will lose any unsaved progress."
        open={confirmOpen}
        positiveAction="Discard"
        onNegative={() => setConfirmOpen(false)}
        onPositive={handleDiscard}
      />

      <ConfirmationAlert
        title="Delete Offer Pool"
        content={`Are you sure you want to delete the offer pool "${offerPoolName}"? This action cannot be undone.`}
        open={confirmDeleteOpen}
        positiveAction="Delete"
        onNegative={() => setConfirmDeleteOpen(false)}
        onPositive={() => {
          setConfirmDeleteOpen(false);
          archiveOfferPool();
          closeAndReset();
        }}
      />

      <ConfirmationAlert
        title="Deactivate Offer Pool"
        content={`Are you sure you want to deactivate the offer pool "${offerPoolName}"?`}
        open={confirmDeactivateOpen}
        positiveAction="Deactivate"
        onNegative={() => setConfirmDeactivateOpen(false)}
        onPositive={() => {
          setConfirmDeactivateOpen(false);
          deactivateOfferPool();
        }}
      />
    </>
  );
};

const useClasses = makeStyles(({ spacing, palette }) => ({
  dialogTitle: {
    "& h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  formTitle: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  formControl: {
    paddingBottom: spacing(2),
  },
  formButton: {
    paddingTop: spacing(2),
  },
  formButtons: {
    display: "flex",
    gap: spacing(1),
    justifyContent: "space-between",
  },
  cardOverflowContainer: {
    maxHeight: 400,
    overflowY: "auto",
    padding: spacing(1),
    backgroundColor: palette.grey[50],
    borderRadius: 4,
    border: `1px solid ${palette.grey[100]}`,
    borderTopColor: palette.grey[200],
  },
}));

export default OfferPoolForm;
