import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  IconButton,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

import { ConversionKeys_conversionKeys_edges_node } from "../../generated/ConversionKeys";
import { useAuth0 } from "../../utils/auth0Provider";
import ConversionKeyForm from "../ConversionKeyForm";
import ConfirmationAlert from "../ConfirmationAlert";
import { useMutation } from "@apollo/client";
import { DeleteConversionKey } from "../../generated/DeleteConversionKey";
import DeleteConversionKeyMutation from "../../mutations/DeleteConversionKeyMutation";
import { UpdateInstanceSettingsRequireConversionKey } from "../../generated/UpdateInstanceSettingsRequireConversionKey";
import UpdateInstanceSettingsRequireConversionKeyMutation from "../../mutations/UpdateInstanceSettingsRequireConversionKeyMutation";

interface ConversionKeyDelete {
  id: string;
  name: string;
}

export interface IConversionKeyListProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  conversionKeys: ConversionKeys_conversionKeys_edges_node[];
  requireConversionKey: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const ConversionKeyList = ({
  hasNextPage,
  hasPreviousPage,
  conversionKeys,
  requireConversionKey,
  onNextPage,
  onPreviousPage
}: IConversionKeyListProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const manageConversionKeys = hasPermission("manage:conversion_keys");

  const [conversionKeyFormOpen, setConversionKeyFormOpen] = useState(false);
  const [requireConversionKeyOpen, setRequireConversionKeyOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [conversionKeyToDelete, setConversionKeyToDelete] = useState<ConversionKeyDelete>();

  const [updateInstanceSettings] = useMutation<UpdateInstanceSettingsRequireConversionKey>(UpdateInstanceSettingsRequireConversionKeyMutation);
  const [deleteConversionKey, { loading }] = useMutation<DeleteConversionKey>(DeleteConversionKeyMutation);

  const handleRequireConversionKeyClick = () => {
    if (requireConversionKey) {
      handleRequireConversionKey();
    } else {
      setRequireConversionKeyOpen(true);
    }
  }

  const handleRequireConversionKey = () => {
    setRequireConversionKeyOpen(false);
    updateInstanceSettings({
      variables: { requireConversionKey: !requireConversionKey },
      refetchQueries: ["InstanceSettings"]
    });
  }

  const handleDeleteClick = (conversionKey: ConversionKeyDelete) => {
    setConversionKeyToDelete(conversionKey);
    setDeleteOpen(true);
  }

  const handleDelete = () => {
    setDeleteOpen(false);
    deleteConversionKey({
      refetchQueries: ["ConversionKeys"],
      variables: { conversionKeyId: conversionKeyToDelete?.id }
    });
    setConversionKeyToDelete(undefined);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Conversion Keys</Typography>

        {manageConversionKeys && (
          <Switch
          checked={requireConversionKey}
          onChange={handleRequireConversionKeyClick}
          name="requireConversionKey"
        />
        )}
      </div>
      <Typography variant="body1">Conversion keys can be used when sending conversions via S2S endpoint</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Last Used</TableCell>
            <TableCell>Created By</TableCell>
            {manageConversionKeys && <TableCell>
              <Button
                color="primary"
                onClick={() => setConversionKeyFormOpen(true)}
                variant="contained"
              >
                <AddIcon className={classes.buttonIcon} />
                Key
              </Button>
            </TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {conversionKeys.map(conversionKey => (
            <TableRow key={conversionKey.id}>
              <TableCell>{conversionKey.name}</TableCell>
              <TableCell>{conversionKey.id}</TableCell>
              <TableCell>{conversionKey.lastUsedAt}</TableCell>
              <TableCell>{conversionKey.createdBy.name} ({conversionKey.createdBy.displayId})</TableCell>
              {manageConversionKeys &&
                <TableCell>
                  <IconButton disabled={loading} onClick={() => handleDeleteClick(conversionKey)}>
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <ConversionKeyForm
        onClose={() => setConversionKeyFormOpen(false)}
        open={conversionKeyFormOpen}
      />

      <ConfirmationAlert
        content="Enabling conversion keys will require all S2S /convert endpoint requests to use one of the below keys in the X-ACCESS-TOKEN header."
        onNegative={() => setRequireConversionKeyOpen(false)}
        onPositive={handleRequireConversionKey}
        open={requireConversionKeyOpen}
        positiveAction="Enable"
        title="Enable conversion keys?"
      />

      <ConfirmationAlert
        content="Deleting a key will revoke access to the conversion S2S endpoint for this token."
        onNegative={() => setDeleteOpen(false)}
        onPositive={handleDelete}
        open={deleteOpen}
        positiveAction="Delete"
        title={`Delete key ${conversionKeyToDelete?.name}?`}
      />
    </>
  )
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: { marginRight: spacing() },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default ConversionKeyList;
