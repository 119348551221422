import React from "react";
import { integration_integration_integration } from "../../generated/Integration";
import {
  Paper,
  Breadcrumbs,
  Typography,
  Button,
  makeStyles,
  Link,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../utils/auth0Provider";
import { format } from "date-fns";
import { Skeleton } from "@material-ui/lab";
import IntegrationsStatusChip from "../IntegrationsStatusChip/IntegrationsStatusChip";

interface IIntegrationShowProps {
  loading: boolean;
  integration?: integration_integration_integration | null;
}

const IntegrationsShow = ({ loading, integration }: IIntegrationShowProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { hasPermission } = useAuth0();
  const manageIntegrations = hasPermission("manage:integrations");

  const renderDefinitionItem = (label: string, value: any) => {
    return (
      <div className={classes.cardListItem}>
        {loading ? (
          <Skeleton component="dt" variant="text" />
        ) : (
          <Typography component="dt" variant="body1">
            {label}
          </Typography>
        )}
        {loading ? (
          <Skeleton component="dd" variant="text" width={210} />
        ) : (
          <Typography component="dd" variant="body1">
            {value}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Link
                underline="hover"
                color="inherit"
                onClick={(e) => history.push("/tech/integrations")}
              >
                <ChevronLeft />
                Integrations
              </Link>
              <Typography color="textPrimary">{integration?.id}</Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.headerItem}>
            {manageIntegrations && (
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(`/tech/integrations/${integration?.id}/edit`)
                }
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        <Divider />
        <div className={classes.gRoot}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={2}>
              <List>
                <ListItem button>
                  <ListItemText primary="Status" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Postback Setup" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Tracking" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Authentication" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Disconnect" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={10}>
              <div className={classes.cardContainer}>
                <Card variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Details
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderDefinitionItem(
                        "Type",
                        integration?.integrationType
                      )}
                      {renderDefinitionItem(
                        "Last Updated",
                        integration?.updatedAt
                          ? format(
                              new Date(integration?.updatedAt),
                              "MM/dd/yyyy HH:mmaa"
                            )
                          : "&mdash;"
                      )}
                      {renderDefinitionItem(
                        "Account ID",
                        integration?.accountId
                      )}
                      {renderDefinitionItem(
                        "External ID",
                        integration?.externalId
                      )}
                      {renderDefinitionItem(
                        "Active",
                        <IntegrationsStatusChip
                          integrationId={integration?.id}
                          active={integration?.active}
                        />
                      )}
                    </dl>
                  </CardContent>
                </Card>

                <Card variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Postback Setup
                    </Typography>
                    <Table className={classes.cardListItemTable} size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2" component="strong">
                              1
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label="PURCHASE"
                              size="small"
                              color="default"
                              variant="outlined"
                              style={{ width: "100%" }}
                            />
                          </TableCell>
                          <TableCell>Subscription Start</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2" component="strong">
                              2
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label="DEPOSIT"
                              size="small"
                              color="default"
                              variant="outlined"
                              style={{ width: "100%" }}
                            />
                          </TableCell>
                          <TableCell>Purchase</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2" component="strong">
                              3
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label="WAGER"
                              size="small"
                              color="default"
                              variant="outlined"
                              style={{ width: "100%" }}
                            />
                          </TableCell>
                          <TableCell>Wager (custom)</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>

                <Card variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Tracking Setup
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderDefinitionItem("Type", "Manually Appended")}
                    </dl>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
      {/* @TODO Add IntegrationForm component here */}
    </>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(2),
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    paddingBottom: spacing(1),
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  gRoot: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: spacing(2),
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: palette.grey[200],
  },
  card: {
    marginBottom: spacing(2),
    maxWidth: 800,
    margin: "0 auto",
    "&.highlight": {
      animation: `$highlight .5s ease-in-out`,
    },
  },
  cardGroupTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    textTransform: "uppercase",
  },
  cardTitle: {
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  cardList: {
    width: "100%",
  },
  cardListItem: {
    display: "flex",
    gap: spacing(1),
    marginBottom: spacing(1),
    "& > dt": {
      fontWeight: "bold",
      minWidth: "12%",
    },
    "& > dd": {
      margin: 0,
      padding: 0,
      width: "90%",
    },
  },
  cardListItemTable: {
    width: "inherit",
  },
}));

export default IntegrationsShow;
