import { useMutation, useQuery } from "@apollo/client";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@material-ui/core";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  UserSubagreementsSearch as IUserSubagreementsSearchQuery,
  UserSubagreementsSearch_subagreements_edges
} from "../../generated/UserSubagreementsSearch";
import UserSubagreementsSearchQuery from "../../queries/UserSubagreementsSearchQuery";
import UpdateClickMutation from "../../mutations/UpdateClickMutation";
import { UpdateClick } from "../../generated/UpdateClick";
import ConfirmationAlert from "../ConfirmationAlert";
import { SubagreementStatus } from "../../generated/globalTypes";
import { ISO31661, ISO31662 } from "../../utils/ISO3166";

export interface IClickFormProps {
  clickId: string;
  currentOfferId: number;
  currentOfferName: string;
  currentPlayerId?: string | null;
  currentPlayerCity?: string | null;
  currentPlayerCountryCode?: string | null;
  currentPlayerStateCode?: string | null;
  currentPlayerPostalCode?: string | null;
  currentSubagreementId: string;
  onClose: () => void;
  open: boolean;
  userId: string;
}

const ClickForm = ({
  clickId,
  currentOfferId,
  currentOfferName,
  currentPlayerId,
  currentPlayerCity,
  currentPlayerCountryCode,
  currentPlayerStateCode,
  currentPlayerPostalCode,
  currentSubagreementId,
  onClose,
  open,
  userId
}: IClickFormProps) => {
  const [dirty, setDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [playerId, setPlayerId] = useState(currentPlayerId);
  const [playerCity, setPlayerCity] = useState(currentPlayerCity);
  const ogCurrentPlayerCountryCode = ISO31661
    .find((countryCode) => countryCode.alpha2 === currentPlayerCountryCode);
  const [playerCountryCode, setPlayerCountryCode] = useState(ogCurrentPlayerCountryCode);
  const countryCodeOptions = [...ISO31661];
  const [countryStateCodeOptions, setCountryStateCodeOptions] = useState([...ISO31662]);
  const ogCurrentPlayerStateCode = ISO31662
    .find((stateCode) => stateCode.code === currentPlayerStateCode);
  const [playerStateCode, setPlayerStateCode] = useState(ogCurrentPlayerStateCode);
  const [playerPostalCode, setPlayerPostalCode] = useState(currentPlayerPostalCode);

  const [subagreementId, setSubagreementId] = useState(currentSubagreementId);

  const { data, loading, refetch } = useQuery<IUserSubagreementsSearchQuery>(
    UserSubagreementsSearchQuery,
    { variables: { status: [SubagreementStatus.ACTIVE], userId } }
  );

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const [updateClick, { loading: loadingUpdate }] = useMutation<UpdateClick>(UpdateClickMutation);

  const setInputState = (
    setFunction: ((value: any) => void)
  ) => (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setFunction(event.target.value);
  };
  
  const handleCountrySelect = (selectedCountryCode: any) => {
    setDirty(true);
    setPlayerCountryCode(selectedCountryCode);
    const newCountryStateCodes = [...ISO31662]
      .filter(
        (stateCode) => selectedCountryCode && stateCode.parent === selectedCountryCode.alpha2
      );
    setCountryStateCodeOptions(newCountryStateCodes);
    setPlayerStateCode(undefined);
  }

  const handleStateSelect = (selectedStateCode: any) => {
    setDirty(true);
    setPlayerStateCode(selectedStateCode);
  }

  const handleSearch = (value: string) => {
    debouncedRefetch({ search: value || undefined });
  }

  const handleSelect = (subagreementEdge?: UserSubagreementsSearch_subagreements_edges) => {
    setDirty(true);
    if (subagreementEdge) {
      setSubagreementId(subagreementEdge.node.id);
    } else {
      setSubagreementId(currentSubagreementId);
      handleSearch("");
    }
  }

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setPlayerId(currentPlayerId);
    setPlayerCity(currentPlayerCity);
    setPlayerCountryCode(ogCurrentPlayerCountryCode);
    setPlayerStateCode(ogCurrentPlayerStateCode);
    setPlayerPostalCode(currentPlayerPostalCode);
    setSubagreementId(currentSubagreementId);
    onClose();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    updateClick({
      awaitRefetchQueries: true,
      refetchQueries: ["ClickQuery"],
      variables: {
        clickId,
        playerId,
        playerCity,
        playerCountryCode: playerCountryCode?.alpha2,
        playerStateCode: playerStateCode?.code,
        playerPostalCode,
        subagreementId
      }
    }).then(() => onCloseReset())
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Update Click</DialogTitle>

          <DialogContent>
            <TextField
              fullWidth
              label="Player ID"
              onChange={setInputState(setPlayerId)}
              value={playerId || ""}
              style={{ marginBottom: 16}}
            />
            <TextField
              fullWidth
              label="Player City"
              onChange={setInputState(setPlayerCity)}
              value={playerCity || ""}
              style={{ marginBottom: 16}}
            />
            <Autocomplete
              value={playerCountryCode || null}
              options={countryCodeOptions}
              getOptionLabel={option => option ? `${option.name} (${option.alpha2})` : ""}
              style={{ marginBottom: 16 }}
              onChange={(_, selectedCountry) => handleCountrySelect(selectedCountry)}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Player Country Code"
                />
              )}
              renderOption={option => (
                option? <span>{`${option.name} (${option.alpha2})`}</span> : ''
              )}
            />
            <Autocomplete
              value={playerStateCode || null}
              options={countryStateCodeOptions}
              getOptionLabel={option => option.name}
              style={{ marginBottom: 16 }}
              onChange={(_, selectedPlayerStateCode) => handleStateSelect(selectedPlayerStateCode)}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Player State Code"
                />
              )}
              renderOption={option => (
                <span>{`${option.name}`}</span>
              )}
            />
            
            <TextField
              fullWidth
              label="Player Postal Code"
              onChange={setInputState(setPlayerPostalCode)}
              value={playerPostalCode || ""}
              style={{ marginBottom: 16}}
            />

            <DialogContentText style={{ marginBottom: 0, marginTop: 16 }}>
              <Typography variant="subtitle1">
                <strong>Current Offer:</strong> {currentOfferName} ({currentOfferId})
              </Typography>
              <Typography variant="subtitle1">
                <strong>NOTE:</strong> Changing offer will recalculate payments for this click.
              </Typography>
            </DialogContentText>

            <Autocomplete
              getOptionLabel={option => option.node.offer.name}
              loading={loading}
              options={data && data.subagreements ? data.subagreements.edges : []}
              onChange={(_, subagreementEdge) => handleSelect(subagreementEdge || undefined)}
              onInputChange={(_, search) => handleSearch(search)}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Change offer..."
                />
              )}
              renderOption={option => (
                <span>{`${option.node.offer.name} (${option.node.offer.id})`}</span>
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button disabled={loadingUpdate || !open} onClick={handleClose}>
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={loadingUpdate}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
}

export default ClickForm;
