import React, { createRef, FormEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import {
  IOfferPoolRequirement,
  IOfferPoolRequirementsGroup,
} from "../OfferPoolForm/OfferPoolForm";
import InputOfferSearch from "../InputOfferSearch/InputOfferSearch";
import OfferPoolFormOfferRequirementSet from "../OfferPoolFormOfferRequirementSet/OfferPoolFormOfferRequirementSet";
import { OfferPoolRequirementType } from "../../generated/globalTypes";
import { OfferPool_offerPool_offerPool_requirements_offer } from "../../generated/OfferPool";

interface IOfferPoolFormOfferRequirementGroupProps
  extends IOfferPoolRequirementsGroup {
  brandId?: number;
  onChange: (requirements: IOfferPoolRequirementsGroup) => void;
  onDelete?: () => void;
  disabled?: boolean;
}

const MAX_REQUIREMENTS = Object.keys(OfferPoolRequirementType).length;

const OfferPoolFormOfferRequirementGroup = ({
  offer,
  brandId,
  requirements,
  onChange,
  onDelete,
  disabled,
}: IOfferPoolFormOfferRequirementGroupProps) => {
  const classes = useStyles();
  const [offerPoolRequirements, setOfferPoolRequirements] = useState<
    IOfferPoolRequirement[]
  >(requirements as IOfferPoolRequirement[]);
  const [disabledRequirementTypes, setDisabledRequirementTypes] = useState<
    OfferPoolRequirementType[]
  >(
    [...requirements].map((r) => r.requirementType as OfferPoolRequirementType)
  );
  const countryReq = requirements.find(
    (r) => r.requirementType === OfferPoolRequirementType.COUNTRY
  );
  const [offerPoolCountries, setOfferPoolCountries] = useState<string[]>(
    countryReq ? [...(countryReq?.requirementValue as string[])] : []
  );
  const cardRef = createRef<HTMLDivElement>();
  const scrollToCard = (card?: HTMLDivElement|null) => {
    if (card) {
      card.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  };

  const emitOnChange = (
    newOfferRequirements: IOfferPoolRequirement[],
    offer?: { id: number; name: string }
  ) => {
    const newDisabledRequirementTypes = [...newOfferRequirements]
      .map((r) => r.requirementType)
      .filter((r) => r !== undefined) as OfferPoolRequirementType[];
    setDisabledRequirementTypes(newDisabledRequirementTypes);

    const countryReq = newOfferRequirements.find(
      (r) => r.requirementType === OfferPoolRequirementType.COUNTRY
    );
    if (countryReq) {
      setOfferPoolCountries([...(countryReq.requirementValue as string[])]);
    }

    onChange({
      // FIXME: This is a hack to get types to work. We probably should not be re-using types from
      // API queries like this as they can quickly get out of sync with the data on the page.
      offer: offer as OfferPool_offerPool_offerPool_requirements_offer,
      requirements: newOfferRequirements,
    });
  };

  const onAddRequirement = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newOfferRequirements = [
      ...offerPoolRequirements,
      {
        requirementType: offerPoolRequirements.length
          ? Object.values(OfferPoolRequirementType).find(
              (r) => !disabledRequirementTypes.includes(r)
            )
          : OfferPoolRequirementType.COUNTRY,
        requirementValue: [],
      } as IOfferPoolRequirement,
    ];
    setOfferPoolRequirements(newOfferRequirements);
    emitOnChange(newOfferRequirements, offer);
    scrollToCard(cardRef?.current);
  };

  const onDeleteRequirement = (index: number) => {
    const newOfferRequirements = [...offerPoolRequirements].filter(
      (_, i) => i !== index
    );
    setOfferPoolRequirements(newOfferRequirements);
    emitOnChange(newOfferRequirements, offer);
  };

  const onRequirementSetChange = (
    requirement: IOfferPoolRequirement,
    index: number
  ) => {
    const newOfferRequirements = [...offerPoolRequirements];
    newOfferRequirements[index] = requirement;
    setOfferPoolRequirements(newOfferRequirements);
    emitOnChange(newOfferRequirements, offer);
  };

  // on Mount
  useEffect(() => {
    scrollToCard(cardRef.current);
  }, [cardRef]);

  return (
    <Card ref={cardRef} variant="outlined" className={classes.root}>
      <div className={classes.header}>
        <IconButton title="Remove this offer" size="small" onClick={onDelete}>
          <Close />
        </IconButton>
      </div>
      <InputOfferSearch
        className={classes.inputSearch}
        label="Offer"
        brandId={brandId}
        defaultValue={offer}
        disabled={disabled}
        onSelect={(offer) => {
          emitOnChange(offerPoolRequirements, offer);
        }}
      />
      {offerPoolRequirements.length > 0 ? (
        offerPoolRequirements.map(
          ({ requirementType, requirementValue }, index) => (
            <OfferPoolFormOfferRequirementSet
              key={requirementType}
              index={requirementType}
              requirementType={requirementType}
              requirementValue={requirementValue}
              offerPoolCountries={offerPoolCountries}
              onChange={(requirement) =>
                onRequirementSetChange(requirement, index)
              }
              onDelete={() => onDeleteRequirement(index)}
              disabled={disabled}
              disabledTypes={disabledRequirementTypes}
            />
          )
        )
      ) : (
        <div style={{ padding: 16 }}>
          <Typography color="textSecondary" align="center">
            No requirements added to this offer.
          </Typography>
        </div>
      )}
      <Button
        fullWidth
        onClick={onAddRequirement}
        disabled={
          disabledRequirementTypes.length >= MAX_REQUIREMENTS ||
          offerPoolRequirements.length >= MAX_REQUIREMENTS
        }
      >
        <Add />
        Requirement
      </Button>
    </Card>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1, 2),
    marginBottom: spacing(1),
    position: "relative",
  },
  header: {
    position: "absolute",
    top: spacing(1),
    right: spacing(1),
  },
  inputSearch: {
    width: "calc(100% - 48px - 0.5em)",
  },
}));

export default OfferPoolFormOfferRequirementGroup;
