import React, { FormEvent, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ConfirmationAlert from "../ConfirmationAlert";
import { IntegrationType } from "../../generated/globalTypes";
import { useMutation } from "@apollo/client";
import CreateIntegrationMutation from "../../mutations/CreateIntegrationMutation";
import IntegrationsStatusChip from "../IntegrationsStatusChip/IntegrationsStatusChip";

interface IIntegrationsFormProps {
  type: "edit" | "create";
  loading?: boolean;
  onCancel?: () => void;
}

interface IIntegrationsFormCreateProps extends IIntegrationsFormProps {
  id?: undefined;
  accountId?: undefined;
  active?: undefined;
  externalId?: undefined;
  integrationType?: undefined | IntegrationType;
}

interface IIntegrationsFormUpdateProps extends IIntegrationsFormProps {
  id: string;
  accountId: string;
  active: boolean;
  externalId: string;
  integrationType: IntegrationType;
}

const IntegrationsForm = ({
  type,
  id,
  accountId,
  active,
  externalId,
  integrationType,
  onCancel,
}: IIntegrationsFormCreateProps | IIntegrationsFormUpdateProps) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [accountIdValue, setAccountIdValue] = useState<string | undefined>(
    accountId
  );
  const [externalIdValue, setExternalIdValue] = useState<string | undefined>(
    externalId
  );
  const [integrationTypeValue, setIntegrationTypeValue] = useState<string | undefined>(
    integrationType || ""
  );
  const [accessTokenValue, setAccessTokenValue] = useState("");

  const [upsertIntegration, { loading }] = useMutation(
    CreateIntegrationMutation
  );

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    upsertIntegration({
      variables: {
        accessToken: accessTokenValue,
        accountId: accountIdValue,
        externalId: externalIdValue,
        integrationType: integrationTypeValue,
      },
    }).then(() => {
      cancelAndReset();
    });
  };

  const cancelAndReset = () => {
    setDirty(false);
    setAccountIdValue("");
    setExternalIdValue("");
    setIntegrationTypeValue("");
    setAccessTokenValue("");

    onCancel && onCancel();
  };

  const handleDiscard = () => {
    setConfirmOpen(false);
    cancelAndReset();
  };

  const onCancelAndReset = () => {
    dirty ? setConfirmOpen(true) : cancelAndReset();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={classes.formTitle}>
        <Typography variant="h2">
          {type === "edit" ? "Edit Integration" : "Create Integration"}
        </Typography>
      </div>

      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <div className={classes.cardTitle}>
            <Typography color="textSecondary" variant="body1">Details</Typography>
            <IntegrationsStatusChip integrationId={id} active={active} />
          </div>
          <FormControl
            fullWidth
            variant="standard"
            className={classes.formControl}
          >
            <InputLabel id="integrationType">Type</InputLabel>
            <Select
              label="Integration Type"
              labelId="integrationType"
              value={integrationTypeValue}
              onChange={(event) => {
                setDirty(true);
                setIntegrationTypeValue(event.target.value as IntegrationType);
              }}
              required
              disabled={loading}
            >
              <MenuItem value={undefined} disabled={true}>
                Type
              </MenuItem>
              {Object.keys(IntegrationType).map((integrationType, i) => (
                <MenuItem value={integrationType} key={i}>
                  {integrationType.slice(0, 1).toUpperCase() +
                    integrationType.toLowerCase().slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="integrationAccountId"
            className={classes.formControl}
            label="Account ID"
            fullWidth
            required
            onChange={(event) => {
              setDirty(true);
              setAccountIdValue(event.target.value);
            }}
            value={accountIdValue}
            disabled={loading}
          />
          <TextField
            id="integrationExternalId"
            className={classes.formControl}
            label="External ID"
            fullWidth
            required
            onChange={(event) => {
              setDirty(true);
              setExternalIdValue(event.target.value);
            }}
            value={externalIdValue}
            disabled={loading}
          />
          <TextField
            id="integrationAccessToken"
            className={classes.formControl}
            label="Access Token"
            fullWidth
            required
            onChange={(event) => {
              setDirty(true);
              setAccessTokenValue(event.target.value);
            }}
            value={accessTokenValue}
            disabled={loading}
          />
        </CardContent>
        <CardActions>
          <Button
            type="button"
            color="default"
            variant="outlined"
            onClick={onCancelAndReset}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "edit" ? "Update" : "Create"}
          </Button>
        </CardActions>
      </Card>
      <ConfirmationAlert
        title="Unsaved Changes"
        content="Closing this form will lose any unsaved progress."
        open={confirmOpen}
        positiveAction="Discard"
        onNegative={() => setConfirmOpen(false)}
        onPositive={handleDiscard}
      />
    </form>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  formTitle: {
    marginBottom: spacing(3),

    "& h2": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  formControl: {
    paddingBottom: spacing(2),
  },
  card: {
    marginBottom: spacing(2),
    "&.highlight": {
      animation: `$highlight .5s ease-in-out`,
    },
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,

    "& .MuiTypography-root": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
  helpText: {
    fontSize: 12,
    marginTop: spacing(1),
    color: palette.grey[600],
  },
}));

export default IntegrationsForm;
