import { gql } from "@apollo/client";

const ClickQuery = gql`
  query Click($clickId: ID!) {
    click(clickId: $clickId) {
      click {
        id
        destination
        playerId
        playerCountryCode,
        playerStateCode,
        playerCity,
        playerPostalCode,
        s1
        s2
        s3
        s4
        s5
        subagreement {
          offer {
            id
            name
          }
          id
          user { id }
        }
        trackReferer
        trackIp
        trackCurrentUrl
        trackUserAgent
        tracking {
          id
          creative {
            name
            offer {
              brand {
                advertiser {
                  name
                }
                name
              }
              name
            }
          }
          subagreement {
            user {
              name
            }
          }
        }
      }
      error {
        errorType
      }
    }
  }
`;

export default ClickQuery;
