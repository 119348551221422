export const ISO31661 = [
  { name: "Andorra", state: "assigned", alpha2: "AD", alpha3: "AND", numeric: "020" },
  { name: "United Arab Emirates", state: "assigned", alpha2: "AE", alpha3: "ARE", numeric: "784" },
  { name: "Afghanistan", state: "assigned", alpha2: "AF", alpha3: "AFG", numeric: "004" },
  { name: "Antigua and Barbuda", state: "assigned", alpha2: "AG", alpha3: "ATG", numeric: "028" },
  { name: "Anguilla", state: "assigned", alpha2: "AI", alpha3: "AIA", numeric: "660" },
  { name: "Albania", state: "assigned", alpha2: "AL", alpha3: "ALB", numeric: "008" },
  { name: "Armenia", state: "assigned", alpha2: "AM", alpha3: "ARM", numeric: "051" },
  { name: "Angola", state: "assigned", alpha2: "AO", alpha3: "AGO", numeric: "024" },
  { name: "Antarctica", state: "assigned", alpha2: "AQ", alpha3: "ATA", numeric: "010" },
  { name: "Argentina", state: "assigned", alpha2: "AR", alpha3: "ARG", numeric: "032" },
  { name: "American Samoa", state: "assigned", alpha2: "AS", alpha3: "ASM", numeric: "016" },
  { name: "Austria", state: "assigned", alpha2: "AT", alpha3: "AUT", numeric: "040" },
  { name: "Australia", state: "assigned", alpha2: "AU", alpha3: "AUS", numeric: "036" },
  { name: "Aruba", state: "assigned", alpha2: "AW", alpha3: "ABW", numeric: "533" },
  { name: "Åland Islands", state: "assigned", alpha2: "AX", alpha3: "ALA", numeric: "248" },
  { name: "Azerbaijan", state: "assigned", alpha2: "AZ", alpha3: "AZE", numeric: "031" },
  { name: "Bosnia and Herzegovina", state: "assigned", alpha2: "BA", alpha3: "BIH", numeric: "070" },
  { name: "Barbados", state: "assigned", alpha2: "BB", alpha3: "BRB", numeric: "052" },
  { name: "Bangladesh", state: "assigned", alpha2: "BD", alpha3: "BGD", numeric: "050" },
  { name: "Belgium", state: "assigned", alpha2: "BE", alpha3: "BEL", numeric: "056" },
  { name: "Burkina Faso", state: "assigned", alpha2: "BF", alpha3: "BFA", numeric: "854" },
  { name: "Bulgaria", state: "assigned", alpha2: "BG", alpha3: "BGR", numeric: "100" },
  { name: "Bahrain", state: "assigned", alpha2: "BH", alpha3: "BHR", numeric: "048" },
  { name: "Burundi", state: "assigned", alpha2: "BI", alpha3: "BDI", numeric: "108" },
  { name: "Benin", state: "assigned", alpha2: "BJ", alpha3: "BEN", numeric: "204" },
  { name: "Saint Barthélemy", state: "assigned", alpha2: "BL", alpha3: "BLM", numeric: "652" },
  { name: "Bermuda", state: "assigned", alpha2: "BM", alpha3: "BMU", numeric: "060" },
  { name: "Brunei Darussalam", state: "assigned", alpha2: "BN", alpha3: "BRN", numeric: "096" },
  { name: "Bolivia (Plurinational State of)", state: "assigned", alpha2: "BO", alpha3: "BOL", numeric: "068" },
  { name: "Bonaire, Sint Eustatius and Saba", state: "assigned", alpha2: "BQ", alpha3: "BES", numeric: "535" },
  { name: "Brazil", state: "assigned", alpha2: "BR", alpha3: "BRA", numeric: "076" },
  { name: "Bahamas", state: "assigned", alpha2: "BS", alpha3: "BHS", numeric: "044" },
  { name: "Bhutan", state: "assigned", alpha2: "BT", alpha3: "BTN", numeric: "064" },
  { name: "Bouvet Island", state: "assigned", alpha2: "BV", alpha3: "BVT", numeric: "074" },
  { name: "Botswana", state: "assigned", alpha2: "BW", alpha3: "BWA", numeric: "072" },
  { name: "Belarus", state: "assigned", alpha2: "BY", alpha3: "BLR", numeric: "112" },
  { name: "Belize", state: "assigned", alpha2: "BZ", alpha3: "BLZ", numeric: "084" },
  { name: "Canada", state: "assigned", alpha2: "CA", alpha3: "CAN", numeric: "124" },
  { name: "Cocos (Keeling) Islands", state: "assigned", alpha2: "CC", alpha3: "CCK", numeric: "166" },
  { name: "Congo, Democratic Republic of the", state: "assigned", alpha2: "CD", alpha3: "COD", numeric: "180" },
  { name: "Central African Republic", state: "assigned", alpha2: "CF", alpha3: "CAF", numeric: "140" },
  { name: "Congo", state: "assigned", alpha2: "CG", alpha3: "COG", numeric: "178" },
  { name: "Switzerland", state: "assigned", alpha2: "CH", alpha3: "CHE", numeric: "756" },
  { name: "Côte d'Ivoire", state: "assigned", alpha2: "CI", alpha3: "CIV", numeric: "384" },
  { name: "Cook Islands", state: "assigned", alpha2: "CK", alpha3: "COK", numeric: "184" },
  { name: "Chile", state: "assigned", alpha2: "CL", alpha3: "CHL", numeric: "152" },
  { name: "Cameroon", state: "assigned", alpha2: "CM", alpha3: "CMR", numeric: "120" },
  { name: "China", state: "assigned", alpha2: "CN", alpha3: "CHN", numeric: "156" },
  { name: "Colombia", state: "assigned", alpha2: "CO", alpha3: "COL", numeric: "170" },
  { name: "Costa Rica", state: "assigned", alpha2: "CR", alpha3: "CRI", numeric: "188" },
  { name: "Cuba", state: "assigned", alpha2: "CU", alpha3: "CUB", numeric: "192" },
  { name: "Cabo Verde", state: "assigned", alpha2: "CV", alpha3: "CPV", numeric: "132" },
  { name: "Curaçao", state: "assigned", alpha2: "CW", alpha3: "CUW", numeric: "531" },
  { name: "Christmas Island", state: "assigned", alpha2: "CX", alpha3: "CXR", numeric: "162" },
  { name: "Cyprus", state: "assigned", alpha2: "CY", alpha3: "CYP", numeric: "196" },
  { name: "Czechia", state: "assigned", alpha2: "CZ", alpha3: "CZE", numeric: "203" },
  { name: "Germany", state: "assigned", alpha2: "DE", alpha3: "DEU", numeric: "276" },
  { name: "Djibouti", state: "assigned", alpha2: "DJ", alpha3: "DJI", numeric: "262" },
  { name: "Denmark", state: "assigned", alpha2: "DK", alpha3: "DNK", numeric: "208" },
  { name: "Dominica", state: "assigned", alpha2: "DM", alpha3: "DMA", numeric: "212" },
  { name: "Dominican Republic", state: "assigned", alpha2: "DO", alpha3: "DOM", numeric: "214" },
  { name: "Algeria", state: "assigned", alpha2: "DZ", alpha3: "DZA", numeric: "012" },
  { name: "Ecuador", state: "assigned", alpha2: "EC", alpha3: "ECU", numeric: "218" },
  { name: "Estonia", state: "assigned", alpha2: "EE", alpha3: "EST", numeric: "233" },
  { name: "Egypt", state: "assigned", alpha2: "EG", alpha3: "EGY", numeric: "818" },
  { name: "Western Sahara", state: "assigned", alpha2: "EH", alpha3: "ESH", numeric: "732" },
  { name: "Eritrea", state: "assigned", alpha2: "ER", alpha3: "ERI", numeric: "232" },
  { name: "Spain", state: "assigned", alpha2: "ES", alpha3: "ESP", numeric: "724" },
  { name: "Ethiopia", state: "assigned", alpha2: "ET", alpha3: "ETH", numeric: "231" },
  { name: "Finland", state: "assigned", alpha2: "FI", alpha3: "FIN", numeric: "246" },
  { name: "Fiji", state: "assigned", alpha2: "FJ", alpha3: "FJI", numeric: "242" },
  { name: "Falkland Islands (Malvinas)", state: "assigned", alpha2: "FK", alpha3: "FLK", numeric: "238" },
  { name: "Micronesia (Federated States of)", state: "assigned", alpha2: "FM", alpha3: "FSM", numeric: "583" },
  { name: "Faroe Islands", state: "assigned", alpha2: "FO", alpha3: "FRO", numeric: "234" },
  { name: "France", state: "assigned", alpha2: "FR", alpha3: "FRA", numeric: "250" },
  { name: "Gabon", state: "assigned", alpha2: "GA", alpha3: "GAB", numeric: "266" },
  { name: "United Kingdom of Great Britain and Northern Ireland", state: "assigned", alpha2: "GB", alpha3: "GBR", numeric: "826" },
  { name: "Grenada", state: "assigned", alpha2: "GD", alpha3: "GRD", numeric: "308" },
  { name: "Georgia", state: "assigned", alpha2: "GE", alpha3: "GEO", numeric: "268" },
  { name: "French Guiana", state: "assigned", alpha2: "GF", alpha3: "GUF", numeric: "254" },
  { name: "Guernsey", state: "assigned", alpha2: "GG", alpha3: "GGY", numeric: "831" },
  { name: "Ghana", state: "assigned", alpha2: "GH", alpha3: "GHA", numeric: "288" },
  { name: "Gibraltar", state: "assigned", alpha2: "GI", alpha3: "GIB", numeric: "292" },
  { name: "Greenland", state: "assigned", alpha2: "GL", alpha3: "GRL", numeric: "304" },
  { name: "Gambia", state: "assigned", alpha2: "GM", alpha3: "GMB", numeric: "270" },
  { name: "Guinea", state: "assigned", alpha2: "GN", alpha3: "GIN", numeric: "324" },
  { name: "Guadeloupe", state: "assigned", alpha2: "GP", alpha3: "GLP", numeric: "312" },
  { name: "Equatorial Guinea", state: "assigned", alpha2: "GQ", alpha3: "GNQ", numeric: "226" },
  { name: "Greece", state: "assigned", alpha2: "GR", alpha3: "GRC", numeric: "300" },
  { name: "South Georgia and the South Sandwich Islands", state: "assigned", alpha2: "GS", alpha3: "SGS", numeric: "239" },
  { name: "Guatemala", state: "assigned", alpha2: "GT", alpha3: "GTM", numeric: "320" },
  { name: "Guam", state: "assigned", alpha2: "GU", alpha3: "GUM", numeric: "316" },
  { name: "Guinea-Bissau", state: "assigned", alpha2: "GW", alpha3: "GNB", numeric: "624" },
  { name: "Guyana", state: "assigned", alpha2: "GY", alpha3: "GUY", numeric: "328" },
  { name: "Hong Kong", state: "assigned", alpha2: "HK", alpha3: "HKG", numeric: "344" },
  { name: "Heard Island and McDonald Islands", state: "assigned", alpha2: "HM", alpha3: "HMD", numeric: "334" },
  { name: "Honduras", state: "assigned", alpha2: "HN", alpha3: "HND", numeric: "340" },
  { name: "Croatia", state: "assigned", alpha2: "HR", alpha3: "HRV", numeric: "191" },
  { name: "Haiti", state: "assigned", alpha2: "HT", alpha3: "HTI", numeric: "332" },
  { name: "Hungary", state: "assigned", alpha2: "HU", alpha3: "HUN", numeric: "348" },
  { name: "Indonesia", state: "assigned", alpha2: "ID", alpha3: "IDN", numeric: "360" },
  { name: "Ireland", state: "assigned", alpha2: "IE", alpha3: "IRL", numeric: "372" },
  { name: "Israel", state: "assigned", alpha2: "IL", alpha3: "ISR", numeric: "376" },
  { name: "Isle of Man", state: "assigned", alpha2: "IM", alpha3: "IMN", numeric: "833" },
  { name: "India", state: "assigned", alpha2: "IN", alpha3: "IND", numeric: "356" },
  { name: "British Indian Ocean Territory", state: "assigned", alpha2: "IO", alpha3: "IOT", numeric: "086" },
  { name: "Iraq", state: "assigned", alpha2: "IQ", alpha3: "IRQ", numeric: "368" },
  { name: "Iran (Islamic Republic of)", state: "assigned", alpha2: "IR", alpha3: "IRN", numeric: "364" },
  { name: "Iceland", state: "assigned", alpha2: "IS", alpha3: "ISL", numeric: "352" },
  { name: "Italy", state: "assigned", alpha2: "IT", alpha3: "ITA", numeric: "380" },
  { name: "Jersey", state: "assigned", alpha2: "JE", alpha3: "JEY", numeric: "832" },
  { name: "Jamaica", state: "assigned", alpha2: "JM", alpha3: "JAM", numeric: "388" },
  { name: "Jordan", state: "assigned", alpha2: "JO", alpha3: "JOR", numeric: "400" },
  { name: "Japan", state: "assigned", alpha2: "JP", alpha3: "JPN", numeric: "392" },
  { name: "Kenya", state: "assigned", alpha2: "KE", alpha3: "KEN", numeric: "404" },
  { name: "Kyrgyzstan", state: "assigned", alpha2: "KG", alpha3: "KGZ", numeric: "417" },
  { name: "Cambodia", state: "assigned", alpha2: "KH", alpha3: "KHM", numeric: "116" },
  { name: "Kiribati", state: "assigned", alpha2: "KI", alpha3: "KIR", numeric: "296" },
  { name: "Comoros", state: "assigned", alpha2: "KM", alpha3: "COM", numeric: "174" },
  { name: "Saint Kitts and Nevis", state: "assigned", alpha2: "KN", alpha3: "KNA", numeric: "659" },
  { name: "Korea (Democratic People's Republic of)", state: "assigned", alpha2: "KP", alpha3: "PRK", numeric: "408" },
  { name: "Korea, Republic of", state: "assigned", alpha2: "KR", alpha3: "KOR", numeric: "410" },
  { name: "Kuwait", state: "assigned", alpha2: "KW", alpha3: "KWT", numeric: "414" },
  { name: "Cayman Islands", state: "assigned", alpha2: "KY", alpha3: "CYM", numeric: "136" },
  { name: "Kazakhstan", state: "assigned", alpha2: "KZ", alpha3: "KAZ", numeric: "398" },
  { name: "Lao People's Democratic Republic", state: "assigned", alpha2: "LA", alpha3: "LAO", numeric: "418" },
  { name: "Lebanon", state: "assigned", alpha2: "LB", alpha3: "LBN", numeric: "422" },
  { name: "Saint Lucia", state: "assigned", alpha2: "LC", alpha3: "LCA", numeric: "662" },
  { name: "Liechtenstein", state: "assigned", alpha2: "LI", alpha3: "LIE", numeric: "438" },
  { name: "Sri Lanka", state: "assigned", alpha2: "LK", alpha3: "LKA", numeric: "144" },
  { name: "Liberia", state: "assigned", alpha2: "LR", alpha3: "LBR", numeric: "430" },
  { name: "Lesotho", state: "assigned", alpha2: "LS", alpha3: "LSO", numeric: "426" },
  { name: "Lithuania", state: "assigned", alpha2: "LT", alpha3: "LTU", numeric: "440" },
  { name: "Luxembourg", state: "assigned", alpha2: "LU", alpha3: "LUX", numeric: "442" },
  { name: "Latvia", state: "assigned", alpha2: "LV", alpha3: "LVA", numeric: "428" },
  { name: "Libya", state: "assigned", alpha2: "LY", alpha3: "LBY", numeric: "434" },
  { name: "Morocco", state: "assigned", alpha2: "MA", alpha3: "MAR", numeric: "504" },
  { name: "Monaco", state: "assigned", alpha2: "MC", alpha3: "MCO", numeric: "492" },
  { name: "Moldova, Republic of", state: "assigned", alpha2: "MD", alpha3: "MDA", numeric: "498" },
  { name: "Montenegro", state: "assigned", alpha2: "ME", alpha3: "MNE", numeric: "499" },
  { name: "Saint Martin (French part)", state: "assigned", alpha2: "MF", alpha3: "MAF", numeric: "663" },
  { name: "Madagascar", state: "assigned", alpha2: "MG", alpha3: "MDG", numeric: "450" },
  { name: "Marshall Islands", state: "assigned", alpha2: "MH", alpha3: "MHL", numeric: "584" },
  { name: "North Macedonia", state: "assigned", alpha2: "MK", alpha3: "MKD", numeric: "807" },
  { name: "Mali", state: "assigned", alpha2: "ML", alpha3: "MLI", numeric: "466" },
  { name: "Myanmar", state: "assigned", alpha2: "MM", alpha3: "MMR", numeric: "104" },
  { name: "Mongolia", state: "assigned", alpha2: "MN", alpha3: "MNG", numeric: "496" },
  { name: "Macao", state: "assigned", alpha2: "MO", alpha3: "MAC", numeric: "446" },
  { name: "Northern Mariana Islands", state: "assigned", alpha2: "MP", alpha3: "MNP", numeric: "580" },
  { name: "Martinique", state: "assigned", alpha2: "MQ", alpha3: "MTQ", numeric: "474" },
  { name: "Mauritania", state: "assigned", alpha2: "MR", alpha3: "MRT", numeric: "478" },
  { name: "Montserrat", state: "assigned", alpha2: "MS", alpha3: "MSR", numeric: "500" },
  { name: "Malta", state: "assigned", alpha2: "MT", alpha3: "MLT", numeric: "470" },
  { name: "Mauritius", state: "assigned", alpha2: "MU", alpha3: "MUS", numeric: "480" },
  { name: "Maldives", state: "assigned", alpha2: "MV", alpha3: "MDV", numeric: "462" },
  { name: "Malawi", state: "assigned", alpha2: "MW", alpha3: "MWI", numeric: "454" },
  { name: "Mexico", state: "assigned", alpha2: "MX", alpha3: "MEX", numeric: "484" },
  { name: "Malaysia", state: "assigned", alpha2: "MY", alpha3: "MYS", numeric: "458" },
  { name: "Mozambique", state: "assigned", alpha2: "MZ", alpha3: "MOZ", numeric: "508" },
  { name: "Namibia", state: "assigned", alpha2: "NA", alpha3: "NAM", numeric: "516" },
  { name: "New Caledonia", state: "assigned", alpha2: "NC", alpha3: "NCL", numeric: "540" },
  { name: "Niger", state: "assigned", alpha2: "NE", alpha3: "NER", numeric: "562" },
  { name: "Norfolk Island", state: "assigned", alpha2: "NF", alpha3: "NFK", numeric: "574" },
  { name: "Nigeria", state: "assigned", alpha2: "NG", alpha3: "NGA", numeric: "566" },
  { name: "Nicaragua", state: "assigned", alpha2: "NI", alpha3: "NIC", numeric: "558" },
  { name: "Netherlands, Kingdom of the", state: "assigned", alpha2: "NL", alpha3: "NLD", numeric: "528" },
  { name: "Norway", state: "assigned", alpha2: "NO", alpha3: "NOR", numeric: "578" },
  { name: "Nepal", state: "assigned", alpha2: "NP", alpha3: "NPL", numeric: "524" },
  { name: "Nauru", state: "assigned", alpha2: "NR", alpha3: "NRU", numeric: "520" },
  { name: "Niue", state: "assigned", alpha2: "NU", alpha3: "NIU", numeric: "570" },
  { name: "New Zealand", state: "assigned", alpha2: "NZ", alpha3: "NZL", numeric: "554" },
  { name: "Oman", state: "assigned", alpha2: "OM", alpha3: "OMN", numeric: "512" },
  { name: "Panama", state: "assigned", alpha2: "PA", alpha3: "PAN", numeric: "591" },
  { name: "Peru", state: "assigned", alpha2: "PE", alpha3: "PER", numeric: "604" },
  { name: "French Polynesia", state: "assigned", alpha2: "PF", alpha3: "PYF", numeric: "258" },
  { name: "Papua New Guinea", state: "assigned", alpha2: "PG", alpha3: "PNG", numeric: "598" },
  { name: "Philippines", state: "assigned", alpha2: "PH", alpha3: "PHL", numeric: "608" },
  { name: "Pakistan", state: "assigned", alpha2: "PK", alpha3: "PAK", numeric: "586" },
  { name: "Poland", state: "assigned", alpha2: "PL", alpha3: "POL", numeric: "616" },
  { name: "Saint Pierre and Miquelon", state: "assigned", alpha2: "PM", alpha3: "SPM", numeric: "666" },
  { name: "Pitcairn", state: "assigned", alpha2: "PN", alpha3: "PCN", numeric: "612" },
  { name: "Puerto Rico", state: "assigned", alpha2: "PR", alpha3: "PRI", numeric: "630" },
  { name: "Palestine, State of", state: "assigned", alpha2: "PS", alpha3: "PSE", numeric: "275" },
  { name: "Portugal", state: "assigned", alpha2: "PT", alpha3: "PRT", numeric: "620" },
  { name: "Palau", state: "assigned", alpha2: "PW", alpha3: "PLW", numeric: "585" },
  { name: "Paraguay", state: "assigned", alpha2: "PY", alpha3: "PRY", numeric: "600" },
  { name: "Qatar", state: "assigned", alpha2: "QA", alpha3: "QAT", numeric: "634" },
  { name: "Réunion", state: "assigned", alpha2: "RE", alpha3: "REU", numeric: "638" },
  { name: "Romania", state: "assigned", alpha2: "RO", alpha3: "ROU", numeric: "642" },
  { name: "Serbia", state: "assigned", alpha2: "RS", alpha3: "SRB", numeric: "688" },
  { name: "Russian Federation", state: "assigned", alpha2: "RU", alpha3: "RUS", numeric: "643" },
  { name: "Rwanda", state: "assigned", alpha2: "RW", alpha3: "RWA", numeric: "646" },
  { name: "Saudi Arabia", state: "assigned", alpha2: "SA", alpha3: "SAU", numeric: "682" },
  { name: "Solomon Islands", state: "assigned", alpha2: "SB", alpha3: "SLB", numeric: "090" },
  { name: "Seychelles", state: "assigned", alpha2: "SC", alpha3: "SYC", numeric: "690" },
  { name: "Sudan", state: "assigned", alpha2: "SD", alpha3: "SDN", numeric: "729" },
  { name: "Sweden", state: "assigned", alpha2: "SE", alpha3: "SWE", numeric: "752" },
  { name: "Singapore", state: "assigned", alpha2: "SG", alpha3: "SGP", numeric: "702" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", state: "assigned", alpha2: "SH", alpha3: "SHN", numeric: "654" },
  { name: "Slovenia", state: "assigned", alpha2: "SI", alpha3: "SVN", numeric: "705" },
  { name: "Svalbard and Jan Mayen", state: "assigned", alpha2: "SJ", alpha3: "SJM", numeric: "744" },
  { name: "Slovakia", state: "assigned", alpha2: "SK", alpha3: "SVK", numeric: "703" },
  { name: "Sierra Leone", state: "assigned", alpha2: "SL", alpha3: "SLE", numeric: "694" },
  { name: "San Marino", state: "assigned", alpha2: "SM", alpha3: "SMR", numeric: "674" },
  { name: "Senegal", state: "assigned", alpha2: "SN", alpha3: "SEN", numeric: "686" },
  { name: "Somalia", state: "assigned", alpha2: "SO", alpha3: "SOM", numeric: "706" },
  { name: "Suriname", state: "assigned", alpha2: "SR", alpha3: "SUR", numeric: "740" },
  { name: "South Sudan", state: "assigned", alpha2: "SS", alpha3: "SSD", numeric: "728" },
  { name: "Sao Tome and Principe", state: "assigned", alpha2: "ST", alpha3: "STP", numeric: "678" },
  { name: "El Salvador", state: "assigned", alpha2: "SV", alpha3: "SLV", numeric: "222" },
  { name: "Sint Maarten (Dutch part)", state: "assigned", alpha2: "SX", alpha3: "SXM", numeric: "534" },
  { name: "Syrian Arab Republic", state: "assigned", alpha2: "SY", alpha3: "SYR", numeric: "760" },
  { name: "Eswatini", state: "assigned", alpha2: "SZ", alpha3: "SWZ", numeric: "748" },
  { name: "Turks and Caicos Islands", state: "assigned", alpha2: "TC", alpha3: "TCA", numeric: "796" },
  { name: "Chad", state: "assigned", alpha2: "TD", alpha3: "TCD", numeric: "148" },
  { name: "French Southern Territories", state: "assigned", alpha2: "TF", alpha3: "ATF", numeric: "260" },
  { name: "Togo", state: "assigned", alpha2: "TG", alpha3: "TGO", numeric: "768" },
  { name: "Thailand", state: "assigned", alpha2: "TH", alpha3: "THA", numeric: "764" },
  { name: "Tajikistan", state: "assigned", alpha2: "TJ", alpha3: "TJK", numeric: "762" },
  { name: "Tokelau", state: "assigned", alpha2: "TK", alpha3: "TKL", numeric: "772" },
  { name: "Timor-Leste", state: "assigned", alpha2: "TL", alpha3: "TLS", numeric: "626" },
  { name: "Turkmenistan", state: "assigned", alpha2: "TM", alpha3: "TKM", numeric: "795" },
  { name: "Tunisia", state: "assigned", alpha2: "TN", alpha3: "TUN", numeric: "788" },
  { name: "Tonga", state: "assigned", alpha2: "TO", alpha3: "TON", numeric: "776" },
  { name: "Türkiye", state: "assigned", alpha2: "TR", alpha3: "TUR", numeric: "792" },
  { name: "Trinidad and Tobago", state: "assigned", alpha2: "TT", alpha3: "TTO", numeric: "780" },
  { name: "Tuvalu", state: "assigned", alpha2: "TV", alpha3: "TUV", numeric: "798" },
  { name: "Taiwan, Province of China", state: "assigned", alpha2: "TW", alpha3: "TWN", numeric: "158" },
  { name: "Tanzania, United Republic of", state: "assigned", alpha2: "TZ", alpha3: "TZA", numeric: "834" },
  { name: "Ukraine", state: "assigned", alpha2: "UA", alpha3: "UKR", numeric: "804" },
  { name: "Uganda", state: "assigned", alpha2: "UG", alpha3: "UGA", numeric: "800" },
  { name: "United States Minor Outlying Islands", state: "assigned", alpha2: "UM", alpha3: "UMI", numeric: "581" },
  { name: "United States of America", state: "assigned", alpha2: "US", alpha3: "USA", numeric: "840" },
  { name: "Uruguay", state: "assigned", alpha2: "UY", alpha3: "URY", numeric: "858" },
  { name: "Uzbekistan", state: "assigned", alpha2: "UZ", alpha3: "UZB", numeric: "860" },
  { name: "Holy See", state: "assigned", alpha2: "VA", alpha3: "VAT", numeric: "336" },
  { name: "Saint Vincent and the Grenadines", state: "assigned", alpha2: "VC", alpha3: "VCT", numeric: "670" },
  { name: "Venezuela (Bolivarian Republic of)", state: "assigned", alpha2: "VE", alpha3: "VEN", numeric: "862" },
  { name: "Virgin Islands (British)", state: "assigned", alpha2: "VG", alpha3: "VGB", numeric: "092" },
  { name: "Virgin Islands (U.S.)", state: "assigned", alpha2: "VI", alpha3: "VIR", numeric: "850" },
  { name: "Viet Nam", state: "assigned", alpha2: "VN", alpha3: "VNM", numeric: "704" },
  { name: "Vanuatu", state: "assigned", alpha2: "VU", alpha3: "VUT", numeric: "548" },
  { name: "Wallis and Futuna", state: "assigned", alpha2: "WF", alpha3: "WLF", numeric: "876" },
  { name: "Samoa", state: "assigned", alpha2: "WS", alpha3: "WSM", numeric: "882" },
  { name: "Yemen", state: "assigned", alpha2: "YE", alpha3: "YEM", numeric: "887" },
  { name: "Mayotte", state: "assigned", alpha2: "YT", alpha3: "MYT", numeric: "175" },
  { name: "South Africa", state: "assigned", alpha2: "ZA", alpha3: "ZAF", numeric: "710" },
  { name: "Zambia", state: "assigned", alpha2: "ZM", alpha3: "ZMB", numeric: "894" },
  { name: "Zimbabwe", state: "assigned", alpha2: "ZW", alpha3: "ZWE", numeric: "716" },
];

export const ISO31662 = [
  { code: "AD_02", name: "Canillo", parent: "AD" },
  { code: "AD_03", name: "Encamp", parent: "AD" },
  { code: "AD_04", name: "La Massana", parent: "AD" },
  { code: "AD_05", name: "Ordino", parent: "AD" },
  { code: "AD_06", name: "Sant Julià de Lòria", parent: "AD" },
  { code: "AD_07", name: "Andorra la Vella", parent: "AD" },
  { code: "AD_08", name: "Escaldes-Engordany", parent: "AD" },
  { code: "AE_AJ", name: "‘Ajmān", parent: "AE" },
  { code: "AE_AZ", name: "Abū Z̧aby", parent: "AE" },
  { code: "AE_DU", name: "Dubayy", parent: "AE" },
  { code: "AE_FU", name: "Al Fujayrah", parent: "AE" },
  { code: "AE_RK", name: "Ra’s al Khaymah", parent: "AE" },
  { code: "AE_SH", name: "Ash Shāriqah", parent: "AE" },
  { code: "AE_UQ", name: "Umm al Qaywayn", parent: "AE" },
  { code: "AF_BAL", name: "Balkh", parent: "AF" },
  { code: "AF_BAM", name: "Bāmyān", parent: "AF" },
  { code: "AF_BDG", name: "Bādghīs", parent: "AF" },
  { code: "AF_BDS", name: "Badakhshān", parent: "AF" },
  { code: "AF_BGL", name: "Baghlān", parent: "AF" },
  { code: "AF_DAY", name: "Dāykundī", parent: "AF" },
  { code: "AF_FRA", name: "Farāh", parent: "AF" },
  { code: "AF_FYB", name: "Fāryāb", parent: "AF" },
  { code: "AF_GHA", name: "Ghaznī", parent: "AF" },
  { code: "AF_GHO", name: "Ghōr", parent: "AF" },
  { code: "AF_HEL", name: "Helmand", parent: "AF" },
  { code: "AF_HER", name: "Herāt", parent: "AF" },
  { code: "AF_JOW", name: "Jowzjān", parent: "AF" },
  { code: "AF_KAB", name: "Kābul", parent: "AF" },
  { code: "AF_KAN", name: "Kandahār", parent: "AF" },
  { code: "AF_KAP", name: "Kāpīsā", parent: "AF" },
  { code: "AF_KDZ", name: "Kunduz", parent: "AF" },
  { code: "AF_KHO", name: "Khōst", parent: "AF" },
  { code: "AF_KNR", name: "Kunaṟ", parent: "AF" },
  { code: "AF_LAG", name: "Laghmān", parent: "AF" },
  { code: "AF_LOG", name: "Lōgar", parent: "AF" },
  { code: "AF_NAN", name: "Nangarhār", parent: "AF" },
  { code: "AF_NIM", name: "Nīmrōz", parent: "AF" },
  { code: "AF_NUR", name: "Nūristān", parent: "AF" },
  { code: "AF_PAN", name: "Panjshayr", parent: "AF" },
  { code: "AF_PAR", name: "Parwān", parent: "AF" },
  { code: "AF_PIA", name: "Paktiyā", parent: "AF" },
  { code: "AF_PKA", name: "Paktīkā", parent: "AF" },
  { code: "AF_SAM", name: "Samangān", parent: "AF" },
  { code: "AF_SAR", name: "Sar-e Pul", parent: "AF" },
  { code: "AF_TAK", name: "Takhār", parent: "AF" },
  { code: "AF_URU", name: "Uruzgān", parent: "AF" },
  { code: "AF_WAR", name: "Wardak", parent: "AF" },
  { code: "AF_ZAB", name: "Zābul", parent: "AF" },
  { code: "AG_03", name: "Saint George", parent: "AG" },
  { code: "AG_04", name: "Saint John", parent: "AG" },
  { code: "AG_05", name: "Saint Mary", parent: "AG" },
  { code: "AG_06", name: "Saint Paul", parent: "AG" },
  { code: "AG_07", name: "Saint Peter", parent: "AG" },
  { code: "AG_08", name: "Saint Philip", parent: "AG" },
  { code: "AG_10", name: "Barbuda", parent: "AG" },
  { code: "AG_11", name: "Redonda", parent: "AG" },
  { code: "AL_01", name: "Berat", parent: "AL" },
  { code: "AL_02", name: "Durrës", parent: "AL" },
  { code: "AL_03", name: "Elbasan", parent: "AL" },
  { code: "AL_04", name: "Fier", parent: "AL" },
  { code: "AL_05", name: "Gjirokastër", parent: "AL" },
  { code: "AL_06", name: "Korçë", parent: "AL" },
  { code: "AL_07", name: "Kukës", parent: "AL" },
  { code: "AL_08", name: "Lezhë", parent: "AL" },
  { code: "AL_09", name: "Dibër", parent: "AL" },
  { code: "AL_10", name: "Shkodër", parent: "AL" },
  { code: "AL_11", name: "Tiranë", parent: "AL" },
  { code: "AL_12", name: "Vlorë", parent: "AL" },
  { code: "AM_AG", name: "Aragac̣otn", parent: "AM" },
  { code: "AM_AR", name: "Ararat", parent: "AM" },
  { code: "AM_AV", name: "Armavir", parent: "AM" },
  { code: "AM_ER", name: "Erevan", parent: "AM" },
  { code: "AM_GR", name: "Geġark'unik'", parent: "AM" },
  { code: "AM_KT", name: "Kotayk'", parent: "AM" },
  { code: "AM_LO", name: "Loṙi", parent: "AM" },
  { code: "AM_SH", name: "Širak", parent: "AM" },
  { code: "AM_SU", name: "Syunik'", parent: "AM" },
  { code: "AM_TV", name: "Tavuš", parent: "AM" },
  { code: "AM_VD", name: "Vayoć Jor", parent: "AM" },
  { code: "AO_BGO", name: "Bengo", parent: "AO" },
  { code: "AO_BGU", name: "Benguela", parent: "AO" },
  { code: "AO_BIE", name: "Bié", parent: "AO" },
  { code: "AO_CAB", name: "Cabinda", parent: "AO" },
  { code: "AO_CCU", name: "Cuando Cubango", parent: "AO" },
  { code: "AO_CNN", name: "Cunene", parent: "AO" },
  { code: "AO_CNO", name: "Cuanza-Norte", parent: "AO" },
  { code: "AO_CUS", name: "Cuanza-Sul", parent: "AO" },
  { code: "AO_HUA", name: "Huambo", parent: "AO" },
  { code: "AO_HUI", name: "Huíla", parent: "AO" },
  { code: "AO_LNO", name: "Lunda-Norte", parent: "AO" },
  { code: "AO_LSU", name: "Lunda-Sul", parent: "AO" },
  { code: "AO_LUA", name: "Luanda", parent: "AO" },
  { code: "AO_MAL", name: "Malange", parent: "AO" },
  { code: "AO_MOX", name: "Moxico", parent: "AO" },
  { code: "AO_NAM", name: "Namibe", parent: "AO" },
  { code: "AO_UIG", name: "Uíge", parent: "AO" },
  { code: "AO_ZAI", name: "Zaire", parent: "AO" },
  { code: "AR_A", name: "Salta", parent: "AR" },
  { code: "AR_B", name: "Buenos Aires", parent: "AR" },
  { code: "AR_C", name: "Ciudad Autónoma de Buenos Aires", parent: "AR" },
  { code: "AR_D", name: "San Luis", parent: "AR" },
  { code: "AR_E", name: "Entre Ríos", parent: "AR" },
  { code: "AR_F", name: "La Rioja", parent: "AR" },
  { code: "AR_G", name: "Santiago del Estero", parent: "AR" },
  { code: "AR_H", name: "Chaco", parent: "AR" },
  { code: "AR_J", name: "San Juan", parent: "AR" },
  { code: "AR_K", name: "Catamarca", parent: "AR" },
  { code: "AR_L", name: "La Pampa", parent: "AR" },
  { code: "AR_M", name: "Mendoza", parent: "AR" },
  { code: "AR_N", name: "Misiones", parent: "AR" },
  { code: "AR_P", name: "Formosa", parent: "AR" },
  { code: "AR_Q", name: "Neuquén", parent: "AR" },
  { code: "AR_R", name: "Río Negro", parent: "AR" },
  { code: "AR_S", name: "Santa Fe", parent: "AR" },
  { code: "AR_T", name: "Tucumán", parent: "AR" },
  { code: "AR_U", name: "Chubut", parent: "AR" },
  { code: "AR_V", name: "Tierra del Fuego", parent: "AR" },
  { code: "AR_W", name: "Corrientes", parent: "AR" },
  { code: "AR_X", name: "Córdoba", parent: "AR" },
  { code: "AR_Y", name: "Jujuy", parent: "AR" },
  { code: "AR_Z", name: "Santa Cruz", parent: "AR" },
  { code: "AT_1", name: "Burgenland", parent: "AT" },
  { code: "AT_2", name: "Kärnten", parent: "AT" },
  { code: "AT_3", name: "Niederösterreich", parent: "AT" },
  { code: "AT_4", name: "Oberösterreich", parent: "AT" },
  { code: "AT_5", name: "Salzburg", parent: "AT" },
  { code: "AT_6", name: "Steiermark", parent: "AT" },
  { code: "AT_7", name: "Tirol", parent: "AT" },
  { code: "AT_8", name: "Vorarlberg", parent: "AT" },
  { code: "AT_9", name: "Wien", parent: "AT" },
  { code: "AU_ACT", name: "Australian Capital Territory", parent: "AU" },
  { code: "AU_NSW", name: "New South Wales", parent: "AU" },
  { code: "AU_NT", name: "Northern Territory", parent: "AU" },
  { code: "AU_QLD", name: "Queensland", parent: "AU" },
  { code: "AU_SA", name: "South Australia", parent: "AU" },
  { code: "AU_TAS", name: "Tasmania", parent: "AU" },
  { code: "AU_VIC", name: "Victoria", parent: "AU" },
  { code: "AU_WA", name: "Western Australia", parent: "AU" },
  { code: "AZ_ABS", name: "Abşeron", parent: "AZ" },
  { code: "AZ_AGA", name: "Ağstafa", parent: "AZ" },
  { code: "AZ_AGC", name: "Ağcabədi", parent: "AZ" },
  { code: "AZ_AGM", name: "Ağdam", parent: "AZ" },
  { code: "AZ_AGS", name: "Ağdaş", parent: "AZ" },
  { code: "AZ_AGU", name: "Ağsu", parent: "AZ" },
  { code: "AZ_AST", name: "Astara", parent: "AZ" },
  { code: "AZ_BA", name: "Bakı", parent: "AZ" },
  { code: "AZ_BAB", name: "Babək", parent: "AZ_NX" },
  { code: "AZ_BAL", name: "Balakən", parent: "AZ" },
  { code: "AZ_BAR", name: "Bərdə", parent: "AZ" },
  { code: "AZ_BEY", name: "Beyləqan", parent: "AZ" },
  { code: "AZ_BIL", name: "Biləsuvar", parent: "AZ" },
  { code: "AZ_CAB", name: "Cəbrayıl", parent: "AZ" },
  { code: "AZ_CAL", name: "Cəlilabad", parent: "AZ" },
  { code: "AZ_CUL", name: "Culfa", parent: "AZ_NX" },
  { code: "AZ_DAS", name: "Daşkəsən", parent: "AZ" },
  { code: "AZ_FUZ", name: "Füzuli", parent: "AZ" },
  { code: "AZ_GA", name: "Gəncə", parent: "AZ" },
  { code: "AZ_GAD", name: "Gədəbəy", parent: "AZ" },
  { code: "AZ_GOR", name: "Goranboy", parent: "AZ" },
  { code: "AZ_GOY", name: "Göyçay", parent: "AZ" },
  { code: "AZ_GYG", name: "Göygöl", parent: "AZ" },
  { code: "AZ_HAC", name: "Hacıqabul", parent: "AZ" },
  { code: "AZ_IMI", name: "İmişli", parent: "AZ" },
  { code: "AZ_ISM", name: "İsmayıllı", parent: "AZ" },
  { code: "AZ_KAL", name: "Kəlbəcər", parent: "AZ" },
  { code: "AZ_KAN", name: "Kǝngǝrli", parent: "AZ_NX" },
  { code: "AZ_KUR", name: "Kürdəmir", parent: "AZ" },
  { code: "AZ_LA", name: "Lənkəran", parent: "AZ" },
  { code: "AZ_LAC", name: "Laçın", parent: "AZ" },
  { code: "AZ_LAN", name: "Lənkəran", parent: "AZ" },
  { code: "AZ_LER", name: "Lerik", parent: "AZ" },
  { code: "AZ_MAS", name: "Masallı", parent: "AZ" },
  { code: "AZ_MI", name: "Mingəçevir", parent: "AZ" },
  { code: "AZ_NA", name: "Naftalan", parent: "AZ" },
  { code: "AZ_NEF", name: "Neftçala", parent: "AZ" },
  { code: "AZ_NV", name: "Naxçıvan", parent: "AZ_NX" },
  { code: "AZ_NX", name: "Naxçıvan", parent: "AZ" },
  { code: "AZ_OGU", name: "Oğuz", parent: "AZ" },
  { code: "AZ_ORD", name: "Ordubad", parent: "AZ_NX" },
  { code: "AZ_QAB", name: "Qəbələ", parent: "AZ" },
  { code: "AZ_QAX", name: "Qax", parent: "AZ" },
  { code: "AZ_QAZ", name: "Qazax", parent: "AZ" },
  { code: "AZ_QBA", name: "Quba", parent: "AZ" },
  { code: "AZ_QBI", name: "Qubadlı", parent: "AZ" },
  { code: "AZ_QOB", name: "Qobustan", parent: "AZ" },
  { code: "AZ_QUS", name: "Qusar", parent: "AZ" },
  { code: "AZ_SA", name: "Şəki", parent: "AZ" },
  { code: "AZ_SAB", name: "Sabirabad", parent: "AZ" },
  { code: "AZ_SAD", name: "Sədərək", parent: "AZ_NX" },
  { code: "AZ_SAH", name: "Şahbuz", parent: "AZ_NX" },
  { code: "AZ_SAK", name: "Şəki", parent: "AZ" },
  { code: "AZ_SAL", name: "Salyan", parent: "AZ" },
  { code: "AZ_SAR", name: "Şərur", parent: "AZ_NX" },
  { code: "AZ_SAT", name: "Saatlı", parent: "AZ" },
  { code: "AZ_SBN", name: "Şabran", parent: "AZ" },
  { code: "AZ_SIY", name: "Siyəzən", parent: "AZ" },
  { code: "AZ_SKR", name: "Şəmkir", parent: "AZ" },
  { code: "AZ_SM", name: "Sumqayıt", parent: "AZ" },
  { code: "AZ_SMI", name: "Şamaxı", parent: "AZ" },
  { code: "AZ_SMX", name: "Samux", parent: "AZ" },
  { code: "AZ_SR", name: "Şirvan", parent: "AZ" },
  { code: "AZ_SUS", name: "Şuşa", parent: "AZ" },
  { code: "AZ_TAR", name: "Tərtər", parent: "AZ" },
  { code: "AZ_TOV", name: "Tovuz", parent: "AZ" },
  { code: "AZ_UCA", name: "Ucar", parent: "AZ" },
  { code: "AZ_XA", name: "Xankəndi", parent: "AZ" },
  { code: "AZ_XAC", name: "Xaçmaz", parent: "AZ" },
  { code: "AZ_XCI", name: "Xocalı", parent: "AZ" },
  { code: "AZ_XIZ", name: "Xızı", parent: "AZ" },
  { code: "AZ_XVD", name: "Xocavənd", parent: "AZ" },
  { code: "AZ_YAR", name: "Yardımlı", parent: "AZ" },
  { code: "AZ_YE", name: "Yevlax", parent: "AZ" },
  { code: "AZ_YEV", name: "Yevlax", parent: "AZ" },
  { code: "AZ_ZAN", name: "Zəngilan", parent: "AZ" },
  { code: "AZ_ZAQ", name: "Zaqatala", parent: "AZ" },
  { code: "AZ_ZAR", name: "Zərdab", parent: "AZ" },
  { code: "BA_BIH", name: "Federacija Bosne i Hercegovine", parent: "BA" },
  { code: "BA_BRC", name: "Brčko distrikt", parent: "BA" },
  { code: "BA_SRP", name: "Republika Srpska", parent: "BA" },
  { code: "BB_01", name: "Christ Church", parent: "BB" },
  { code: "BB_02", name: "Saint Andrew", parent: "BB" },
  { code: "BB_03", name: "Saint George", parent: "BB" },
  { code: "BB_04", name: "Saint James", parent: "BB" },
  { code: "BB_05", name: "Saint John", parent: "BB" },
  { code: "BB_06", name: "Saint Joseph", parent: "BB" },
  { code: "BB_07", name: "Saint Lucy", parent: "BB" },
  { code: "BB_08", name: "Saint Michael", parent: "BB" },
  { code: "BB_09", name: "Saint Peter", parent: "BB" },
  { code: "BB_10", name: "Saint Philip", parent: "BB" },
  { code: "BB_11", name: "Saint Thomas", parent: "BB" },
  { code: "BD_01", name: "Bandarban", parent: "BD_B" },
  { code: "BD_02", name: "Barguna", parent: "BD_A" },
  { code: "BD_03", name: "Bogura", parent: "BD_E" },
  { code: "BD_04", name: "Brahmanbaria", parent: "BD_B" },
  { code: "BD_05", name: "Bagerhat", parent: "BD_D" },
  { code: "BD_06", name: "Barishal", parent: "BD_A" },
  { code: "BD_07", name: "Bhola", parent: "BD_A" },
  { code: "BD_08", name: "Cumilla", parent: "BD_B" },
  { code: "BD_09", name: "Chandpur", parent: "BD_B" },
  { code: "BD_10", name: "Chattogram", parent: "BD_B" },
  { code: "BD_11", name: "Cox's Bazar", parent: "BD_B" },
  { code: "BD_12", name: "Chuadanga", parent: "BD_D" },
  { code: "BD_13", name: "Dhaka", parent: "BD_C" },
  { code: "BD_14", name: "Dinajpur", parent: "BD_F" },
  { code: "BD_15", name: "Faridpur", parent: "BD_C" },
  { code: "BD_16", name: "Feni", parent: "BD_B" },
  { code: "BD_17", name: "Gopalganj", parent: "BD_C" },
  { code: "BD_18", name: "Gazipur", parent: "BD_C" },
  { code: "BD_19", name: "Gaibandha", parent: "BD_F" },
  { code: "BD_20", name: "Habiganj", parent: "BD_G" },
  { code: "BD_21", name: "Jamalpur", parent: "BD_H" },
  { code: "BD_22", name: "Jashore", parent: "BD_D" },
  { code: "BD_23", name: "Jhenaidah", parent: "BD_D" },
  { code: "BD_24", name: "Joypurhat", parent: "BD_E" },
  { code: "BD_25", name: "Jhalakathi", parent: "BD_A" },
  { code: "BD_26", name: "Kishoreganj", parent: "BD_C" },
  { code: "BD_27", name: "Khulna", parent: "BD_D" },
  { code: "BD_28", name: "Kurigram", parent: "BD_F" },
  { code: "BD_29", name: "Khagrachhari", parent: "BD_B" },
  { code: "BD_30", name: "Kushtia", parent: "BD_D" },
  { code: "BD_31", name: "Lakshmipur", parent: "BD_B" },
  { code: "BD_32", name: "Lalmonirhat", parent: "BD_F" },
  { code: "BD_33", name: "Manikganj", parent: "BD_C" },
  { code: "BD_34", name: "Mymensingh", parent: "BD_H" },
  { code: "BD_35", name: "Munshiganj", parent: "BD_C" },
  { code: "BD_36", name: "Madaripur", parent: "BD_C" },
  { code: "BD_37", name: "Magura", parent: "BD_D" },
  { code: "BD_38", name: "Moulvibazar", parent: "BD_G" },
  { code: "BD_39", name: "Meherpur", parent: "BD_D" },
  { code: "BD_40", name: "Narayanganj", parent: "BD_C" },
  { code: "BD_41", name: "Netrakona", parent: "BD_H" },
  { code: "BD_42", name: "Narsingdi", parent: "BD_C" },
  { code: "BD_43", name: "Narail", parent: "BD_D" },
  { code: "BD_44", name: "Natore", parent: "BD_E" },
  { code: "BD_45", name: "Chapai Nawabganj", parent: "BD_E" },
  { code: "BD_46", name: "Nilphamari", parent: "BD_F" },
  { code: "BD_47", name: "Noakhali", parent: "BD_B" },
  { code: "BD_48", name: "Naogaon", parent: "BD_E" },
  { code: "BD_49", name: "Pabna", parent: "BD_E" },
  { code: "BD_50", name: "Pirojpur", parent: "BD_A" },
  { code: "BD_51", name: "Patuakhali", parent: "BD_A" },
  { code: "BD_52", name: "Panchagarh", parent: "BD_F" },
  { code: "BD_53", name: "Rajbari", parent: "BD_C" },
  { code: "BD_54", name: "Rajshahi", parent: "BD_E" },
  { code: "BD_55", name: "Rangpur", parent: "BD_F" },
  { code: "BD_56", name: "Rangamati", parent: "BD_B" },
  { code: "BD_57", name: "Sherpur", parent: "BD_H" },
  { code: "BD_58", name: "Satkhira", parent: "BD_D" },
  { code: "BD_59", name: "Sirajganj", parent: "BD_E" },
  { code: "BD_60", name: "Sylhet", parent: "BD_G" },
  { code: "BD_61", name: "Sunamganj", parent: "BD_G" },
  { code: "BD_62", name: "Shariatpur", parent: "BD_C" },
  { code: "BD_63", name: "Tangail", parent: "BD_C" },
  { code: "BD_64", name: "Thakurgaon", parent: "BD_F" },
  { code: "BD_A", name: "Barishal", parent: "BD" },
  { code: "BD_B", name: "Chattogram", parent: "BD" },
  { code: "BD_C", name: "Dhaka", parent: "BD" },
  { code: "BD_D", name: "Khulna", parent: "BD" },
  { code: "BD_E", name: "Rajshahi", parent: "BD" },
  { code: "BD_F", name: "Rangpur", parent: "BD" },
  { code: "BD_G", name: "Sylhet", parent: "BD" },
  { code: "BD_H", name: "Mymensingh", parent: "BD" },
  { code: "BE_BRU", name: "Brussels Hoofdstedelijk Gewest", parent: "BE" },
  { code: "BE_VAN", name: "Antwerpen", parent: "BE" },
  { code: "BE_VBR", name: "Vlaams-Brabant", parent: "BE" },
  { code: "BE_VLG", name: "Vlaams Gewest", parent: "BE" },
  { code: "BE_VLI", name: "Limburg", parent: "BE" },
  { code: "BE_VOV", name: "Oost-Vlaanderen", parent: "BE" },
  { code: "BE_VWV", name: "West-Vlaanderen", parent: "BE" },
  { code: "BE_WAL", name: "Waals Gewest", parent: "BE" },
  { code: "BE_WBR", name: "Brabant wallon", parent: "BE" },
  { code: "BE_WHT", name: "Hainaut", parent: "BE" },
  { code: "BE_WLG", name: "Liège", parent: "BE" },
  { code: "BE_WLX", name: "Luxembourg", parent: "BE" },
  { code: "BE_WNA", name: "Namur", parent: "BE" },
  { code: "BF_01", name: "Boucle du Mouhoun", parent: "BF" },
  { code: "BF_02", name: "Cascades", parent: "BF" },
  { code: "BF_03", name: "Centre", parent: "BF" },
  { code: "BF_04", name: "Centre-Est", parent: "BF" },
  { code: "BF_05", name: "Centre-Nord", parent: "BF" },
  { code: "BF_06", name: "Centre-Ouest", parent: "BF" },
  { code: "BF_07", name: "Centre-Sud", parent: "BF" },
  { code: "BF_08", name: "Est", parent: "BF" },
  { code: "BF_09", name: "Hauts-Bassins", parent: "BF" },
  { code: "BF_10", name: "Nord", parent: "BF" },
  { code: "BF_11", name: "Plateau-Central", parent: "BF" },
  { code: "BF_12", name: "Sahel", parent: "BF" },
  { code: "BF_13", name: "Sud-Ouest", parent: "BF" },
  { code: "BF_BAL", name: "Balé", parent: "BF_01" },
  { code: "BF_BAM", name: "Bam", parent: "BF_05" },
  { code: "BF_BAN", name: "Banwa", parent: "BF_01" },
  { code: "BF_BAZ", name: "Bazèga", parent: "BF_07" },
  { code: "BF_BGR", name: "Bougouriba", parent: "BF_13" },
  { code: "BF_BLG", name: "Boulgou", parent: "BF_04" },
  { code: "BF_BLK", name: "Boulkiemdé", parent: "BF_06" },
  { code: "BF_COM", name: "Comoé", parent: "BF_02" },
  { code: "BF_GAN", name: "Ganzourgou", parent: "BF_11" },
  { code: "BF_GNA", name: "Gnagna", parent: "BF_08" },
  { code: "BF_GOU", name: "Gourma", parent: "BF_08" },
  { code: "BF_HOU", name: "Houet", parent: "BF_09" },
  { code: "BF_IOB", name: "Ioba", parent: "BF_13" },
  { code: "BF_KAD", name: "Kadiogo", parent: "BF_03" },
  { code: "BF_KEN", name: "Kénédougou", parent: "BF_09" },
  { code: "BF_KMD", name: "Komondjari", parent: "BF_08" },
  { code: "BF_KMP", name: "Kompienga", parent: "BF_08" },
  { code: "BF_KOP", name: "Koulpélogo", parent: "BF_04" },
  { code: "BF_KOS", name: "Kossi", parent: "BF_01" },
  { code: "BF_KOT", name: "Kouritenga", parent: "BF_04" },
  { code: "BF_KOW", name: "Kourwéogo", parent: "BF_11" },
  { code: "BF_LER", name: "Léraba", parent: "BF_02" },
  { code: "BF_LOR", name: "Loroum", parent: "BF_10" },
  { code: "BF_MOU", name: "Mouhoun", parent: "BF_01" },
  { code: "BF_NAM", name: "Namentenga", parent: "BF_05" },
  { code: "BF_NAO", name: "Nahouri", parent: "BF_07" },
  { code: "BF_NAY", name: "Nayala", parent: "BF_01" },
  { code: "BF_NOU", name: "Noumbiel", parent: "BF_13" },
  { code: "BF_OUB", name: "Oubritenga", parent: "BF_11" },
  { code: "BF_OUD", name: "Oudalan", parent: "BF_12" },
  { code: "BF_PAS", name: "Passoré", parent: "BF_10" },
  { code: "BF_PON", name: "Poni", parent: "BF_13" },
  { code: "BF_SEN", name: "Séno", parent: "BF_12" },
  { code: "BF_SIS", name: "Sissili", parent: "BF_06" },
  { code: "BF_SMT", name: "Sanmatenga", parent: "BF_05" },
  { code: "BF_SNG", name: "Sanguié", parent: "BF_06" },
  { code: "BF_SOM", name: "Soum", parent: "BF_12" },
  { code: "BF_SOR", name: "Sourou", parent: "BF_01" },
  { code: "BF_TAP", name: "Tapoa", parent: "BF_08" },
  { code: "BF_TUI", name: "Tuy", parent: "BF_09" },
  { code: "BF_YAG", name: "Yagha", parent: "BF_12" },
  { code: "BF_YAT", name: "Yatenga", parent: "BF_10" },
  { code: "BF_ZIR", name: "Ziro", parent: "BF_06" },
  { code: "BF_ZON", name: "Zondoma", parent: "BF_10" },
  { code: "BF_ZOU", name: "Zoundwéogo", parent: "BF_07" },
  { code: "BG_01", name: "Blagoevgrad", parent: "BG" },
  { code: "BG_02", name: "Burgas", parent: "BG" },
  { code: "BG_03", name: "Varna", parent: "BG" },
  { code: "BG_04", name: "Veliko Tarnovo", parent: "BG" },
  { code: "BG_05", name: "Vidin", parent: "BG" },
  { code: "BG_06", name: "Vratsa", parent: "BG" },
  { code: "BG_07", name: "Gabrovo", parent: "BG" },
  { code: "BG_08", name: "Dobrich", parent: "BG" },
  { code: "BG_09", name: "Kardzhali", parent: "BG" },
  { code: "BG_10", name: "Kyustendil", parent: "BG" },
  { code: "BG_11", name: "Lovech", parent: "BG" },
  { code: "BG_12", name: "Montana", parent: "BG" },
  { code: "BG_13", name: "Pazardzhik", parent: "BG" },
  { code: "BG_14", name: "Pernik", parent: "BG" },
  { code: "BG_15", name: "Pleven", parent: "BG" },
  { code: "BG_16", name: "Plovdiv", parent: "BG" },
  { code: "BG_17", name: "Razgrad", parent: "BG" },
  { code: "BG_18", name: "Ruse", parent: "BG" },
  { code: "BG_19", name: "Silistra", parent: "BG" },
  { code: "BG_20", name: "Sliven", parent: "BG" },
  { code: "BG_21", name: "Smolyan", parent: "BG" },
  { code: "BG_22", name: "Sofia (stolitsa)", parent: "BG" },
  { code: "BG_23", name: "Sofia", parent: "BG" },
  { code: "BG_24", name: "Stara Zagora", parent: "BG" },
  { code: "BG_25", name: "Targovishte", parent: "BG" },
  { code: "BG_26", name: "Haskovo", parent: "BG" },
  { code: "BG_27", name: "Shumen", parent: "BG" },
  { code: "BG_28", name: "Yambol", parent: "BG" },
  { code: "BH_13", name: "Al ‘Āşimah", parent: "BH" },
  { code: "BH_14", name: "Al Janūbīyah", parent: "BH" },
  { code: "BH_15", name: "Al Muḩarraq", parent: "BH" },
  { code: "BH_17", name: "Ash Shamālīyah", parent: "BH" },
  { code: "BI_BB", name: "Bubanza", parent: "BI" },
  { code: "BI_BL", name: "Bujumbura Rural", parent: "BI" },
  { code: "BI_BM", name: "Bujumbura Mairie", parent: "BI" },
  { code: "BI_BR", name: "Bururi", parent: "BI" },
  { code: "BI_CA", name: "Cankuzo", parent: "BI" },
  { code: "BI_CI", name: "Cibitoke", parent: "BI" },
  { code: "BI_GI", name: "Gitega", parent: "BI" },
  { code: "BI_KI", name: "Kirundo", parent: "BI" },
  { code: "BI_KR", name: "Karuzi", parent: "BI" },
  { code: "BI_KY", name: "Kayanza", parent: "BI" },
  { code: "BI_MA", name: "Makamba", parent: "BI" },
  { code: "BI_MU", name: "Muramvya", parent: "BI" },
  { code: "BI_MW", name: "Mwaro", parent: "BI" },
  { code: "BI_MY", name: "Muyinga", parent: "BI" },
  { code: "BI_NG", name: "Ngozi", parent: "BI" },
  { code: "BI_RM", name: "Rumonge", parent: "BI" },
  { code: "BI_RT", name: "Rutana", parent: "BI" },
  { code: "BI_RY", name: "Ruyigi", parent: "BI" },
  { code: "BJ_AK", name: "Atacora", parent: "BJ" },
  { code: "BJ_AL", name: "Alibori", parent: "BJ" },
  { code: "BJ_AQ", name: "Atlantique", parent: "BJ" },
  { code: "BJ_BO", name: "Borgou", parent: "BJ" },
  { code: "BJ_CO", name: "Collines", parent: "BJ" },
  { code: "BJ_DO", name: "Donga", parent: "BJ" },
  { code: "BJ_KO", name: "Couffo", parent: "BJ" },
  { code: "BJ_LI", name: "Littoral", parent: "BJ" },
  { code: "BJ_MO", name: "Mono", parent: "BJ" },
  { code: "BJ_OU", name: "Ouémé", parent: "BJ" },
  { code: "BJ_PL", name: "Plateau", parent: "BJ" },
  { code: "BJ_ZO", name: "Zou", parent: "BJ" },
  { code: "BN_BE", name: "Belait", parent: "BN" },
  { code: "BN_BM", name: "Brunei-Muara", parent: "BN" },
  { code: "BN_TE", name: "Temburong", parent: "BN" },
  { code: "BN_TU", name: "Tutong", parent: "BN" },
  { code: "BO_B", name: "El Beni", parent: "BO" },
  { code: "BO_C", name: "Cochabamba", parent: "BO" },
  { code: "BO_H", name: "Chuquisaca", parent: "BO" },
  { code: "BO_L", name: "La Paz", parent: "BO" },
  { code: "BO_N", name: "Pando", parent: "BO" },
  { code: "BO_O", name: "Oruro", parent: "BO" },
  { code: "BO_P", name: "Potosí", parent: "BO" },
  { code: "BO_S", name: "Santa Cruz", parent: "BO" },
  { code: "BO_T", name: "Tarija", parent: "BO" },
  { code: "BQ_BO", name: "Bonaire", parent: "BQ" },
  { code: "BQ_SA", name: "Saba", parent: "BQ" },
  { code: "BQ_SE", name: "Sint Eustatius", parent: "BQ" },
  { code: "BR_AC", name: "Acre", parent: "BR" },
  { code: "BR_AL", name: "Alagoas", parent: "BR" },
  { code: "BR_AM", name: "Amazonas", parent: "BR" },
  { code: "BR_AP", name: "Amapá", parent: "BR" },
  { code: "BR_BA", name: "Bahia", parent: "BR" },
  { code: "BR_CE", name: "Ceará", parent: "BR" },
  { code: "BR_DF", name: "Distrito Federal", parent: "BR" },
  { code: "BR_ES", name: "Espírito Santo", parent: "BR" },
  { code: "BR_GO", name: "Goiás", parent: "BR" },
  { code: "BR_MA", name: "Maranhão", parent: "BR" },
  { code: "BR_MG", name: "Minas Gerais", parent: "BR" },
  { code: "BR_MS", name: "Mato Grosso do Sul", parent: "BR" },
  { code: "BR_MT", name: "Mato Grosso", parent: "BR" },
  { code: "BR_PA", name: "Pará", parent: "BR" },
  { code: "BR_PB", name: "Paraíba", parent: "BR" },
  { code: "BR_PE", name: "Pernambuco", parent: "BR" },
  { code: "BR_PI", name: "Piauí", parent: "BR" },
  { code: "BR_PR", name: "Paraná", parent: "BR" },
  { code: "BR_RJ", name: "Rio de Janeiro", parent: "BR" },
  { code: "BR_RN", name: "Rio Grande do Norte", parent: "BR" },
  { code: "BR_RO", name: "Rondônia", parent: "BR" },
  { code: "BR_RR", name: "Roraima", parent: "BR" },
  { code: "BR_RS", name: "Rio Grande do Sul", parent: "BR" },
  { code: "BR_SC", name: "Santa Catarina", parent: "BR" },
  { code: "BR_SE", name: "Sergipe", parent: "BR" },
  { code: "BR_SP", name: "São Paulo", parent: "BR" },
  { code: "BR_TO", name: "Tocantins", parent: "BR" },
  { code: "BS_AK", name: "Acklins", parent: "BS" },
  { code: "BS_BI", name: "Bimini", parent: "BS" },
  { code: "BS_BP", name: "Black Point", parent: "BS" },
  { code: "BS_BY", name: "Berry Islands", parent: "BS" },
  { code: "BS_CE", name: "Central Eleuthera", parent: "BS" },
  { code: "BS_CI", name: "Cat Island", parent: "BS" },
  { code: "BS_CK", name: "Crooked Island and Long Cay", parent: "BS" },
  { code: "BS_CO", name: "Central Abaco", parent: "BS" },
  { code: "BS_CS", name: "Central Andros", parent: "BS" },
  { code: "BS_EG", name: "East Grand Bahama", parent: "BS" },
  { code: "BS_EX", name: "Exuma", parent: "BS" },
  { code: "BS_FP", name: "City of Freeport", parent: "BS" },
  { code: "BS_GC", name: "Grand Cay", parent: "BS" },
  { code: "BS_HI", name: "Harbour Island", parent: "BS" },
  { code: "BS_HT", name: "Hope Town", parent: "BS" },
  { code: "BS_IN", name: "Inagua", parent: "BS" },
  { code: "BS_LI", name: "Long Island", parent: "BS" },
  { code: "BS_MC", name: "Mangrove Cay", parent: "BS" },
  { code: "BS_MG", name: "Mayaguana", parent: "BS" },
  { code: "BS_MI", name: "Moore's Island", parent: "BS" },
  { code: "BS_NE", name: "North Eleuthera", parent: "BS" },
  { code: "BS_NO", name: "North Abaco", parent: "BS" },
  { code: "BS_NP", name: "New Providence", parent: "BS" },
  { code: "BS_NS", name: "North Andros", parent: "BS" },
  { code: "BS_RC", name: "Rum Cay", parent: "BS" },
  { code: "BS_RI", name: "Ragged Island", parent: "BS" },
  { code: "BS_SA", name: "South Andros", parent: "BS" },
  { code: "BS_SE", name: "South Eleuthera", parent: "BS" },
  { code: "BS_SO", name: "South Abaco", parent: "BS" },
  { code: "BS_SS", name: "San Salvador", parent: "BS" },
  { code: "BS_SW", name: "Spanish Wells", parent: "BS" },
  { code: "BS_WG", name: "West Grand Bahama", parent: "BS" },
  { code: "BT_11", name: "Paro", parent: "BT" },
  { code: "BT_12", name: "Chhukha", parent: "BT" },
  { code: "BT_13", name: "Haa", parent: "BT" },
  { code: "BT_14", name: "Samtse", parent: "BT" },
  { code: "BT_15", name: "Thimphu", parent: "BT" },
  { code: "BT_21", name: "Tsirang", parent: "BT" },
  { code: "BT_22", name: "Dagana", parent: "BT" },
  { code: "BT_23", name: "Punakha", parent: "BT" },
  { code: "BT_24", name: "Wangdue Phodrang", parent: "BT" },
  { code: "BT_31", name: "Sarpang", parent: "BT" },
  { code: "BT_32", name: "Trongsa", parent: "BT" },
  { code: "BT_33", name: "Bumthang", parent: "BT" },
  { code: "BT_34", name: "Zhemgang", parent: "BT" },
  { code: "BT_41", name: "Trashigang", parent: "BT" },
  { code: "BT_42", name: "Monggar", parent: "BT" },
  { code: "BT_43", name: "Pema Gatshel", parent: "BT" },
  { code: "BT_44", name: "Lhuentse", parent: "BT" },
  { code: "BT_45", name: "Samdrup Jongkhar", parent: "BT" },
  { code: "BT_GA", name: "Gasa", parent: "BT" },
  { code: "BT_TY", name: "Trashi Yangtse", parent: "BT" },
  { code: "BW_CE", name: "Central", parent: "BW" },
  { code: "BW_CH", name: "Chobe", parent: "BW" },
  { code: "BW_FR", name: "Francistown", parent: "BW" },
  { code: "BW_GA", name: "Gaborone", parent: "BW" },
  { code: "BW_GH", name: "Ghanzi", parent: "BW" },
  { code: "BW_JW", name: "Jwaneng", parent: "BW" },
  { code: "BW_KG", name: "Kgalagadi", parent: "BW" },
  { code: "BW_KL", name: "Kgatleng", parent: "BW" },
  { code: "BW_KW", name: "Kweneng", parent: "BW" },
  { code: "BW_LO", name: "Lobatse", parent: "BW" },
  { code: "BW_NE", name: "North East", parent: "BW" },
  { code: "BW_NW", name: "North West", parent: "BW" },
  { code: "BW_SE", name: "South East", parent: "BW" },
  { code: "BW_SO", name: "Southern", parent: "BW" },
  { code: "BW_SP", name: "Selibe Phikwe", parent: "BW" },
  { code: "BW_ST", name: "Sowa Town", parent: "BW" },
  { code: "BY_BR", name: "Brestskaya voblasts'", parent: "BY" },
  { code: "BY_HM", name: "Horad Minsk", parent: "BY" },
  { code: "BY_HO", name: "Homyel'skaya voblasts'", parent: "BY" },
  { code: "BY_HR", name: "Hrodzyenskaya voblasts'", parent: "BY" },
  { code: "BY_MA", name: "Mahilyowskaya voblasts'", parent: "BY" },
  { code: "BY_MI", name: "Minskaya voblasts'", parent: "BY" },
  { code: "BY_VI", name: "Vitsyebskaya voblasts'", parent: "BY" },
  { code: "BZ_BZ", name: "Belize", parent: "BZ" },
  { code: "BZ_CY", name: "Cayo", parent: "BZ" },
  { code: "BZ_CZL", name: "Corozal", parent: "BZ" },
  { code: "BZ_OW", name: "Orange Walk", parent: "BZ" },
  { code: "BZ_SC", name: "Stann Creek", parent: "BZ" },
  { code: "BZ_TOL", name: "Toledo", parent: "BZ" },
  { code: "CA_AB", name: "Alberta", parent: "CA" },
  { code: "CA_BC", name: "British Columbia", parent: "CA" },
  { code: "CA_MB", name: "Manitoba", parent: "CA" },
  { code: "CA_NB", name: "New Brunswick", parent: "CA" },
  { code: "CA_NL", name: "Newfoundland and Labrador", parent: "CA" },
  { code: "CA_NS", name: "Nova Scotia", parent: "CA" },
  { code: "CA_NT", name: "Northwest Territories", parent: "CA" },
  { code: "CA_NU", name: "Nunavut", parent: "CA" },
  { code: "CA_ON", name: "Ontario", parent: "CA" },
  { code: "CA_PE", name: "Prince Edward Island", parent: "CA" },
  { code: "CA_QC", name: "Quebec", parent: "CA" },
  { code: "CA_SK", name: "Saskatchewan", parent: "CA" },
  { code: "CA_YT", name: "Yukon", parent: "CA" },
  { code: "CD_BC", name: "Kongo Central", parent: "CD" },
  { code: "CD_BU", name: "Bas-Uélé", parent: "CD" },
  { code: "CD_EQ", name: "Équateur", parent: "CD" },
  { code: "CD_HK", name: "Haut-Katanga", parent: "CD" },
  { code: "CD_HL", name: "Haut-Lomami", parent: "CD" },
  { code: "CD_HU", name: "Haut-Uélé", parent: "CD" },
  { code: "CD_IT", name: "Ituri", parent: "CD" },
  { code: "CD_KC", name: "Kasaï Central", parent: "CD" },
  { code: "CD_KE", name: "Kasaï Oriental", parent: "CD" },
  { code: "CD_KG", name: "Kwango", parent: "CD" },
  { code: "CD_KL", name: "Kwilu", parent: "CD" },
  { code: "CD_KN", name: "Kinshasa", parent: "CD" },
  { code: "CD_KS", name: "Kasaï", parent: "CD" },
  { code: "CD_LO", name: "Lomami", parent: "CD" },
  { code: "CD_LU", name: "Lualaba", parent: "CD" },
  { code: "CD_MA", name: "Maniema", parent: "CD" },
  { code: "CD_MN", name: "Mai-Ndombe", parent: "CD" },
  { code: "CD_MO", name: "Mongala", parent: "CD" },
  { code: "CD_NK", name: "Nord-Kivu", parent: "CD" },
  { code: "CD_NU", name: "Nord-Ubangi", parent: "CD" },
  { code: "CD_SA", name: "Sankuru", parent: "CD" },
  { code: "CD_SK", name: "Sud-Kivu", parent: "CD" },
  { code: "CD_SU", name: "Sud-Ubangi", parent: "CD" },
  { code: "CD_TA", name: "Tanganyika", parent: "CD" },
  { code: "CD_TO", name: "Tshopo", parent: "CD" },
  { code: "CD_TU", name: "Tshuapa", parent: "CD" },
  { code: "CF_AC", name: "Ouham", parent: "CF" },
  { code: "CF_BB", name: "Bamingui-Bangoran", parent: "CF" },
  { code: "CF_BGF", name: "Bangui", parent: "CF" },
  { code: "CF_BK", name: "Basse-Kotto", parent: "CF" },
  { code: "CF_HK", name: "Haute-Kotto", parent: "CF" },
  { code: "CF_HM", name: "Haut-Mbomou", parent: "CF" },
  { code: "CF_HS", name: "Haute-Sangha / Mambéré-Kadéï", parent: "CF" },
  { code: "CF_KB", name: "Gribingui", parent: "CF" },
  { code: "CF_KG", name: "Kémo-Gribingui", parent: "CF" },
  { code: "CF_LB", name: "Lobaye", parent: "CF" },
  { code: "CF_MB", name: "Mbomou", parent: "CF" },
  { code: "CF_MP", name: "Ombella-Mpoko", parent: "CF" },
  { code: "CF_NM", name: "Nana-Mambéré", parent: "CF" },
  { code: "CF_OP", name: "Ouham-Pendé", parent: "CF" },
  { code: "CF_SE", name: "Sangha", parent: "CF" },
  { code: "CF_UK", name: "Ouaka", parent: "CF" },
  { code: "CF_VK", name: "Vakaga", parent: "CF" },
  { code: "CG_11", name: "Bouenza", parent: "CG" },
  { code: "CG_12", name: "Pool", parent: "CG" },
  { code: "CG_13", name: "Sangha", parent: "CG" },
  { code: "CG_14", name: "Plateaux", parent: "CG" },
  { code: "CG_15", name: "Cuvette-Ouest", parent: "CG" },
  { code: "CG_16", name: "Pointe-Noire", parent: "CG" },
  { code: "CG_2", name: "Lékoumou", parent: "CG" },
  { code: "CG_5", name: "Kouilou", parent: "CG" },
  { code: "CG_7", name: "Likouala", parent: "CG" },
  { code: "CG_8", name: "Cuvette", parent: "CG" },
  { code: "CG_9", name: "Niari", parent: "CG" },
  { code: "CG_BZV", name: "Brazzaville", parent: "CG" },
  { code: "CH_AG", name: "Aargau", parent: "CH" },
  { code: "CH_AI", name: "Appenzell Innerrhoden", parent: "CH" },
  { code: "CH_AR", name: "Appenzell Ausserrhoden", parent: "CH" },
  { code: "CH_BE", name: "Bern", parent: "CH" },
  { code: "CH_BL", name: "Basel-Landschaft", parent: "CH" },
  { code: "CH_BS", name: "Basel-Stadt", parent: "CH" },
  { code: "CH_FR", name: "Fribourg", parent: "CH" },
  { code: "CH_GE", name: "Genève", parent: "CH" },
  { code: "CH_GL", name: "Glarus", parent: "CH" },
  { code: "CH_GR", name: "Graubünden", parent: "CH" },
  { code: "CH_JU", name: "Jura", parent: "CH" },
  { code: "CH_LU", name: "Luzern", parent: "CH" },
  { code: "CH_NE", name: "Neuchâtel", parent: "CH" },
  { code: "CH_NW", name: "Nidwalden", parent: "CH" },
  { code: "CH_OW", name: "Obwalden", parent: "CH" },
  { code: "CH_SG", name: "Sankt Gallen", parent: "CH" },
  { code: "CH_SH", name: "Schaffhausen", parent: "CH" },
  { code: "CH_SO", name: "Solothurn", parent: "CH" },
  { code: "CH_SZ", name: "Schwyz", parent: "CH" },
  { code: "CH_TG", name: "Thurgau", parent: "CH" },
  { code: "CH_TI", name: "Ticino", parent: "CH" },
  { code: "CH_UR", name: "Uri", parent: "CH" },
  { code: "CH_VD", name: "Vaud", parent: "CH" },
  { code: "CH_VS", name: "Valais", parent: "CH" },
  { code: "CH_ZG", name: "Zug", parent: "CH" },
  { code: "CH_ZH", name: "Zürich", parent: "CH" },
  { code: "CI_AB", name: "Abidjan", parent: "CI" },
  { code: "CI_BS", name: "Bas-Sassandra", parent: "CI" },
  { code: "CI_CM", name: "Comoé", parent: "CI" },
  { code: "CI_DN", name: "Denguélé", parent: "CI" },
  { code: "CI_GD", name: "Gôh-Djiboua", parent: "CI" },
  { code: "CI_LC", name: "Lacs", parent: "CI" },
  { code: "CI_LG", name: "Lagunes", parent: "CI" },
  { code: "CI_MG", name: "Montagnes", parent: "CI" },
  { code: "CI_SM", name: "Sassandra-Marahoué", parent: "CI" },
  { code: "CI_SV", name: "Savanes", parent: "CI" },
  { code: "CI_VB", name: "Vallée du Bandama", parent: "CI" },
  { code: "CI_WR", name: "Woroba", parent: "CI" },
  { code: "CI_YM", name: "Yamoussoukro", parent: "CI" },
  { code: "CI_ZZ", name: "Zanzan", parent: "CI" },
  { code: "CL_AI", name: "Aisén del General Carlos Ibañez del Campo", parent: "CL" },
  { code: "CL_AN", name: "Antofagasta", parent: "CL" },
  { code: "CL_AP", name: "Arica y Parinacota", parent: "CL" },
  { code: "CL_AR", name: "La Araucanía", parent: "CL" },
  { code: "CL_AT", name: "Atacama", parent: "CL" },
  { code: "CL_BI", name: "Biobío", parent: "CL" },
  { code: "CL_CO", name: "Coquimbo", parent: "CL" },
  { code: "CL_LI", name: "Libertador General Bernardo O'Higgins", parent: "CL" },
  { code: "CL_LL", name: "Los Lagos", parent: "CL" },
  { code: "CL_LR", name: "Los Ríos", parent: "CL" },
  { code: "CL_MA", name: "Magallanes", parent: "CL" },
  { code: "CL_ML", name: "Maule", parent: "CL" },
  { code: "CL_NB", name: "Ñuble", parent: "CL" },
  { code: "CL_RM", name: "Región Metropolitana de Santiago", parent: "CL" },
  { code: "CL_TA", name: "Tarapacá", parent: "CL" },
  { code: "CL_VS", name: "Valparaíso", parent: "CL" },
  { code: "CM_AD", name: "Adamaoua", parent: "CM" },
  { code: "CM_CE", name: "Centre", parent: "CM" },
  { code: "CM_EN", name: "Far North", parent: "CM" },
  { code: "CM_ES", name: "East", parent: "CM" },
  { code: "CM_LT", name: "Littoral", parent: "CM" },
  { code: "CM_NO", name: "North", parent: "CM" },
  { code: "CM_NW", name: "North-West", parent: "CM" },
  { code: "CM_OU", name: "West", parent: "CM" },
  { code: "CM_SU", name: "South", parent: "CM" },
  { code: "CM_SW", name: "South-West", parent: "CM" },
  { code: "CN_AH", name: "Anhui Sheng", parent: "CN" },
  { code: "CN_BJ", name: "Beijing Shi", parent: "CN" },
  { code: "CN_CQ", name: "Chongqing Shi", parent: "CN" },
  { code: "CN_FJ", name: "Fujian Sheng", parent: "CN" },
  { code: "CN_GD", name: "Guangdong Sheng", parent: "CN" },
  { code: "CN_GS", name: "Gansu Sheng", parent: "CN" },
  { code: "CN_GX", name: "Guangxi Zhuangzu Zizhiqu", parent: "CN" },
  { code: "CN_GZ", name: "Guizhou Sheng", parent: "CN" },
  { code: "CN_HA", name: "Henan Sheng", parent: "CN" },
  { code: "CN_HB", name: "Hubei Sheng", parent: "CN" },
  { code: "CN_HE", name: "Hebei Sheng", parent: "CN" },
  { code: "CN_HI", name: "Hainan Sheng", parent: "CN" },
  { code: "CN_HK", name: "Hong Kong SAR", parent: "CN" },
  { code: "CN_HL", name: "Heilongjiang Sheng", parent: "CN" },
  { code: "CN_HN", name: "Hunan Sheng", parent: "CN" },
  { code: "CN_JL", name: "Jilin Sheng", parent: "CN" },
  { code: "CN_JS", name: "Jiangsu Sheng", parent: "CN" },
  { code: "CN_JX", name: "Jiangxi Sheng", parent: "CN" },
  { code: "CN_LN", name: "Liaoning Sheng", parent: "CN" },
  { code: "CN_MO", name: "Macao SAR", parent: "CN" },
  { code: "CN_NM", name: "Nei Mongol Zizhiqu", parent: "CN" },
  { code: "CN_NX", name: "Ningxia Huizu Zizhiqu", parent: "CN" },
  { code: "CN_QH", name: "Qinghai Sheng", parent: "CN" },
  { code: "CN_SC", name: "Sichuan Sheng", parent: "CN" },
  { code: "CN_SD", name: "Shandong Sheng", parent: "CN" },
  { code: "CN_SH", name: "Shanghai Shi", parent: "CN" },
  { code: "CN_SN", name: "Shaanxi Sheng", parent: "CN" },
  { code: "CN_SX", name: "Shanxi Sheng", parent: "CN" },
  { code: "CN_TJ", name: "Tianjin Shi", parent: "CN" },
  { code: "CN_TW", name: "Taiwan Sheng", parent: "CN" },
  { code: "CN_XJ", name: "Xinjiang Uygur Zizhiqu", parent: "CN" },
  { code: "CN_XZ", name: "Xizang Zizhiqu", parent: "CN" },
  { code: "CN_YN", name: "Yunnan Sheng", parent: "CN" },
  { code: "CN_ZJ", name: "Zhejiang Sheng", parent: "CN" },
  { code: "CO_AMA", name: "Amazonas", parent: "CO" },
  { code: "CO_ANT", name: "Antioquia", parent: "CO" },
  { code: "CO_ARA", name: "Arauca", parent: "CO" },
  { code: "CO_ATL", name: "Atlántico", parent: "CO" },
  { code: "CO_BOL", name: "Bolívar", parent: "CO" },
  { code: "CO_BOY", name: "Boyacá", parent: "CO" },
  { code: "CO_CAL", name: "Caldas", parent: "CO" },
  { code: "CO_CAQ", name: "Caquetá", parent: "CO" },
  { code: "CO_CAS", name: "Casanare", parent: "CO" },
  { code: "CO_CAU", name: "Cauca", parent: "CO" },
  { code: "CO_CES", name: "Cesar", parent: "CO" },
  { code: "CO_CHO", name: "Chocó", parent: "CO" },
  { code: "CO_COR", name: "Córdoba", parent: "CO" },
  { code: "CO_CUN", name: "Cundinamarca", parent: "CO" },
  { code: "CO_DC", name: "Distrito Capital de Bogotá", parent: "CO" },
  { code: "CO_GUA", name: "Guainía", parent: "CO" },
  { code: "CO_GUV", name: "Guaviare", parent: "CO" },
  { code: "CO_HUI", name: "Huila", parent: "CO" },
  { code: "CO_LAG", name: "La Guajira", parent: "CO" },
  { code: "CO_MAG", name: "Magdalena", parent: "CO" },
  { code: "CO_MET", name: "Meta", parent: "CO" },
  { code: "CO_NAR", name: "Nariño", parent: "CO" },
  { code: "CO_NSA", name: "Norte de Santander", parent: "CO" },
  { code: "CO_PUT", name: "Putumayo", parent: "CO" },
  { code: "CO_QUI", name: "Quindío", parent: "CO" },
  { code: "CO_RIS", name: "Risaralda", parent: "CO" },
  { code: "CO_SAN", name: "Santander", parent: "CO" },
  { code: "CO_SAP", name: "San Andrés, Providencia y Santa Catalina", parent: "CO" },
  { code: "CO_SUC", name: "Sucre", parent: "CO" },
  { code: "CO_TOL", name: "Tolima", parent: "CO" },
  { code: "CO_VAC", name: "Valle del Cauca", parent: "CO" },
  { code: "CO_VAU", name: "Vaupés", parent: "CO" },
  { code: "CO_VID", name: "Vichada", parent: "CO" },
  { code: "CR_A", name: "Alajuela", parent: "CR" },
  { code: "CR_C", name: "Cartago", parent: "CR" },
  { code: "CR_G", name: "Guanacaste", parent: "CR" },
  { code: "CR_H", name: "Heredia", parent: "CR" },
  { code: "CR_L", name: "Limón", parent: "CR" },
  { code: "CR_P", name: "Puntarenas", parent: "CR" },
  { code: "CR_SJ", name: "San José", parent: "CR" },
  { code: "CU_01", name: "Pinar del Río", parent: "CU" },
  { code: "CU_03", name: "La Habana", parent: "CU" },
  { code: "CU_04", name: "Matanzas", parent: "CU" },
  { code: "CU_05", name: "Villa Clara", parent: "CU" },
  { code: "CU_06", name: "Cienfuegos", parent: "CU" },
  { code: "CU_07", name: "Sancti Spíritus", parent: "CU" },
  { code: "CU_08", name: "Ciego de Ávila", parent: "CU" },
  { code: "CU_09", name: "Camagüey", parent: "CU" },
  { code: "CU_10", name: "Las Tunas", parent: "CU" },
  { code: "CU_11", name: "Holguín", parent: "CU" },
  { code: "CU_12", name: "Granma", parent: "CU" },
  { code: "CU_13", name: "Santiago de Cuba", parent: "CU" },
  { code: "CU_14", name: "Guantánamo", parent: "CU" },
  { code: "CU_15", name: "Artemisa", parent: "CU" },
  { code: "CU_16", name: "Mayabeque", parent: "CU" },
  { code: "CU_99", name: "Isla de la Juventud", parent: "CU" },
  { code: "CV_B", name: "Ilhas de Barlavento", parent: "CV" },
  { code: "CV_BR", name: "Brava", parent: "CV_S" },
  { code: "CV_BV", name: "Boa Vista", parent: "CV_B" },
  { code: "CV_CA", name: "Santa Catarina", parent: "CV_S" },
  { code: "CV_CF", name: "Santa Catarina do Fogo", parent: "CV_S" },
  { code: "CV_CR", name: "Santa Cruz", parent: "CV_S" },
  { code: "CV_MA", name: "Maio", parent: "CV_S" },
  { code: "CV_MO", name: "Mosteiros", parent: "CV_S" },
  { code: "CV_PA", name: "Paul", parent: "CV_B" },
  { code: "CV_PN", name: "Porto Novo", parent: "CV_B" },
  { code: "CV_PR", name: "Praia", parent: "CV_S" },
  { code: "CV_RB", name: "Ribeira Brava", parent: "CV_B" },
  { code: "CV_RG", name: "Ribeira Grande", parent: "CV_B" },
  { code: "CV_RS", name: "Ribeira Grande de Santiago", parent: "CV_S" },
  { code: "CV_S", name: "Ilhas de Sotavento", parent: "CV" },
  { code: "CV_SD", name: "São Domingos", parent: "CV_S" },
  { code: "CV_SF", name: "São Filipe", parent: "CV_S" },
  { code: "CV_SL", name: "Sal", parent: "CV_B" },
  { code: "CV_SM", name: "São Miguel", parent: "CV_S" },
  { code: "CV_SO", name: "São Lourenço dos Órgãos", parent: "CV_S" },
  { code: "CV_SS", name: "São Salvador do Mundo", parent: "CV_S" },
  { code: "CV_SV", name: "São Vicente", parent: "CV_B" },
  { code: "CV_TA", name: "Tarrafal", parent: "CV_S" },
  { code: "CV_TS", name: "Tarrafal de São Nicolau", parent: "CV_B" },
  { code: "CY_01", name: "Lefkosia", parent: "CY" },
  { code: "CY_02", name: "Lemesos", parent: "CY" },
  { code: "CY_03", name: "Larnaka", parent: "CY" },
  { code: "CY_04", name: "Ammochostos", parent: "CY" },
  { code: "CY_05", name: "Pafos", parent: "CY" },
  { code: "CY_06", name: "Keryneia", parent: "CY" },
  { code: "CZ_10", name: "Praha, Hlavní město", parent: "CZ" },
  { code: "CZ_20", name: "Středočeský kraj", parent: "CZ" },
  { code: "CZ_201", name: "Benešov", parent: "CZ_20" },
  { code: "CZ_202", name: "Beroun", parent: "CZ_20" },
  { code: "CZ_203", name: "Kladno", parent: "CZ_20" },
  { code: "CZ_204", name: "Kolín", parent: "CZ_20" },
  { code: "CZ_205", name: "Kutná Hora", parent: "CZ_20" },
  { code: "CZ_206", name: "Mělník", parent: "CZ_20" },
  { code: "CZ_207", name: "Mladá Boleslav", parent: "CZ_20" },
  { code: "CZ_208", name: "Nymburk", parent: "CZ_20" },
  { code: "CZ_209", name: "Praha-východ", parent: "CZ_20" },
  { code: "CZ_20A", name: "Praha-západ", parent: "CZ_20" },
  { code: "CZ_20B", name: "Příbram", parent: "CZ_20" },
  { code: "CZ_20C", name: "Rakovník", parent: "CZ_20" },
  { code: "CZ_31", name: "Jihočeský kraj", parent: "CZ" },
  { code: "CZ_311", name: "České Budějovice", parent: "CZ_31" },
  { code: "CZ_312", name: "Český Krumlov", parent: "CZ_31" },
  { code: "CZ_313", name: "Jindřichův Hradec", parent: "CZ_31" },
  { code: "CZ_314", name: "Písek", parent: "CZ_31" },
  { code: "CZ_315", name: "Prachatice", parent: "CZ_31" },
  { code: "CZ_316", name: "Strakonice", parent: "CZ_31" },
  { code: "CZ_317", name: "Tábor", parent: "CZ_31" },
  { code: "CZ_32", name: "Plzeňský kraj", parent: "CZ" },
  { code: "CZ_321", name: "Domažlice", parent: "CZ_32" },
  { code: "CZ_322", name: "Klatovy", parent: "CZ_32" },
  { code: "CZ_323", name: "Plzeň-město", parent: "CZ_32" },
  { code: "CZ_324", name: "Plzeň-jih", parent: "CZ_32" },
  { code: "CZ_325", name: "Plzeň-sever", parent: "CZ_32" },
  { code: "CZ_326", name: "Rokycany", parent: "CZ_32" },
  { code: "CZ_327", name: "Tachov", parent: "CZ_32" },
  { code: "CZ_41", name: "Karlovarský kraj", parent: "CZ" },
  { code: "CZ_411", name: "Cheb", parent: "CZ_41" },
  { code: "CZ_412", name: "Karlovy Vary", parent: "CZ_41" },
  { code: "CZ_413", name: "Sokolov", parent: "CZ_41" },
  { code: "CZ_42", name: "Ústecký kraj", parent: "CZ" },
  { code: "CZ_421", name: "Děčín", parent: "CZ_42" },
  { code: "CZ_422", name: "Chomutov", parent: "CZ_42" },
  { code: "CZ_423", name: "Litoměřice", parent: "CZ_42" },
  { code: "CZ_424", name: "Louny", parent: "CZ_42" },
  { code: "CZ_425", name: "Most", parent: "CZ_42" },
  { code: "CZ_426", name: "Teplice", parent: "CZ_42" },
  { code: "CZ_427", name: "Ústí nad Labem", parent: "CZ_42" },
  { code: "CZ_51", name: "Liberecký kraj", parent: "CZ" },
  { code: "CZ_511", name: "Česká Lípa", parent: "CZ_51" },
  { code: "CZ_512", name: "Jablonec nad Nisou", parent: "CZ_51" },
  { code: "CZ_513", name: "Liberec", parent: "CZ_51" },
  { code: "CZ_514", name: "Semily", parent: "CZ_51" },
  { code: "CZ_52", name: "Královéhradecký kraj", parent: "CZ" },
  { code: "CZ_521", name: "Hradec Králové", parent: "CZ_52" },
  { code: "CZ_522", name: "Jičín", parent: "CZ_52" },
  { code: "CZ_523", name: "Náchod", parent: "CZ_52" },
  { code: "CZ_524", name: "Rychnov nad Kněžnou", parent: "CZ_52" },
  { code: "CZ_525", name: "Trutnov", parent: "CZ_52" },
  { code: "CZ_53", name: "Pardubický kraj", parent: "CZ" },
  { code: "CZ_531", name: "Chrudim", parent: "CZ_53" },
  { code: "CZ_532", name: "Pardubice", parent: "CZ_53" },
  { code: "CZ_533", name: "Svitavy", parent: "CZ_53" },
  { code: "CZ_534", name: "Ústí nad Orlicí", parent: "CZ_53" },
  { code: "CZ_63", name: "Kraj Vysočina", parent: "CZ" },
  { code: "CZ_631", name: "Havlíčkův Brod", parent: "CZ_63" },
  { code: "CZ_632", name: "Jihlava", parent: "CZ_63" },
  { code: "CZ_633", name: "Pelhřimov", parent: "CZ_63" },
  { code: "CZ_634", name: "Třebíč", parent: "CZ_63" },
  { code: "CZ_635", name: "Žďár nad Sázavou", parent: "CZ_63" },
  { code: "CZ_64", name: "Jihomoravský kraj", parent: "CZ" },
  { code: "CZ_641", name: "Blansko", parent: "CZ_64" },
  { code: "CZ_642", name: "Brno-město", parent: "CZ_64" },
  { code: "CZ_643", name: "Brno-venkov", parent: "CZ_64" },
  { code: "CZ_644", name: "Břeclav", parent: "CZ_64" },
  { code: "CZ_645", name: "Hodonín", parent: "CZ_64" },
  { code: "CZ_646", name: "Vyškov", parent: "CZ_64" },
  { code: "CZ_647", name: "Znojmo", parent: "CZ_64" },
  { code: "CZ_71", name: "Olomoucký kraj", parent: "CZ" },
  { code: "CZ_711", name: "Jeseník", parent: "CZ_71" },
  { code: "CZ_712", name: "Olomouc", parent: "CZ_71" },
  { code: "CZ_713", name: "Prostějov", parent: "CZ_71" },
  { code: "CZ_714", name: "Přerov", parent: "CZ_71" },
  { code: "CZ_715", name: "Šumperk", parent: "CZ_71" },
  { code: "CZ_72", name: "Zlínský kraj", parent: "CZ" },
  { code: "CZ_721", name: "Kroměříž", parent: "CZ_72" },
  { code: "CZ_722", name: "Uherské Hradiště", parent: "CZ_72" },
  { code: "CZ_723", name: "Vsetín", parent: "CZ_72" },
  { code: "CZ_724", name: "Zlín", parent: "CZ_72" },
  { code: "CZ_80", name: "Moravskoslezský kraj", parent: "CZ" },
  { code: "CZ_801", name: "Bruntál", parent: "CZ_80" },
  { code: "CZ_802", name: "Frýdek-Místek", parent: "CZ_80" },
  { code: "CZ_803", name: "Karviná", parent: "CZ_80" },
  { code: "CZ_804", name: "Nový Jičín", parent: "CZ_80" },
  { code: "CZ_805", name: "Opava", parent: "CZ_80" },
  { code: "CZ_806", name: "Ostrava-město", parent: "CZ_80" },
  { code: "DE_BB", name: "Brandenburg", parent: "DE" },
  { code: "DE_BE", name: "Berlin", parent: "DE" },
  { code: "DE_BW", name: "Baden-Württemberg", parent: "DE" },
  { code: "DE_BY", name: "Bayern", parent: "DE" },
  { code: "DE_HB", name: "Bremen", parent: "DE" },
  { code: "DE_HE", name: "Hessen", parent: "DE" },
  { code: "DE_HH", name: "Hamburg", parent: "DE" },
  { code: "DE_MV", name: "Mecklenburg-Vorpommern", parent: "DE" },
  { code: "DE_NI", name: "Niedersachsen", parent: "DE" },
  { code: "DE_NW", name: "Nordrhein-Westfalen", parent: "DE" },
  { code: "DE_RP", name: "Rheinland-Pfalz", parent: "DE" },
  { code: "DE_SH", name: "Schleswig-Holstein", parent: "DE" },
  { code: "DE_SL", name: "Saarland", parent: "DE" },
  { code: "DE_SN", name: "Sachsen", parent: "DE" },
  { code: "DE_ST", name: "Sachsen-Anhalt", parent: "DE" },
  { code: "DE_TH", name: "Thüringen", parent: "DE" },
  { code: "DJ_AR", name: "Arta", parent: "DJ" },
  { code: "DJ_AS", name: "Ali Sabieh", parent: "DJ" },
  { code: "DJ_DI", name: "Dikhil", parent: "DJ" },
  { code: "DJ_DJ", name: "Djibouti", parent: "DJ" },
  { code: "DJ_OB", name: "Obock", parent: "DJ" },
  { code: "DJ_TA", name: "Tadjourah", parent: "DJ" },
  { code: "DK_015", name: "København", parent: "DK" },
  { code: "DK_020", name: "Frederiksborg", parent: "DK" },
  { code: "DK_025", name: "Roskilde", parent: "DK" },
  { code: "DK_030", name: "Vestsjælland", parent: "DK" },
  { code: "DK_035", name: "Storstrøm", parent: "DK" },
  { code: "DK_040", name: "Bornholm", parent: "DK" },
  { code: "DK_042", name: "Fyn", parent: "DK" },
  { code: "DK_050", name: "Sønderjylland", parent: "DK" },
  { code: "DK_055", name: "Ribe", parent: "DK" },
  { code: "DK_060", name: "Vejle", parent: "DK" },
  { code: "DK_065", name: "Ringkøbing", parent: "DK" },
  { code: "DK_070", name: "Århus", parent: "DK" },
  { code: "DK_076", name: "Viborg", parent: "DK" },
  { code: "DK_080", name: "Nordjylland", parent: "DK" },
  { code: "DK_101", name: "København", parent: "DK" },
  { code: "DK_147", name: "Frederiksberg", parent: "DK" },
  { code: "DK_81", name: "Region Nordjylland", parent: "DK" },
  { code: "DK_82", name: "Region Midjylland", parent: "DK" },
  { code: "DK_83", name: "Region Syddanmark", parent: "DK" },
  { code: "DK_84", name: "Region Hovedstaden", parent: "DK" },
  { code: "DK_85", name: "Region Sjælland", parent: "DK" },
  { code: "DM_02", name: "Saint Andrew", parent: "DM" },
  { code: "DM_03", name: "Saint David", parent: "DM" },
  { code: "DM_04", name: "Saint George", parent: "DM" },
  { code: "DM_05", name: "Saint John", parent: "DM" },
  { code: "DM_06", name: "Saint Joseph", parent: "DM" },
  { code: "DM_07", name: "Saint Luke", parent: "DM" },
  { code: "DM_08", name: "Saint Mark", parent: "DM" },
  { code: "DM_09", name: "Saint Patrick", parent: "DM" },
  { code: "DM_10", name: "Saint Paul", parent: "DM" },
  { code: "DM_11", name: "Saint Peter", parent: "DM" },
  { code: "DO_01", name: "Distrito Nacional (Santo Domingo)", parent: "DO_40" },
  { code: "DO_02", name: "Azua", parent: "DO_41" },
  { code: "DO_03", name: "Baoruco", parent: "DO_38" },
  { code: "DO_04", name: "Barahona", parent: "DO_38" },
  { code: "DO_05", name: "Dajabón", parent: "DO_34" },
  { code: "DO_06", name: "Duarte", parent: "DO_33" },
  { code: "DO_07", name: "Elías Piña", parent: "DO_37" },
  { code: "DO_08", name: "El Seibo", parent: "DO_42" },
  { code: "DO_09", name: "Espaillat", parent: "DO_35" },
  { code: "DO_10", name: "Independencia", parent: "DO_38" },
  { code: "DO_11", name: "La Altagracia", parent: "DO_42" },
  { code: "DO_12", name: "La Romana", parent: "DO_42" },
  { code: "DO_13", name: "La Vega", parent: "DO_36" },
  { code: "DO_14", name: "María Trinidad Sánchez", parent: "DO_33" },
  { code: "DO_15", name: "Monte Cristi", parent: "DO_34" },
  { code: "DO_16", name: "Pedernales", parent: "DO_38" },
  { code: "DO_17", name: "Peravia", parent: "DO_41" },
  { code: "DO_18", name: "Puerto Plata", parent: "DO_35" },
  { code: "DO_19", name: "Hermanas Mirabal", parent: "DO_33" },
  { code: "DO_20", name: "Samaná", parent: "DO_33" },
  { code: "DO_21", name: "San Cristóbal", parent: "DO_41" },
  { code: "DO_22", name: "San Juan", parent: "DO_37" },
  { code: "DO_23", name: "San Pedro de Macorís", parent: "DO_39" },
  { code: "DO_24", name: "Sánchez Ramírez", parent: "DO_36" },
  { code: "DO_25", name: "Santiago", parent: "DO_35" },
  { code: "DO_26", name: "Santiago Rodríguez", parent: "DO_34" },
  { code: "DO_27", name: "Valverde", parent: "DO_34" },
  { code: "DO_28", name: "Monseñor Nouel", parent: "DO_36" },
  { code: "DO_29", name: "Monte Plata", parent: "DO_39" },
  { code: "DO_30", name: "Hato Mayor", parent: "DO_39" },
  { code: "DO_31", name: "San José de Ocoa", parent: "DO_41" },
  { code: "DO_32", name: "Santo Domingo", parent: "DO_40" },
  { code: "DO_33", name: "Cibao Nordeste", parent: "DO" },
  { code: "DO_34", name: "Cibao Noroeste", parent: "DO" },
  { code: "DO_35", name: "Cibao Norte", parent: "DO" },
  { code: "DO_36", name: "Cibao Sur", parent: "DO" },
  { code: "DO_37", name: "El Valle", parent: "DO" },
  { code: "DO_38", name: "Enriquillo", parent: "DO" },
  { code: "DO_39", name: "Higuamo", parent: "DO" },
  { code: "DO_40", name: "Ozama", parent: "DO" },
  { code: "DO_41", name: "Valdesia", parent: "DO" },
  { code: "DO_42", name: "Yuma", parent: "DO" },
  { code: "DZ_01", name: "Adrar", parent: "DZ" },
  { code: "DZ_02", name: "Chlef", parent: "DZ" },
  { code: "DZ_03", name: "Laghouat", parent: "DZ" },
  { code: "DZ_04", name: "Oum el Bouaghi", parent: "DZ" },
  { code: "DZ_05", name: "Batna", parent: "DZ" },
  { code: "DZ_06", name: "Béjaïa", parent: "DZ" },
  { code: "DZ_07", name: "Biskra", parent: "DZ" },
  { code: "DZ_08", name: "Béchar", parent: "DZ" },
  { code: "DZ_09", name: "Blida", parent: "DZ" },
  { code: "DZ_10", name: "Bouira", parent: "DZ" },
  { code: "DZ_11", name: "Tamanrasset", parent: "DZ" },
  { code: "DZ_12", name: "Tébessa", parent: "DZ" },
  { code: "DZ_13", name: "Tlemcen", parent: "DZ" },
  { code: "DZ_14", name: "Tiaret", parent: "DZ" },
  { code: "DZ_15", name: "Tizi Ouzou", parent: "DZ" },
  { code: "DZ_16", name: "Alger", parent: "DZ" },
  { code: "DZ_17", name: "Djelfa", parent: "DZ" },
  { code: "DZ_18", name: "Jijel", parent: "DZ" },
  { code: "DZ_19", name: "Sétif", parent: "DZ" },
  { code: "DZ_20", name: "Saïda", parent: "DZ" },
  { code: "DZ_21", name: "Skikda", parent: "DZ" },
  { code: "DZ_22", name: "Sidi Bel Abbès", parent: "DZ" },
  { code: "DZ_23", name: "Annaba", parent: "DZ" },
  { code: "DZ_24", name: "Guelma", parent: "DZ" },
  { code: "DZ_25", name: "Constantine", parent: "DZ" },
  { code: "DZ_26", name: "Médéa", parent: "DZ" },
  { code: "DZ_27", name: "Mostaganem", parent: "DZ" },
  { code: "DZ_28", name: "M'sila", parent: "DZ" },
  { code: "DZ_29", name: "Mascara", parent: "DZ" },
  { code: "DZ_30", name: "Ouargla", parent: "DZ" },
  { code: "DZ_31", name: "Oran", parent: "DZ" },
  { code: "DZ_32", name: "El Bayadh", parent: "DZ" },
  { code: "DZ_33", name: "Illizi", parent: "DZ" },
  { code: "DZ_34", name: "Bordj Bou Arréridj", parent: "DZ" },
  { code: "DZ_35", name: "Boumerdès", parent: "DZ" },
  { code: "DZ_36", name: "El Tarf", parent: "DZ" },
  { code: "DZ_37", name: "Tindouf", parent: "DZ" },
  { code: "DZ_38", name: "Tissemsilt", parent: "DZ" },
  { code: "DZ_39", name: "El Oued", parent: "DZ" },
  { code: "DZ_40", name: "Khenchela", parent: "DZ" },
  { code: "DZ_41", name: "Souk Ahras", parent: "DZ" },
  { code: "DZ_42", name: "Tipaza", parent: "DZ" },
  { code: "DZ_43", name: "Mila", parent: "DZ" },
  { code: "DZ_44", name: "Aïn Defla", parent: "DZ" },
  { code: "DZ_45", name: "Naama", parent: "DZ" },
  { code: "DZ_46", name: "Aïn Témouchent", parent: "DZ" },
  { code: "DZ_47", name: "Ghardaïa", parent: "DZ" },
  { code: "DZ_48", name: "Relizane", parent: "DZ" },
  { code: "DZ_49", name: "Timimoun", parent: "DZ" },
  { code: "DZ_50", name: "Bordj Badji Mokhtar", parent: "DZ" },
  { code: "DZ_51", name: "Ouled Djellal", parent: "DZ" },
  { code: "DZ_52", name: "Béni Abbès", parent: "DZ" },
  { code: "DZ_53", name: "In Salah", parent: "DZ" },
  { code: "DZ_54", name: "In Guezzam", parent: "DZ" },
  { code: "DZ_55", name: "Touggourt", parent: "DZ" },
  { code: "DZ_56", name: "Djanet", parent: "DZ" },
  { code: "DZ_57", name: "El Meghaier", parent: "DZ" },
  { code: "DZ_58", name: "El Meniaa", parent: "DZ" },
  { code: "EC_A", name: "Azuay", parent: "EC" },
  { code: "EC_B", name: "Bolívar", parent: "EC" },
  { code: "EC_C", name: "Carchi", parent: "EC" },
  { code: "EC_D", name: "Orellana", parent: "EC" },
  { code: "EC_E", name: "Esmeraldas", parent: "EC" },
  { code: "EC_F", name: "Cañar", parent: "EC" },
  { code: "EC_G", name: "Guayas", parent: "EC" },
  { code: "EC_H", name: "Chimborazo", parent: "EC" },
  { code: "EC_I", name: "Imbabura", parent: "EC" },
  { code: "EC_L", name: "Loja", parent: "EC" },
  { code: "EC_M", name: "Manabí", parent: "EC" },
  { code: "EC_N", name: "Napo", parent: "EC" },
  { code: "EC_O", name: "El Oro", parent: "EC" },
  { code: "EC_P", name: "Pichincha", parent: "EC" },
  { code: "EC_R", name: "Los Ríos", parent: "EC" },
  { code: "EC_S", name: "Morona Santiago", parent: "EC" },
  { code: "EC_SD", name: "Santo Domingo de los Tsáchilas", parent: "EC" },
  { code: "EC_SE", name: "Santa Elena", parent: "EC" },
  { code: "EC_T", name: "Tungurahua", parent: "EC" },
  { code: "EC_U", name: "Sucumbíos", parent: "EC" },
  { code: "EC_W", name: "Galápagos", parent: "EC" },
  { code: "EC_X", name: "Cotopaxi", parent: "EC" },
  { code: "EC_Y", name: "Pastaza", parent: "EC" },
  { code: "EC_Z", name: "Zamora Chinchipe", parent: "EC" },
  { code: "EE_130", name: "Alutaguse", parent: "EE" },
  { code: "EE_141", name: "Anija", parent: "EE" },
  { code: "EE_142", name: "Antsla", parent: "EE" },
  { code: "EE_171", name: "Elva", parent: "EE" },
  { code: "EE_184", name: "Haapsalu", parent: "EE" },
  { code: "EE_191", name: "Haljala", parent: "EE" },
  { code: "EE_198", name: "Harku", parent: "EE" },
  { code: "EE_205", name: "Hiiumaa", parent: "EE" },
  { code: "EE_214", name: "Häädemeeste", parent: "EE" },
  { code: "EE_245", name: "Jõelähtme", parent: "EE" },
  { code: "EE_247", name: "Jõgeva", parent: "EE" },
  { code: "EE_251", name: "Jõhvi", parent: "EE" },
  { code: "EE_255", name: "Järva", parent: "EE" },
  { code: "EE_272", name: "Kadrina", parent: "EE" },
  { code: "EE_283", name: "Kambja", parent: "EE" },
  { code: "EE_284", name: "Kanepi", parent: "EE" },
  { code: "EE_291", name: "Kastre", parent: "EE" },
  { code: "EE_293", name: "Kehtna", parent: "EE" },
  { code: "EE_296", name: "Keila", parent: "EE" },
  { code: "EE_303", name: "Kihnu", parent: "EE" },
  { code: "EE_305", name: "Kiili", parent: "EE" },
  { code: "EE_317", name: "Kohila", parent: "EE" },
  { code: "EE_321", name: "Kohtla-Järve", parent: "EE" },
  { code: "EE_338", name: "Kose", parent: "EE" },
  { code: "EE_353", name: "Kuusalu", parent: "EE" },
  { code: "EE_37", name: "Harjumaa", parent: "EE" },
  { code: "EE_39", name: "Hiiumaa", parent: "EE" },
  { code: "EE_424", name: "Loksa", parent: "EE" },
  { code: "EE_430", name: "Lääneranna", parent: "EE" },
  { code: "EE_431", name: "Lääne-Harju", parent: "EE" },
  { code: "EE_432", name: "Luunja", parent: "EE" },
  { code: "EE_441", name: "Lääne-Nigula", parent: "EE" },
  { code: "EE_442", name: "Lüganuse", parent: "EE" },
  { code: "EE_446", name: "Maardu", parent: "EE" },
  { code: "EE_45", name: "Ida-Virumaa", parent: "EE" },
  { code: "EE_478", name: "Muhu", parent: "EE" },
  { code: "EE_480", name: "Mulgi", parent: "EE" },
  { code: "EE_486", name: "Mustvee", parent: "EE" },
  { code: "EE_50", name: "Jõgevamaa", parent: "EE" },
  { code: "EE_503", name: "Märjamaa", parent: "EE" },
  { code: "EE_511", name: "Narva", parent: "EE" },
  { code: "EE_514", name: "Narva-Jõesuu", parent: "EE" },
  { code: "EE_52", name: "Järvamaa", parent: "EE" },
  { code: "EE_528", name: "Nõo", parent: "EE" },
  { code: "EE_557", name: "Otepää", parent: "EE" },
  { code: "EE_56", name: "Läänemaa", parent: "EE" },
  { code: "EE_567", name: "Paide", parent: "EE" },
  { code: "EE_586", name: "Peipsiääre", parent: "EE" },
  { code: "EE_60", name: "Lääne-Virumaa", parent: "EE" },
  { code: "EE_615", name: "Põhja-Sakala", parent: "EE" },
  { code: "EE_618", name: "Põltsamaa", parent: "EE" },
  { code: "EE_622", name: "Põlva", parent: "EE" },
  { code: "EE_624", name: "Pärnu", parent: "EE" },
  { code: "EE_638", name: "Põhja-Pärnumaa", parent: "EE" },
  { code: "EE_64", name: "Põlvamaa", parent: "EE" },
  { code: "EE_651", name: "Raasiku", parent: "EE" },
  { code: "EE_653", name: "Rae", parent: "EE" },
  { code: "EE_661", name: "Rakvere", parent: "EE" },
  { code: "EE_663", name: "Rakvere", parent: "EE" },
  { code: "EE_668", name: "Rapla", parent: "EE" },
  { code: "EE_68", name: "Pärnumaa", parent: "EE" },
  { code: "EE_689", name: "Ruhnu", parent: "EE" },
  { code: "EE_698", name: "Rõuge", parent: "EE" },
  { code: "EE_708", name: "Räpina", parent: "EE" },
  { code: "EE_71", name: "Raplamaa", parent: "EE" },
  { code: "EE_712", name: "Saarde", parent: "EE" },
  { code: "EE_714", name: "Saaremaa", parent: "EE" },
  { code: "EE_719", name: "Saku", parent: "EE" },
  { code: "EE_726", name: "Saue", parent: "EE" },
  { code: "EE_732", name: "Setomaa", parent: "EE" },
  { code: "EE_735", name: "Sillamäe", parent: "EE" },
  { code: "EE_74", name: "Saaremaa", parent: "EE" },
  { code: "EE_784", name: "Tallinn", parent: "EE" },
  { code: "EE_79", name: "Tartumaa", parent: "EE" },
  { code: "EE_792", name: "Tapa", parent: "EE" },
  { code: "EE_793", name: "Tartu", parent: "EE" },
  { code: "EE_796", name: "Tartu", parent: "EE" },
  { code: "EE_803", name: "Toila", parent: "EE" },
  { code: "EE_809", name: "Tori", parent: "EE" },
  { code: "EE_81", name: "Valgamaa", parent: "EE" },
  { code: "EE_824", name: "Tõrva", parent: "EE" },
  { code: "EE_834", name: "Türi", parent: "EE" },
  { code: "EE_84", name: "Viljandimaa", parent: "EE" },
  { code: "EE_855", name: "Valga", parent: "EE" },
  { code: "EE_87", name: "Võrumaa", parent: "EE" },
  { code: "EE_890", name: "Viimsi", parent: "EE" },
  { code: "EE_897", name: "Viljandi", parent: "EE" },
  { code: "EE_899", name: "Viljandi", parent: "EE" },
  { code: "EE_901", name: "Vinni", parent: "EE" },
  { code: "EE_903", name: "Viru-Nigula", parent: "EE" },
  { code: "EE_907", name: "Vormsi", parent: "EE" },
  { code: "EE_917", name: "Võru", parent: "EE" },
  { code: "EE_919", name: "Võru", parent: "EE" },
  { code: "EE_928", name: "Väike-Maarja", parent: "EE" },
  { code: "EG_ALX", name: "Al Iskandarīyah", parent: "EG" },
  { code: "EG_ASN", name: "Aswān", parent: "EG" },
  { code: "EG_AST", name: "Asyūţ", parent: "EG" },
  { code: "EG_BA", name: "Al Baḩr al Aḩmar", parent: "EG" },
  { code: "EG_BH", name: "Al Buḩayrah", parent: "EG" },
  { code: "EG_BNS", name: "Banī Suwayf", parent: "EG" },
  { code: "EG_C", name: "Al Qāhirah", parent: "EG" },
  { code: "EG_DK", name: "Ad Daqahlīyah", parent: "EG" },
  { code: "EG_DT", name: "Dumyāţ", parent: "EG" },
  { code: "EG_FYM", name: "Al Fayyūm", parent: "EG" },
  { code: "EG_GH", name: "Al Gharbīyah", parent: "EG" },
  { code: "EG_GZ", name: "Al Jīzah", parent: "EG" },
  { code: "EG_IS", name: "Al Ismā'īlīyah", parent: "EG" },
  { code: "EG_JS", name: "Janūb Sīnā'", parent: "EG" },
  { code: "EG_KB", name: "Al Qalyūbīyah", parent: "EG" },
  { code: "EG_KFS", name: "Kafr ash Shaykh", parent: "EG" },
  { code: "EG_KN", name: "Qinā", parent: "EG" },
  { code: "EG_LX", name: "Al Uqşur", parent: "EG" },
  { code: "EG_MN", name: "Al Minyā", parent: "EG" },
  { code: "EG_MNF", name: "Al Minūfīyah", parent: "EG" },
  { code: "EG_MT", name: "Maţrūḩ", parent: "EG" },
  { code: "EG_PTS", name: "Būr Sa‘īd", parent: "EG" },
  { code: "EG_SHG", name: "Sūhāj", parent: "EG" },
  { code: "EG_SHR", name: "Ash Sharqīyah", parent: "EG" },
  { code: "EG_SIN", name: "Shamāl Sīnā'", parent: "EG" },
  { code: "EG_SUZ", name: "As Suways", parent: "EG" },
  { code: "EG_WAD", name: "Al Wādī al Jadīd", parent: "EG" },
  { code: "ER_AN", name: "Ansabā", parent: "ER" },
  { code: "ER_DK", name: "Janūbī al Baḩrī al Aḩmar", parent: "ER" },
  { code: "ER_DU", name: "Al Janūbī", parent: "ER" },
  { code: "ER_GB", name: "Qāsh-Barkah", parent: "ER" },
  { code: "ER_MA", name: "Al Awsaţ", parent: "ER" },
  { code: "ER_SK", name: "Shimālī al Baḩrī al Aḩmar", parent: "ER" },
  { code: "ES_A", name: "Alicante", parent: "ES_VC" },
  { code: "ES_AB", name: "Albacete", parent: "ES_CM" },
  { code: "ES_AL", name: "Almería", parent: "ES_AN" },
  { code: "ES_AN", name: "Andalucía", parent: "ES" },
  { code: "ES_AR", name: "Aragón", parent: "ES" },
  { code: "ES_AS", name: "Asturias, Principado de", parent: "ES" },
  { code: "ES_AV", name: "Ávila", parent: "ES_CL" },
  { code: "ES_B", name: "Barcelona", parent: "ES_CT" },
  { code: "ES_BA", name: "Badajoz", parent: "ES_EX" },
  { code: "ES_BI", name: "Bizkaia", parent: "ES_PV" },
  { code: "ES_BU", name: "Burgos", parent: "ES_CL" },
  { code: "ES_C", name: "A Coruña", parent: "ES_GA" },
  { code: "ES_CA", name: "Cádiz", parent: "ES_AN" },
  { code: "ES_CB", name: "Cantabria", parent: "ES" },
  { code: "ES_CC", name: "Cáceres", parent: "ES_EX" },
  { code: "ES_CE", name: "Ceuta", parent: "ES" },
  { code: "ES_CL", name: "Castilla y León", parent: "ES" },
  { code: "ES_CM", name: "Castilla-La Mancha", parent: "ES" },
  { code: "ES_CN", name: "Canarias", parent: "ES" },
  { code: "ES_CO", name: "Córdoba", parent: "ES_AN" },
  { code: "ES_CR", name: "Ciudad Real", parent: "ES_CM" },
  { code: "ES_CS", name: "Castellón", parent: "ES_VC" },
  { code: "ES_CT", name: "Catalunya", parent: "ES" },
  { code: "ES_CU", name: "Cuenca", parent: "ES_CM" },
  { code: "ES_EX", name: "Extremadura", parent: "ES" },
  { code: "ES_GA", name: "Galicia", parent: "ES" },
  { code: "ES_GC", name: "Las Palmas", parent: "ES_CN" },
  { code: "ES_GI", name: "Girona", parent: "ES_CT" },
  { code: "ES_GR", name: "Granada", parent: "ES_AN" },
  { code: "ES_GU", name: "Guadalajara", parent: "ES_CM" },
  { code: "ES_H", name: "Huelva", parent: "ES_AN" },
  { code: "ES_HU", name: "Huesca", parent: "ES_AR" },
  { code: "ES_IB", name: "Illes Balears", parent: "ES" },
  { code: "ES_J", name: "Jaén", parent: "ES_AN" },
  { code: "ES_L", name: "Lleida", parent: "ES_CT" },
  { code: "ES_LE", name: "León", parent: "ES_CL" },
  { code: "ES_LO", name: "La Rioja", parent: "ES_RI" },
  { code: "ES_LU", name: "Lugo", parent: "ES_GA" },
  { code: "ES_M", name: "Madrid", parent: "ES_MD" },
  { code: "ES_MA", name: "Málaga", parent: "ES_AN" },
  { code: "ES_MC", name: "Murcia, Región de", parent: "ES" },
  { code: "ES_MD", name: "Madrid, Comunidad de", parent: "ES" },
  { code: "ES_ML", name: "Melilla", parent: "ES" },
  { code: "ES_MU", name: "Murcia", parent: "ES_MC" },
  { code: "ES_NA", name: "Navarra", parent: "ES_NC" },
  { code: "ES_NC", name: "Navarra, Comunidad Foral de", parent: "ES" },
  { code: "ES_O", name: "Asturias", parent: "ES_AS" },
  { code: "ES_OR", name: "Ourense", parent: "ES_GA" },
  { code: "ES_P", name: "Palencia", parent: "ES_CL" },
  { code: "ES_PM", name: "Illes Balears", parent: "ES_IB" },
  { code: "ES_PO", name: "Pontevedra", parent: "ES_GA" },
  { code: "ES_PV", name: "País Vasco", parent: "ES" },
  { code: "ES_RI", name: "La Rioja", parent: "ES" },
  { code: "ES_S", name: "Cantabria", parent: "ES_CB" },
  { code: "ES_SA", name: "Salamanca", parent: "ES_CL" },
  { code: "ES_SE", name: "Sevilla", parent: "ES_AN" },
  { code: "ES_SG", name: "Segovia", parent: "ES_CL" },
  { code: "ES_SO", name: "Soria", parent: "ES_CL" },
  { code: "ES_SS", name: "Gipuzkoa", parent: "ES_PV" },
  { code: "ES_T", name: "Tarragona", parent: "ES_CT" },
  { code: "ES_TE", name: "Teruel", parent: "ES_AR" },
  { code: "ES_TF", name: "Santa Cruz de Tenerife", parent: "ES_CN" },
  { code: "ES_TO", name: "Toledo", parent: "ES_CM" },
  { code: "ES_V", name: "Valencia", parent: "ES_VC" },
  { code: "ES_VA", name: "Valladolid", parent: "ES_CL" },
  { code: "ES_VC", name: "Valenciana, Comunidad", parent: "ES" },
  { code: "ES_VI", name: "Álava", parent: "ES_PV" },
  { code: "ES_Z", name: "Zaragoza", parent: "ES_AR" },
  { code: "ES_ZA", name: "Zamora", parent: "ES_CL" },
  { code: "ET_AA", name: "Ādīs Ābeba", parent: "ET" },
  { code: "ET_AF", name: "Āfar", parent: "ET" },
  { code: "ET_AM", name: "Āmara", parent: "ET" },
  { code: "ET_BE", name: "Bīnshangul Gumuz", parent: "ET" },
  { code: "ET_DD", name: "Dirē Dawa", parent: "ET" },
  { code: "ET_GA", name: "Gambēla Hizboch", parent: "ET" },
  { code: "ET_HA", name: "Hārerī Hizb", parent: "ET" },
  { code: "ET_OR", name: "Oromīya", parent: "ET" },
  { code: "ET_SI", name: "Sīdama", parent: "ET" },
  { code: "ET_SN", name: "YeDebub Bihēroch Bihēreseboch na Hizboch", parent: "ET" },
  { code: "ET_SO", name: "Sumalē", parent: "ET" },
  { code: "ET_SW", name: "YeDebub M‘irab Ītyop’iya Hizboch", parent: "ET" },
  { code: "ET_TI", name: "Tigray", parent: "ET" },
  { code: "FI_01", name: "Ahvenanmaan maakunta", parent: "FI" },
  { code: "FI_02", name: "Etelä-Karjala", parent: "FI" },
  { code: "FI_03", name: "Etelä-Pohjanmaa", parent: "FI" },
  { code: "FI_04", name: "Etelä-Savo", parent: "FI" },
  { code: "FI_05", name: "Kainuu", parent: "FI" },
  { code: "FI_06", name: "Kanta-Häme", parent: "FI" },
  { code: "FI_07", name: "Keski-Pohjanmaa", parent: "FI" },
  { code: "FI_08", name: "Keski-Suomi", parent: "FI" },
  { code: "FI_09", name: "Kymenlaakso", parent: "FI" },
  { code: "FI_10", name: "Lappi", parent: "FI" },
  { code: "FI_11", name: "Pirkanmaa", parent: "FI" },
  { code: "FI_12", name: "Pohjanmaa", parent: "FI" },
  { code: "FI_13", name: "Pohjois-Karjala", parent: "FI" },
  { code: "FI_14", name: "Pohjois-Pohjanmaa", parent: "FI" },
  { code: "FI_15", name: "Pohjois-Savo", parent: "FI" },
  { code: "FI_16", name: "Päijät-Häme", parent: "FI" },
  { code: "FI_17", name: "Satakunta", parent: "FI" },
  { code: "FI_18", name: "Uusimaa", parent: "FI" },
  { code: "FI_19", name: "Varsinais-Suomi", parent: "FI" },
  { code: "FJ_01", name: "Ba", parent: "FJ_W" },
  { code: "FJ_02", name: "Bua", parent: "FJ_N" },
  { code: "FJ_03", name: "Cakaudrove", parent: "FJ_N" },
  { code: "FJ_04", name: "Kadavu", parent: "FJ_E" },
  { code: "FJ_05", name: "Lau", parent: "FJ_E" },
  { code: "FJ_06", name: "Lomaiviti", parent: "FJ_E" },
  { code: "FJ_07", name: "Macuata", parent: "FJ_N" },
  { code: "FJ_08", name: "Nadroga and Navosa", parent: "FJ_W" },
  { code: "FJ_09", name: "Naitasiri", parent: "FJ_C" },
  { code: "FJ_10", name: "Namosi", parent: "FJ_C" },
  { code: "FJ_11", name: "Ra", parent: "FJ_W" },
  { code: "FJ_12", name: "Rewa", parent: "FJ_C" },
  { code: "FJ_13", name: "Serua", parent: "FJ_C" },
  { code: "FJ_14", name: "Tailevu", parent: "FJ_C" },
  { code: "FJ_C", name: "Central", parent: "FJ" },
  { code: "FJ_E", name: "Eastern", parent: "FJ" },
  { code: "FJ_N", name: "Northern", parent: "FJ" },
  { code: "FJ_R", name: "Rotuma", parent: "FJ" },
  { code: "FJ_W", name: "Western", parent: "FJ" },
  { code: "FM_KSA", name: "Kosrae", parent: "FM" },
  { code: "FM_PNI", name: "Pohnpei", parent: "FM" },
  { code: "FM_TRK", name: "Chuuk", parent: "FM" },
  { code: "FM_YAP", name: "Yap", parent: "FM" },
  { code: "FR_01", name: "Ain", parent: "FR_ARA" },
  { code: "FR_02", name: "Aisne", parent: "FR_HDF" },
  { code: "FR_03", name: "Allier", parent: "FR_ARA" },
  { code: "FR_04", name: "Alpes-de-Haute-Provence", parent: "FR_PAC" },
  { code: "FR_05", name: "Hautes-Alpes", parent: "FR_PAC" },
  { code: "FR_06", name: "Alpes-Maritimes", parent: "FR_PAC" },
  { code: "FR_07", name: "Ardèche", parent: "FR_ARA" },
  { code: "FR_08", name: "Ardennes", parent: "FR_GES" },
  { code: "FR_09", name: "Ariège", parent: "FR_OCC" },
  { code: "FR_10", name: "Aube", parent: "FR_GES" },
  { code: "FR_11", name: "Aude", parent: "FR_OCC" },
  { code: "FR_12", name: "Aveyron", parent: "FR_OCC" },
  { code: "FR_13", name: "Bouches-du-Rhône", parent: "FR_PAC" },
  { code: "FR_14", name: "Calvados", parent: "FR_NOR" },
  { code: "FR_15", name: "Cantal", parent: "FR_ARA" },
  { code: "FR_16", name: "Charente", parent: "FR_NAQ" },
  { code: "FR_17", name: "Charente-Maritime", parent: "FR_NAQ" },
  { code: "FR_18", name: "Cher", parent: "FR_CVL" },
  { code: "FR_19", name: "Corrèze", parent: "FR_NAQ" },
  { code: "FR_20R", name: "Corse", parent: "FR" },
  { code: "FR_21", name: "Côte-d'Or", parent: "FR_BFC" },
  { code: "FR_22", name: "Côtes-d'Armor", parent: "FR_BRE" },
  { code: "FR_23", name: "Creuse", parent: "FR_NAQ" },
  { code: "FR_24", name: "Dordogne", parent: "FR_NAQ" },
  { code: "FR_25", name: "Doubs", parent: "FR_BFC" },
  { code: "FR_26", name: "Drôme", parent: "FR_ARA" },
  { code: "FR_27", name: "Eure", parent: "FR_NOR" },
  { code: "FR_28", name: "Eure-et-Loir", parent: "FR_CVL" },
  { code: "FR_29", name: "Finistère", parent: "FR_BRE" },
  { code: "FR_2A", name: "Corse-du-Sud", parent: "FR_20R" },
  { code: "FR_2B", name: "Haute-Corse", parent: "FR_20R" },
  { code: "FR_30", name: "Gard", parent: "FR_OCC" },
  { code: "FR_31", name: "Haute-Garonne", parent: "FR_OCC" },
  { code: "FR_32", name: "Gers", parent: "FR_OCC" },
  { code: "FR_33", name: "Gironde", parent: "FR_NAQ" },
  { code: "FR_34", name: "Hérault", parent: "FR_OCC" },
  { code: "FR_35", name: "Ille-et-Vilaine", parent: "FR_BRE" },
  { code: "FR_36", name: "Indre", parent: "FR_CVL" },
  { code: "FR_37", name: "Indre-et-Loire", parent: "FR_CVL" },
  { code: "FR_38", name: "Isère", parent: "FR_ARA" },
  { code: "FR_39", name: "Jura", parent: "FR_BFC" },
  { code: "FR_40", name: "Landes", parent: "FR_NAQ" },
  { code: "FR_41", name: "Loir-et-Cher", parent: "FR_CVL" },
  { code: "FR_42", name: "Loire", parent: "FR_ARA" },
  { code: "FR_43", name: "Haute-Loire", parent: "FR_ARA" },
  { code: "FR_44", name: "Loire-Atlantique", parent: "FR_PDL" },
  { code: "FR_45", name: "Loiret", parent: "FR_CVL" },
  { code: "FR_46", name: "Lot", parent: "FR_OCC" },
  { code: "FR_47", name: "Lot-et-Garonne", parent: "FR_NAQ" },
  { code: "FR_48", name: "Lozère", parent: "FR_OCC" },
  { code: "FR_49", name: "Maine-et-Loire", parent: "FR_PDL" },
  { code: "FR_50", name: "Manche", parent: "FR_NOR" },
  { code: "FR_51", name: "Marne", parent: "FR_GES" },
  { code: "FR_52", name: "Haute-Marne", parent: "FR_GES" },
  { code: "FR_53", name: "Mayenne", parent: "FR_PDL" },
  { code: "FR_54", name: "Meurthe-et-Moselle", parent: "FR_GES" },
  { code: "FR_55", name: "Meuse", parent: "FR_GES" },
  { code: "FR_56", name: "Morbihan", parent: "FR_BRE" },
  { code: "FR_57", name: "Moselle", parent: "FR_GES" },
  { code: "FR_58", name: "Nièvre", parent: "FR_BFC" },
  { code: "FR_59", name: "Nord", parent: "FR_HDF" },
  { code: "FR_60", name: "Oise", parent: "FR_HDF" },
  { code: "FR_61", name: "Orne", parent: "FR_NOR" },
  { code: "FR_62", name: "Pas-de-Calais", parent: "FR_HDF" },
  { code: "FR_63", name: "Puy-de-Dôme", parent: "FR_ARA" },
  { code: "FR_64", name: "Pyrénées-Atlantiques", parent: "FR_NAQ" },
  { code: "FR_65", name: "Hautes-Pyrénées", parent: "FR_OCC" },
  { code: "FR_66", name: "Pyrénées-Orientales", parent: "FR_OCC" },
  { code: "FR_67", name: "Bas-Rhin", parent: "FR_6AE" },
  { code: "FR_68", name: "Haut-Rhin", parent: "FR_6AE" },
  { code: "FR_69", name: "Rhône", parent: "FR_ARA" },
  { code: "FR_69M", name: "Métropole de Lyon", parent: "FR_ARA" },
  { code: "FR_6AE", name: "Alsace", parent: "FR_GES" },
  { code: "FR_70", name: "Haute-Saône", parent: "FR_BFC" },
  { code: "FR_71", name: "Saône-et-Loire", parent: "FR_BFC" },
  { code: "FR_72", name: "Sarthe", parent: "FR_PDL" },
  { code: "FR_73", name: "Savoie", parent: "FR_ARA" },
  { code: "FR_74", name: "Haute-Savoie", parent: "FR_ARA" },
  { code: "FR_75C", name: "Paris", parent: "FR_IDF" },
  { code: "FR_76", name: "Seine-Maritime", parent: "FR_NOR" },
  { code: "FR_77", name: "Seine-et-Marne", parent: "FR_IDF" },
  { code: "FR_78", name: "Yvelines", parent: "FR_IDF" },
  { code: "FR_79", name: "Deux-Sèvres", parent: "FR_NAQ" },
  { code: "FR_80", name: "Somme", parent: "FR_HDF" },
  { code: "FR_81", name: "Tarn", parent: "FR_OCC" },
  { code: "FR_82", name: "Tarn-et-Garonne", parent: "FR_OCC" },
  { code: "FR_83", name: "Var", parent: "FR_PAC" },
  { code: "FR_84", name: "Vaucluse", parent: "FR_PAC" },
  { code: "FR_85", name: "Vendée", parent: "FR_PDL" },
  { code: "FR_86", name: "Vienne", parent: "FR_NAQ" },
  { code: "FR_87", name: "Haute-Vienne", parent: "FR_NAQ" },
  { code: "FR_88", name: "Vosges", parent: "FR_GES" },
  { code: "FR_89", name: "Yonne", parent: "FR_BFC" },
  { code: "FR_90", name: "Territoire de Belfort", parent: "FR_BFC" },
  { code: "FR_91", name: "Essonne", parent: "FR_IDF" },
  { code: "FR_92", name: "Hauts-de-Seine", parent: "FR_IDF" },
  { code: "FR_93", name: "Seine-Saint-Denis", parent: "FR_IDF" },
  { code: "FR_94", name: "Val-de-Marne", parent: "FR_IDF" },
  { code: "FR_95", name: "Val-d'Oise", parent: "FR_IDF" },
  { code: "FR_971", name: "Guadeloupe", parent: "FR" },
  { code: "FR_972", name: "Martinique", parent: "FR" },
  { code: "FR_973", name: "Guyane (française)", parent: "FR" },
  { code: "FR_974", name: "La Réunion", parent: "FR" },
  { code: "FR_976", name: "Mayotte", parent: "FR" },
  { code: "FR_ARA", name: "Auvergne-Rhône-Alpes", parent: "FR" },
  { code: "FR_BFC", name: "Bourgogne-Franche-Comté", parent: "FR" },
  { code: "FR_BL", name: "Saint-Barthélemy", parent: "FR" },
  { code: "FR_BRE", name: "Bretagne", parent: "FR" },
  { code: "FR_CP", name: "Clipperton", parent: "FR" },
  { code: "FR_CVL", name: "Centre-Val de Loire", parent: "FR" },
  { code: "FR_GES", name: "Grand-Est", parent: "FR" },
  { code: "FR_HDF", name: "Hauts-de-France", parent: "FR" },
  { code: "FR_IDF", name: "Île-de-France", parent: "FR" },
  { code: "FR_MF", name: "Saint-Martin", parent: "FR" },
  { code: "FR_NAQ", name: "Nouvelle-Aquitaine", parent: "FR" },
  { code: "FR_NC", name: "Nouvelle-Calédonie", parent: "FR" },
  { code: "FR_NOR", name: "Normandie", parent: "FR" },
  { code: "FR_OCC", name: "Occitanie", parent: "FR" },
  { code: "FR_PAC", name: "Provence-Alpes-Côte-d’Azur", parent: "FR" },
  { code: "FR_PDL", name: "Pays-de-la-Loire", parent: "FR" },
  { code: "FR_PF", name: "Polynésie française", parent: "FR" },
  { code: "FR_PM", name: "Saint-Pierre-et-Miquelon", parent: "FR" },
  { code: "FR_TF", name: "Terres australes françaises", parent: "FR" },
  { code: "FR_WF", name: "Wallis-et-Futuna", parent: "FR" },
  { code: "GA_1", name: "Estuaire", parent: "GA" },
  { code: "GA_2", name: "Haut-Ogooué", parent: "GA" },
  { code: "GA_3", name: "Moyen-Ogooué", parent: "GA" },
  { code: "GA_4", name: "Ngounié", parent: "GA" },
  { code: "GA_5", name: "Nyanga", parent: "GA" },
  { code: "GA_6", name: "Ogooué-Ivindo", parent: "GA" },
  { code: "GA_7", name: "Ogooué-Lolo", parent: "GA" },
  { code: "GA_8", name: "Ogooué-Maritime", parent: "GA" },
  { code: "GA_9", name: "Woleu-Ntem", parent: "GA" },
  { code: "GB_ABC", name: "Armagh City, Banbridge and Craigavon", parent: "GB_NIR" },
  { code: "GB_ABD", name: "Aberdeenshire", parent: "GB_SCT" },
  { code: "GB_ABE", name: "Aberdeen City", parent: "GB_SCT" },
  { code: "GB_AGB", name: "Argyll and Bute", parent: "GB_SCT" },
  { code: "GB_AGY", name: "Isle of Anglesey", parent: "GB_WLS" },
  { code: "GB_AND", name: "Ards and North Down", parent: "GB_NIR" },
  { code: "GB_ANN", name: "Antrim and Newtownabbey", parent: "GB_NIR" },
  { code: "GB_ANS", name: "Angus", parent: "GB_SCT" },
  { code: "GB_BAS", name: "Bath and North East Somerset", parent: "GB_ENG" },
  { code: "GB_BBD", name: "Blackburn with Darwen", parent: "GB_ENG" },
  { code: "GB_BCP", name: "Bournemouth, Christchurch and Poole", parent: "GB_ENG" },
  { code: "GB_BDF", name: "Bedford", parent: "GB_ENG" },
  { code: "GB_BDG", name: "Barking and Dagenham", parent: "GB_ENG" },
  { code: "GB_BEN", name: "Brent", parent: "GB_ENG" },
  { code: "GB_BEX", name: "Bexley", parent: "GB_ENG" },
  { code: "GB_BFS", name: "Belfast City", parent: "GB_NIR" },
  { code: "GB_BGE", name: "Bridgend", parent: "GB_WLS" },
  { code: "GB_BGW", name: "Blaenau Gwent", parent: "GB_WLS" },
  { code: "GB_BIR", name: "Birmingham", parent: "GB_ENG" },
  { code: "GB_BKM", name: "Buckinghamshire", parent: "GB_ENG" },
  { code: "GB_BNE", name: "Barnet", parent: "GB_ENG" },
  { code: "GB_BNH", name: "Brighton and Hove", parent: "GB_ENG" },
  { code: "GB_BNS", name: "Barnsley", parent: "GB_ENG" },
  { code: "GB_BOL", name: "Bolton", parent: "GB_ENG" },
  { code: "GB_BPL", name: "Blackpool", parent: "GB_ENG" },
  { code: "GB_BRC", name: "Bracknell Forest", parent: "GB_ENG" },
  { code: "GB_BRD", name: "Bradford", parent: "GB_ENG" },
  { code: "GB_BRY", name: "Bromley", parent: "GB_ENG" },
  { code: "GB_BST", name: "Bristol, City of", parent: "GB_ENG" },
  { code: "GB_BUR", name: "Bury", parent: "GB_ENG" },
  { code: "GB_CAM", name: "Cambridgeshire", parent: "GB_ENG" },
  { code: "GB_CAY", name: "Caerphilly", parent: "GB_WLS" },
  { code: "GB_CBF", name: "Central Bedfordshire", parent: "GB_ENG" },
  { code: "GB_CCG", name: "Causeway Coast and Glens", parent: "GB_NIR" },
  { code: "GB_CGN", name: "Ceredigion", parent: "GB_WLS" },
  { code: "GB_CHE", name: "Cheshire East", parent: "GB_ENG" },
  { code: "GB_CHW", name: "Cheshire West and Chester", parent: "GB_ENG" },
  { code: "GB_CLD", name: "Calderdale", parent: "GB_ENG" },
  { code: "GB_CLK", name: "Clackmannanshire", parent: "GB_SCT" },
  { code: "GB_CMA", name: "Cumbria", parent: "GB_ENG" },
  { code: "GB_CMD", name: "Camden", parent: "GB_ENG" },
  { code: "GB_CMN", name: "Carmarthenshire", parent: "GB_WLS" },
  { code: "GB_CON", name: "Cornwall", parent: "GB_ENG" },
  { code: "GB_COV", name: "Coventry", parent: "GB_ENG" },
  { code: "GB_CRF", name: "Cardiff", parent: "GB_WLS" },
  { code: "GB_CRY", name: "Croydon", parent: "GB_ENG" },
  { code: "GB_CWY", name: "Conwy", parent: "GB_WLS" },
  { code: "GB_DAL", name: "Darlington", parent: "GB_ENG" },
  { code: "GB_DBY", name: "Derbyshire", parent: "GB_ENG" },
  { code: "GB_DEN", name: "Denbighshire", parent: "GB_WLS" },
  { code: "GB_DER", name: "Derby", parent: "GB_ENG" },
  { code: "GB_DEV", name: "Devon", parent: "GB_ENG" },
  { code: "GB_DGY", name: "Dumfries and Galloway", parent: "GB_SCT" },
  { code: "GB_DNC", name: "Doncaster", parent: "GB_ENG" },
  { code: "GB_DND", name: "Dundee City", parent: "GB_SCT" },
  { code: "GB_DOR", name: "Dorset", parent: "GB_ENG" },
  { code: "GB_DRS", name: "Derry and Strabane", parent: "GB_NIR" },
  { code: "GB_DUD", name: "Dudley", parent: "GB_ENG" },
  { code: "GB_DUR", name: "Durham, County", parent: "GB_ENG" },
  { code: "GB_EAL", name: "Ealing", parent: "GB_ENG" },
  { code: "GB_EAW", name: "England and Wales", parent: "GB" },
  { code: "GB_EAY", name: "East Ayrshire", parent: "GB_SCT" },
  { code: "GB_EDH", name: "Edinburgh, City of", parent: "GB_SCT" },
  { code: "GB_EDU", name: "East Dunbartonshire", parent: "GB_SCT" },
  { code: "GB_ELN", name: "East Lothian", parent: "GB_SCT" },
  { code: "GB_ELS", name: "Eilean Siar", parent: "GB_SCT" },
  { code: "GB_ENF", name: "Enfield", parent: "GB_ENG" },
  { code: "GB_ENG", name: "England", parent: "GB" },
  { code: "GB_ERW", name: "East Renfrewshire", parent: "GB_SCT" },
  { code: "GB_ERY", name: "East Riding of Yorkshire", parent: "GB_ENG" },
  { code: "GB_ESS", name: "Essex", parent: "GB_ENG" },
  { code: "GB_ESX", name: "East Sussex", parent: "GB_ENG" },
  { code: "GB_FAL", name: "Falkirk", parent: "GB_SCT" },
  { code: "GB_FIF", name: "Fife", parent: "GB_SCT" },
  { code: "GB_FLN", name: "Flintshire", parent: "GB_WLS" },
  { code: "GB_FMO", name: "Fermanagh and Omagh", parent: "GB_NIR" },
  { code: "GB_GAT", name: "Gateshead", parent: "GB_ENG" },
  { code: "GB_GBN", name: "Great Britain", parent: "GB" },
  { code: "GB_GLG", name: "Glasgow City", parent: "GB_SCT" },
  { code: "GB_GLS", name: "Gloucestershire", parent: "GB_ENG" },
  { code: "GB_GRE", name: "Greenwich", parent: "GB_ENG" },
  { code: "GB_GWN", name: "Gwynedd", parent: "GB_WLS" },
  { code: "GB_HAL", name: "Halton", parent: "GB_ENG" },
  { code: "GB_HAM", name: "Hampshire", parent: "GB_ENG" },
  { code: "GB_HAV", name: "Havering", parent: "GB_ENG" },
  { code: "GB_HCK", name: "Hackney", parent: "GB_ENG" },
  { code: "GB_HEF", name: "Herefordshire", parent: "GB_ENG" },
  { code: "GB_HIL", name: "Hillingdon", parent: "GB_ENG" },
  { code: "GB_HLD", name: "Highland", parent: "GB_SCT" },
  { code: "GB_HMF", name: "Hammersmith and Fulham", parent: "GB_ENG" },
  { code: "GB_HNS", name: "Hounslow", parent: "GB_ENG" },
  { code: "GB_HPL", name: "Hartlepool", parent: "GB_ENG" },
  { code: "GB_HRT", name: "Hertfordshire", parent: "GB_ENG" },
  { code: "GB_HRW", name: "Harrow", parent: "GB_ENG" },
  { code: "GB_HRY", name: "Haringey", parent: "GB_ENG" },
  { code: "GB_IOS", name: "Isles of Scilly", parent: "GB_ENG" },
  { code: "GB_IOW", name: "Isle of Wight", parent: "GB_ENG" },
  { code: "GB_ISL", name: "Islington", parent: "GB_ENG" },
  { code: "GB_IVC", name: "Inverclyde", parent: "GB_SCT" },
  { code: "GB_KEC", name: "Kensington and Chelsea", parent: "GB_ENG" },
  { code: "GB_KEN", name: "Kent", parent: "GB_ENG" },
  { code: "GB_KHL", name: "Kingston upon Hull", parent: "GB_ENG" },
  { code: "GB_KIR", name: "Kirklees", parent: "GB_ENG" },
  { code: "GB_KTT", name: "Kingston upon Thames", parent: "GB_ENG" },
  { code: "GB_KWL", name: "Knowsley", parent: "GB_ENG" },
  { code: "GB_LAN", name: "Lancashire", parent: "GB_ENG" },
  { code: "GB_LBC", name: "Lisburn and Castlereagh", parent: "GB_NIR" },
  { code: "GB_LBH", name: "Lambeth", parent: "GB_ENG" },
  { code: "GB_LCE", name: "Leicester", parent: "GB_ENG" },
  { code: "GB_LDS", name: "Leeds", parent: "GB_ENG" },
  { code: "GB_LEC", name: "Leicestershire", parent: "GB_ENG" },
  { code: "GB_LEW", name: "Lewisham", parent: "GB_ENG" },
  { code: "GB_LIN", name: "Lincolnshire", parent: "GB_ENG" },
  { code: "GB_LIV", name: "Liverpool", parent: "GB_ENG" },
  { code: "GB_LND", name: "London, City of", parent: "GB_ENG" },
  { code: "GB_LUT", name: "Luton", parent: "GB_ENG" },
  { code: "GB_MAN", name: "Manchester", parent: "GB_ENG" },
  { code: "GB_MDB", name: "Middlesbrough", parent: "GB_ENG" },
  { code: "GB_MDW", name: "Medway", parent: "GB_ENG" },
  { code: "GB_MEA", name: "Mid and East Antrim", parent: "GB_NIR" },
  { code: "GB_MIK", name: "Milton Keynes", parent: "GB_ENG" },
  { code: "GB_MLN", name: "Midlothian", parent: "GB_SCT" },
  { code: "GB_MON", name: "Monmouthshire", parent: "GB_WLS" },
  { code: "GB_MRT", name: "Merton", parent: "GB_ENG" },
  { code: "GB_MRY", name: "Moray", parent: "GB_SCT" },
  { code: "GB_MTY", name: "Merthyr Tydfil", parent: "GB_WLS" },
  { code: "GB_MUL", name: "Mid-Ulster", parent: "GB_NIR" },
  { code: "GB_NAY", name: "North Ayrshire", parent: "GB_SCT" },
  { code: "GB_NBL", name: "Northumberland", parent: "GB_ENG" },
  { code: "GB_NEL", name: "North East Lincolnshire", parent: "GB_ENG" },
  { code: "GB_NET", name: "Newcastle upon Tyne", parent: "GB_ENG" },
  { code: "GB_NFK", name: "Norfolk", parent: "GB_ENG" },
  { code: "GB_NGM", name: "Nottingham", parent: "GB_ENG" },
  { code: "GB_NIR", name: "Northern Ireland", parent: "GB" },
  { code: "GB_NLK", name: "North Lanarkshire", parent: "GB_SCT" },
  { code: "GB_NLN", name: "North Lincolnshire", parent: "GB_ENG" },
  { code: "GB_NMD", name: "Newry, Mourne and Down", parent: "GB_NIR" },
  { code: "GB_NNH", name: "North Northamptonshire", parent: "GB_ENG" },
  { code: "GB_NSM", name: "North Somerset", parent: "GB_ENG" },
  { code: "GB_NTL", name: "Neath Port Talbot", parent: "GB_WLS" },
  { code: "GB_NTT", name: "Nottinghamshire", parent: "GB_ENG" },
  { code: "GB_NTY", name: "North Tyneside", parent: "GB_ENG" },
  { code: "GB_NWM", name: "Newham", parent: "GB_ENG" },
  { code: "GB_NWP", name: "Newport", parent: "GB_WLS" },
  { code: "GB_NYK", name: "North Yorkshire", parent: "GB_ENG" },
  { code: "GB_OLD", name: "Oldham", parent: "GB_ENG" },
  { code: "GB_ORK", name: "Orkney Islands", parent: "GB_SCT" },
  { code: "GB_OXF", name: "Oxfordshire", parent: "GB_ENG" },
  { code: "GB_PEM", name: "Pembrokeshire", parent: "GB_WLS" },
  { code: "GB_PKN", name: "Perth and Kinross", parent: "GB_SCT" },
  { code: "GB_PLY", name: "Plymouth", parent: "GB_ENG" },
  { code: "GB_POR", name: "Portsmouth", parent: "GB_ENG" },
  { code: "GB_POW", name: "Powys", parent: "GB_WLS" },
  { code: "GB_PTE", name: "Peterborough", parent: "GB_ENG" },
  { code: "GB_RCC", name: "Redcar and Cleveland", parent: "GB_ENG" },
  { code: "GB_RCH", name: "Rochdale", parent: "GB_ENG" },
  { code: "GB_RCT", name: "Rhondda Cynon Taff", parent: "GB_WLS" },
  { code: "GB_RDB", name: "Redbridge", parent: "GB_ENG" },
  { code: "GB_RDG", name: "Reading", parent: "GB_ENG" },
  { code: "GB_RFW", name: "Renfrewshire", parent: "GB_SCT" },
  { code: "GB_RIC", name: "Richmond upon Thames", parent: "GB_ENG" },
  { code: "GB_ROT", name: "Rotherham", parent: "GB_ENG" },
  { code: "GB_RUT", name: "Rutland", parent: "GB_ENG" },
  { code: "GB_SAW", name: "Sandwell", parent: "GB_ENG" },
  { code: "GB_SAY", name: "South Ayrshire", parent: "GB_SCT" },
  { code: "GB_SCB", name: "Scottish Borders", parent: "GB_SCT" },
  { code: "GB_SCT", name: "Scotland", parent: "GB" },
  { code: "GB_SFK", name: "Suffolk", parent: "GB_ENG" },
  { code: "GB_SFT", name: "Sefton", parent: "GB_ENG" },
  { code: "GB_SGC", name: "South Gloucestershire", parent: "GB_ENG" },
  { code: "GB_SHF", name: "Sheffield", parent: "GB_ENG" },
  { code: "GB_SHN", name: "St. Helens", parent: "GB_ENG" },
  { code: "GB_SHR", name: "Shropshire", parent: "GB_ENG" },
  { code: "GB_SKP", name: "Stockport", parent: "GB_ENG" },
  { code: "GB_SLF", name: "Salford", parent: "GB_ENG" },
  { code: "GB_SLG", name: "Slough", parent: "GB_ENG" },
  { code: "GB_SLK", name: "South Lanarkshire", parent: "GB_SCT" },
  { code: "GB_SND", name: "Sunderland", parent: "GB_ENG" },
  { code: "GB_SOL", name: "Solihull", parent: "GB_ENG" },
  { code: "GB_SOM", name: "Somerset", parent: "GB_ENG" },
  { code: "GB_SOS", name: "Southend-on-Sea", parent: "GB_ENG" },
  { code: "GB_SRY", name: "Surrey", parent: "GB_ENG" },
  { code: "GB_STE", name: "Stoke-on-Trent", parent: "GB_ENG" },
  { code: "GB_STG", name: "Stirling", parent: "GB_SCT" },
  { code: "GB_STH", name: "Southampton", parent: "GB_ENG" },
  { code: "GB_STN", name: "Sutton", parent: "GB_ENG" },
  { code: "GB_STS", name: "Staffordshire", parent: "GB_ENG" },
  { code: "GB_STT", name: "Stockton-on-Tees", parent: "GB_ENG" },
  { code: "GB_STY", name: "South Tyneside", parent: "GB_ENG" },
  { code: "GB_SWA", name: "Swansea", parent: "GB_WLS" },
  { code: "GB_SWD", name: "Swindon", parent: "GB_ENG" },
  { code: "GB_SWK", name: "Southwark", parent: "GB_ENG" },
  { code: "GB_TAM", name: "Tameside", parent: "GB_ENG" },
  { code: "GB_TFW", name: "Telford and Wrekin", parent: "GB_ENG" },
  { code: "GB_THR", name: "Thurrock", parent: "GB_ENG" },
  { code: "GB_TOB", name: "Torbay", parent: "GB_ENG" },
  { code: "GB_TOF", name: "Torfaen", parent: "GB_WLS" },
  { code: "GB_TRF", name: "Trafford", parent: "GB_ENG" },
  { code: "GB_TWH", name: "Tower Hamlets", parent: "GB_ENG" },
  { code: "GB_UKM", name: "United Kingdom", parent: "GB" },
  { code: "GB_VGL", name: "Vale of Glamorgan, The", parent: "GB_WLS" },
  { code: "GB_WAR", name: "Warwickshire", parent: "GB_ENG" },
  { code: "GB_WBK", name: "West Berkshire", parent: "GB_ENG" },
  { code: "GB_WDU", name: "West Dunbartonshire", parent: "GB_SCT" },
  { code: "GB_WFT", name: "Waltham Forest", parent: "GB_ENG" },
  { code: "GB_WGN", name: "Wigan", parent: "GB_ENG" },
  { code: "GB_WIL", name: "Wiltshire", parent: "GB_ENG" },
  { code: "GB_WKF", name: "Wakefield", parent: "GB_ENG" },
  { code: "GB_WLL", name: "Walsall", parent: "GB_ENG" },
  { code: "GB_WLN", name: "West Lothian", parent: "GB_SCT" },
  { code: "GB_WLS", name: "Wales", parent: "GB" },
  { code: "GB_WLV", name: "Wolverhampton", parent: "GB_ENG" },
  { code: "GB_WND", name: "Wandsworth", parent: "GB_ENG" },
  { code: "GB_WNH", name: "West Northamptonshire", parent: "GB_ENG" },
  { code: "GB_WNM", name: "Windsor and Maidenhead", parent: "GB_ENG" },
  { code: "GB_WOK", name: "Wokingham", parent: "GB_ENG" },
  { code: "GB_WOR", name: "Worcestershire", parent: "GB_ENG" },
  { code: "GB_WRL", name: "Wirral", parent: "GB_ENG" },
  { code: "GB_WRT", name: "Warrington", parent: "GB_ENG" },
  { code: "GB_WRX", name: "Wrexham", parent: "GB_WLS" },
  { code: "GB_WSM", name: "Westminster", parent: "GB_ENG" },
  { code: "GB_WSX", name: "West Sussex", parent: "GB_ENG" },
  { code: "GB_YOR", name: "York", parent: "GB_ENG" },
  { code: "GB_ZET", name: "Shetland Islands", parent: "GB_SCT" },
  { code: "GD_01", name: "Saint Andrew", parent: "GD" },
  { code: "GD_02", name: "Saint David", parent: "GD" },
  { code: "GD_03", name: "Saint George", parent: "GD" },
  { code: "GD_04", name: "Saint John", parent: "GD" },
  { code: "GD_05", name: "Saint Mark", parent: "GD" },
  { code: "GD_06", name: "Saint Patrick", parent: "GD" },
  { code: "GD_10", name: "Southern Grenadine Islands", parent: "GD" },
  { code: "GE_AB", name: "Abkhazia", parent: "GE" },
  { code: "GE_AJ", name: "Ajaria", parent: "GE" },
  { code: "GE_GU", name: "Guria", parent: "GE" },
  { code: "GE_IM", name: "Imereti", parent: "GE" },
  { code: "GE_KA", name: "K'akheti", parent: "GE" },
  { code: "GE_KK", name: "Kvemo Kartli", parent: "GE" },
  { code: "GE_MM", name: "Mtskheta-Mtianeti", parent: "GE" },
  { code: "GE_RL", name: "Rach'a-Lechkhumi-Kvemo Svaneti", parent: "GE" },
  { code: "GE_SJ", name: "Samtskhe-Javakheti", parent: "GE" },
  { code: "GE_SK", name: "Shida Kartli", parent: "GE" },
  { code: "GE_SZ", name: "Samegrelo-Zemo Svaneti", parent: "GE" },
  { code: "GE_TB", name: "Tbilisi", parent: "GE" },
  { code: "GH_AA", name: "Greater Accra", parent: "GH" },
  { code: "GH_AF", name: "Ahafo", parent: "GH" },
  { code: "GH_AH", name: "Ashanti", parent: "GH" },
  { code: "GH_BA", name: "Brong-Ahafo", parent: "GH" },
  { code: "GH_BE", name: "Bono East", parent: "GH" },
  { code: "GH_BO", name: "Bono", parent: "GH" },
  { code: "GH_CP", name: "Central", parent: "GH" },
  { code: "GH_EP", name: "Eastern", parent: "GH" },
  { code: "GH_NE", name: "North East", parent: "GH" },
  { code: "GH_NP", name: "Northern", parent: "GH" },
  { code: "GH_OT", name: "Oti", parent: "GH" },
  { code: "GH_SV", name: "Savannah", parent: "GH" },
  { code: "GH_TV", name: "Volta", parent: "GH" },
  { code: "GH_UE", name: "Upper East", parent: "GH" },
  { code: "GH_UW", name: "Upper West", parent: "GH" },
  { code: "GH_WN", name: "Western North", parent: "GH" },
  { code: "GH_WP", name: "Western", parent: "GH" },
  { code: "GL_AV", name: "Avannaata Kommunia", parent: "GL" },
  { code: "GL_KU", name: "Kommune Kujalleq", parent: "GL" },
  { code: "GL_QE", name: "Qeqqata Kommunia", parent: "GL" },
  { code: "GL_QT", name: "Kommune Qeqertalik", parent: "GL" },
  { code: "GL_SM", name: "Kommuneqarfik Sermersooq", parent: "GL" },
  { code: "GM_B", name: "Banjul", parent: "GM" },
  { code: "GM_L", name: "Lower River", parent: "GM" },
  { code: "GM_M", name: "Central River", parent: "GM" },
  { code: "GM_N", name: "North Bank", parent: "GM" },
  { code: "GM_U", name: "Upper River", parent: "GM" },
  { code: "GM_W", name: "Western", parent: "GM" },
  { code: "GN_B", name: "Boké", parent: "GN" },
  { code: "GN_BE", name: "Beyla", parent: "GN_N" },
  { code: "GN_BF", name: "Boffa", parent: "GN_B" },
  { code: "GN_BK", name: "Boké", parent: "GN_B" },
  { code: "GN_C", name: "Conakry", parent: "GN" },
  { code: "GN_CO", name: "Coyah", parent: "GN_D" },
  { code: "GN_D", name: "Kindia", parent: "GN" },
  { code: "GN_DB", name: "Dabola", parent: "GN_F" },
  { code: "GN_DI", name: "Dinguiraye", parent: "GN_F" },
  { code: "GN_DL", name: "Dalaba", parent: "GN_M" },
  { code: "GN_DU", name: "Dubréka", parent: "GN_D" },
  { code: "GN_F", name: "Faranah", parent: "GN" },
  { code: "GN_FA", name: "Faranah", parent: "GN_F" },
  { code: "GN_FO", name: "Forécariah", parent: "GN_D" },
  { code: "GN_FR", name: "Fria", parent: "GN_B" },
  { code: "GN_GA", name: "Gaoual", parent: "GN_B" },
  { code: "GN_GU", name: "Guékédou", parent: "GN_N" },
  { code: "GN_K", name: "Kankan", parent: "GN" },
  { code: "GN_KA", name: "Kankan", parent: "GN_K" },
  { code: "GN_KB", name: "Koubia", parent: "GN_L" },
  { code: "GN_KD", name: "Kindia", parent: "GN_D" },
  { code: "GN_KE", name: "Kérouané", parent: "GN_K" },
  { code: "GN_KN", name: "Koundara", parent: "GN_B" },
  { code: "GN_KO", name: "Kouroussa", parent: "GN_K" },
  { code: "GN_KS", name: "Kissidougou", parent: "GN_F" },
  { code: "GN_L", name: "Labé", parent: "GN" },
  { code: "GN_LA", name: "Labé", parent: "GN_L" },
  { code: "GN_LE", name: "Lélouma", parent: "GN_L" },
  { code: "GN_LO", name: "Lola", parent: "GN_N" },
  { code: "GN_M", name: "Mamou", parent: "GN" },
  { code: "GN_MC", name: "Macenta", parent: "GN_N" },
  { code: "GN_MD", name: "Mandiana", parent: "GN_K" },
  { code: "GN_ML", name: "Mali", parent: "GN_L" },
  { code: "GN_MM", name: "Mamou", parent: "GN_M" },
  { code: "GN_N", name: "Nzérékoré", parent: "GN" },
  { code: "GN_NZ", name: "Nzérékoré", parent: "GN_N" },
  { code: "GN_PI", name: "Pita", parent: "GN_M" },
  { code: "GN_SI", name: "Siguiri", parent: "GN_K" },
  { code: "GN_TE", name: "Télimélé", parent: "GN_D" },
  { code: "GN_TO", name: "Tougué", parent: "GN_L" },
  { code: "GN_YO", name: "Yomou", parent: "GN_N" },
  { code: "GQ_AN", name: "Annobón", parent: "GQ_I" },
  { code: "GQ_BN", name: "Bioko Norte", parent: "GQ_I" },
  { code: "GQ_BS", name: "Bioko Sur", parent: "GQ_I" },
  { code: "GQ_C", name: "Región Continental", parent: "GQ" },
  { code: "GQ_CS", name: "Centro Sur", parent: "GQ_C" },
  { code: "GQ_DJ", name: "Djibloho", parent: "GQ_C" },
  { code: "GQ_I", name: "Región Insular", parent: "GQ" },
  { code: "GQ_KN", name: "Kié-Ntem", parent: "GQ_C" },
  { code: "GQ_LI", name: "Litoral", parent: "GQ_C" },
  { code: "GQ_WN", name: "Wele-Nzas", parent: "GQ_C" },
  { code: "GR_69", name: "Ágion Óros", parent: "GR" },
  { code: "GR_A", name: "Anatolikí Makedonía kai Thráki", parent: "GR" },
  { code: "GR_B", name: "Kentrikí Makedonía", parent: "GR" },
  { code: "GR_C", name: "Dytikí Makedonía", parent: "GR" },
  { code: "GR_D", name: "Ípeiros", parent: "GR" },
  { code: "GR_E", name: "Thessalía", parent: "GR" },
  { code: "GR_F", name: "Ionía Nísia", parent: "GR" },
  { code: "GR_G", name: "Dytikí Elláda", parent: "GR" },
  { code: "GR_H", name: "Stereá Elláda", parent: "GR" },
  { code: "GR_I", name: "Attikí", parent: "GR" },
  { code: "GR_J", name: "Pelopónnisos", parent: "GR" },
  { code: "GR_K", name: "Vóreio Aigaío", parent: "GR" },
  { code: "GR_L", name: "Nótio Aigaío", parent: "GR" },
  { code: "GR_M", name: "Kríti", parent: "GR" },
  { code: "GT_01", name: "Guatemala", parent: "GT" },
  { code: "GT_02", name: "El Progreso", parent: "GT" },
  { code: "GT_03", name: "Sacatepéquez", parent: "GT" },
  { code: "GT_04", name: "Chimaltenango", parent: "GT" },
  { code: "GT_05", name: "Escuintla", parent: "GT" },
  { code: "GT_06", name: "Santa Rosa", parent: "GT" },
  { code: "GT_07", name: "Sololá", parent: "GT" },
  { code: "GT_08", name: "Totonicapán", parent: "GT" },
  { code: "GT_09", name: "Quetzaltenango", parent: "GT" },
  { code: "GT_10", name: "Suchitepéquez", parent: "GT" },
  { code: "GT_11", name: "Retalhuleu", parent: "GT" },
  { code: "GT_12", name: "San Marcos", parent: "GT" },
  { code: "GT_13", name: "Huehuetenango", parent: "GT" },
  { code: "GT_14", name: "Quiché", parent: "GT" },
  { code: "GT_15", name: "Baja Verapaz", parent: "GT" },
  { code: "GT_16", name: "Alta Verapaz", parent: "GT" },
  { code: "GT_17", name: "Petén", parent: "GT" },
  { code: "GT_18", name: "Izabal", parent: "GT" },
  { code: "GT_19", name: "Zacapa", parent: "GT" },
  { code: "GT_20", name: "Chiquimula", parent: "GT" },
  { code: "GT_21", name: "Jalapa", parent: "GT" },
  { code: "GT_22", name: "Jutiapa", parent: "GT" },
  { code: "GW_BA", name: "Bafatá", parent: "GW_L" },
  { code: "GW_BL", name: "Bolama / Bijagós", parent: "GW_S" },
  { code: "GW_BM", name: "Biombo", parent: "GW_N" },
  { code: "GW_BS", name: "Bissau", parent: "GW" },
  { code: "GW_CA", name: "Cacheu", parent: "GW_N" },
  { code: "GW_GA", name: "Gabú", parent: "GW_L" },
  { code: "GW_L", name: "Leste", parent: "GW" },
  { code: "GW_N", name: "Norte", parent: "GW" },
  { code: "GW_OI", name: "Oio", parent: "GW_N" },
  { code: "GW_QU", name: "Quinara", parent: "GW_S" },
  { code: "GW_S", name: "Sul", parent: "GW" },
  { code: "GW_TO", name: "Tombali", parent: "GW_S" },
  { code: "GY_BA", name: "Barima-Waini", parent: "GY" },
  { code: "GY_CU", name: "Cuyuni-Mazaruni", parent: "GY" },
  { code: "GY_DE", name: "Demerara-Mahaica", parent: "GY" },
  { code: "GY_EB", name: "East Berbice-Corentyne", parent: "GY" },
  { code: "GY_ES", name: "Essequibo Islands-West Demerara", parent: "GY" },
  { code: "GY_MA", name: "Mahaica-Berbice", parent: "GY" },
  { code: "GY_PM", name: "Pomeroon-Supenaam", parent: "GY" },
  { code: "GY_PT", name: "Potaro-Siparuni", parent: "GY" },
  { code: "GY_UD", name: "Upper Demerara-Berbice", parent: "GY" },
  { code: "GY_UT", name: "Upper Takutu-Upper Essequibo", parent: "GY" },
  { code: "HN_AT", name: "Atlántida", parent: "HN" },
  { code: "HN_CH", name: "Choluteca", parent: "HN" },
  { code: "HN_CL", name: "Colón", parent: "HN" },
  { code: "HN_CM", name: "Comayagua", parent: "HN" },
  { code: "HN_CP", name: "Copán", parent: "HN" },
  { code: "HN_CR", name: "Cortés", parent: "HN" },
  { code: "HN_EP", name: "El Paraíso", parent: "HN" },
  { code: "HN_FM", name: "Francisco Morazán", parent: "HN" },
  { code: "HN_GD", name: "Gracias a Dios", parent: "HN" },
  { code: "HN_IB", name: "Islas de la Bahía", parent: "HN" },
  { code: "HN_IN", name: "Intibucá", parent: "HN" },
  { code: "HN_LE", name: "Lempira", parent: "HN" },
  { code: "HN_LP", name: "La Paz", parent: "HN" },
  { code: "HN_OC", name: "Ocotepeque", parent: "HN" },
  { code: "HN_OL", name: "Olancho", parent: "HN" },
  { code: "HN_SB", name: "Santa Bárbara", parent: "HN" },
  { code: "HN_VA", name: "Valle", parent: "HN" },
  { code: "HN_YO", name: "Yoro", parent: "HN" },
  { code: "HR_01", name: "Zagrebačka županija", parent: "HR" },
  { code: "HR_02", name: "Krapinsko-zagorska županija", parent: "HR" },
  { code: "HR_03", name: "Sisačko-moslavačka županija", parent: "HR" },
  { code: "HR_04", name: "Karlovačka županija", parent: "HR" },
  { code: "HR_05", name: "Varaždinska županija", parent: "HR" },
  { code: "HR_06", name: "Koprivničko-križevačka županija", parent: "HR" },
  { code: "HR_07", name: "Bjelovarsko-bilogorska županija", parent: "HR" },
  { code: "HR_08", name: "Primorsko-goranska županija", parent: "HR" },
  { code: "HR_09", name: "Ličko-senjska županija", parent: "HR" },
  { code: "HR_10", name: "Virovitičko-podravska županija", parent: "HR" },
  { code: "HR_11", name: "Požeško-slavonska županija", parent: "HR" },
  { code: "HR_12", name: "Brodsko-posavska županija", parent: "HR" },
  { code: "HR_13", name: "Zadarska županija", parent: "HR" },
  { code: "HR_14", name: "Osječko-baranjska županija", parent: "HR" },
  { code: "HR_15", name: "Šibensko-kninska županija", parent: "HR" },
  { code: "HR_16", name: "Vukovarsko-srijemska županija", parent: "HR" },
  { code: "HR_17", name: "Splitsko-dalmatinska županija", parent: "HR" },
  { code: "HR_18", name: "Istarska županija", parent: "HR" },
  { code: "HR_19", name: "Dubrovačko-neretvanska županija", parent: "HR" },
  { code: "HR_20", name: "Međimurska županija", parent: "HR" },
  { code: "HR_21", name: "Grad Zagreb", parent: "HR" },
  { code: "HT_AR", name: "Artibonite", parent: "HT" },
  { code: "HT_CE", name: "Centre", parent: "HT" },
  { code: "HT_GA", name: "Grande’Anse", parent: "HT" },
  { code: "HT_ND", name: "Nord", parent: "HT" },
  { code: "HT_NE", name: "Nord-Est", parent: "HT" },
  { code: "HT_NI", name: "Nippes", parent: "HT" },
  { code: "HT_NO", name: "Nord-Ouest", parent: "HT" },
  { code: "HT_OU", name: "Ouest", parent: "HT" },
  { code: "HT_SD", name: "Sud", parent: "HT" },
  { code: "HT_SE", name: "Sud-Est", parent: "HT" },
  { code: "HU_BA", name: "Baranya", parent: "HU" },
  { code: "HU_BC", name: "Békéscsaba", parent: "HU" },
  { code: "HU_BE", name: "Békés", parent: "HU" },
  { code: "HU_BK", name: "Bács-Kiskun", parent: "HU" },
  { code: "HU_BU", name: "Budapest", parent: "HU" },
  { code: "HU_BZ", name: "Borsod-Abaúj-Zemplén", parent: "HU" },
  { code: "HU_CS", name: "Csongrád-Csanád", parent: "HU" },
  { code: "HU_DE", name: "Debrecen", parent: "HU" },
  { code: "HU_DU", name: "Dunaújváros", parent: "HU" },
  { code: "HU_EG", name: "Eger", parent: "HU" },
  { code: "HU_ER", name: "Érd", parent: "HU" },
  { code: "HU_FE", name: "Fejér", parent: "HU" },
  { code: "HU_GS", name: "Győr-Moson-Sopron", parent: "HU" },
  { code: "HU_GY", name: "Győr", parent: "HU" },
  { code: "HU_HB", name: "Hajdú-Bihar", parent: "HU" },
  { code: "HU_HE", name: "Heves", parent: "HU" },
  { code: "HU_HV", name: "Hódmezővásárhely", parent: "HU" },
  { code: "HU_JN", name: "Jász-Nagykun-Szolnok", parent: "HU" },
  { code: "HU_KE", name: "Komárom-Esztergom", parent: "HU" },
  { code: "HU_KM", name: "Kecskemét", parent: "HU" },
  { code: "HU_KV", name: "Kaposvár", parent: "HU" },
  { code: "HU_MI", name: "Miskolc", parent: "HU" },
  { code: "HU_NK", name: "Nagykanizsa", parent: "HU" },
  { code: "HU_NO", name: "Nógrád", parent: "HU" },
  { code: "HU_NY", name: "Nyíregyháza", parent: "HU" },
  { code: "HU_PE", name: "Pest", parent: "HU" },
  { code: "HU_PS", name: "Pécs", parent: "HU" },
  { code: "HU_SD", name: "Szeged", parent: "HU" },
  { code: "HU_SF", name: "Székesfehérvár", parent: "HU" },
  { code: "HU_SH", name: "Szombathely", parent: "HU" },
  { code: "HU_SK", name: "Szolnok", parent: "HU" },
  { code: "HU_SN", name: "Sopron", parent: "HU" },
  { code: "HU_SO", name: "Somogy", parent: "HU" },
  { code: "HU_SS", name: "Szekszárd", parent: "HU" },
  { code: "HU_ST", name: "Salgótarján", parent: "HU" },
  { code: "HU_SZ", name: "Szabolcs-Szatmár-Bereg", parent: "HU" },
  { code: "HU_TB", name: "Tatabánya", parent: "HU" },
  { code: "HU_TO", name: "Tolna", parent: "HU" },
  { code: "HU_VA", name: "Vas", parent: "HU" },
  { code: "HU_VE", name: "Veszprém", parent: "HU" },
  { code: "HU_VM", name: "Veszprém", parent: "HU" },
  { code: "HU_ZA", name: "Zala", parent: "HU" },
  { code: "HU_ZE", name: "Zalaegerszeg", parent: "HU" },
  { code: "ID_AC", name: "Aceh", parent: "ID_SM" },
  { code: "ID_BA", name: "Bali", parent: "ID_NU" },
  { code: "ID_BB", name: "Kepulauan Bangka Belitung", parent: "ID_SM" },
  { code: "ID_BE", name: "Bengkulu", parent: "ID_SM" },
  { code: "ID_BT", name: "Banten", parent: "ID_JW" },
  { code: "ID_GO", name: "Gorontalo", parent: "ID_SL" },
  { code: "ID_JA", name: "Jambi", parent: "ID_SM" },
  { code: "ID_JB", name: "Jawa Barat", parent: "ID_JW" },
  { code: "ID_JI", name: "Jawa Timur", parent: "ID_JW" },
  { code: "ID_JK", name: "Jakarta Raya", parent: "ID_JW" },
  { code: "ID_JT", name: "Jawa Tengah", parent: "ID_JW" },
  { code: "ID_JW", name: "Jawa", parent: "ID" },
  { code: "ID_KA", name: "Kalimantan", parent: "ID" },
  { code: "ID_KB", name: "Kalimantan Barat", parent: "ID_KA" },
  { code: "ID_KI", name: "Kalimantan Timur", parent: "ID_KA" },
  { code: "ID_KR", name: "Kepulauan Riau", parent: "ID_SM" },
  { code: "ID_KS", name: "Kalimantan Selatan", parent: "ID_KA" },
  { code: "ID_KT", name: "Kalimantan Tengah", parent: "ID_KA" },
  { code: "ID_KU", name: "Kalimantan Utara", parent: "ID_KA" },
  { code: "ID_LA", name: "Lampung", parent: "ID_SM" },
  { code: "ID_MA", name: "Maluku", parent: "ID_ML" },
  { code: "ID_ML", name: "Maluku", parent: "ID" },
  { code: "ID_MU", name: "Maluku Utara", parent: "ID_ML" },
  { code: "ID_NB", name: "Nusa Tenggara Barat", parent: "ID_NU" },
  { code: "ID_NT", name: "Nusa Tenggara Timur", parent: "ID_NU" },
  { code: "ID_NU", name: "Nusa Tenggara", parent: "ID" },
  { code: "ID_PA", name: "Papua", parent: "ID_PP" },
  { code: "ID_PB", name: "Papua Barat", parent: "ID_PP" },
  { code: "ID_PE", name: "Papua Pengunungan", parent: "ID_PP" },
  { code: "ID_PP", name: "Papua", parent: "ID" },
  { code: "ID_PS", name: "Papua Selatan", parent: "ID_PP" },
  { code: "ID_PT", name: "Papua Tengah", parent: "ID_PP" },
  { code: "ID_RI", name: "Riau", parent: "ID_SM" },
  { code: "ID_SA", name: "Sulawesi Utara", parent: "ID_SL" },
  { code: "ID_SB", name: "Sumatera Barat", parent: "ID_SM" },
  { code: "ID_SG", name: "Sulawesi Tenggara", parent: "ID_SL" },
  { code: "ID_SL", name: "Sulawesi", parent: "ID" },
  { code: "ID_SM", name: "Sumatera", parent: "ID" },
  { code: "ID_SN", name: "Sulawesi Selatan", parent: "ID_SL" },
  { code: "ID_SR", name: "Sulawesi Barat", parent: "ID_SL" },
  { code: "ID_SS", name: "Sumatera Selatan", parent: "ID_SM" },
  { code: "ID_ST", name: "Sulawesi Tengah", parent: "ID_SL" },
  { code: "ID_SU", name: "Sumatera Utara", parent: "ID_SM" },
  { code: "ID_YO", name: "Yogyakarta", parent: "ID_JW" },
  { code: "IE_C", name: "Connaught", parent: "IE" },
  { code: "IE_CE", name: "Clare", parent: "IE_M" },
  { code: "IE_CN", name: "Cavan", parent: "IE_U" },
  { code: "IE_CO", name: "Cork", parent: "IE_M" },
  { code: "IE_CW", name: "Carlow", parent: "IE_L" },
  { code: "IE_D", name: "Dublin", parent: "IE_L" },
  { code: "IE_DL", name: "Donegal", parent: "IE_U" },
  { code: "IE_G", name: "Galway", parent: "IE_C" },
  { code: "IE_KE", name: "Kildare", parent: "IE_L" },
  { code: "IE_KK", name: "Kilkenny", parent: "IE_L" },
  { code: "IE_KY", name: "Kerry", parent: "IE_M" },
  { code: "IE_L", name: "Leinster", parent: "IE" },
  { code: "IE_LD", name: "Longford", parent: "IE_L" },
  { code: "IE_LH", name: "Louth", parent: "IE_L" },
  { code: "IE_LK", name: "Limerick", parent: "IE_M" },
  { code: "IE_LM", name: "Leitrim", parent: "IE_C" },
  { code: "IE_LS", name: "Laois", parent: "IE_L" },
  { code: "IE_M", name: "Munster", parent: "IE" },
  { code: "IE_MH", name: "Meath", parent: "IE_L" },
  { code: "IE_MN", name: "Monaghan", parent: "IE_U" },
  { code: "IE_MO", name: "Mayo", parent: "IE_C" },
  { code: "IE_OY", name: "Offaly", parent: "IE_L" },
  { code: "IE_RN", name: "Roscommon", parent: "IE_C" },
  { code: "IE_SO", name: "Sligo", parent: "IE_C" },
  { code: "IE_TA", name: "Tipperary", parent: "IE_M" },
  { code: "IE_U", name: "Ulster", parent: "IE" },
  { code: "IE_WD", name: "Waterford", parent: "IE_M" },
  { code: "IE_WH", name: "Westmeath", parent: "IE_L" },
  { code: "IE_WW", name: "Wicklow", parent: "IE_L" },
  { code: "IE_WX", name: "Wexford", parent: "IE_L" },
  { code: "IL_D", name: "HaDarom", parent: "IL" },
  { code: "IL_HA", name: "H̱efa", parent: "IL" },
  { code: "IL_JM", name: "Yerushalayim", parent: "IL" },
  { code: "IL_M", name: "HaMerkaz", parent: "IL" },
  { code: "IL_TA", name: "Tel Aviv", parent: "IL" },
  { code: "IL_Z", name: "HaTsafon", parent: "IL" },
  { code: "IN_AN", name: "Andaman and Nicobar Islands", parent: "IN" },
  { code: "IN_AP", name: "Andhra Pradesh", parent: "IN" },
  { code: "IN_AR", name: "Arunāchal Pradesh", parent: "IN" },
  { code: "IN_AS", name: "Assam", parent: "IN" },
  { code: "IN_BR", name: "Bihār", parent: "IN" },
  { code: "IN_CH", name: "Chandīgarh", parent: "IN" },
  { code: "IN_CT", name: "Chhattīsgarh", parent: "IN" },
  { code: "IN_DH", name: "Dādra and Nagar Haveli and Damān and Diu", parent: "IN" },
  { code: "IN_DL", name: "Delhi", parent: "IN" },
  { code: "IN_GA", name: "Goa", parent: "IN" },
  { code: "IN_GJ", name: "Gujarāt", parent: "IN" },
  { code: "IN_HP", name: "Himāchal Pradesh", parent: "IN" },
  { code: "IN_HR", name: "Haryāna", parent: "IN" },
  { code: "IN_JH", name: "Jhārkhand", parent: "IN" },
  { code: "IN_JK", name: "Jammu and Kashmīr", parent: "IN" },
  { code: "IN_KA", name: "Karnātaka", parent: "IN" },
  { code: "IN_KL", name: "Kerala", parent: "IN" },
  { code: "IN_LA", name: "Ladākh", parent: "IN" },
  { code: "IN_LD", name: "Lakshadweep", parent: "IN" },
  { code: "IN_MH", name: "Mahārāshtra", parent: "IN" },
  { code: "IN_ML", name: "Meghālaya", parent: "IN" },
  { code: "IN_MN", name: "Manipur", parent: "IN" },
  { code: "IN_MP", name: "Madhya Pradesh", parent: "IN" },
  { code: "IN_MZ", name: "Mizoram", parent: "IN" },
  { code: "IN_NL", name: "Nāgāland", parent: "IN" },
  { code: "IN_OR", name: "Odisha", parent: "IN" },
  { code: "IN_PB", name: "Punjab", parent: "IN" },
  { code: "IN_PY", name: "Puducherry", parent: "IN" },
  { code: "IN_RJ", name: "Rājasthān", parent: "IN" },
  { code: "IN_SK", name: "Sikkim", parent: "IN" },
  { code: "IN_TG", name: "Telangāna", parent: "IN" },
  { code: "IN_TN", name: "Tamil Nādu", parent: "IN" },
  { code: "IN_TR", name: "Tripura", parent: "IN" },
  { code: "IN_UP", name: "Uttar Pradesh", parent: "IN" },
  { code: "IN_UT", name: "Uttarākhand", parent: "IN" },
  { code: "IN_WB", name: "West Bengal", parent: "IN" },
  { code: "IQ_AN", name: "Al Anbār", parent: "IQ" },
  { code: "IQ_AR", name: "Arbīl", parent: "IQ_KR" },
  { code: "IQ_BA", name: "Al Başrah", parent: "IQ" },
  { code: "IQ_BB", name: "Bābil", parent: "IQ" },
  { code: "IQ_BG", name: "Baghdād", parent: "IQ" },
  { code: "IQ_DA", name: "Dahūk", parent: "IQ_KR" },
  { code: "IQ_DI", name: "Diyālá", parent: "IQ" },
  { code: "IQ_DQ", name: "Dhī Qār", parent: "IQ" },
  { code: "IQ_KA", name: "Karbalā’", parent: "IQ" },
  { code: "IQ_KI", name: "Kirkūk", parent: "IQ" },
  { code: "IQ_KR", name: "Iqlīm Kūrdistān", parent: "IQ" },
  { code: "IQ_MA", name: "Maysān", parent: "IQ" },
  { code: "IQ_MU", name: "Al Muthanná", parent: "IQ" },
  { code: "IQ_NA", name: "An Najaf", parent: "IQ" },
  { code: "IQ_NI", name: "Nīnawá", parent: "IQ" },
  { code: "IQ_QA", name: "Al Qādisīyah", parent: "IQ" },
  { code: "IQ_SD", name: "Şalāḩ ad Dīn", parent: "IQ" },
  { code: "IQ_SU", name: "As Sulaymānīyah", parent: "IQ_KR" },
  { code: "IQ_WA", name: "Wāsiţ", parent: "IQ" },
  { code: "IR_00", name: "Markazī", parent: "IR" },
  { code: "IR_01", name: "Gīlān", parent: "IR" },
  { code: "IR_02", name: "Māzandarān", parent: "IR" },
  { code: "IR_03", name: "Āz̄ārbāyjān-e Shārqī", parent: "IR" },
  { code: "IR_04", name: "Āz̄ārbāyjān-e Ghārbī", parent: "IR" },
  { code: "IR_05", name: "Kermānshāh", parent: "IR" },
  { code: "IR_06", name: "Khūzestān", parent: "IR" },
  { code: "IR_07", name: "Fārs", parent: "IR" },
  { code: "IR_08", name: "Kermān", parent: "IR" },
  { code: "IR_09", name: "Khorāsān-e Raẕavī", parent: "IR" },
  { code: "IR_10", name: "Eşfahān", parent: "IR" },
  { code: "IR_11", name: "Sīstān va Balūchestān", parent: "IR" },
  { code: "IR_12", name: "Kordestān", parent: "IR" },
  { code: "IR_13", name: "Hamadān", parent: "IR" },
  { code: "IR_14", name: "Chahār Maḩāl va Bakhtīārī", parent: "IR" },
  { code: "IR_15", name: "Lorestān", parent: "IR" },
  { code: "IR_16", name: "Īlām", parent: "IR" },
  { code: "IR_17", name: "Kohgīlūyeh va Bowyer Aḩmad", parent: "IR" },
  { code: "IR_18", name: "Būshehr", parent: "IR" },
  { code: "IR_19", name: "Zanjān", parent: "IR" },
  { code: "IR_20", name: "Semnān", parent: "IR" },
  { code: "IR_21", name: "Yazd", parent: "IR" },
  { code: "IR_22", name: "Hormozgān", parent: "IR" },
  { code: "IR_23", name: "Tehrān", parent: "IR" },
  { code: "IR_24", name: "Ardabīl", parent: "IR" },
  { code: "IR_25", name: "Qom", parent: "IR" },
  { code: "IR_26", name: "Qazvīn", parent: "IR" },
  { code: "IR_27", name: "Golestān", parent: "IR" },
  { code: "IR_28", name: "Khorāsān-e Shomālī", parent: "IR" },
  { code: "IR_29", name: "Khorāsān-e Jonūbī", parent: "IR" },
  { code: "IR_30", name: "Alborz", parent: "IR" },
  { code: "IS_1", name: "Höfuðborgarsvæði", parent: "IS" },
  { code: "IS_2", name: "Suðurnes", parent: "IS" },
  { code: "IS_3", name: "Vesturland", parent: "IS" },
  { code: "IS_4", name: "Vestfirðir", parent: "IS" },
  { code: "IS_5", name: "Norðurland vestra", parent: "IS" },
  { code: "IS_6", name: "Norðurland eystra", parent: "IS" },
  { code: "IS_7", name: "Austurland", parent: "IS" },
  { code: "IS_8", name: "Suðurland", parent: "IS" },
  { code: "IS_AKN", name: "Akraneskaupstaður", parent: "IS" },
  { code: "IS_AKU", name: "Akureyrarbær", parent: "IS" },
  { code: "IS_ARN", name: "Árneshreppur", parent: "IS" },
  { code: "IS_ASA", name: "Ásahreppur", parent: "IS" },
  { code: "IS_BLA", name: "Bláskógabyggð", parent: "IS" },
  { code: "IS_BOG", name: "Borgarbyggð", parent: "IS" },
  { code: "IS_BOL", name: "Bolungarvíkurkaupstaður", parent: "IS" },
  { code: "IS_DAB", name: "Dalabyggð", parent: "IS" },
  { code: "IS_DAV", name: "Dalvíkurbyggð", parent: "IS" },
  { code: "IS_EOM", name: "Eyja- og Miklaholtshreppur", parent: "IS" },
  { code: "IS_EYF", name: "Eyjafjarðarsveit", parent: "IS" },
  { code: "IS_FJD", name: "Fjarðabyggð", parent: "IS" },
  { code: "IS_FJL", name: "Fjallabyggð", parent: "IS" },
  { code: "IS_FLA", name: "Flóahreppur", parent: "IS" },
  { code: "IS_FLR", name: "Fljótsdalshreppur", parent: "IS" },
  { code: "IS_GAR", name: "Garðabær", parent: "IS" },
  { code: "IS_GOG", name: "Grímsnes- og Grafningshreppur", parent: "IS" },
  { code: "IS_GRN", name: "Grindavíkurbær", parent: "IS" },
  { code: "IS_GRU", name: "Grundarfjarðarbær", parent: "IS" },
  { code: "IS_GRY", name: "Grýtubakkahreppur", parent: "IS" },
  { code: "IS_HAF", name: "Hafnarfjarðarkaupstaður", parent: "IS" },
  { code: "IS_HRG", name: "Hörgársveit", parent: "IS" },
  { code: "IS_HRU", name: "Hrunamannahreppur", parent: "IS" },
  { code: "IS_HUG", name: "Húnabyggð", parent: "IS" },
  { code: "IS_HUV", name: "Húnaþing vestra", parent: "IS" },
  { code: "IS_HVA", name: "Hvalfjarðarsveit", parent: "IS" },
  { code: "IS_HVE", name: "Hveragerðisbær", parent: "IS" },
  { code: "IS_ISA", name: "Ísafjarðarbær", parent: "IS" },
  { code: "IS_KAL", name: "Kaldrananeshreppur", parent: "IS" },
  { code: "IS_KJO", name: "Kjósarhreppur", parent: "IS" },
  { code: "IS_KOP", name: "Kópavogsbær", parent: "IS" },
  { code: "IS_LAN", name: "Langanesbyggð", parent: "IS" },
  { code: "IS_MOS", name: "Mosfellsbær", parent: "IS" },
  { code: "IS_MUL", name: "Múlaþing", parent: "IS" },
  { code: "IS_MYR", name: "Mýrdalshreppur", parent: "IS" },
  { code: "IS_NOR", name: "Norðurþing", parent: "IS" },
  { code: "IS_RGE", name: "Rangárþing eystra", parent: "IS" },
  { code: "IS_RGY", name: "Rangárþing ytra", parent: "IS" },
  { code: "IS_RHH", name: "Reykhólahreppur", parent: "IS" },
  { code: "IS_RKN", name: "Reykjanesbær", parent: "IS" },
  { code: "IS_RKV", name: "Reykjavíkurborg", parent: "IS" },
  { code: "IS_SBT", name: "Svalbarðsstrandarhreppur", parent: "IS" },
  { code: "IS_SDN", name: "Suðurnesjabær", parent: "IS" },
  { code: "IS_SDV", name: "Súðavíkurhreppur", parent: "IS" },
  { code: "IS_SEL", name: "Seltjarnarnesbær", parent: "IS" },
  { code: "IS_SFA", name: "Sveitarfélagið Árborg", parent: "IS" },
  { code: "IS_SHF", name: "Sveitarfélagið Hornafjörður", parent: "IS" },
  { code: "IS_SKF", name: "Skaftárhreppur", parent: "IS" },
  { code: "IS_SKG", name: "Skagabyggð", parent: "IS" },
  { code: "IS_SKO", name: "Skorradalshreppur", parent: "IS" },
  { code: "IS_SKR", name: "Skagafjörður", parent: "IS" },
  { code: "IS_SNF", name: "Snæfellsbær", parent: "IS" },
  { code: "IS_SOG", name: "Skeiða- og Gnúpverjahreppur", parent: "IS" },
  { code: "IS_SOL", name: "Sveitarfélagið Ölfus", parent: "IS" },
  { code: "IS_SSS", name: "Sveitarfélagið Skagaströnd", parent: "IS" },
  { code: "IS_STR", name: "Strandabyggð", parent: "IS" },
  { code: "IS_STY", name: "Stykkishólmsbær", parent: "IS" },
  { code: "IS_SVG", name: "Sveitarfélagið Vogar", parent: "IS" },
  { code: "IS_TAL", name: "Tálknafjarðarhreppur", parent: "IS" },
  { code: "IS_THG", name: "Þingeyjarsveit", parent: "IS" },
  { code: "IS_TJO", name: "Tjörneshreppur", parent: "IS" },
  { code: "IS_VEM", name: "Vestmannaeyjabær", parent: "IS" },
  { code: "IS_VER", name: "Vesturbyggð", parent: "IS" },
  { code: "IS_VOP", name: "Vopnafjarðarhreppur", parent: "IS" },
  { code: "IT_21", name: "Piemonte", parent: "IT" },
  { code: "IT_23", name: "Valle d'Aosta, Val d'Aoste", parent: "IT" },
  { code: "IT_25", name: "Lombardia", parent: "IT" },
  { code: "IT_32", name: "Trentino-Alto Adige, Trentino-Südtirol", parent: "IT" },
  { code: "IT_34", name: "Veneto", parent: "IT" },
  { code: "IT_36", name: "Friuli Venezia Giulia", parent: "IT" },
  { code: "IT_42", name: "Liguria", parent: "IT" },
  { code: "IT_45", name: "Emilia-Romagna", parent: "IT" },
  { code: "IT_52", name: "Toscana", parent: "IT" },
  { code: "IT_55", name: "Umbria", parent: "IT" },
  { code: "IT_57", name: "Marche", parent: "IT" },
  { code: "IT_62", name: "Lazio", parent: "IT" },
  { code: "IT_65", name: "Abruzzo", parent: "IT" },
  { code: "IT_67", name: "Molise", parent: "IT" },
  { code: "IT_72", name: "Campania", parent: "IT" },
  { code: "IT_75", name: "Puglia", parent: "IT" },
  { code: "IT_77", name: "Basilicata", parent: "IT" },
  { code: "IT_78", name: "Calabria", parent: "IT" },
  { code: "IT_82", name: "Sicilia", parent: "IT" },
  { code: "IT_88", name: "Sardegna", parent: "IT" },
  { code: "IT_AG", name: "Agrigento", parent: "IT_82" },
  { code: "IT_AL", name: "Alessandria", parent: "IT_21" },
  { code: "IT_AN", name: "Ancona", parent: "IT_57" },
  { code: "IT_AP", name: "Ascoli Piceno", parent: "IT_57" },
  { code: "IT_AQ", name: "L'Aquila", parent: "IT_65" },
  { code: "IT_AR", name: "Arezzo", parent: "IT_52" },
  { code: "IT_AT", name: "Asti", parent: "IT_21" },
  { code: "IT_AV", name: "Avellino", parent: "IT_72" },
  { code: "IT_BA", name: "Bari", parent: "IT_75" },
  { code: "IT_BG", name: "Bergamo", parent: "IT_25" },
  { code: "IT_BI", name: "Biella", parent: "IT_21" },
  { code: "IT_BL", name: "Belluno", parent: "IT_34" },
  { code: "IT_BN", name: "Benevento", parent: "IT_72" },
  { code: "IT_BO", name: "Bologna", parent: "IT_45" },
  { code: "IT_BR", name: "Brindisi", parent: "IT_75" },
  { code: "IT_BS", name: "Brescia", parent: "IT_25" },
  { code: "IT_BT", name: "Barletta-Andria-Trani", parent: "IT_75" },
  { code: "IT_BZ", name: "Bolzano, Bozen", parent: "IT_32" },
  { code: "IT_CA", name: "Cagliari", parent: "IT_88" },
  { code: "IT_CB", name: "Campobasso", parent: "IT_67" },
  { code: "IT_CE", name: "Caserta", parent: "IT_72" },
  { code: "IT_CH", name: "Chieti", parent: "IT_65" },
  { code: "IT_CL", name: "Caltanissetta", parent: "IT_82" },
  { code: "IT_CN", name: "Cuneo", parent: "IT_21" },
  { code: "IT_CO", name: "Como", parent: "IT_25" },
  { code: "IT_CR", name: "Cremona", parent: "IT_25" },
  { code: "IT_CS", name: "Cosenza", parent: "IT_78" },
  { code: "IT_CT", name: "Catania", parent: "IT_82" },
  { code: "IT_CZ", name: "Catanzaro", parent: "IT_78" },
  { code: "IT_EN", name: "Enna", parent: "IT_82" },
  { code: "IT_FC", name: "Forlì-Cesena", parent: "IT_45" },
  { code: "IT_FE", name: "Ferrara", parent: "IT_45" },
  { code: "IT_FG", name: "Foggia", parent: "IT_75" },
  { code: "IT_FI", name: "Firenze", parent: "IT_52" },
  { code: "IT_FM", name: "Fermo", parent: "IT_57" },
  { code: "IT_FR", name: "Frosinone", parent: "IT_62" },
  { code: "IT_GE", name: "Genova", parent: "IT_42" },
  { code: "IT_GO", name: "Gorizia", parent: "IT_36" },
  { code: "IT_GR", name: "Grosseto", parent: "IT_52" },
  { code: "IT_IM", name: "Imperia", parent: "IT_42" },
  { code: "IT_IS", name: "Isernia", parent: "IT_67" },
  { code: "IT_KR", name: "Crotone", parent: "IT_78" },
  { code: "IT_LC", name: "Lecco", parent: "IT_25" },
  { code: "IT_LE", name: "Lecce", parent: "IT_75" },
  { code: "IT_LI", name: "Livorno", parent: "IT_52" },
  { code: "IT_LO", name: "Lodi", parent: "IT_25" },
  { code: "IT_LT", name: "Latina", parent: "IT_62" },
  { code: "IT_LU", name: "Lucca", parent: "IT_52" },
  { code: "IT_MB", name: "Monza e Brianza", parent: "IT_25" },
  { code: "IT_MC", name: "Macerata", parent: "IT_57" },
  { code: "IT_ME", name: "Messina", parent: "IT_82" },
  { code: "IT_MI", name: "Milano", parent: "IT_25" },
  { code: "IT_MN", name: "Mantova", parent: "IT_25" },
  { code: "IT_MO", name: "Modena", parent: "IT_45" },
  { code: "IT_MS", name: "Massa-Carrara", parent: "IT_52" },
  { code: "IT_MT", name: "Matera", parent: "IT_77" },
  { code: "IT_NA", name: "Napoli", parent: "IT_72" },
  { code: "IT_NO", name: "Novara", parent: "IT_21" },
  { code: "IT_NU", name: "Nuoro", parent: "IT_88" },
  { code: "IT_OR", name: "Oristano", parent: "IT_88" },
  { code: "IT_PA", name: "Palermo", parent: "IT_82" },
  { code: "IT_PC", name: "Piacenza", parent: "IT_45" },
  { code: "IT_PD", name: "Padova", parent: "IT_34" },
  { code: "IT_PE", name: "Pescara", parent: "IT_65" },
  { code: "IT_PG", name: "Perugia", parent: "IT_55" },
  { code: "IT_PI", name: "Pisa", parent: "IT_52" },
  { code: "IT_PN", name: "Pordenone", parent: "IT_36" },
  { code: "IT_PO", name: "Prato", parent: "IT_52" },
  { code: "IT_PR", name: "Parma", parent: "IT_45" },
  { code: "IT_PT", name: "Pistoia", parent: "IT_52" },
  { code: "IT_PU", name: "Pesaro e Urbino", parent: "IT_57" },
  { code: "IT_PV", name: "Pavia", parent: "IT_25" },
  { code: "IT_PZ", name: "Potenza", parent: "IT_77" },
  { code: "IT_RA", name: "Ravenna", parent: "IT_45" },
  { code: "IT_RC", name: "Reggio Calabria", parent: "IT_78" },
  { code: "IT_RE", name: "Reggio Emilia", parent: "IT_45" },
  { code: "IT_RG", name: "Ragusa", parent: "IT_82" },
  { code: "IT_RI", name: "Rieti", parent: "IT_62" },
  { code: "IT_RM", name: "Roma", parent: "IT_62" },
  { code: "IT_RN", name: "Rimini", parent: "IT_45" },
  { code: "IT_RO", name: "Rovigo", parent: "IT_34" },
  { code: "IT_SA", name: "Salerno", parent: "IT_72" },
  { code: "IT_SI", name: "Siena", parent: "IT_52" },
  { code: "IT_SO", name: "Sondrio", parent: "IT_25" },
  { code: "IT_SP", name: "La Spezia", parent: "IT_42" },
  { code: "IT_SR", name: "Siracusa", parent: "IT_82" },
  { code: "IT_SS", name: "Sassari", parent: "IT_88" },
  { code: "IT_SU", name: "Sud Sardegna", parent: "IT_88" },
  { code: "IT_SV", name: "Savona", parent: "IT_42" },
  { code: "IT_TA", name: "Taranto", parent: "IT_75" },
  { code: "IT_TE", name: "Teramo", parent: "IT_65" },
  { code: "IT_TN", name: "Trento", parent: "IT_32" },
  { code: "IT_TO", name: "Torino", parent: "IT_21" },
  { code: "IT_TP", name: "Trapani", parent: "IT_82" },
  { code: "IT_TR", name: "Terni", parent: "IT_55" },
  { code: "IT_TS", name: "Trieste", parent: "IT_36" },
  { code: "IT_TV", name: "Treviso", parent: "IT_34" },
  { code: "IT_UD", name: "Udine", parent: "IT_36" },
  { code: "IT_VA", name: "Varese", parent: "IT_25" },
  { code: "IT_VB", name: "Verbano-Cusio-Ossola", parent: "IT_21" },
  { code: "IT_VC", name: "Vercelli", parent: "IT_21" },
  { code: "IT_VE", name: "Venezia", parent: "IT_34" },
  { code: "IT_VI", name: "Vicenza", parent: "IT_34" },
  { code: "IT_VR", name: "Verona", parent: "IT_34" },
  { code: "IT_VT", name: "Viterbo", parent: "IT_62" },
  { code: "IT_VV", name: "Vibo Valentia", parent: "IT_78" },
  { code: "JM_01", name: "Kingston", parent: "JM" },
  { code: "JM_02", name: "Saint Andrew", parent: "JM" },
  { code: "JM_03", name: "Saint Thomas", parent: "JM" },
  { code: "JM_04", name: "Portland", parent: "JM" },
  { code: "JM_05", name: "Saint Mary", parent: "JM" },
  { code: "JM_06", name: "Saint Ann", parent: "JM" },
  { code: "JM_07", name: "Trelawny", parent: "JM" },
  { code: "JM_08", name: "Saint James", parent: "JM" },
  { code: "JM_09", name: "Hanover", parent: "JM" },
  { code: "JM_10", name: "Westmoreland", parent: "JM" },
  { code: "JM_11", name: "Saint Elizabeth", parent: "JM" },
  { code: "JM_12", name: "Manchester", parent: "JM" },
  { code: "JM_13", name: "Clarendon", parent: "JM" },
  { code: "JM_14", name: "Saint Catherine", parent: "JM" },
  { code: "JO_AJ", name: "‘Ajlūn", parent: "JO" },
  { code: "JO_AM", name: "Al ‘A̅şimah", parent: "JO" },
  { code: "JO_AQ", name: "Al ‘Aqabah", parent: "JO" },
  { code: "JO_AT", name: "Aţ Ţafīlah", parent: "JO" },
  { code: "JO_AZ", name: "Az Zarqā’", parent: "JO" },
  { code: "JO_BA", name: "Al Balqā’", parent: "JO" },
  { code: "JO_IR", name: "Irbid", parent: "JO" },
  { code: "JO_JA", name: "Jarash", parent: "JO" },
  { code: "JO_KA", name: "Al Karak", parent: "JO" },
  { code: "JO_MA", name: "Al Mafraq", parent: "JO" },
  { code: "JO_MD", name: "Mādabā", parent: "JO" },
  { code: "JO_MN", name: "Ma‘ān", parent: "JO" },
  { code: "JP_01", name: "Hokkaidô", parent: "JP" },
  { code: "JP_02", name: "Aomori", parent: "JP" },
  { code: "JP_03", name: "Iwate", parent: "JP" },
  { code: "JP_04", name: "Miyagi", parent: "JP" },
  { code: "JP_05", name: "Akita", parent: "JP" },
  { code: "JP_06", name: "Yamagata", parent: "JP" },
  { code: "JP_07", name: "Hukusima", parent: "JP" },
  { code: "JP_08", name: "Ibaraki", parent: "JP" },
  { code: "JP_09", name: "Totigi", parent: "JP" },
  { code: "JP_10", name: "Gunma", parent: "JP" },
  { code: "JP_11", name: "Saitama", parent: "JP" },
  { code: "JP_12", name: "Tiba", parent: "JP" },
  { code: "JP_13", name: "Tôkyô", parent: "JP" },
  { code: "JP_14", name: "Kanagawa", parent: "JP" },
  { code: "JP_15", name: "Niigata", parent: "JP" },
  { code: "JP_16", name: "Toyama", parent: "JP" },
  { code: "JP_17", name: "Isikawa", parent: "JP" },
  { code: "JP_18", name: "Hukui", parent: "JP" },
  { code: "JP_19", name: "Yamanasi", parent: "JP" },
  { code: "JP_20", name: "Nagano", parent: "JP" },
  { code: "JP_21", name: "Gihu", parent: "JP" },
  { code: "JP_22", name: "Sizuoka", parent: "JP" },
  { code: "JP_23", name: "Aiti", parent: "JP" },
  { code: "JP_24", name: "Mie", parent: "JP" },
  { code: "JP_25", name: "Siga", parent: "JP" },
  { code: "JP_26", name: "Kyôto", parent: "JP" },
  { code: "JP_27", name: "Ôsaka", parent: "JP" },
  { code: "JP_28", name: "Hyôgo", parent: "JP" },
  { code: "JP_29", name: "Nara", parent: "JP" },
  { code: "JP_30", name: "Wakayama", parent: "JP" },
  { code: "JP_31", name: "Tottori", parent: "JP" },
  { code: "JP_32", name: "Simane", parent: "JP" },
  { code: "JP_33", name: "Okayama", parent: "JP" },
  { code: "JP_34", name: "Hirosima", parent: "JP" },
  { code: "JP_35", name: "Yamaguti", parent: "JP" },
  { code: "JP_36", name: "Tokusima", parent: "JP" },
  { code: "JP_37", name: "Kagawa", parent: "JP" },
  { code: "JP_38", name: "Ehime", parent: "JP" },
  { code: "JP_39", name: "Kôti", parent: "JP" },
  { code: "JP_40", name: "Hukuoka", parent: "JP" },
  { code: "JP_41", name: "Saga", parent: "JP" },
  { code: "JP_42", name: "Nagasaki", parent: "JP" },
  { code: "JP_43", name: "Kumamoto", parent: "JP" },
  { code: "JP_44", name: "Ôita", parent: "JP" },
  { code: "JP_45", name: "Miyazaki", parent: "JP" },
  { code: "JP_46", name: "Kagosima", parent: "JP" },
  { code: "JP_47", name: "Okinawa", parent: "JP" },
  { code: "KE_01", name: "Baringo", parent: "KE" },
  { code: "KE_02", name: "Bomet", parent: "KE" },
  { code: "KE_03", name: "Bungoma", parent: "KE" },
  { code: "KE_04", name: "Busia", parent: "KE" },
  { code: "KE_05", name: "Elgeyo/Marakwet", parent: "KE" },
  { code: "KE_06", name: "Embu", parent: "KE" },
  { code: "KE_07", name: "Garissa", parent: "KE" },
  { code: "KE_08", name: "Homa Bay", parent: "KE" },
  { code: "KE_09", name: "Isiolo", parent: "KE" },
  { code: "KE_10", name: "Kajiado", parent: "KE" },
  { code: "KE_11", name: "Kakamega", parent: "KE" },
  { code: "KE_12", name: "Kericho", parent: "KE" },
  { code: "KE_13", name: "Kiambu", parent: "KE" },
  { code: "KE_14", name: "Kilifi", parent: "KE" },
  { code: "KE_15", name: "Kirinyaga", parent: "KE" },
  { code: "KE_16", name: "Kisii", parent: "KE" },
  { code: "KE_17", name: "Kisumu", parent: "KE" },
  { code: "KE_18", name: "Kitui", parent: "KE" },
  { code: "KE_19", name: "Kwale", parent: "KE" },
  { code: "KE_20", name: "Laikipia", parent: "KE" },
  { code: "KE_21", name: "Lamu", parent: "KE" },
  { code: "KE_22", name: "Machakos", parent: "KE" },
  { code: "KE_23", name: "Makueni", parent: "KE" },
  { code: "KE_24", name: "Mandera", parent: "KE" },
  { code: "KE_25", name: "Marsabit", parent: "KE" },
  { code: "KE_26", name: "Meru", parent: "KE" },
  { code: "KE_27", name: "Migori", parent: "KE" },
  { code: "KE_28", name: "Mombasa", parent: "KE" },
  { code: "KE_29", name: "Murang'a", parent: "KE" },
  { code: "KE_30", name: "Nairobi City", parent: "KE" },
  { code: "KE_31", name: "Nakuru", parent: "KE" },
  { code: "KE_32", name: "Nandi", parent: "KE" },
  { code: "KE_33", name: "Narok", parent: "KE" },
  { code: "KE_34", name: "Nyamira", parent: "KE" },
  { code: "KE_35", name: "Nyandarua", parent: "KE" },
  { code: "KE_36", name: "Nyeri", parent: "KE" },
  { code: "KE_37", name: "Samburu", parent: "KE" },
  { code: "KE_38", name: "Siaya", parent: "KE" },
  { code: "KE_39", name: "Taita/Taveta", parent: "KE" },
  { code: "KE_40", name: "Tana River", parent: "KE" },
  { code: "KE_41", name: "Tharaka-Nithi", parent: "KE" },
  { code: "KE_42", name: "Trans Nzoia", parent: "KE" },
  { code: "KE_43", name: "Turkana", parent: "KE" },
  { code: "KE_44", name: "Uasin Gishu", parent: "KE" },
  { code: "KE_45", name: "Vihiga", parent: "KE" },
  { code: "KE_46", name: "Wajir", parent: "KE" },
  { code: "KE_47", name: "West Pokot", parent: "KE" },
  { code: "KG_B", name: "Batken", parent: "KG" },
  { code: "KG_C", name: "Chüy", parent: "KG" },
  { code: "KG_GB", name: "Bishkek Shaary", parent: "KG" },
  { code: "KG_GO", name: "Osh Shaary", parent: "KG" },
  { code: "KG_J", name: "Jalal-Abad", parent: "KG" },
  { code: "KG_N", name: "Naryn", parent: "KG" },
  { code: "KG_O", name: "Osh", parent: "KG" },
  { code: "KG_T", name: "Talas", parent: "KG" },
  { code: "KG_Y", name: "Ysyk-Köl", parent: "KG" },
  { code: "KH_1", name: "Banteay Mean Choăy", parent: "KH" },
  { code: "KH_10", name: "Kracheh", parent: "KH" },
  { code: "KH_11", name: "Mondol Kiri", parent: "KH" },
  { code: "KH_12", name: "Phnom Penh", parent: "KH" },
  { code: "KH_13", name: "Preah Vihear", parent: "KH" },
  { code: "KH_14", name: "Prey Veaeng", parent: "KH" },
  { code: "KH_15", name: "Pousaat", parent: "KH" },
  { code: "KH_16", name: "Rotanak Kiri", parent: "KH" },
  { code: "KH_17", name: "Siem Reab", parent: "KH" },
  { code: "KH_18", name: "Preah Sihanouk", parent: "KH" },
  { code: "KH_19", name: "Stueng Traeng", parent: "KH" },
  { code: "KH_2", name: "Baat Dambang", parent: "KH" },
  { code: "KH_20", name: "Svaay Rieng", parent: "KH" },
  { code: "KH_21", name: "Taakaev", parent: "KH" },
  { code: "KH_22", name: "Otdar Mean Chey", parent: "KH" },
  { code: "KH_23", name: "Kaeb", parent: "KH" },
  { code: "KH_24", name: "Pailin", parent: "KH" },
  { code: "KH_25", name: "Tbong Khmum", parent: "KH" },
  { code: "KH_3", name: "Kampong Chaam", parent: "KH" },
  { code: "KH_4", name: "Kampong Chhnang", parent: "KH" },
  { code: "KH_5", name: "Kampong Spueu", parent: "KH" },
  { code: "KH_6", name: "Kampong Thum", parent: "KH" },
  { code: "KH_7", name: "Kampot", parent: "KH" },
  { code: "KH_8", name: "Kandaal", parent: "KH" },
  { code: "KH_9", name: "Kaoh Kong", parent: "KH" },
  { code: "KI_G", name: "Gilbert Islands", parent: "KI" },
  { code: "KI_L", name: "Line Islands", parent: "KI" },
  { code: "KI_P", name: "Phoenix Islands", parent: "KI" },
  { code: "KM_A", name: "Anjouan", parent: "KM" },
  { code: "KM_G", name: "Grande Comore", parent: "KM" },
  { code: "KM_M", name: "Mohéli", parent: "KM" },
  { code: "KN_01", name: "Christ Church Nichola Town", parent: "KN_K" },
  { code: "KN_02", name: "Saint Anne Sandy Point", parent: "KN_K" },
  { code: "KN_03", name: "Saint George Basseterre", parent: "KN_K" },
  { code: "KN_04", name: "Saint George Gingerland", parent: "KN_N" },
  { code: "KN_05", name: "Saint James Windward", parent: "KN_N" },
  { code: "KN_06", name: "Saint John Capisterre", parent: "KN_K" },
  { code: "KN_07", name: "Saint John Figtree", parent: "KN_N" },
  { code: "KN_08", name: "Saint Mary Cayon", parent: "KN_K" },
  { code: "KN_09", name: "Saint Paul Capisterre", parent: "KN_K" },
  { code: "KN_10", name: "Saint Paul Charlestown", parent: "KN_N" },
  { code: "KN_11", name: "Saint Peter Basseterre", parent: "KN_K" },
  { code: "KN_12", name: "Saint Thomas Lowland", parent: "KN_N" },
  { code: "KN_13", name: "Saint Thomas Middle Island", parent: "KN_K" },
  { code: "KN_15", name: "Trinity Palmetto Point", parent: "KN_K" },
  { code: "KN_K", name: "Saint Kitts", parent: "KN" },
  { code: "KN_N", name: "Nevis", parent: "KN" },
  { code: "KP_01", name: "Pyongyang", parent: "KP" },
  { code: "KP_02", name: "South Pyongan", parent: "KP" },
  { code: "KP_03", name: "North Pyongan", parent: "KP" },
  { code: "KP_04", name: "Chagang", parent: "KP" },
  { code: "KP_05", name: "South Hwanghae", parent: "KP" },
  { code: "KP_06", name: "North Hwanghae", parent: "KP" },
  { code: "KP_07", name: "Kangwon", parent: "KP" },
  { code: "KP_08", name: "South Hamgyong", parent: "KP" },
  { code: "KP_09", name: "North Hamgyong", parent: "KP" },
  { code: "KP_10", name: "Ryanggang", parent: "KP" },
  { code: "KP_13", name: "Rason", parent: "KP" },
  { code: "KP_14", name: "Nampo", parent: "KP" },
  { code: "KP_15", name: "Kaesong", parent: "KP" },
  { code: "KR_11", name: "Seoul", parent: "KR" },
  { code: "KR_26", name: "Busan", parent: "KR" },
  { code: "KR_27", name: "Daegu", parent: "KR" },
  { code: "KR_28", name: "Incheon", parent: "KR" },
  { code: "KR_29", name: "Gwangju", parent: "KR" },
  { code: "KR_30", name: "Daejeon", parent: "KR" },
  { code: "KR_31", name: "Ulsan", parent: "KR" },
  { code: "KR_41", name: "Gyeonggi", parent: "KR" },
  { code: "KR_42", name: "Gangwon", parent: "KR" },
  { code: "KR_43", name: "North Chungcheong", parent: "KR" },
  { code: "KR_44", name: "South Chungcheong", parent: "KR" },
  { code: "KR_45", name: "North Jeolla", parent: "KR" },
  { code: "KR_46", name: "South Jeolla", parent: "KR" },
  { code: "KR_47", name: "North Gyeongsang", parent: "KR" },
  { code: "KR_48", name: "South Gyeongsang", parent: "KR" },
  { code: "KR_49", name: "Jeju", parent: "KR" },
  { code: "KR_50", name: "Sejong", parent: "KR" },
  { code: "KW_AH", name: "Al Aḩmadī", parent: "KW" },
  { code: "KW_FA", name: "Al Farwānīyah", parent: "KW" },
  { code: "KW_HA", name: "Ḩawallī", parent: "KW" },
  { code: "KW_JA", name: "Al Jahrā’", parent: "KW" },
  { code: "KW_KU", name: "Al ‘Āşimah", parent: "KW" },
  { code: "KW_MU", name: "Mubārak al Kabīr", parent: "KW" },
  { code: "KZ_10", name: "Abay oblysy", parent: "KZ" },
  { code: "KZ_11", name: "Aqmola oblysy", parent: "KZ" },
  { code: "KZ_15", name: "Aqtöbe oblysy", parent: "KZ" },
  { code: "KZ_19", name: "Almaty oblysy", parent: "KZ" },
  { code: "KZ_23", name: "Atyraū oblysy", parent: "KZ" },
  { code: "KZ_27", name: "Batys Qazaqstan oblysy", parent: "KZ" },
  { code: "KZ_31", name: "Zhambyl oblysy", parent: "KZ" },
  { code: "KZ_33", name: "Zhetisū oblysy", parent: "KZ" },
  { code: "KZ_35", name: "Qaraghandy oblysy", parent: "KZ" },
  { code: "KZ_39", name: "Qostanay oblysy", parent: "KZ" },
  { code: "KZ_43", name: "Qyzylorda oblysy", parent: "KZ" },
  { code: "KZ_47", name: "Mangghystaū oblysy", parent: "KZ" },
  { code: "KZ_55", name: "Pavlodar oblysy", parent: "KZ" },
  { code: "KZ_59", name: "Soltüstik Qazaqstan oblysy", parent: "KZ" },
  { code: "KZ_61", name: "Türkistan oblysy", parent: "KZ" },
  { code: "KZ_62", name: "Ulytaū oblysy", parent: "KZ" },
  { code: "KZ_63", name: "Shyghys Qazaqstan oblysy", parent: "KZ" },
  { code: "KZ_71", name: "Astana", parent: "KZ" },
  { code: "KZ_75", name: "Almaty", parent: "KZ" },
  { code: "KZ_79", name: "Shymkent", parent: "KZ" },
  { code: "LA_AT", name: "Attapu", parent: "LA" },
  { code: "LA_BK", name: "Bokèo", parent: "LA" },
  { code: "LA_BL", name: "Bolikhamxai", parent: "LA" },
  { code: "LA_CH", name: "Champasak", parent: "LA" },
  { code: "LA_HO", name: "Houaphan", parent: "LA" },
  { code: "LA_KH", name: "Khammouan", parent: "LA" },
  { code: "LA_LM", name: "Louang Namtha", parent: "LA" },
  { code: "LA_LP", name: "Louangphabang", parent: "LA" },
  { code: "LA_OU", name: "Oudômxai", parent: "LA" },
  { code: "LA_PH", name: "Phôngsali", parent: "LA" },
  { code: "LA_SL", name: "Salavan", parent: "LA" },
  { code: "LA_SV", name: "Savannakhét", parent: "LA" },
  { code: "LA_VI", name: "Viangchan", parent: "LA" },
  { code: "LA_VT", name: "Viangchan", parent: "LA" },
  { code: "LA_XA", name: "Xaignabouli", parent: "LA" },
  { code: "LA_XE", name: "Xékong", parent: "LA" },
  { code: "LA_XI", name: "Xiangkhouang", parent: "LA" },
  { code: "LA_XS", name: "Xaisômboun", parent: "LA" },
  { code: "LB_AK", name: "Aakkâr", parent: "LB" },
  { code: "LB_AS", name: "Liban-Nord", parent: "LB" },
  { code: "LB_BA", name: "Beyrouth", parent: "LB" },
  { code: "LB_BH", name: "Baalbek-Hermel", parent: "LB" },
  { code: "LB_BI", name: "Béqaa", parent: "LB" },
  { code: "LB_JA", name: "Liban-Sud", parent: "LB" },
  { code: "LB_JL", name: "Mont-Liban", parent: "LB" },
  { code: "LB_NA", name: "Nabatîyé", parent: "LB" },
  { code: "LC_01", name: "Anse la Raye", parent: "LC" },
  { code: "LC_02", name: "Castries", parent: "LC" },
  { code: "LC_03", name: "Choiseul", parent: "LC" },
  { code: "LC_05", name: "Dennery", parent: "LC" },
  { code: "LC_06", name: "Gros Islet", parent: "LC" },
  { code: "LC_07", name: "Laborie", parent: "LC" },
  { code: "LC_08", name: "Micoud", parent: "LC" },
  { code: "LC_10", name: "Soufrière", parent: "LC" },
  { code: "LC_11", name: "Vieux Fort", parent: "LC" },
  { code: "LC_12", name: "Canaries", parent: "LC" },
  { code: "LI_01", name: "Balzers", parent: "LI" },
  { code: "LI_02", name: "Eschen", parent: "LI" },
  { code: "LI_03", name: "Gamprin", parent: "LI" },
  { code: "LI_04", name: "Mauren", parent: "LI" },
  { code: "LI_05", name: "Planken", parent: "LI" },
  { code: "LI_06", name: "Ruggell", parent: "LI" },
  { code: "LI_07", name: "Schaan", parent: "LI" },
  { code: "LI_08", name: "Schellenberg", parent: "LI" },
  { code: "LI_09", name: "Triesen", parent: "LI" },
  { code: "LI_10", name: "Triesenberg", parent: "LI" },
  { code: "LI_11", name: "Vaduz", parent: "LI" },
  { code: "LK_1", name: "Western Province", parent: "LK" },
  { code: "LK_11", name: "Colombo", parent: "LK_1" },
  { code: "LK_12", name: "Gampaha", parent: "LK_1" },
  { code: "LK_13", name: "Kalutara", parent: "LK_1" },
  { code: "LK_2", name: "Central Province", parent: "LK" },
  { code: "LK_21", name: "Kandy", parent: "LK_2" },
  { code: "LK_22", name: "Matale", parent: "LK_2" },
  { code: "LK_23", name: "Nuwara Eliya", parent: "LK_2" },
  { code: "LK_3", name: "Southern Province", parent: "LK" },
  { code: "LK_31", name: "Galle", parent: "LK_3" },
  { code: "LK_32", name: "Matara", parent: "LK_3" },
  { code: "LK_33", name: "Hambantota", parent: "LK_3" },
  { code: "LK_4", name: "Northern Province", parent: "LK" },
  { code: "LK_41", name: "Jaffna", parent: "LK_4" },
  { code: "LK_42", name: "Kilinochchi", parent: "LK_4" },
  { code: "LK_43", name: "Mannar", parent: "LK_4" },
  { code: "LK_44", name: "Vavuniya", parent: "LK_4" },
  { code: "LK_45", name: "Mullaittivu", parent: "LK_4" },
  { code: "LK_5", name: "Eastern Province", parent: "LK" },
  { code: "LK_51", name: "Batticaloa", parent: "LK_5" },
  { code: "LK_52", name: "Ampara", parent: "LK_5" },
  { code: "LK_53", name: "Trincomalee", parent: "LK_5" },
  { code: "LK_6", name: "North Western Province", parent: "LK" },
  { code: "LK_61", name: "Kurunegala", parent: "LK_6" },
  { code: "LK_62", name: "Puttalam", parent: "LK_6" },
  { code: "LK_7", name: "North Central Province", parent: "LK" },
  { code: "LK_71", name: "Anuradhapura", parent: "LK_7" },
  { code: "LK_72", name: "Polonnaruwa", parent: "LK_7" },
  { code: "LK_8", name: "Uva Province", parent: "LK" },
  { code: "LK_81", name: "Badulla", parent: "LK_8" },
  { code: "LK_82", name: "Monaragala", parent: "LK_8" },
  { code: "LK_9", name: "Sabaragamuwa Province", parent: "LK" },
  { code: "LK_91", name: "Ratnapura", parent: "LK_9" },
  { code: "LK_92", name: "Kegalla", parent: "LK_9" },
  { code: "LR_BG", name: "Bong", parent: "LR" },
  { code: "LR_BM", name: "Bomi", parent: "LR" },
  { code: "LR_CM", name: "Grand Cape Mount", parent: "LR" },
  { code: "LR_GB", name: "Grand Bassa", parent: "LR" },
  { code: "LR_GG", name: "Grand Gedeh", parent: "LR" },
  { code: "LR_GK", name: "Grand Kru", parent: "LR" },
  { code: "LR_GP", name: "Gbarpolu", parent: "LR" },
  { code: "LR_LO", name: "Lofa", parent: "LR" },
  { code: "LR_MG", name: "Margibi", parent: "LR" },
  { code: "LR_MO", name: "Montserrado", parent: "LR" },
  { code: "LR_MY", name: "Maryland", parent: "LR" },
  { code: "LR_NI", name: "Nimba", parent: "LR" },
  { code: "LR_RG", name: "River Gee", parent: "LR" },
  { code: "LR_RI", name: "River Cess", parent: "LR" },
  { code: "LR_SI", name: "Sinoe", parent: "LR" },
  { code: "LS_A", name: "Maseru", parent: "LS" },
  { code: "LS_B", name: "Botha-Bothe", parent: "LS" },
  { code: "LS_C", name: "Leribe", parent: "LS" },
  { code: "LS_D", name: "Berea", parent: "LS" },
  { code: "LS_E", name: "Mafeteng", parent: "LS" },
  { code: "LS_F", name: "Mohale's Hoek", parent: "LS" },
  { code: "LS_G", name: "Quthing", parent: "LS" },
  { code: "LS_H", name: "Qacha's Nek", parent: "LS" },
  { code: "LS_J", name: "Mokhotlong", parent: "LS" },
  { code: "LS_K", name: "Thaba-Tseka", parent: "LS" },
  { code: "LT_01", name: "Akmenė", parent: "LT_SA" },
  { code: "LT_02", name: "Alytaus miestas", parent: "LT_AL" },
  { code: "LT_03", name: "Alytus", parent: "LT_AL" },
  { code: "LT_04", name: "Anykščiai", parent: "LT_UT" },
  { code: "LT_05", name: "Birštonas", parent: "LT_KU" },
  { code: "LT_06", name: "Biržai", parent: "LT_PN" },
  { code: "LT_07", name: "Druskininkai", parent: "LT_AL" },
  { code: "LT_08", name: "Elektrėnai", parent: "LT_VL" },
  { code: "LT_09", name: "Ignalina", parent: "LT_UT" },
  { code: "LT_10", name: "Jonava", parent: "LT_KU" },
  { code: "LT_11", name: "Joniškis", parent: "LT_SA" },
  { code: "LT_12", name: "Jurbarkas", parent: "LT_TA" },
  { code: "LT_13", name: "Kaišiadorys", parent: "LT_KU" },
  { code: "LT_14", name: "Kalvarija", parent: "LT_MR" },
  { code: "LT_15", name: "Kauno miestas", parent: "LT_KU" },
  { code: "LT_16", name: "Kaunas", parent: "LT_KU" },
  { code: "LT_17", name: "Kazlų Rūdos", parent: "LT_MR" },
  { code: "LT_18", name: "Kėdainiai", parent: "LT_KU" },
  { code: "LT_19", name: "Kelmė", parent: "LT_SA" },
  { code: "LT_20", name: "Klaipėdos miestas", parent: "LT_KL" },
  { code: "LT_21", name: "Klaipėda", parent: "LT_KL" },
  { code: "LT_22", name: "Kretinga", parent: "LT_KL" },
  { code: "LT_23", name: "Kupiškis", parent: "LT_PN" },
  { code: "LT_24", name: "Lazdijai", parent: "LT_AL" },
  { code: "LT_25", name: "Marijampolė", parent: "LT_MR" },
  { code: "LT_26", name: "Mažeikiai", parent: "LT_TE" },
  { code: "LT_27", name: "Molėtai", parent: "LT_UT" },
  { code: "LT_28", name: "Neringa", parent: "LT_KL" },
  { code: "LT_29", name: "Pagėgiai", parent: "LT_TA" },
  { code: "LT_30", name: "Pakruojis", parent: "LT_SA" },
  { code: "LT_31", name: "Palangos miestas", parent: "LT_KL" },
  { code: "LT_32", name: "Panevėžio miestas", parent: "LT_PN" },
  { code: "LT_33", name: "Panevėžys", parent: "LT_PN" },
  { code: "LT_34", name: "Pasvalys", parent: "LT_PN" },
  { code: "LT_35", name: "Plungė", parent: "LT_TE" },
  { code: "LT_36", name: "Prienai", parent: "LT_KU" },
  { code: "LT_37", name: "Radviliškis", parent: "LT_SA" },
  { code: "LT_38", name: "Raseiniai", parent: "LT_KU" },
  { code: "LT_39", name: "Rietavas", parent: "LT_TE" },
  { code: "LT_40", name: "Rokiškis", parent: "LT_PN" },
  { code: "LT_41", name: "Šakiai", parent: "LT_MR" },
  { code: "LT_42", name: "Šalčininkai", parent: "LT_VL" },
  { code: "LT_43", name: "Šiaulių miestas", parent: "LT_SA" },
  { code: "LT_44", name: "Šiauliai", parent: "LT_SA" },
  { code: "LT_45", name: "Šilalė", parent: "LT_TA" },
  { code: "LT_46", name: "Šilutė", parent: "LT_KL" },
  { code: "LT_47", name: "Širvintos", parent: "LT_VL" },
  { code: "LT_48", name: "Skuodas", parent: "LT_KL" },
  { code: "LT_49", name: "Švenčionys", parent: "LT_VL" },
  { code: "LT_50", name: "Tauragė", parent: "LT_TA" },
  { code: "LT_51", name: "Telšiai", parent: "LT_TE" },
  { code: "LT_52", name: "Trakai", parent: "LT_VL" },
  { code: "LT_53", name: "Ukmergė", parent: "LT_VL" },
  { code: "LT_54", name: "Utena", parent: "LT_UT" },
  { code: "LT_55", name: "Varėna", parent: "LT_AL" },
  { code: "LT_56", name: "Vilkaviškis", parent: "LT_MR" },
  { code: "LT_57", name: "Vilniaus miestas", parent: "LT_VL" },
  { code: "LT_58", name: "Vilnius", parent: "LT_VL" },
  { code: "LT_59", name: "Visaginas", parent: "LT_UT" },
  { code: "LT_60", name: "Zarasai", parent: "LT_UT" },
  { code: "LT_AL", name: "Alytaus apskritis", parent: "LT" },
  { code: "LT_KL", name: "Klaipėdos apskritis", parent: "LT" },
  { code: "LT_KU", name: "Kauno apskritis", parent: "LT" },
  { code: "LT_MR", name: "Marijampolės apskritis", parent: "LT" },
  { code: "LT_PN", name: "Panevėžio apskritis", parent: "LT" },
  { code: "LT_SA", name: "Šiaulių apskritis", parent: "LT" },
  { code: "LT_TA", name: "Tauragės apskritis", parent: "LT" },
  { code: "LT_TE", name: "Telšių apskritis", parent: "LT" },
  { code: "LT_UT", name: "Utenos apskritis", parent: "LT" },
  { code: "LT_VL", name: "Vilniaus apskritis", parent: "LT" },
  { code: "LU_CA", name: "Capellen", parent: "LU" },
  { code: "LU_CL", name: "Clerf", parent: "LU" },
  { code: "LU_D", name: "Diekirch (de, lb)", parent: "LU" },
  { code: "LU_DI", name: "Diekirch", parent: "LU" },
  { code: "LU_EC", name: "Echternach", parent: "LU" },
  { code: "LU_ES", name: "Esch an der Alzette", parent: "LU" },
  { code: "LU_G", name: "Grevenmacher (de, lb)", parent: "LU" },
  { code: "LU_GR", name: "Grevenmacher", parent: "LU" },
  { code: "LU_L", name: "Luxembourg", parent: "LU" },
  { code: "LU_LU", name: "Luxemburg", parent: "LU" },
  { code: "LU_ME", name: "Mersch", parent: "LU" },
  { code: "LU_RD", name: "Redingen", parent: "LU" },
  { code: "LU_RM", name: "Remich", parent: "LU" },
  { code: "LU_VD", name: "Vianden", parent: "LU" },
  { code: "LU_WI", name: "Wiltz", parent: "LU" },
  { code: "LV_002", name: "Aizkraukles novads", parent: "LV" },
  { code: "LV_007", name: "Alūksnes novads", parent: "LV" },
  { code: "LV_011", name: "Ādažu novads", parent: "LV" },
  { code: "LV_015", name: "Balvu novads", parent: "LV" },
  { code: "LV_016", name: "Bauskas novads", parent: "LV" },
  { code: "LV_022", name: "Cēsu novads", parent: "LV" },
  { code: "LV_026", name: "Dobeles novads", parent: "LV" },
  { code: "LV_033", name: "Gulbenes novads", parent: "LV" },
  { code: "LV_041", name: "Jelgavas novads", parent: "LV" },
  { code: "LV_042", name: "Jēkabpils novads", parent: "LV" },
  { code: "LV_047", name: "Krāslavas novads", parent: "LV" },
  { code: "LV_050", name: "Kuldīgas novads", parent: "LV" },
  { code: "LV_052", name: "Ķekavas novads", parent: "LV" },
  { code: "LV_054", name: "Limbažu novads", parent: "LV" },
  { code: "LV_056", name: "Līvānu novads", parent: "LV" },
  { code: "LV_058", name: "Ludzas novads", parent: "LV" },
  { code: "LV_059", name: "Madonas novads", parent: "LV" },
  { code: "LV_062", name: "Mārupes novads", parent: "LV" },
  { code: "LV_067", name: "Ogres novads", parent: "LV" },
  { code: "LV_068", name: "Olaines novads", parent: "LV" },
  { code: "LV_073", name: "Preiļu novads", parent: "LV" },
  { code: "LV_077", name: "Rēzeknes novads", parent: "LV" },
  { code: "LV_080", name: "Ropažu novads", parent: "LV" },
  { code: "LV_087", name: "Salaspils novads", parent: "LV" },
  { code: "LV_088", name: "Saldus novads", parent: "LV" },
  { code: "LV_089", name: "Saulkrastu novads", parent: "LV" },
  { code: "LV_091", name: "Siguldas novads", parent: "LV" },
  { code: "LV_094", name: "Smiltenes novads", parent: "LV" },
  { code: "LV_097", name: "Talsu novads", parent: "LV" },
  { code: "LV_099", name: "Tukuma novads", parent: "LV" },
  { code: "LV_101", name: "Valkas novads", parent: "LV" },
  { code: "LV_102", name: "Varakļānu novads", parent: "LV" },
  { code: "LV_106", name: "Ventspils novads", parent: "LV" },
  { code: "LV_111", name: "Augšdaugavas novads", parent: "LV" },
  { code: "LV_112", name: "Dienvidkurzemes Novads", parent: "LV" },
  { code: "LV_113", name: "Valmieras Novads", parent: "LV" },
  { code: "LV_DGV", name: "Daugavpils", parent: "LV" },
  { code: "LV_JEL", name: "Jelgava", parent: "LV" },
  { code: "LV_JUR", name: "Jūrmala", parent: "LV" },
  { code: "LV_LPX", name: "Liepāja", parent: "LV" },
  { code: "LV_REZ", name: "Rēzekne", parent: "LV" },
  { code: "LV_RIX", name: "Rīga", parent: "LV" },
  { code: "LV_VEN", name: "Ventspils", parent: "LV" },
  { code: "LY_BA", name: "Banghāzī", parent: "LY" },
  { code: "LY_BU", name: "Al Buţnān", parent: "LY" },
  { code: "LY_DR", name: "Darnah", parent: "LY" },
  { code: "LY_GT", name: "Ghāt", parent: "LY" },
  { code: "LY_JA", name: "Al Jabal al Akhḑar", parent: "LY" },
  { code: "LY_JG", name: "Al Jabal al Gharbī", parent: "LY" },
  { code: "LY_JI", name: "Al Jafārah", parent: "LY" },
  { code: "LY_JU", name: "Al Jufrah", parent: "LY" },
  { code: "LY_KF", name: "Al Kufrah", parent: "LY" },
  { code: "LY_MB", name: "Al Marqab", parent: "LY" },
  { code: "LY_MI", name: "Mişrātah", parent: "LY" },
  { code: "LY_MJ", name: "Al Marj", parent: "LY" },
  { code: "LY_MQ", name: "Murzuq", parent: "LY" },
  { code: "LY_NL", name: "Nālūt", parent: "LY" },
  { code: "LY_NQ", name: "An Nuqāţ al Khams", parent: "LY" },
  { code: "LY_SB", name: "Sabhā", parent: "LY" },
  { code: "LY_SR", name: "Surt", parent: "LY" },
  { code: "LY_TB", name: "Ţarābulus", parent: "LY" },
  { code: "LY_WA", name: "Al Wāḩāt", parent: "LY" },
  { code: "LY_WD", name: "Wādī al Ḩayāt", parent: "LY" },
  { code: "LY_WS", name: "Wādī ash Shāţi’", parent: "LY" },
  { code: "LY_ZA", name: "Az Zāwiyah", parent: "LY" },
  { code: "MA_01", name: "Tanger-Tétouan-Al Hoceïma", parent: "MA" },
  { code: "MA_02", name: "L'Oriental", parent: "MA" },
  { code: "MA_03", name: "Fès-Meknès", parent: "MA" },
  { code: "MA_04", name: "Rabat-Salé-Kénitra", parent: "MA" },
  { code: "MA_05", name: "Béni Mellal-Khénifra", parent: "MA" },
  { code: "MA_06", name: "Casablanca-Settat", parent: "MA" },
  { code: "MA_07", name: "Marrakech-Safi", parent: "MA" },
  { code: "MA_08", name: "Drâa-Tafilalet", parent: "MA" },
  { code: "MA_09", name: "Souss-Massa", parent: "MA" },
  { code: "MA_10", name: "Guelmim-Oued Noun (EH-partial)", parent: "MA" },
  { code: "MA_11", name: "Laâyoune-Sakia El Hamra (EH-partial)", parent: "MA" },
  { code: "MA_12", name: "Dakhla-Oued Ed-Dahab (EH)", parent: "MA" },
  { code: "MA_AGD", name: "Agadir-Ida-Ou-Tanane", parent: "MA_09" },
  { code: "MA_AOU", name: "Aousserd (EH)", parent: "MA_12" },
  { code: "MA_ASZ", name: "Assa-Zag (EH-partial)", parent: "MA_10" },
  { code: "MA_AZI", name: "Azilal", parent: "MA_05" },
  { code: "MA_BEM", name: "Béni Mellal", parent: "MA_05" },
  { code: "MA_BER", name: "Berkane", parent: "MA_02" },
  { code: "MA_BES", name: "Benslimane", parent: "MA_06" },
  { code: "MA_BOD", name: "Boujdour (EH)", parent: "MA_11" },
  { code: "MA_BOM", name: "Boulemane", parent: "MA_03" },
  { code: "MA_BRR", name: "Berrechid", parent: "MA_06" },
  { code: "MA_CAS", name: "Casablanca", parent: "MA_06" },
  { code: "MA_CHE", name: "Chefchaouen", parent: "MA_01" },
  { code: "MA_CHI", name: "Chichaoua", parent: "MA_07" },
  { code: "MA_CHT", name: "Chtouka-Ait Baha", parent: "MA_06" },
  { code: "MA_DRI", name: "Driouch", parent: "MA_02" },
  { code: "MA_ERR", name: "Errachidia", parent: "MA_08" },
  { code: "MA_ESI", name: "Essaouira", parent: "MA_07" },
  { code: "MA_ESM", name: "Es-Semara (EH-partial)", parent: "MA_11" },
  { code: "MA_FAH", name: "Fahs-Anjra", parent: "MA_01" },
  { code: "MA_FES", name: "Fès", parent: "MA_03" },
  { code: "MA_FIG", name: "Figuig", parent: "MA_02" },
  { code: "MA_FQH", name: "Fquih Ben Salah", parent: "MA_05" },
  { code: "MA_GUE", name: "Guelmim", parent: "MA_10" },
  { code: "MA_GUF", name: "Guercif", parent: "MA_02" },
  { code: "MA_HAJ", name: "El Hajeb", parent: "MA_03" },
  { code: "MA_HAO", name: "Al Haouz", parent: "MA_07" },
  { code: "MA_HOC", name: "Al Hoceïma", parent: "MA_01" },
  { code: "MA_IFR", name: "Ifrane", parent: "MA_03" },
  { code: "MA_INE", name: "Inezgane-Ait Melloul", parent: "MA_09" },
  { code: "MA_JDI", name: "El Jadida", parent: "MA_06" },
  { code: "MA_JRA", name: "Jerada", parent: "MA_02" },
  { code: "MA_KEN", name: "Kénitra", parent: "MA_04" },
  { code: "MA_KES", name: "El Kelâa des Sraghna", parent: "MA_07" },
  { code: "MA_KHE", name: "Khémisset", parent: "MA_04" },
  { code: "MA_KHN", name: "Khénifra", parent: "MA_05" },
  { code: "MA_KHO", name: "Khouribga", parent: "MA_05" },
  { code: "MA_LAA", name: "Laâyoune (EH)", parent: "MA_11" },
  { code: "MA_LAR", name: "Larache", parent: "MA_01" },
  { code: "MA_MAR", name: "Marrakech", parent: "MA_07" },
  { code: "MA_MDF", name: "M’diq-Fnideq", parent: "MA_01" },
  { code: "MA_MED", name: "Médiouna", parent: "MA_06" },
  { code: "MA_MEK", name: "Meknès", parent: "MA_03" },
  { code: "MA_MID", name: "Midelt", parent: "MA_08" },
  { code: "MA_MOH", name: "Mohammadia", parent: "MA_06" },
  { code: "MA_MOU", name: "Moulay Yacoub", parent: "MA_03" },
  { code: "MA_NAD", name: "Nador", parent: "MA_02" },
  { code: "MA_NOU", name: "Nouaceur", parent: "MA_04" },
  { code: "MA_OUA", name: "Ouarzazate", parent: "MA_08" },
  { code: "MA_OUD", name: "Oued Ed-Dahab (EH)", parent: "MA_12" },
  { code: "MA_OUJ", name: "Oujda-Angad", parent: "MA_02" },
  { code: "MA_OUZ", name: "Ouezzane", parent: "MA_01" },
  { code: "MA_RAB", name: "Rabat", parent: "MA_04" },
  { code: "MA_REH", name: "Rehamna", parent: "MA_07" },
  { code: "MA_SAF", name: "Safi", parent: "MA_07" },
  { code: "MA_SAL", name: "Salé", parent: "MA_04" },
  { code: "MA_SEF", name: "Sefrou", parent: "MA_03" },
  { code: "MA_SET", name: "Settat", parent: "MA_06" },
  { code: "MA_SIB", name: "Sidi Bennour", parent: "MA_06" },
  { code: "MA_SIF", name: "Sidi Ifni", parent: "MA_10" },
  { code: "MA_SIK", name: "Sidi Kacem", parent: "MA_04" },
  { code: "MA_SIL", name: "Sidi Slimane", parent: "MA_04" },
  { code: "MA_SKH", name: "Skhirate-Témara", parent: "MA_04" },
  { code: "MA_TAF", name: "Tarfaya (EH-partial)", parent: "MA_11" },
  { code: "MA_TAI", name: "Taourirt", parent: "MA_02" },
  { code: "MA_TAO", name: "Taounate", parent: "MA_03" },
  { code: "MA_TAR", name: "Taroudannt", parent: "MA_09" },
  { code: "MA_TAT", name: "Tata", parent: "MA_09" },
  { code: "MA_TAZ", name: "Taza", parent: "MA_03" },
  { code: "MA_TET", name: "Tétouan", parent: "MA_01" },
  { code: "MA_TIN", name: "Tinghir", parent: "MA_08" },
  { code: "MA_TIZ", name: "Tiznit", parent: "MA_09" },
  { code: "MA_TNG", name: "Tanger-Assilah", parent: "MA_01" },
  { code: "MA_TNT", name: "Tan-Tan (EH-partial)", parent: "MA_10" },
  { code: "MA_YUS", name: "Youssoufia", parent: "MA_07" },
  { code: "MA_ZAG", name: "Zagora", parent: "MA_08" },
  { code: "MC_CL", name: "La Colle", parent: "MC" },
  { code: "MC_CO", name: "La Condamine", parent: "MC" },
  { code: "MC_FO", name: "Fontvieille", parent: "MC" },
  { code: "MC_GA", name: "La Gare", parent: "MC" },
  { code: "MC_JE", name: "Jardin Exotique", parent: "MC" },
  { code: "MC_LA", name: "Larvotto", parent: "MC" },
  { code: "MC_MA", name: "Malbousquet", parent: "MC" },
  { code: "MC_MC", name: "Monte-Carlo", parent: "MC" },
  { code: "MC_MG", name: "Moneghetti", parent: "MC" },
  { code: "MC_MO", name: "Monaco-Ville", parent: "MC" },
  { code: "MC_MU", name: "Moulins", parent: "MC" },
  { code: "MC_PH", name: "Port-Hercule", parent: "MC" },
  { code: "MC_SD", name: "Sainte-Dévote", parent: "MC" },
  { code: "MC_SO", name: "La Source", parent: "MC" },
  { code: "MC_SP", name: "Spélugues", parent: "MC" },
  { code: "MC_SR", name: "Saint-Roman", parent: "MC" },
  { code: "MC_VR", name: "Vallon de la Rousse", parent: "MC" },
  { code: "MD_AN", name: "Anenii Noi", parent: "MD" },
  { code: "MD_BA", name: "Bălți", parent: "MD" },
  { code: "MD_BD", name: "Bender", parent: "MD" },
  { code: "MD_BR", name: "Briceni", parent: "MD" },
  { code: "MD_BS", name: "Basarabeasca", parent: "MD" },
  { code: "MD_CA", name: "Cahul", parent: "MD" },
  { code: "MD_CL", name: "Călărași", parent: "MD" },
  { code: "MD_CM", name: "Cimișlia", parent: "MD" },
  { code: "MD_CR", name: "Criuleni", parent: "MD" },
  { code: "MD_CS", name: "Căușeni", parent: "MD" },
  { code: "MD_CT", name: "Cantemir", parent: "MD" },
  { code: "MD_CU", name: "Chișinău", parent: "MD" },
  { code: "MD_DO", name: "Dondușeni", parent: "MD" },
  { code: "MD_DR", name: "Drochia", parent: "MD" },
  { code: "MD_DU", name: "Dubăsari", parent: "MD" },
  { code: "MD_ED", name: "Edineț", parent: "MD" },
  { code: "MD_FA", name: "Fălești", parent: "MD" },
  { code: "MD_FL", name: "Florești", parent: "MD" },
  { code: "MD_GA", name: "Găgăuzia, Unitatea teritorială autonomă (UTAG)", parent: "MD" },
  { code: "MD_GL", name: "Glodeni", parent: "MD" },
  { code: "MD_HI", name: "Hîncești", parent: "MD" },
  { code: "MD_IA", name: "Ialoveni", parent: "MD" },
  { code: "MD_LE", name: "Leova", parent: "MD" },
  { code: "MD_NI", name: "Nisporeni", parent: "MD" },
  { code: "MD_OC", name: "Ocnița", parent: "MD" },
  { code: "MD_OR", name: "Orhei", parent: "MD" },
  { code: "MD_RE", name: "Rezina", parent: "MD" },
  { code: "MD_RI", name: "Rîșcani", parent: "MD" },
  { code: "MD_SD", name: "Șoldănești", parent: "MD" },
  { code: "MD_SI", name: "Sîngerei", parent: "MD" },
  { code: "MD_SN", name: "Stînga Nistrului, unitatea teritorială din", parent: "MD" },
  { code: "MD_SO", name: "Soroca", parent: "MD" },
  { code: "MD_ST", name: "Strășeni", parent: "MD" },
  { code: "MD_SV", name: "Ștefan Vodă", parent: "MD" },
  { code: "MD_TA", name: "Taraclia", parent: "MD" },
  { code: "MD_TE", name: "Telenești", parent: "MD" },
  { code: "MD_UN", name: "Ungheni", parent: "MD" },
  { code: "ME_01", name: "Andrijevica", parent: "ME" },
  { code: "ME_02", name: "Bar", parent: "ME" },
  { code: "ME_03", name: "Berane", parent: "ME" },
  { code: "ME_04", name: "Bijelo Polje", parent: "ME" },
  { code: "ME_05", name: "Budva", parent: "ME" },
  { code: "ME_06", name: "Cetinje", parent: "ME" },
  { code: "ME_07", name: "Danilovgrad", parent: "ME" },
  { code: "ME_08", name: "Herceg-Novi", parent: "ME" },
  { code: "ME_09", name: "Kolašin", parent: "ME" },
  { code: "ME_10", name: "Kotor", parent: "ME" },
  { code: "ME_11", name: "Mojkovac", parent: "ME" },
  { code: "ME_12", name: "Nikšić", parent: "ME" },
  { code: "ME_13", name: "Plav", parent: "ME" },
  { code: "ME_14", name: "Pljevlja", parent: "ME" },
  { code: "ME_15", name: "Plužine", parent: "ME" },
  { code: "ME_16", name: "Podgorica", parent: "ME" },
  { code: "ME_17", name: "Rožaje", parent: "ME" },
  { code: "ME_18", name: "Šavnik", parent: "ME" },
  { code: "ME_19", name: "Tivat", parent: "ME" },
  { code: "ME_20", name: "Ulcinj", parent: "ME" },
  { code: "ME_21", name: "Žabljak", parent: "ME" },
  { code: "ME_22", name: "Gusinje", parent: "ME" },
  { code: "ME_23", name: "Petnjica", parent: "ME" },
  { code: "ME_24", name: "Tuzi", parent: "ME" },
  { code: "MG_A", name: "Toamasina", parent: "MG" },
  { code: "MG_D", name: "Antsiranana", parent: "MG" },
  { code: "MG_F", name: "Fianarantsoa", parent: "MG" },
  { code: "MG_M", name: "Mahajanga", parent: "MG" },
  { code: "MG_T", name: "Antananarivo", parent: "MG" },
  { code: "MG_U", name: "Toliara", parent: "MG" },
  { code: "MH_ALK", name: "Ailuk", parent: "MH_T" },
  { code: "MH_ALL", name: "Ailinglaplap", parent: "MH_L" },
  { code: "MH_ARN", name: "Arno", parent: "MH_T" },
  { code: "MH_AUR", name: "Aur", parent: "MH_T" },
  { code: "MH_EBO", name: "Ebon", parent: "MH_L" },
  { code: "MH_ENI", name: "Enewetak & Ujelang", parent: "MH_L" },
  { code: "MH_JAB", name: "Jabat", parent: "MH_L" },
  { code: "MH_JAL", name: "Jaluit", parent: "MH_L" },
  { code: "MH_KIL", name: "Bikini & Kili", parent: "MH_L" },
  { code: "MH_KWA", name: "Kwajalein", parent: "MH_L" },
  { code: "MH_L", name: "Ralik chain", parent: "MH" },
  { code: "MH_LAE", name: "Lae", parent: "MH_L" },
  { code: "MH_LIB", name: "Lib", parent: "MH_L" },
  { code: "MH_LIK", name: "Likiep", parent: "MH_T" },
  { code: "MH_MAJ", name: "Majuro", parent: "MH_T" },
  { code: "MH_MAL", name: "Maloelap", parent: "MH_T" },
  { code: "MH_MEJ", name: "Mejit", parent: "MH_T" },
  { code: "MH_MIL", name: "Mili", parent: "MH_T" },
  { code: "MH_NMK", name: "Namdrik", parent: "MH_L" },
  { code: "MH_NMU", name: "Namu", parent: "MH_L" },
  { code: "MH_RON", name: "Rongelap", parent: "MH_L" },
  { code: "MH_T", name: "Ratak chain", parent: "MH" },
  { code: "MH_UJA", name: "Ujae", parent: "MH_L" },
  { code: "MH_UTI", name: "Utrik", parent: "MH_T" },
  { code: "MH_WTH", name: "Wotho", parent: "MH_L" },
  { code: "MH_WTJ", name: "Wotje", parent: "MH_T" },
  { code: "MK_101", name: "Veles", parent: "MK" },
  { code: "MK_102", name: "Gradsko", parent: "MK" },
  { code: "MK_103", name: "Demir Kapija", parent: "MK" },
  { code: "MK_104", name: "Kavadarci", parent: "MK" },
  { code: "MK_105", name: "Lozovo", parent: "MK" },
  { code: "MK_106", name: "Negotino", parent: "MK" },
  { code: "MK_107", name: "Rosoman", parent: "MK" },
  { code: "MK_108", name: "Sveti Nikole", parent: "MK" },
  { code: "MK_109", name: "Čaška", parent: "MK" },
  { code: "MK_201", name: "Berovo", parent: "MK" },
  { code: "MK_202", name: "Vinica", parent: "MK" },
  { code: "MK_203", name: "Delčevo", parent: "MK" },
  { code: "MK_204", name: "Zrnovci", parent: "MK" },
  { code: "MK_205", name: "Karbinci", parent: "MK" },
  { code: "MK_206", name: "Kočani", parent: "MK" },
  { code: "MK_207", name: "Makedonska Kamenica", parent: "MK" },
  { code: "MK_208", name: "Pehčevo", parent: "MK" },
  { code: "MK_209", name: "Probištip", parent: "MK" },
  { code: "MK_210", name: "Češinovo-Obleševo", parent: "MK" },
  { code: "MK_211", name: "Štip", parent: "MK" },
  { code: "MK_301", name: "Vevčani", parent: "MK" },
  { code: "MK_303", name: "Debar", parent: "MK" },
  { code: "MK_304", name: "Debrca", parent: "MK" },
  { code: "MK_307", name: "Kičevo", parent: "MK" },
  { code: "MK_308", name: "Makedonski Brod", parent: "MK" },
  { code: "MK_310", name: "Ohrid", parent: "MK" },
  { code: "MK_311", name: "Plasnica", parent: "MK" },
  { code: "MK_312", name: "Struga", parent: "MK" },
  { code: "MK_313", name: "Centar Župa", parent: "MK" },
  { code: "MK_401", name: "Bogdanci", parent: "MK" },
  { code: "MK_402", name: "Bosilovo", parent: "MK" },
  { code: "MK_403", name: "Valandovo", parent: "MK" },
  { code: "MK_404", name: "Vasilevo", parent: "MK" },
  { code: "MK_405", name: "Gevgelija", parent: "MK" },
  { code: "MK_406", name: "Dojran", parent: "MK" },
  { code: "MK_407", name: "Konče", parent: "MK" },
  { code: "MK_408", name: "Novo Selo", parent: "MK" },
  { code: "MK_409", name: "Radoviš", parent: "MK" },
  { code: "MK_410", name: "Strumica", parent: "MK" },
  { code: "MK_501", name: "Bitola", parent: "MK" },
  { code: "MK_502", name: "Demir Hisar", parent: "MK" },
  { code: "MK_503", name: "Dolneni", parent: "MK" },
  { code: "MK_504", name: "Krivogaštani", parent: "MK" },
  { code: "MK_505", name: "Kruševo", parent: "MK" },
  { code: "MK_506", name: "Mogila", parent: "MK" },
  { code: "MK_507", name: "Novaci", parent: "MK" },
  { code: "MK_508", name: "Prilep", parent: "MK" },
  { code: "MK_509", name: "Resen", parent: "MK" },
  { code: "MK_601", name: "Bogovinje", parent: "MK" },
  { code: "MK_602", name: "Brvenica", parent: "MK" },
  { code: "MK_603", name: "Vrapčište", parent: "MK" },
  { code: "MK_604", name: "Gostivar", parent: "MK" },
  { code: "MK_605", name: "Želino", parent: "MK" },
  { code: "MK_606", name: "Jegunovce", parent: "MK" },
  { code: "MK_607", name: "Mavrovo i Rostuše", parent: "MK" },
  { code: "MK_608", name: "Tearce", parent: "MK" },
  { code: "MK_609", name: "Tetovo", parent: "MK" },
  { code: "MK_701", name: "Kratovo", parent: "MK" },
  { code: "MK_702", name: "Kriva Palanka", parent: "MK" },
  { code: "MK_703", name: "Kumanovo", parent: "MK" },
  { code: "MK_704", name: "Lipkovo", parent: "MK" },
  { code: "MK_705", name: "Rankovce", parent: "MK" },
  { code: "MK_706", name: "Staro Nagoričane", parent: "MK" },
  { code: "MK_801", name: "Aerodrom", parent: "MK" },
  { code: "MK_802", name: "Aračinovo", parent: "MK" },
  { code: "MK_803", name: "Butel", parent: "MK" },
  { code: "MK_804", name: "Gazi Baba", parent: "MK" },
  { code: "MK_805", name: "Gjorče Petrov", parent: "MK" },
  { code: "MK_806", name: "Zelenikovo", parent: "MK" },
  { code: "MK_807", name: "Ilinden", parent: "MK" },
  { code: "MK_808", name: "Karpoš", parent: "MK" },
  { code: "MK_809", name: "Kisela Voda", parent: "MK" },
  { code: "MK_810", name: "Petrovec", parent: "MK" },
  { code: "MK_811", name: "Saraj", parent: "MK" },
  { code: "MK_812", name: "Sopište", parent: "MK" },
  { code: "MK_813", name: "Studeničani", parent: "MK" },
  { code: "MK_814", name: "Centar", parent: "MK" },
  { code: "MK_815", name: "Čair", parent: "MK" },
  { code: "MK_816", name: "Čučer-Sandevo", parent: "MK" },
  { code: "MK_817", name: "Šuto Orizari", parent: "MK" },
  { code: "ML_1", name: "Kayes", parent: "ML" },
  { code: "ML_10", name: "Taoudénit", parent: "ML" },
  { code: "ML_2", name: "Koulikoro", parent: "ML" },
  { code: "ML_3", name: "Sikasso", parent: "ML" },
  { code: "ML_4", name: "Ségou", parent: "ML" },
  { code: "ML_5", name: "Mopti", parent: "ML" },
  { code: "ML_6", name: "Tombouctou", parent: "ML" },
  { code: "ML_7", name: "Gao", parent: "ML" },
  { code: "ML_8", name: "Kidal", parent: "ML" },
  { code: "ML_9", name: "Ménaka", parent: "ML" },
  { code: "ML_BKO", name: "Bamako", parent: "ML" },
  { code: "MM_01", name: "Sagaing", parent: "MM" },
  { code: "MM_02", name: "Bago", parent: "MM" },
  { code: "MM_03", name: "Magway", parent: "MM" },
  { code: "MM_04", name: "Mandalay", parent: "MM" },
  { code: "MM_05", name: "Tanintharyi", parent: "MM" },
  { code: "MM_06", name: "Yangon", parent: "MM" },
  { code: "MM_07", name: "Ayeyarwady", parent: "MM" },
  { code: "MM_11", name: "Kachin", parent: "MM" },
  { code: "MM_12", name: "Kayah", parent: "MM" },
  { code: "MM_13", name: "Kayin", parent: "MM" },
  { code: "MM_14", name: "Chin", parent: "MM" },
  { code: "MM_15", name: "Mon", parent: "MM" },
  { code: "MM_16", name: "Rakhine", parent: "MM" },
  { code: "MM_17", name: "Shan", parent: "MM" },
  { code: "MM_18", name: "Nay Pyi Taw", parent: "MM" },
  { code: "MN_035", name: "Orhon", parent: "MN" },
  { code: "MN_037", name: "Darhan uul", parent: "MN" },
  { code: "MN_039", name: "Hentiy", parent: "MN" },
  { code: "MN_041", name: "Hövsgöl", parent: "MN" },
  { code: "MN_043", name: "Hovd", parent: "MN" },
  { code: "MN_046", name: "Uvs", parent: "MN" },
  { code: "MN_047", name: "Töv", parent: "MN" },
  { code: "MN_049", name: "Selenge", parent: "MN" },
  { code: "MN_051", name: "Sühbaatar", parent: "MN" },
  { code: "MN_053", name: "Ömnögovĭ", parent: "MN" },
  { code: "MN_055", name: "Övörhangay", parent: "MN" },
  { code: "MN_057", name: "Dzavhan", parent: "MN" },
  { code: "MN_059", name: "Dundgovĭ", parent: "MN" },
  { code: "MN_061", name: "Dornod", parent: "MN" },
  { code: "MN_063", name: "Dornogovĭ", parent: "MN" },
  { code: "MN_064", name: "Govĭ-Sümber", parent: "MN" },
  { code: "MN_065", name: "Govĭ-Altay", parent: "MN" },
  { code: "MN_067", name: "Bulgan", parent: "MN" },
  { code: "MN_069", name: "Bayanhongor", parent: "MN" },
  { code: "MN_071", name: "Bayan-Ölgiy", parent: "MN" },
  { code: "MN_073", name: "Arhangay", parent: "MN" },
  { code: "MN_1", name: "Ulaanbaatar", parent: "MN" },
  { code: "MR_01", name: "Hodh ech Chargui", parent: "MR" },
  { code: "MR_02", name: "Hodh el Gharbi", parent: "MR" },
  { code: "MR_03", name: "Assaba", parent: "MR" },
  { code: "MR_04", name: "Gorgol", parent: "MR" },
  { code: "MR_05", name: "Brakna", parent: "MR" },
  { code: "MR_06", name: "Trarza", parent: "MR" },
  { code: "MR_07", name: "Adrar", parent: "MR" },
  { code: "MR_08", name: "Dakhlet Nouâdhibou", parent: "MR" },
  { code: "MR_09", name: "Tagant", parent: "MR" },
  { code: "MR_10", name: "Guidimaka", parent: "MR" },
  { code: "MR_11", name: "Tiris Zemmour", parent: "MR" },
  { code: "MR_12", name: "Inchiri", parent: "MR" },
  { code: "MR_13", name: "Nuwākshūţ al Gharbīyah", parent: "MR" },
  { code: "MR_14", name: "Nuwākshūţ ash Shamālīyah", parent: "MR" },
  { code: "MR_15", name: "Nuwākshūţ al Janūbīyah", parent: "MR" },
  { code: "MT_01", name: "Attard", parent: "MT" },
  { code: "MT_02", name: "Balzan", parent: "MT" },
  { code: "MT_03", name: "Birgu", parent: "MT" },
  { code: "MT_04", name: "Birkirkara", parent: "MT" },
  { code: "MT_05", name: "Birżebbuġa", parent: "MT" },
  { code: "MT_06", name: "Bormla", parent: "MT" },
  { code: "MT_07", name: "Dingli", parent: "MT" },
  { code: "MT_08", name: "Fgura", parent: "MT" },
  { code: "MT_09", name: "Floriana", parent: "MT" },
  { code: "MT_10", name: "Fontana", parent: "MT" },
  { code: "MT_11", name: "Gudja", parent: "MT" },
  { code: "MT_12", name: "Gżira", parent: "MT" },
  { code: "MT_13", name: "Għajnsielem", parent: "MT" },
  { code: "MT_14", name: "Għarb", parent: "MT" },
  { code: "MT_15", name: "Għargħur", parent: "MT" },
  { code: "MT_16", name: "Għasri", parent: "MT" },
  { code: "MT_17", name: "Għaxaq", parent: "MT" },
  { code: "MT_18", name: "Ħamrun", parent: "MT" },
  { code: "MT_19", name: "Iklin", parent: "MT" },
  { code: "MT_20", name: "Isla", parent: "MT" },
  { code: "MT_21", name: "Kalkara", parent: "MT" },
  { code: "MT_22", name: "Kerċem", parent: "MT" },
  { code: "MT_23", name: "Kirkop", parent: "MT" },
  { code: "MT_24", name: "Lija", parent: "MT" },
  { code: "MT_25", name: "Luqa", parent: "MT" },
  { code: "MT_26", name: "Marsa", parent: "MT" },
  { code: "MT_27", name: "Marsaskala", parent: "MT" },
  { code: "MT_28", name: "Marsaxlokk", parent: "MT" },
  { code: "MT_29", name: "Mdina", parent: "MT" },
  { code: "MT_30", name: "Mellieħa", parent: "MT" },
  { code: "MT_31", name: "Mġarr", parent: "MT" },
  { code: "MT_32", name: "Mosta", parent: "MT" },
  { code: "MT_33", name: "Mqabba", parent: "MT" },
  { code: "MT_34", name: "Msida", parent: "MT" },
  { code: "MT_35", name: "Mtarfa", parent: "MT" },
  { code: "MT_36", name: "Munxar", parent: "MT" },
  { code: "MT_37", name: "Nadur", parent: "MT" },
  { code: "MT_38", name: "Naxxar", parent: "MT" },
  { code: "MT_39", name: "Paola", parent: "MT" },
  { code: "MT_40", name: "Pembroke", parent: "MT" },
  { code: "MT_41", name: "Pietà", parent: "MT" },
  { code: "MT_42", name: "Qala", parent: "MT" },
  { code: "MT_43", name: "Qormi", parent: "MT" },
  { code: "MT_44", name: "Qrendi", parent: "MT" },
  { code: "MT_45", name: "Rabat Gozo", parent: "MT" },
  { code: "MT_46", name: "Rabat Malta", parent: "MT" },
  { code: "MT_47", name: "Safi", parent: "MT" },
  { code: "MT_48", name: "Saint Julian's", parent: "MT" },
  { code: "MT_49", name: "Saint John", parent: "MT" },
  { code: "MT_50", name: "Saint Lawrence", parent: "MT" },
  { code: "MT_51", name: "Saint Paul's Bay", parent: "MT" },
  { code: "MT_52", name: "Sannat", parent: "MT" },
  { code: "MT_53", name: "Saint Lucia's", parent: "MT" },
  { code: "MT_54", name: "Santa Venera", parent: "MT" },
  { code: "MT_55", name: "Siġġiewi", parent: "MT" },
  { code: "MT_56", name: "Sliema", parent: "MT" },
  { code: "MT_57", name: "Swieqi", parent: "MT" },
  { code: "MT_58", name: "Ta' Xbiex", parent: "MT" },
  { code: "MT_59", name: "Tarxien", parent: "MT" },
  { code: "MT_60", name: "Valletta", parent: "MT" },
  { code: "MT_61", name: "Xagħra", parent: "MT" },
  { code: "MT_62", name: "Xewkija", parent: "MT" },
  { code: "MT_63", name: "Xgħajra", parent: "MT" },
  { code: "MT_64", name: "Żabbar", parent: "MT" },
  { code: "MT_65", name: "Żebbuġ Gozo", parent: "MT" },
  { code: "MT_66", name: "Żebbuġ Malta", parent: "MT" },
  { code: "MT_67", name: "Żejtun", parent: "MT" },
  { code: "MT_68", name: "Żurrieq", parent: "MT" },
  { code: "MU_AG", name: "Agalega Islands", parent: "MU" },
  { code: "MU_BL", name: "Black River", parent: "MU" },
  { code: "MU_CC", name: "Cargados Carajos Shoals", parent: "MU" },
  { code: "MU_FL", name: "Flacq", parent: "MU" },
  { code: "MU_GP", name: "Grand Port", parent: "MU" },
  { code: "MU_MO", name: "Moka", parent: "MU" },
  { code: "MU_PA", name: "Pamplemousses", parent: "MU" },
  { code: "MU_PL", name: "Port Louis", parent: "MU" },
  { code: "MU_PW", name: "Plaines Wilhems", parent: "MU" },
  { code: "MU_RO", name: "Rodrigues Island", parent: "MU" },
  { code: "MU_RR", name: "Rivière du Rempart", parent: "MU" },
  { code: "MU_SA", name: "Savanne", parent: "MU" },
  { code: "MV_00", name: "South Ari Atoll", parent: "MV" },
  { code: "MV_01", name: "Addu City", parent: "MV" },
  { code: "MV_02", name: "North Ari Atoll", parent: "MV" },
  { code: "MV_03", name: "Faadhippolhu", parent: "MV" },
  { code: "MV_04", name: "Felidhu Atoll", parent: "MV" },
  { code: "MV_05", name: "Hahdhunmathi", parent: "MV" },
  { code: "MV_07", name: "North Thiladhunmathi", parent: "MV" },
  { code: "MV_08", name: "Kolhumadulu", parent: "MV" },
  { code: "MV_12", name: "Mulaku Atoll", parent: "MV" },
  { code: "MV_13", name: "North Maalhosmadulu", parent: "MV" },
  { code: "MV_14", name: "North Nilandhe Atoll", parent: "MV" },
  { code: "MV_17", name: "South Nilandhe Atoll", parent: "MV" },
  { code: "MV_20", name: "South Maalhosmadulu", parent: "MV" },
  { code: "MV_23", name: "South Thiladhunmathi", parent: "MV" },
  { code: "MV_24", name: "North Miladhunmadulu", parent: "MV" },
  { code: "MV_25", name: "South Miladhunmadulu", parent: "MV" },
  { code: "MV_26", name: "Male Atoll", parent: "MV" },
  { code: "MV_27", name: "North Huvadhu Atoll", parent: "MV" },
  { code: "MV_28", name: "South Huvadhu Atoll", parent: "MV" },
  { code: "MV_29", name: "Fuvammulah", parent: "MV" },
  { code: "MV_MLE", name: "Male", parent: "MV" },
  { code: "MW_BA", name: "Balaka", parent: "MW_S" },
  { code: "MW_BL", name: "Blantyre", parent: "MW_S" },
  { code: "MW_C", name: "Central Region", parent: "MW" },
  { code: "MW_CK", name: "Chikwawa", parent: "MW_S" },
  { code: "MW_CR", name: "Chiradzulu", parent: "MW_S" },
  { code: "MW_CT", name: "Chitipa", parent: "MW_N" },
  { code: "MW_DE", name: "Dedza", parent: "MW_C" },
  { code: "MW_DO", name: "Dowa", parent: "MW_C" },
  { code: "MW_KR", name: "Karonga", parent: "MW_N" },
  { code: "MW_KS", name: "Kasungu", parent: "MW_C" },
  { code: "MW_LI", name: "Lilongwe", parent: "MW_C" },
  { code: "MW_LK", name: "Likoma", parent: "MW_N" },
  { code: "MW_MC", name: "Mchinji", parent: "MW_C" },
  { code: "MW_MG", name: "Mangochi", parent: "MW_S" },
  { code: "MW_MH", name: "Machinga", parent: "MW_S" },
  { code: "MW_MU", name: "Mulanje", parent: "MW_S" },
  { code: "MW_MW", name: "Mwanza", parent: "MW_S" },
  { code: "MW_MZ", name: "Mzimba", parent: "MW_N" },
  { code: "MW_N", name: "Northern Region", parent: "MW" },
  { code: "MW_NB", name: "Nkhata Bay", parent: "MW_N" },
  { code: "MW_NE", name: "Neno", parent: "MW_S" },
  { code: "MW_NI", name: "Ntchisi", parent: "MW_C" },
  { code: "MW_NK", name: "Nkhotakota", parent: "MW_C" },
  { code: "MW_NS", name: "Nsanje", parent: "MW_S" },
  { code: "MW_NU", name: "Ntcheu", parent: "MW_C" },
  { code: "MW_PH", name: "Phalombe", parent: "MW_S" },
  { code: "MW_RU", name: "Rumphi", parent: "MW_N" },
  { code: "MW_S", name: "Southern Region", parent: "MW" },
  { code: "MW_SA", name: "Salima", parent: "MW_C" },
  { code: "MW_TH", name: "Thyolo", parent: "MW_S" },
  { code: "MW_ZO", name: "Zomba", parent: "MW_S" },
  { code: "MX_AGU", name: "Aguascalientes", parent: "MX" },
  { code: "MX_BCN", name: "Baja California", parent: "MX" },
  { code: "MX_BCS", name: "Baja California Sur", parent: "MX" },
  { code: "MX_CAM", name: "Campeche", parent: "MX" },
  { code: "MX_CHH", name: "Chihuahua", parent: "MX" },
  { code: "MX_CHP", name: "Chiapas", parent: "MX" },
  { code: "MX_CMX", name: "Ciudad de México", parent: "MX" },
  { code: "MX_COA", name: "Coahuila de Zaragoza", parent: "MX" },
  { code: "MX_COL", name: "Colima", parent: "MX" },
  { code: "MX_DUR", name: "Durango", parent: "MX" },
  { code: "MX_GRO", name: "Guerrero", parent: "MX" },
  { code: "MX_GUA", name: "Guanajuato", parent: "MX" },
  { code: "MX_HID", name: "Hidalgo", parent: "MX" },
  { code: "MX_JAL", name: "Jalisco", parent: "MX" },
  { code: "MX_MEX", name: "México", parent: "MX" },
  { code: "MX_MIC", name: "Michoacán de Ocampo", parent: "MX" },
  { code: "MX_MOR", name: "Morelos", parent: "MX" },
  { code: "MX_NAY", name: "Nayarit", parent: "MX" },
  { code: "MX_NLE", name: "Nuevo León", parent: "MX" },
  { code: "MX_OAX", name: "Oaxaca", parent: "MX" },
  { code: "MX_PUE", name: "Puebla", parent: "MX" },
  { code: "MX_QUE", name: "Querétaro", parent: "MX" },
  { code: "MX_ROO", name: "Quintana Roo", parent: "MX" },
  { code: "MX_SIN", name: "Sinaloa", parent: "MX" },
  { code: "MX_SLP", name: "San Luis Potosí", parent: "MX" },
  { code: "MX_SON", name: "Sonora", parent: "MX" },
  { code: "MX_TAB", name: "Tabasco", parent: "MX" },
  { code: "MX_TAM", name: "Tamaulipas", parent: "MX" },
  { code: "MX_TLA", name: "Tlaxcala", parent: "MX" },
  { code: "MX_VER", name: "Veracruz de Ignacio de la Llave", parent: "MX" },
  { code: "MX_YUC", name: "Yucatán", parent: "MX" },
  { code: "MX_ZAC", name: "Zacatecas", parent: "MX" },
  { code: "MY_01", name: "Johor", parent: "MY" },
  { code: "MY_02", name: "Kedah", parent: "MY" },
  { code: "MY_03", name: "Kelantan", parent: "MY" },
  { code: "MY_04", name: "Melaka", parent: "MY" },
  { code: "MY_05", name: "Negeri Sembilan", parent: "MY" },
  { code: "MY_06", name: "Pahang", parent: "MY" },
  { code: "MY_07", name: "Pulau Pinang", parent: "MY" },
  { code: "MY_08", name: "Perak", parent: "MY" },
  { code: "MY_09", name: "Perlis", parent: "MY" },
  { code: "MY_10", name: "Selangor", parent: "MY" },
  { code: "MY_11", name: "Terengganu", parent: "MY" },
  { code: "MY_12", name: "Sabah", parent: "MY" },
  { code: "MY_13", name: "Sarawak", parent: "MY" },
  { code: "MY_14", name: "Wilayah Persekutuan Kuala Lumpur", parent: "MY" },
  { code: "MY_15", name: "Wilayah Persekutuan Labuan", parent: "MY" },
  { code: "MY_16", name: "Wilayah Persekutuan Putrajaya", parent: "MY" },
  { code: "MZ_A", name: "Niassa", parent: "MZ" },
  { code: "MZ_B", name: "Manica", parent: "MZ" },
  { code: "MZ_G", name: "Gaza", parent: "MZ" },
  { code: "MZ_I", name: "Inhambane", parent: "MZ" },
  { code: "MZ_L", name: "Maputo", parent: "MZ" },
  { code: "MZ_MPM", name: "Maputo", parent: "MZ" },
  { code: "MZ_N", name: "Nampula", parent: "MZ" },
  { code: "MZ_P", name: "Cabo Delgado", parent: "MZ" },
  { code: "MZ_Q", name: "Zambézia", parent: "MZ" },
  { code: "MZ_S", name: "Sofala", parent: "MZ" },
  { code: "MZ_T", name: "Tete", parent: "MZ" },
  { code: "NA_CA", name: "Zambezi", parent: "NA" },
  { code: "NA_ER", name: "Erongo", parent: "NA" },
  { code: "NA_HA", name: "Hardap", parent: "NA" },
  { code: "NA_KA", name: "//Karas", parent: "NA" },
  { code: "NA_KE", name: "Kavango East", parent: "NA" },
  { code: "NA_KH", name: "Khomas", parent: "NA" },
  { code: "NA_KU", name: "Kunene", parent: "NA" },
  { code: "NA_KW", name: "Kavango West", parent: "NA" },
  { code: "NA_OD", name: "Otjozondjupa", parent: "NA" },
  { code: "NA_OH", name: "Omaheke", parent: "NA" },
  { code: "NA_ON", name: "Oshana", parent: "NA" },
  { code: "NA_OS", name: "Omusati", parent: "NA" },
  { code: "NA_OT", name: "Oshikoto", parent: "NA" },
  { code: "NA_OW", name: "Ohangwena", parent: "NA" },
  { code: "NE_1", name: "Agadez", parent: "NE" },
  { code: "NE_2", name: "Diffa", parent: "NE" },
  { code: "NE_3", name: "Dosso", parent: "NE" },
  { code: "NE_4", name: "Maradi", parent: "NE" },
  { code: "NE_5", name: "Tahoua", parent: "NE" },
  { code: "NE_6", name: "Tillabéri", parent: "NE" },
  { code: "NE_7", name: "Zinder", parent: "NE" },
  { code: "NE_8", name: "Niamey", parent: "NE" },
  { code: "NG_AB", name: "Abia", parent: "NG" },
  { code: "NG_AD", name: "Adamawa", parent: "NG" },
  { code: "NG_AK", name: "Akwa Ibom", parent: "NG" },
  { code: "NG_AN", name: "Anambra", parent: "NG" },
  { code: "NG_BA", name: "Bauchi", parent: "NG" },
  { code: "NG_BE", name: "Benue", parent: "NG" },
  { code: "NG_BO", name: "Borno", parent: "NG" },
  { code: "NG_BY", name: "Bayelsa", parent: "NG" },
  { code: "NG_CR", name: "Cross River", parent: "NG" },
  { code: "NG_DE", name: "Delta", parent: "NG" },
  { code: "NG_EB", name: "Ebonyi", parent: "NG" },
  { code: "NG_ED", name: "Edo", parent: "NG" },
  { code: "NG_EK", name: "Ekiti", parent: "NG" },
  { code: "NG_EN", name: "Enugu", parent: "NG" },
  { code: "NG_FC", name: "Abuja Federal Capital Territory", parent: "NG" },
  { code: "NG_GO", name: "Gombe", parent: "NG" },
  { code: "NG_IM", name: "Imo", parent: "NG" },
  { code: "NG_JI", name: "Jigawa", parent: "NG" },
  { code: "NG_KD", name: "Kaduna", parent: "NG" },
  { code: "NG_KE", name: "Kebbi", parent: "NG" },
  { code: "NG_KN", name: "Kano", parent: "NG" },
  { code: "NG_KO", name: "Kogi", parent: "NG" },
  { code: "NG_KT", name: "Katsina", parent: "NG" },
  { code: "NG_KW", name: "Kwara", parent: "NG" },
  { code: "NG_LA", name: "Lagos", parent: "NG" },
  { code: "NG_NA", name: "Nasarawa", parent: "NG" },
  { code: "NG_NI", name: "Niger", parent: "NG" },
  { code: "NG_OG", name: "Ogun", parent: "NG" },
  { code: "NG_ON", name: "Ondo", parent: "NG" },
  { code: "NG_OS", name: "Osun", parent: "NG" },
  { code: "NG_OY", name: "Oyo", parent: "NG" },
  { code: "NG_PL", name: "Plateau", parent: "NG" },
  { code: "NG_RI", name: "Rivers", parent: "NG" },
  { code: "NG_SO", name: "Sokoto", parent: "NG" },
  { code: "NG_TA", name: "Taraba", parent: "NG" },
  { code: "NG_YO", name: "Yobe", parent: "NG" },
  { code: "NG_ZA", name: "Zamfara", parent: "NG" },
  { code: "NI_AN", name: "Costa Caribe Norte", parent: "NI" },
  { code: "NI_AS", name: "Costa Caribe Sur", parent: "NI" },
  { code: "NI_BO", name: "Boaco", parent: "NI" },
  { code: "NI_CA", name: "Carazo", parent: "NI" },
  { code: "NI_CI", name: "Chinandega", parent: "NI" },
  { code: "NI_CO", name: "Chontales", parent: "NI" },
  { code: "NI_ES", name: "Estelí", parent: "NI" },
  { code: "NI_GR", name: "Granada", parent: "NI" },
  { code: "NI_JI", name: "Jinotega", parent: "NI" },
  { code: "NI_LE", name: "León", parent: "NI" },
  { code: "NI_MD", name: "Madriz", parent: "NI" },
  { code: "NI_MN", name: "Managua", parent: "NI" },
  { code: "NI_MS", name: "Masaya", parent: "NI" },
  { code: "NI_MT", name: "Matagalpa", parent: "NI" },
  { code: "NI_NS", name: "Nueva Segovia", parent: "NI" },
  { code: "NI_RI", name: "Rivas", parent: "NI" },
  { code: "NI_SJ", name: "Río San Juan", parent: "NI" },
  { code: "NL_AW", name: "Aruba", parent: "NL" },
  { code: "NL_BQ1", name: "Bonaire", parent: "NL" },
  { code: "NL_BQ2", name: "Saba", parent: "NL" },
  { code: "NL_BQ3", name: "Sint Eustatius", parent: "NL" },
  { code: "NL_CW", name: "Curaçao", parent: "NL" },
  { code: "NL_DR", name: "Drenthe", parent: "NL" },
  { code: "NL_FL", name: "Flevoland", parent: "NL" },
  { code: "NL_FR", name: "Fryslân", parent: "NL" },
  { code: "NL_GE", name: "Gelderland", parent: "NL" },
  { code: "NL_GR", name: "Groningen", parent: "NL" },
  { code: "NL_LI", name: "Limburg", parent: "NL" },
  { code: "NL_NB", name: "Noord-Brabant", parent: "NL" },
  { code: "NL_NH", name: "Noord-Holland", parent: "NL" },
  { code: "NL_OV", name: "Overijssel", parent: "NL" },
  { code: "NL_SX", name: "Sint Maarten", parent: "NL" },
  { code: "NL_UT", name: "Utrecht", parent: "NL" },
  { code: "NL_ZE", name: "Zeeland", parent: "NL" },
  { code: "NL_ZH", name: "Zuid-Holland", parent: "NL" },
  { code: "NO_03", name: "Oslo", parent: "NO" },
  { code: "NO_11", name: "Rogaland", parent: "NO" },
  { code: "NO_15", name: "Møre og Romsdal", parent: "NO" },
  { code: "NO_18", name: "Nordland", parent: "NO" },
  { code: "NO_21", name: "Svalbard", parent: "NO" },
  { code: "NO_22", name: "Jan Mayen", parent: "NO" },
  { code: "NO_30", name: "Viken", parent: "NO" },
  { code: "NO_34", name: "Innlandet", parent: "NO" },
  { code: "NO_38", name: "Vestfold og Telemark", parent: "NO" },
  { code: "NO_42", name: "Agder", parent: "NO" },
  { code: "NO_46", name: "Vestland", parent: "NO" },
  { code: "NO_50", name: "Trøndelag / Trööndelage (-)", parent: "NO" },
  { code: "NO_54", name: "Troms og Finnmark / Romsa ja Finnmárku", parent: "NO" },
  { code: "NP_P1", name: "Pradesh 1", parent: "NP" },
  { code: "NP_P2", name: "Madhesh", parent: "NP" },
  { code: "NP_P3", name: "Bāgmatī", parent: "NP" },
  { code: "NP_P4", name: "Gaṇḍakī", parent: "NP" },
  { code: "NP_P5", name: "Lumbinī", parent: "NP" },
  { code: "NP_P6", name: "Karṇālī", parent: "NP" },
  { code: "NP_P7", name: "Sudūrpashchim", parent: "NP" },
  { code: "NR_01", name: "Aiwo", parent: "NR" },
  { code: "NR_02", name: "Anabar", parent: "NR" },
  { code: "NR_03", name: "Anetan", parent: "NR" },
  { code: "NR_04", name: "Anibare", parent: "NR" },
  { code: "NR_05", name: "Baitsi", parent: "NR" },
  { code: "NR_06", name: "Boe", parent: "NR" },
  { code: "NR_07", name: "Buada", parent: "NR" },
  { code: "NR_08", name: "Denigomodu", parent: "NR" },
  { code: "NR_09", name: "Ewa", parent: "NR" },
  { code: "NR_10", name: "Ijuw", parent: "NR" },
  { code: "NR_11", name: "Meneng", parent: "NR" },
  { code: "NR_12", name: "Nibok", parent: "NR" },
  { code: "NR_13", name: "Uaboe", parent: "NR" },
  { code: "NR_14", name: "Yaren", parent: "NR" },
  { code: "NZ_AUK", name: "Auckland", parent: "NZ" },
  { code: "NZ_BOP", name: "Bay of Plenty", parent: "NZ" },
  { code: "NZ_CAN", name: "Canterbury", parent: "NZ" },
  { code: "NZ_CIT", name: "Chatham Islands Territory", parent: "NZ" },
  { code: "NZ_GIS", name: "Gisborne", parent: "NZ" },
  { code: "NZ_HKB", name: "Hawke's Bay", parent: "NZ" },
  { code: "NZ_MBH", name: "Marlborough", parent: "NZ" },
  { code: "NZ_MWT", name: "Manawatū-Whanganui", parent: "NZ" },
  { code: "NZ_NSN", name: "Nelson", parent: "NZ" },
  { code: "NZ_NTL", name: "Northland", parent: "NZ" },
  { code: "NZ_OTA", name: "Otago", parent: "NZ" },
  { code: "NZ_STL", name: "Southland", parent: "NZ" },
  { code: "NZ_TAS", name: "Tasman", parent: "NZ" },
  { code: "NZ_TKI", name: "Taranaki", parent: "NZ" },
  { code: "NZ_WGN", name: "Greater Wellington", parent: "NZ" },
  { code: "NZ_WKO", name: "Waikato", parent: "NZ" },
  { code: "NZ_WTC", name: "West Coast", parent: "NZ" },
  { code: "OM_BJ", name: "Janūb al Bāţinah", parent: "OM" },
  { code: "OM_BS", name: "Shamāl al Bāţinah", parent: "OM" },
  { code: "OM_BU", name: "Al Buraymī", parent: "OM" },
  { code: "OM_DA", name: "Ad Dākhilīyah", parent: "OM" },
  { code: "OM_MA", name: "Masqaţ", parent: "OM" },
  { code: "OM_MU", name: "Musandam", parent: "OM" },
  { code: "OM_SJ", name: "Janūb ash Sharqīyah", parent: "OM" },
  { code: "OM_SS", name: "Shamāl ash Sharqīyah", parent: "OM" },
  { code: "OM_WU", name: "Al Wusţá", parent: "OM" },
  { code: "OM_ZA", name: "Az̧ Z̧āhirah", parent: "OM" },
  { code: "OM_ZU", name: "Z̧ufār", parent: "OM" },
  { code: "PA_1", name: "Bocas del Toro", parent: "PA" },
  { code: "PA_10", name: "Panamá Oeste", parent: "PA" },
  { code: "PA_2", name: "Coclé", parent: "PA" },
  { code: "PA_3", name: "Colón", parent: "PA" },
  { code: "PA_4", name: "Chiriquí", parent: "PA" },
  { code: "PA_5", name: "Darién", parent: "PA" },
  { code: "PA_6", name: "Herrera", parent: "PA" },
  { code: "PA_7", name: "Los Santos", parent: "PA" },
  { code: "PA_8", name: "Panamá", parent: "PA" },
  { code: "PA_9", name: "Veraguas", parent: "PA" },
  { code: "PA_EM", name: "Emberá", parent: "PA" },
  { code: "PA_KY", name: "Guna Yala", parent: "PA" },
  { code: "PA_NB", name: "Ngäbe-Buglé", parent: "PA" },
  { code: "PA_NT", name: "Naso Tjër Di", parent: "PA" },
  { code: "PE_AMA", name: "Amazonas", parent: "PE" },
  { code: "PE_ANC", name: "Ancash", parent: "PE" },
  { code: "PE_APU", name: "Apurímac", parent: "PE" },
  { code: "PE_ARE", name: "Arequipa", parent: "PE" },
  { code: "PE_AYA", name: "Ayacucho", parent: "PE" },
  { code: "PE_CAJ", name: "Cajamarca", parent: "PE" },
  { code: "PE_CAL", name: "El Callao", parent: "PE" },
  { code: "PE_CUS", name: "Cusco", parent: "PE" },
  { code: "PE_HUC", name: "Huánuco", parent: "PE" },
  { code: "PE_HUV", name: "Huancavelica", parent: "PE" },
  { code: "PE_ICA", name: "Ica", parent: "PE" },
  { code: "PE_JUN", name: "Junín", parent: "PE" },
  { code: "PE_LAL", name: "La Libertad", parent: "PE" },
  { code: "PE_LAM", name: "Lambayeque", parent: "PE" },
  { code: "PE_LIM", name: "Lima", parent: "PE" },
  { code: "PE_LMA", name: "Municipalidad Metropolitana de Lima", parent: "PE" },
  { code: "PE_LOR", name: "Loreto", parent: "PE" },
  { code: "PE_MDD", name: "Madre de Dios", parent: "PE" },
  { code: "PE_MOQ", name: "Moquegua", parent: "PE" },
  { code: "PE_PAS", name: "Pasco", parent: "PE" },
  { code: "PE_PIU", name: "Piura", parent: "PE" },
  { code: "PE_PUN", name: "Puno", parent: "PE" },
  { code: "PE_SAM", name: "San Martín", parent: "PE" },
  { code: "PE_TAC", name: "Tacna", parent: "PE" },
  { code: "PE_TUM", name: "Tumbes", parent: "PE" },
  { code: "PE_UCA", name: "Ucayali", parent: "PE" },
  { code: "PG_CPK", name: "Chimbu", parent: "PG" },
  { code: "PG_CPM", name: "Central", parent: "PG" },
  { code: "PG_EBR", name: "East New Britain", parent: "PG" },
  { code: "PG_EHG", name: "Eastern Highlands", parent: "PG" },
  { code: "PG_EPW", name: "Enga", parent: "PG" },
  { code: "PG_ESW", name: "East Sepik", parent: "PG" },
  { code: "PG_GPK", name: "Gulf", parent: "PG" },
  { code: "PG_HLA", name: "Hela", parent: "PG" },
  { code: "PG_JWK", name: "Jiwaka", parent: "PG" },
  { code: "PG_MBA", name: "Milne Bay", parent: "PG" },
  { code: "PG_MPL", name: "Morobe", parent: "PG" },
  { code: "PG_MPM", name: "Madang", parent: "PG" },
  { code: "PG_MRL", name: "Manus", parent: "PG" },
  { code: "PG_NCD", name: "National Capital District (Port Moresby)", parent: "PG" },
  { code: "PG_NIK", name: "New Ireland", parent: "PG" },
  { code: "PG_NPP", name: "Northern", parent: "PG" },
  { code: "PG_NSB", name: "Bougainville", parent: "PG" },
  { code: "PG_SAN", name: "West Sepik", parent: "PG" },
  { code: "PG_SHM", name: "Southern Highlands", parent: "PG" },
  { code: "PG_WBK", name: "West New Britain", parent: "PG" },
  { code: "PG_WHM", name: "Western Highlands", parent: "PG" },
  { code: "PG_WPD", name: "Western", parent: "PG" },
  { code: "PH_00", name: "National Capital Region", parent: "PH" },
  { code: "PH_01", name: "Ilocos", parent: "PH" },
  { code: "PH_02", name: "Cagayan Valley", parent: "PH" },
  { code: "PH_03", name: "Central Luzon", parent: "PH" },
  { code: "PH_05", name: "Bicol", parent: "PH" },
  { code: "PH_06", name: "Western Visayas", parent: "PH" },
  { code: "PH_07", name: "Central Visayas", parent: "PH" },
  { code: "PH_08", name: "Eastern Visayas", parent: "PH" },
  { code: "PH_09", name: "Zamboanga Peninsula", parent: "PH" },
  { code: "PH_10", name: "Northern Mindanao", parent: "PH" },
  { code: "PH_11", name: "Davao", parent: "PH" },
  { code: "PH_12", name: "Soccsksargen", parent: "PH" },
  { code: "PH_13", name: "Caraga", parent: "PH" },
  { code: "PH_14", name: "Autonomous Region in Muslim Mindanao", parent: "PH" },
  { code: "PH_15", name: "Cordillera Administrative Region", parent: "PH" },
  { code: "PH_40", name: "Calabarzon", parent: "PH" },
  { code: "PH_41", name: "Mimaropa", parent: "PH" },
  { code: "PH_ABR", name: "Abra", parent: "PH_15" },
  { code: "PH_AGN", name: "Agusan del Norte", parent: "PH_13" },
  { code: "PH_AGS", name: "Agusan del Sur", parent: "PH_13" },
  { code: "PH_AKL", name: "Aklan", parent: "PH_06" },
  { code: "PH_ALB", name: "Albay", parent: "PH_05" },
  { code: "PH_ANT", name: "Antique", parent: "PH_06" },
  { code: "PH_APA", name: "Apayao", parent: "PH_15" },
  { code: "PH_AUR", name: "Aurora", parent: "PH_03" },
  { code: "PH_BAN", name: "Bataan", parent: "PH_03" },
  { code: "PH_BAS", name: "Basilan", parent: "PH_09" },
  { code: "PH_BEN", name: "Benguet", parent: "PH_15" },
  { code: "PH_BIL", name: "Biliran", parent: "PH_08" },
  { code: "PH_BOH", name: "Bohol", parent: "PH_07" },
  { code: "PH_BTG", name: "Batangas", parent: "PH_40" },
  { code: "PH_BTN", name: "Batanes", parent: "PH_02" },
  { code: "PH_BUK", name: "Bukidnon", parent: "PH_10" },
  { code: "PH_BUL", name: "Bulacan", parent: "PH_03" },
  { code: "PH_CAG", name: "Cagayan", parent: "PH_02" },
  { code: "PH_CAM", name: "Camiguin", parent: "PH_10" },
  { code: "PH_CAN", name: "Camarines Norte", parent: "PH_05" },
  { code: "PH_CAP", name: "Capiz", parent: "PH_06" },
  { code: "PH_CAS", name: "Camarines Sur", parent: "PH_05" },
  { code: "PH_CAT", name: "Catanduanes", parent: "PH_05" },
  { code: "PH_CAV", name: "Cavite", parent: "PH_40" },
  { code: "PH_CEB", name: "Cebu", parent: "PH_07" },
  { code: "PH_COM", name: "Davao de Oro", parent: "PH_11" },
  { code: "PH_DAO", name: "Davao Oriental", parent: "PH_11" },
  { code: "PH_DAS", name: "Davao del Sur", parent: "PH_11" },
  { code: "PH_DAV", name: "Davao del Norte", parent: "PH_11" },
  { code: "PH_DIN", name: "Dinagat Islands", parent: "PH_13" },
  { code: "PH_DVO", name: "Davao Occidental", parent: "PH_11" },
  { code: "PH_EAS", name: "Eastern Samar", parent: "PH_08" },
  { code: "PH_GUI", name: "Guimaras", parent: "PH_06" },
  { code: "PH_IFU", name: "Ifugao", parent: "PH_15" },
  { code: "PH_ILI", name: "Iloilo", parent: "PH_06" },
  { code: "PH_ILN", name: "Ilocos Norte", parent: "PH_01" },
  { code: "PH_ILS", name: "Ilocos Sur", parent: "PH_01" },
  { code: "PH_ISA", name: "Isabela", parent: "PH_02" },
  { code: "PH_KAL", name: "Kalinga", parent: "PH_15" },
  { code: "PH_LAG", name: "Laguna", parent: "PH_40" },
  { code: "PH_LAN", name: "Lanao del Norte", parent: "PH_12" },
  { code: "PH_LAS", name: "Lanao del Sur", parent: "PH_14" },
  { code: "PH_LEY", name: "Leyte", parent: "PH_08" },
  { code: "PH_LUN", name: "La Union", parent: "PH_01" },
  { code: "PH_MAD", name: "Marinduque", parent: "PH_41" },
  { code: "PH_MAG", name: "Maguindanao", parent: "PH_14" },
  { code: "PH_MAS", name: "Masbate", parent: "PH_05" },
  { code: "PH_MDC", name: "Mindoro Occidental", parent: "PH_41" },
  { code: "PH_MDR", name: "Mindoro Oriental", parent: "PH_41" },
  { code: "PH_MOU", name: "Mountain Province", parent: "PH_15" },
  { code: "PH_MSC", name: "Misamis Occidental", parent: "PH_10" },
  { code: "PH_MSR", name: "Misamis Oriental", parent: "PH_10" },
  { code: "PH_NCO", name: "Cotabato", parent: "PH_12" },
  { code: "PH_NEC", name: "Negros Occidental", parent: "PH_06" },
  { code: "PH_NER", name: "Negros Oriental", parent: "PH_07" },
  { code: "PH_NSA", name: "Northern Samar", parent: "PH_08" },
  { code: "PH_NUE", name: "Nueva Ecija", parent: "PH_03" },
  { code: "PH_NUV", name: "Nueva Vizcaya", parent: "PH_02" },
  { code: "PH_PAM", name: "Pampanga", parent: "PH_03" },
  { code: "PH_PAN", name: "Pangasinan", parent: "PH_01" },
  { code: "PH_PLW", name: "Palawan", parent: "PH_41" },
  { code: "PH_QUE", name: "Quezon", parent: "PH_40" },
  { code: "PH_QUI", name: "Quirino", parent: "PH_02" },
  { code: "PH_RIZ", name: "Rizal", parent: "PH_40" },
  { code: "PH_ROM", name: "Romblon", parent: "PH_41" },
  { code: "PH_SAR", name: "Sarangani", parent: "PH_11" },
  { code: "PH_SCO", name: "South Cotabato", parent: "PH_11" },
  { code: "PH_SIG", name: "Siquijor", parent: "PH_07" },
  { code: "PH_SLE", name: "Southern Leyte", parent: "PH_08" },
  { code: "PH_SLU", name: "Sulu", parent: "PH_14" },
  { code: "PH_SOR", name: "Sorsogon", parent: "PH_05" },
  { code: "PH_SUK", name: "Sultan Kudarat", parent: "PH_12" },
  { code: "PH_SUN", name: "Surigao del Norte", parent: "PH_13" },
  { code: "PH_SUR", name: "Surigao del Sur", parent: "PH_13" },
  { code: "PH_TAR", name: "Tarlac", parent: "PH_03" },
  { code: "PH_TAW", name: "Tawi-Tawi", parent: "PH_14" },
  { code: "PH_WSA", name: "Samar", parent: "PH_08" },
  { code: "PH_ZAN", name: "Zamboanga del Norte", parent: "PH_09" },
  { code: "PH_ZAS", name: "Zamboanga del Sur", parent: "PH_09" },
  { code: "PH_ZMB", name: "Zambales", parent: "PH_03" },
  { code: "PH_ZSI", name: "Zamboanga Sibugay", parent: "PH_09" },
  { code: "PK_BA", name: "Balochistan", parent: "PK" },
  { code: "PK_GB", name: "Gilgit-Baltistan", parent: "PK" },
  { code: "PK_IS", name: "Islamabad", parent: "PK" },
  { code: "PK_JK", name: "Azad Jammu and Kashmir", parent: "PK" },
  { code: "PK_KP", name: "Khyber Pakhtunkhwa", parent: "PK" },
  { code: "PK_PB", name: "Punjab", parent: "PK" },
  { code: "PK_SD", name: "Sindh", parent: "PK" },
  { code: "PK_TA", name: "Federally Administered Tribal Areas", parent: "PK" },
  { code: "PL_02", name: "Dolnośląskie", parent: "PL" },
  { code: "PL_04", name: "Kujawsko-pomorskie", parent: "PL" },
  { code: "PL_06", name: "Lubelskie", parent: "PL" },
  { code: "PL_08", name: "Lubuskie", parent: "PL" },
  { code: "PL_10", name: "Łódzkie", parent: "PL" },
  { code: "PL_12", name: "Małopolskie", parent: "PL" },
  { code: "PL_14", name: "Mazowieckie", parent: "PL" },
  { code: "PL_16", name: "Opolskie", parent: "PL" },
  { code: "PL_18", name: "Podkarpackie", parent: "PL" },
  { code: "PL_20", name: "Podlaskie", parent: "PL" },
  { code: "PL_22", name: "Pomorskie", parent: "PL" },
  { code: "PL_24", name: "Śląskie", parent: "PL" },
  { code: "PL_26", name: "Świętokrzyskie", parent: "PL" },
  { code: "PL_28", name: "Warmińsko-mazurskie", parent: "PL" },
  { code: "PL_30", name: "Wielkopolskie", parent: "PL" },
  { code: "PL_32", name: "Zachodniopomorskie", parent: "PL" },
  { code: "PS_BTH", name: "Bethlehem", parent: "PS" },
  { code: "PS_DEB", name: "Deir El Balah", parent: "PS" },
  { code: "PS_GZA", name: "Gaza", parent: "PS" },
  { code: "PS_HBN", name: "Hebron", parent: "PS" },
  { code: "PS_JEM", name: "Jerusalem", parent: "PS" },
  { code: "PS_JEN", name: "Jenin", parent: "PS" },
  { code: "PS_JRH", name: "Jericho and Al Aghwar", parent: "PS" },
  { code: "PS_KYS", name: "Khan Yunis", parent: "PS" },
  { code: "PS_NBS", name: "Nablus", parent: "PS" },
  { code: "PS_NGZ", name: "North Gaza", parent: "PS" },
  { code: "PS_QQA", name: "Qalqilya", parent: "PS" },
  { code: "PS_RBH", name: "Ramallah", parent: "PS" },
  { code: "PS_RFH", name: "Rafah", parent: "PS" },
  { code: "PS_SLT", name: "Salfit", parent: "PS" },
  { code: "PS_TBS", name: "Tubas", parent: "PS" },
  { code: "PS_TKM", name: "Tulkarm", parent: "PS" },
  { code: "PT_01", name: "Aveiro", parent: "PT" },
  { code: "PT_02", name: "Beja", parent: "PT" },
  { code: "PT_03", name: "Braga", parent: "PT" },
  { code: "PT_04", name: "Bragança", parent: "PT" },
  { code: "PT_05", name: "Castelo Branco", parent: "PT" },
  { code: "PT_06", name: "Coimbra", parent: "PT" },
  { code: "PT_07", name: "Évora", parent: "PT" },
  { code: "PT_08", name: "Faro", parent: "PT" },
  { code: "PT_09", name: "Guarda", parent: "PT" },
  { code: "PT_10", name: "Leiria", parent: "PT" },
  { code: "PT_11", name: "Lisboa", parent: "PT" },
  { code: "PT_12", name: "Portalegre", parent: "PT" },
  { code: "PT_13", name: "Porto", parent: "PT" },
  { code: "PT_14", name: "Santarém", parent: "PT" },
  { code: "PT_15", name: "Setúbal", parent: "PT" },
  { code: "PT_16", name: "Viana do Castelo", parent: "PT" },
  { code: "PT_17", name: "Vila Real", parent: "PT" },
  { code: "PT_18", name: "Viseu", parent: "PT" },
  { code: "PT_20", name: "Região Autónoma dos Açores", parent: "PT" },
  { code: "PT_30", name: "Região Autónoma da Madeira", parent: "PT" },
  { code: "PW_002", name: "Aimeliik", parent: "PW" },
  { code: "PW_004", name: "Airai", parent: "PW" },
  { code: "PW_010", name: "Angaur", parent: "PW" },
  { code: "PW_050", name: "Hatohobei", parent: "PW" },
  { code: "PW_100", name: "Kayangel", parent: "PW" },
  { code: "PW_150", name: "Koror", parent: "PW" },
  { code: "PW_212", name: "Melekeok", parent: "PW" },
  { code: "PW_214", name: "Ngaraard", parent: "PW" },
  { code: "PW_218", name: "Ngarchelong", parent: "PW" },
  { code: "PW_222", name: "Ngardmau", parent: "PW" },
  { code: "PW_224", name: "Ngatpang", parent: "PW" },
  { code: "PW_226", name: "Ngchesar", parent: "PW" },
  { code: "PW_227", name: "Ngeremlengui", parent: "PW" },
  { code: "PW_228", name: "Ngiwal", parent: "PW" },
  { code: "PW_350", name: "Peleliu", parent: "PW" },
  { code: "PW_370", name: "Sonsorol", parent: "PW" },
  { code: "PY_1", name: "Concepción", parent: "PY" },
  { code: "PY_10", name: "Alto Paraná", parent: "PY" },
  { code: "PY_11", name: "Central", parent: "PY" },
  { code: "PY_12", name: "Ñeembucú", parent: "PY" },
  { code: "PY_13", name: "Amambay", parent: "PY" },
  { code: "PY_14", name: "Canindeyú", parent: "PY" },
  { code: "PY_15", name: "Presidente Hayes", parent: "PY" },
  { code: "PY_16", name: "Alto Paraguay", parent: "PY" },
  { code: "PY_19", name: "Boquerón", parent: "PY" },
  { code: "PY_2", name: "San Pedro", parent: "PY" },
  { code: "PY_3", name: "Cordillera", parent: "PY" },
  { code: "PY_4", name: "Guairá", parent: "PY" },
  { code: "PY_5", name: "Caaguazú", parent: "PY" },
  { code: "PY_6", name: "Caazapá", parent: "PY" },
  { code: "PY_7", name: "Itapúa", parent: "PY" },
  { code: "PY_8", name: "Misiones", parent: "PY" },
  { code: "PY_9", name: "Paraguarí", parent: "PY" },
  { code: "PY_ASU", name: "Asunción", parent: "PY" },
  { code: "QA_DA", name: "Ad Dawḩah", parent: "QA" },
  { code: "QA_KH", name: "Al Khawr wa adh Dhakhīrah", parent: "QA" },
  { code: "QA_MS", name: "Ash Shamāl", parent: "QA" },
  { code: "QA_RA", name: "Ar Rayyān", parent: "QA" },
  { code: "QA_SH", name: "Ash Shīḩānīyah", parent: "QA" },
  { code: "QA_US", name: "Umm Şalāl", parent: "QA" },
  { code: "QA_WA", name: "Al Wakrah", parent: "QA" },
  { code: "QA_ZA", name: "Az̧ Z̧a‘āyin", parent: "QA" },
  { code: "RO_AB", name: "Alba", parent: "RO" },
  { code: "RO_AG", name: "Argeș", parent: "RO" },
  { code: "RO_AR", name: "Arad", parent: "RO" },
  { code: "RO_B", name: "București", parent: "RO" },
  { code: "RO_BC", name: "Bacău", parent: "RO" },
  { code: "RO_BH", name: "Bihor", parent: "RO" },
  { code: "RO_BN", name: "Bistrița-Năsăud", parent: "RO" },
  { code: "RO_BR", name: "Brăila", parent: "RO" },
  { code: "RO_BT", name: "Botoșani", parent: "RO" },
  { code: "RO_BV", name: "Brașov", parent: "RO" },
  { code: "RO_BZ", name: "Buzău", parent: "RO" },
  { code: "RO_CJ", name: "Cluj", parent: "RO" },
  { code: "RO_CL", name: "Călărași", parent: "RO" },
  { code: "RO_CS", name: "Caraș-Severin", parent: "RO" },
  { code: "RO_CT", name: "Constanța", parent: "RO" },
  { code: "RO_CV", name: "Covasna", parent: "RO" },
  { code: "RO_DB", name: "Dâmbovița", parent: "RO" },
  { code: "RO_DJ", name: "Dolj", parent: "RO" },
  { code: "RO_GJ", name: "Gorj", parent: "RO" },
  { code: "RO_GL", name: "Galați", parent: "RO" },
  { code: "RO_GR", name: "Giurgiu", parent: "RO" },
  { code: "RO_HD", name: "Hunedoara", parent: "RO" },
  { code: "RO_HR", name: "Harghita", parent: "RO" },
  { code: "RO_IF", name: "Ilfov", parent: "RO" },
  { code: "RO_IL", name: "Ialomița", parent: "RO" },
  { code: "RO_IS", name: "Iași", parent: "RO" },
  { code: "RO_MH", name: "Mehedinți", parent: "RO" },
  { code: "RO_MM", name: "Maramureș", parent: "RO" },
  { code: "RO_MS", name: "Mureș", parent: "RO" },
  { code: "RO_NT", name: "Neamț", parent: "RO" },
  { code: "RO_OT", name: "Olt", parent: "RO" },
  { code: "RO_PH", name: "Prahova", parent: "RO" },
  { code: "RO_SB", name: "Sibiu", parent: "RO" },
  { code: "RO_SJ", name: "Sălaj", parent: "RO" },
  { code: "RO_SM", name: "Satu Mare", parent: "RO" },
  { code: "RO_SV", name: "Suceava", parent: "RO" },
  { code: "RO_TL", name: "Tulcea", parent: "RO" },
  { code: "RO_TM", name: "Timiș", parent: "RO" },
  { code: "RO_TR", name: "Teleorman", parent: "RO" },
  { code: "RO_VL", name: "Vâlcea", parent: "RO" },
  { code: "RO_VN", name: "Vrancea", parent: "RO" },
  { code: "RO_VS", name: "Vaslui", parent: "RO" },
  { code: "RS_00", name: "Beograd", parent: "RS" },
  { code: "RS_01", name: "Severnobački okrug", parent: "RS_VO" },
  { code: "RS_02", name: "Srednjebanatski okrug", parent: "RS_VO" },
  { code: "RS_03", name: "Severnobanatski okrug", parent: "RS_VO" },
  { code: "RS_04", name: "Južnobanatski okrug", parent: "RS_VO" },
  { code: "RS_05", name: "Zapadnobački okrug", parent: "RS_VO" },
  { code: "RS_06", name: "Južnobački okrug", parent: "RS_VO" },
  { code: "RS_07", name: "Sremski okrug", parent: "RS_VO" },
  { code: "RS_08", name: "Mačvanski okrug", parent: "RS" },
  { code: "RS_09", name: "Kolubarski okrug", parent: "RS" },
  { code: "RS_10", name: "Podunavski okrug", parent: "RS" },
  { code: "RS_11", name: "Braničevski okrug", parent: "RS" },
  { code: "RS_12", name: "Šumadijski okrug", parent: "RS" },
  { code: "RS_13", name: "Pomoravski okrug", parent: "RS" },
  { code: "RS_14", name: "Borski okrug", parent: "RS" },
  { code: "RS_15", name: "Zaječarski okrug", parent: "RS" },
  { code: "RS_16", name: "Zlatiborski okrug", parent: "RS" },
  { code: "RS_17", name: "Moravički okrug", parent: "RS" },
  { code: "RS_18", name: "Raški okrug", parent: "RS" },
  { code: "RS_19", name: "Rasinski okrug", parent: "RS" },
  { code: "RS_20", name: "Nišavski okrug", parent: "RS" },
  { code: "RS_21", name: "Toplički okrug", parent: "RS" },
  { code: "RS_22", name: "Pirotski okrug", parent: "RS" },
  { code: "RS_23", name: "Jablanički okrug", parent: "RS" },
  { code: "RS_24", name: "Pčinjski okrug", parent: "RS" },
  { code: "RS_25", name: "Kosovski okrug", parent: "RS_KM" },
  { code: "RS_26", name: "Pećki okrug", parent: "RS_KM" },
  { code: "RS_27", name: "Prizrenski okrug", parent: "RS_KM" },
  { code: "RS_28", name: "Kosovsko-Mitrovački okrug", parent: "RS_KM" },
  { code: "RS_29", name: "Kosovsko-Pomoravski okrug", parent: "RS_KM" },
  { code: "RS_KM", name: "Kosovo-Metohija", parent: "RS" },
  { code: "RS_VO", name: "Vojvodina", parent: "RS" },
  { code: "RU_AD", name: "Adygeya, Respublika", parent: "RU" },
  { code: "RU_AL", name: "Altay, Respublika", parent: "RU" },
  { code: "RU_ALT", name: "Altayskiy kray", parent: "RU" },
  { code: "RU_AMU", name: "Amurskaya oblast'", parent: "RU" },
  { code: "RU_ARK", name: "Arkhangel'skaya oblast'", parent: "RU" },
  { code: "RU_AST", name: "Astrakhanskaya oblast'", parent: "RU" },
  { code: "RU_BA", name: "Bashkortostan, Respublika", parent: "RU" },
  { code: "RU_BEL", name: "Belgorodskaya oblast'", parent: "RU" },
  { code: "RU_BRY", name: "Bryanskaya oblast'", parent: "RU" },
  { code: "RU_BU", name: "Buryatiya, Respublika", parent: "RU" },
  { code: "RU_CE", name: "Chechenskaya Respublika", parent: "RU" },
  { code: "RU_CHE", name: "Chelyabinskaya oblast'", parent: "RU" },
  { code: "RU_CHU", name: "Chukotskiy avtonomnyy okrug", parent: "RU" },
  { code: "RU_CU", name: "Chuvashskaya Respublika", parent: "RU" },
  { code: "RU_DA", name: "Dagestan, Respublika", parent: "RU" },
  { code: "RU_IN", name: "Ingushetiya, Respublika", parent: "RU" },
  { code: "RU_IRK", name: "Irkutskaya oblast'", parent: "RU" },
  { code: "RU_IVA", name: "Ivanovskaya oblast'", parent: "RU" },
  { code: "RU_KAM", name: "Kamchatskiy kray", parent: "RU" },
  { code: "RU_KB", name: "Kabardino-Balkarskaya Respublika", parent: "RU" },
  { code: "RU_KC", name: "Karachayevo-Cherkesskaya Respublika", parent: "RU" },
  { code: "RU_KDA", name: "Krasnodarskiy kray", parent: "RU" },
  { code: "RU_KEM", name: "Kemerovskaya oblast'", parent: "RU" },
  { code: "RU_KGD", name: "Kaliningradskaya oblast'", parent: "RU" },
  { code: "RU_KGN", name: "Kurganskaya oblast'", parent: "RU" },
  { code: "RU_KHA", name: "Khabarovskiy kray", parent: "RU" },
  { code: "RU_KHM", name: "Khanty-Mansiyskiy avtonomnyy okrug", parent: "RU" },
  { code: "RU_KIR", name: "Kirovskaya oblast'", parent: "RU" },
  { code: "RU_KK", name: "Khakasiya, Respublika", parent: "RU" },
  { code: "RU_KL", name: "Kalmykiya, Respublika", parent: "RU" },
  { code: "RU_KLU", name: "Kaluzhskaya oblast'", parent: "RU" },
  { code: "RU_KO", name: "Komi, Respublika", parent: "RU" },
  { code: "RU_KOS", name: "Kostromskaya oblast'", parent: "RU" },
  { code: "RU_KR", name: "Kareliya, Respublika", parent: "RU" },
  { code: "RU_KRS", name: "Kurskaya oblast'", parent: "RU" },
  { code: "RU_KYA", name: "Krasnoyarskiy kray", parent: "RU" },
  { code: "RU_LEN", name: "Leningradskaya oblast'", parent: "RU" },
  { code: "RU_LIP", name: "Lipetskaya oblast'", parent: "RU" },
  { code: "RU_MAG", name: "Magadanskaya oblast'", parent: "RU" },
  { code: "RU_ME", name: "Mariy El, Respublika", parent: "RU" },
  { code: "RU_MO", name: "Mordoviya, Respublika", parent: "RU" },
  { code: "RU_MOS", name: "Moskovskaya oblast'", parent: "RU" },
  { code: "RU_MOW", name: "Moskva", parent: "RU" },
  { code: "RU_MUR", name: "Murmanskaya oblast'", parent: "RU" },
  { code: "RU_NEN", name: "Nenetskiy avtonomnyy okrug", parent: "RU" },
  { code: "RU_NGR", name: "Novgorodskaya oblast'", parent: "RU" },
  { code: "RU_NIZ", name: "Nizhegorodskaya oblast'", parent: "RU" },
  { code: "RU_NVS", name: "Novosibirskaya oblast'", parent: "RU" },
  { code: "RU_OMS", name: "Omskaya oblast'", parent: "RU" },
  { code: "RU_ORE", name: "Orenburgskaya oblast'", parent: "RU" },
  { code: "RU_ORL", name: "Orlovskaya oblast'", parent: "RU" },
  { code: "RU_PER", name: "Permskiy kray", parent: "RU" },
  { code: "RU_PNZ", name: "Penzenskaya oblast'", parent: "RU" },
  { code: "RU_PRI", name: "Primorskiy kray", parent: "RU" },
  { code: "RU_PSK", name: "Pskovskaya oblast'", parent: "RU" },
  { code: "RU_ROS", name: "Rostovskaya oblast'", parent: "RU" },
  { code: "RU_RYA", name: "Ryazanskaya oblast'", parent: "RU" },
  { code: "RU_SA", name: "Saha, Respublika", parent: "RU" },
  { code: "RU_SAK", name: "Sakhalinskaya oblast'", parent: "RU" },
  { code: "RU_SAM", name: "Samarskaya oblast'", parent: "RU" },
  { code: "RU_SAR", name: "Saratovskaya oblast'", parent: "RU" },
  { code: "RU_SE", name: "Severnaya Osetiya, Respublika", parent: "RU" },
  { code: "RU_SMO", name: "Smolenskaya oblast'", parent: "RU" },
  { code: "RU_SPE", name: "Sankt-Peterburg", parent: "RU" },
  { code: "RU_STA", name: "Stavropol'skiy kray", parent: "RU" },
  { code: "RU_SVE", name: "Sverdlovskaya oblast'", parent: "RU" },
  { code: "RU_TA", name: "Tatarstan, Respublika", parent: "RU" },
  { code: "RU_TAM", name: "Tambovskaya oblast'", parent: "RU" },
  { code: "RU_TOM", name: "Tomskaya oblast'", parent: "RU" },
  { code: "RU_TUL", name: "Tul'skaya oblast'", parent: "RU" },
  { code: "RU_TVE", name: "Tverskaya oblast'", parent: "RU" },
  { code: "RU_TY", name: "Tyva, Respublika", parent: "RU" },
  { code: "RU_TYU", name: "Tyumenskaya oblast'", parent: "RU" },
  { code: "RU_UD", name: "Udmurtskaya Respublika", parent: "RU" },
  { code: "RU_ULY", name: "Ul'yanovskaya oblast'", parent: "RU" },
  { code: "RU_VGG", name: "Volgogradskaya oblast'", parent: "RU" },
  { code: "RU_VLA", name: "Vladimirskaya oblast'", parent: "RU" },
  { code: "RU_VLG", name: "Vologodskaya oblast'", parent: "RU" },
  { code: "RU_VOR", name: "Voronezhskaya oblast'", parent: "RU" },
  { code: "RU_YAN", name: "Yamalo-Nenetskiy avtonomnyy okrug", parent: "RU" },
  { code: "RU_YAR", name: "Yaroslavskaya oblast'", parent: "RU" },
  { code: "RU_YEV", name: "Yevreyskaya avtonomnaya oblast'", parent: "RU" },
  { code: "RU_ZAB", name: "Zabaykal'skiy kray", parent: "RU" },
  { code: "RW_01", name: "City of Kigali", parent: "RW" },
  { code: "RW_02", name: "Eastern", parent: "RW" },
  { code: "RW_03", name: "Northern", parent: "RW" },
  { code: "RW_04", name: "Western", parent: "RW" },
  { code: "RW_05", name: "Southern", parent: "RW" },
  { code: "SA_01", name: "Ar Riyāḑ", parent: "SA" },
  { code: "SA_02", name: "Makkah al Mukarramah", parent: "SA" },
  { code: "SA_03", name: "Al Madīnah al Munawwarah", parent: "SA" },
  { code: "SA_04", name: "Ash Sharqīyah", parent: "SA" },
  { code: "SA_05", name: "Al Qaşīm", parent: "SA" },
  { code: "SA_06", name: "Ḩā'il", parent: "SA" },
  { code: "SA_07", name: "Tabūk", parent: "SA" },
  { code: "SA_08", name: "Al Ḩudūd ash Shamālīyah", parent: "SA" },
  { code: "SA_09", name: "Jāzān", parent: "SA" },
  { code: "SA_10", name: "Najrān", parent: "SA" },
  { code: "SA_11", name: "Al Bāḩah", parent: "SA" },
  { code: "SA_12", name: "Al Jawf", parent: "SA" },
  { code: "SA_14", name: "'Asīr", parent: "SA" },
  { code: "SB_CE", name: "Central", parent: "SB" },
  { code: "SB_CH", name: "Choiseul", parent: "SB" },
  { code: "SB_CT", name: "Capital Territory (Honiara)", parent: "SB" },
  { code: "SB_GU", name: "Guadalcanal", parent: "SB" },
  { code: "SB_IS", name: "Isabel", parent: "SB" },
  { code: "SB_MK", name: "Makira-Ulawa", parent: "SB" },
  { code: "SB_ML", name: "Malaita", parent: "SB" },
  { code: "SB_RB", name: "Rennell and Bellona", parent: "SB" },
  { code: "SB_TE", name: "Temotu", parent: "SB" },
  { code: "SB_WE", name: "Western", parent: "SB" },
  { code: "SC_01", name: "Anse aux Pins", parent: "SC" },
  { code: "SC_02", name: "Anse Boileau", parent: "SC" },
  { code: "SC_03", name: "Anse Etoile", parent: "SC" },
  { code: "SC_04", name: "Au Cap", parent: "SC" },
  { code: "SC_05", name: "Anse Royale", parent: "SC" },
  { code: "SC_06", name: "Baie Lazare", parent: "SC" },
  { code: "SC_07", name: "Baie Sainte Anne", parent: "SC" },
  { code: "SC_08", name: "Beau Vallon", parent: "SC" },
  { code: "SC_09", name: "Bel Air", parent: "SC" },
  { code: "SC_10", name: "Bel Ombre", parent: "SC" },
  { code: "SC_11", name: "Cascade", parent: "SC" },
  { code: "SC_12", name: "Glacis", parent: "SC" },
  { code: "SC_13", name: "Grand Anse Mahe", parent: "SC" },
  { code: "SC_14", name: "Grand Anse Praslin", parent: "SC" },
  { code: "SC_15", name: "La Digue", parent: "SC" },
  { code: "SC_16", name: "English River", parent: "SC" },
  { code: "SC_17", name: "Mont Buxton", parent: "SC" },
  { code: "SC_18", name: "Mont Fleuri", parent: "SC" },
  { code: "SC_19", name: "Plaisance", parent: "SC" },
  { code: "SC_20", name: "Pointe Larue", parent: "SC" },
  { code: "SC_21", name: "Port Glaud", parent: "SC" },
  { code: "SC_22", name: "Saint Louis", parent: "SC" },
  { code: "SC_23", name: "Takamaka", parent: "SC" },
  { code: "SC_24", name: "Les Mamelles", parent: "SC" },
  { code: "SC_25", name: "Roche Caiman", parent: "SC" },
  { code: "SC_26", name: "Ile Perseverance I", parent: "SC" },
  { code: "SC_27", name: "Ile Perseverance II", parent: "SC" },
  { code: "SD_DC", name: "Wasaţ Dārfūr", parent: "SD" },
  { code: "SD_DE", name: "Sharq Dārfūr", parent: "SD" },
  { code: "SD_DN", name: "Shamāl Dārfūr", parent: "SD" },
  { code: "SD_DS", name: "Janūb Dārfūr", parent: "SD" },
  { code: "SD_DW", name: "Gharb Dārfūr", parent: "SD" },
  { code: "SD_GD", name: "Al Qaḑārif", parent: "SD" },
  { code: "SD_GK", name: "Gharb Kurdufān", parent: "SD" },
  { code: "SD_GZ", name: "Al Jazīrah", parent: "SD" },
  { code: "SD_KA", name: "Kassalā", parent: "SD" },
  { code: "SD_KH", name: "Al Kharţūm", parent: "SD" },
  { code: "SD_KN", name: "Shamāl Kurdufān", parent: "SD" },
  { code: "SD_KS", name: "Janūb Kurdufān", parent: "SD" },
  { code: "SD_NB", name: "An Nīl al Azraq", parent: "SD" },
  { code: "SD_NO", name: "Ash Shamālīyah", parent: "SD" },
  { code: "SD_NR", name: "Nahr an Nīl", parent: "SD" },
  { code: "SD_NW", name: "An Nīl al Abyaḑ", parent: "SD" },
  { code: "SD_RS", name: "Al Baḩr al Aḩmar", parent: "SD" },
  { code: "SD_SI", name: "Sinnār", parent: "SD" },
  { code: "SE_AB", name: "Stockholms län", parent: "SE" },
  { code: "SE_AC", name: "Västerbottens län", parent: "SE" },
  { code: "SE_BD", name: "Norrbottens län", parent: "SE" },
  { code: "SE_C", name: "Uppsala län", parent: "SE" },
  { code: "SE_D", name: "Södermanlands län", parent: "SE" },
  { code: "SE_E", name: "Östergötlands län", parent: "SE" },
  { code: "SE_F", name: "Jönköpings län", parent: "SE" },
  { code: "SE_G", name: "Kronobergs län", parent: "SE" },
  { code: "SE_H", name: "Kalmar län", parent: "SE" },
  { code: "SE_I", name: "Gotlands län", parent: "SE" },
  { code: "SE_K", name: "Blekinge län", parent: "SE" },
  { code: "SE_M", name: "Skåne län", parent: "SE" },
  { code: "SE_N", name: "Hallands län", parent: "SE" },
  { code: "SE_O", name: "Västra Götalands län", parent: "SE" },
  { code: "SE_S", name: "Värmlands län", parent: "SE" },
  { code: "SE_T", name: "Örebro län", parent: "SE" },
  { code: "SE_U", name: "Västmanlands län", parent: "SE" },
  { code: "SE_W", name: "Dalarnas län", parent: "SE" },
  { code: "SE_X", name: "Gävleborgs län", parent: "SE" },
  { code: "SE_Y", name: "Västernorrlands län", parent: "SE" },
  { code: "SE_Z", name: "Jämtlands län", parent: "SE" },
  { code: "SG_01", name: "Central Singapore", parent: "SG" },
  { code: "SG_02", name: "North East", parent: "SG" },
  { code: "SG_03", name: "North West", parent: "SG" },
  { code: "SG_04", name: "South East", parent: "SG" },
  { code: "SG_05", name: "South West", parent: "SG" },
  { code: "SH_AC", name: "Ascension", parent: "SH" },
  { code: "SH_HL", name: "Saint Helena", parent: "SH" },
  { code: "SH_TA", name: "Tristan da Cunha", parent: "SH" },
  { code: "SI_001", name: "Ajdovščina", parent: "SI" },
  { code: "SI_002", name: "Beltinci", parent: "SI" },
  { code: "SI_003", name: "Bled", parent: "SI" },
  { code: "SI_004", name: "Bohinj", parent: "SI" },
  { code: "SI_005", name: "Borovnica", parent: "SI" },
  { code: "SI_006", name: "Bovec", parent: "SI" },
  { code: "SI_007", name: "Brda", parent: "SI" },
  { code: "SI_008", name: "Brezovica", parent: "SI" },
  { code: "SI_009", name: "Brežice", parent: "SI" },
  { code: "SI_010", name: "Tišina", parent: "SI" },
  { code: "SI_011", name: "Celje", parent: "SI" },
  { code: "SI_012", name: "Cerklje na Gorenjskem", parent: "SI" },
  { code: "SI_013", name: "Cerknica", parent: "SI" },
  { code: "SI_014", name: "Cerkno", parent: "SI" },
  { code: "SI_015", name: "Črenšovci", parent: "SI" },
  { code: "SI_016", name: "Črna na Koroškem", parent: "SI" },
  { code: "SI_017", name: "Črnomelj", parent: "SI" },
  { code: "SI_018", name: "Destrnik", parent: "SI" },
  { code: "SI_019", name: "Divača", parent: "SI" },
  { code: "SI_020", name: "Dobrepolje", parent: "SI" },
  { code: "SI_021", name: "Dobrova-Polhov Gradec", parent: "SI" },
  { code: "SI_022", name: "Dol pri Ljubljani", parent: "SI" },
  { code: "SI_023", name: "Domžale", parent: "SI" },
  { code: "SI_024", name: "Dornava", parent: "SI" },
  { code: "SI_025", name: "Dravograd", parent: "SI" },
  { code: "SI_026", name: "Duplek", parent: "SI" },
  { code: "SI_027", name: "Gorenja vas-Poljane", parent: "SI" },
  { code: "SI_028", name: "Gorišnica", parent: "SI" },
  { code: "SI_029", name: "Gornja Radgona", parent: "SI" },
  { code: "SI_030", name: "Gornji Grad", parent: "SI" },
  { code: "SI_031", name: "Gornji Petrovci", parent: "SI" },
  { code: "SI_032", name: "Grosuplje", parent: "SI" },
  { code: "SI_033", name: "Šalovci", parent: "SI" },
  { code: "SI_034", name: "Hrastnik", parent: "SI" },
  { code: "SI_035", name: "Hrpelje-Kozina", parent: "SI" },
  { code: "SI_036", name: "Idrija", parent: "SI" },
  { code: "SI_037", name: "Ig", parent: "SI" },
  { code: "SI_038", name: "Ilirska Bistrica", parent: "SI" },
  { code: "SI_039", name: "Ivančna Gorica", parent: "SI" },
  { code: "SI_040", name: "Izola", parent: "SI" },
  { code: "SI_041", name: "Jesenice", parent: "SI" },
  { code: "SI_042", name: "Juršinci", parent: "SI" },
  { code: "SI_043", name: "Kamnik", parent: "SI" },
  { code: "SI_044", name: "Kanal ob Soči", parent: "SI" },
  { code: "SI_045", name: "Kidričevo", parent: "SI" },
  { code: "SI_046", name: "Kobarid", parent: "SI" },
  { code: "SI_047", name: "Kobilje", parent: "SI" },
  { code: "SI_048", name: "Kočevje", parent: "SI" },
  { code: "SI_049", name: "Komen", parent: "SI" },
  { code: "SI_050", name: "Koper", parent: "SI" },
  { code: "SI_051", name: "Kozje", parent: "SI" },
  { code: "SI_052", name: "Kranj", parent: "SI" },
  { code: "SI_053", name: "Kranjska Gora", parent: "SI" },
  { code: "SI_054", name: "Krško", parent: "SI" },
  { code: "SI_055", name: "Kungota", parent: "SI" },
  { code: "SI_056", name: "Kuzma", parent: "SI" },
  { code: "SI_057", name: "Laško", parent: "SI" },
  { code: "SI_058", name: "Lenart", parent: "SI" },
  { code: "SI_059", name: "Lendava", parent: "SI" },
  { code: "SI_060", name: "Litija", parent: "SI" },
  { code: "SI_061", name: "Ljubljana", parent: "SI" },
  { code: "SI_062", name: "Ljubno", parent: "SI" },
  { code: "SI_063", name: "Ljutomer", parent: "SI" },
  { code: "SI_064", name: "Logatec", parent: "SI" },
  { code: "SI_065", name: "Loška dolina", parent: "SI" },
  { code: "SI_066", name: "Loški Potok", parent: "SI" },
  { code: "SI_067", name: "Luče", parent: "SI" },
  { code: "SI_068", name: "Lukovica", parent: "SI" },
  { code: "SI_069", name: "Majšperk", parent: "SI" },
  { code: "SI_070", name: "Maribor", parent: "SI" },
  { code: "SI_071", name: "Medvode", parent: "SI" },
  { code: "SI_072", name: "Mengeš", parent: "SI" },
  { code: "SI_073", name: "Metlika", parent: "SI" },
  { code: "SI_074", name: "Mežica", parent: "SI" },
  { code: "SI_075", name: "Miren-Kostanjevica", parent: "SI" },
  { code: "SI_076", name: "Mislinja", parent: "SI" },
  { code: "SI_077", name: "Moravče", parent: "SI" },
  { code: "SI_078", name: "Moravske Toplice", parent: "SI" },
  { code: "SI_079", name: "Mozirje", parent: "SI" },
  { code: "SI_080", name: "Murska Sobota", parent: "SI" },
  { code: "SI_081", name: "Muta", parent: "SI" },
  { code: "SI_082", name: "Naklo", parent: "SI" },
  { code: "SI_083", name: "Nazarje", parent: "SI" },
  { code: "SI_084", name: "Nova Gorica", parent: "SI" },
  { code: "SI_085", name: "Novo Mesto", parent: "SI" },
  { code: "SI_086", name: "Odranci", parent: "SI" },
  { code: "SI_087", name: "Ormož", parent: "SI" },
  { code: "SI_088", name: "Osilnica", parent: "SI" },
  { code: "SI_089", name: "Pesnica", parent: "SI" },
  { code: "SI_090", name: "Piran", parent: "SI" },
  { code: "SI_091", name: "Pivka", parent: "SI" },
  { code: "SI_092", name: "Podčetrtek", parent: "SI" },
  { code: "SI_093", name: "Podvelka", parent: "SI" },
  { code: "SI_094", name: "Postojna", parent: "SI" },
  { code: "SI_095", name: "Preddvor", parent: "SI" },
  { code: "SI_096", name: "Ptuj", parent: "SI" },
  { code: "SI_097", name: "Puconci", parent: "SI" },
  { code: "SI_098", name: "Rače-Fram", parent: "SI" },
  { code: "SI_099", name: "Radeče", parent: "SI" },
  { code: "SI_100", name: "Radenci", parent: "SI" },
  { code: "SI_101", name: "Radlje ob Dravi", parent: "SI" },
  { code: "SI_102", name: "Radovljica", parent: "SI" },
  { code: "SI_103", name: "Ravne na Koroškem", parent: "SI" },
  { code: "SI_104", name: "Ribnica", parent: "SI" },
  { code: "SI_105", name: "Rogašovci", parent: "SI" },
  { code: "SI_106", name: "Rogaška Slatina", parent: "SI" },
  { code: "SI_107", name: "Rogatec", parent: "SI" },
  { code: "SI_108", name: "Ruše", parent: "SI" },
  { code: "SI_109", name: "Semič", parent: "SI" },
  { code: "SI_110", name: "Sevnica", parent: "SI" },
  { code: "SI_111", name: "Sežana", parent: "SI" },
  { code: "SI_112", name: "Slovenj Gradec", parent: "SI" },
  { code: "SI_113", name: "Slovenska Bistrica", parent: "SI" },
  { code: "SI_114", name: "Slovenske Konjice", parent: "SI" },
  { code: "SI_115", name: "Starše", parent: "SI" },
  { code: "SI_116", name: "Sveti Jurij ob Ščavnici", parent: "SI" },
  { code: "SI_117", name: "Šenčur", parent: "SI" },
  { code: "SI_118", name: "Šentilj", parent: "SI" },
  { code: "SI_119", name: "Šentjernej", parent: "SI" },
  { code: "SI_120", name: "Šentjur", parent: "SI" },
  { code: "SI_121", name: "Škocjan", parent: "SI" },
  { code: "SI_122", name: "Škofja Loka", parent: "SI" },
  { code: "SI_123", name: "Škofljica", parent: "SI" },
  { code: "SI_124", name: "Šmarje pri Jelšah", parent: "SI" },
  { code: "SI_125", name: "Šmartno ob Paki", parent: "SI" },
  { code: "SI_126", name: "Šoštanj", parent: "SI" },
  { code: "SI_127", name: "Štore", parent: "SI" },
  { code: "SI_128", name: "Tolmin", parent: "SI" },
  { code: "SI_129", name: "Trbovlje", parent: "SI" },
  { code: "SI_130", name: "Trebnje", parent: "SI" },
  { code: "SI_131", name: "Tržič", parent: "SI" },
  { code: "SI_132", name: "Turnišče", parent: "SI" },
  { code: "SI_133", name: "Velenje", parent: "SI" },
  { code: "SI_134", name: "Velike Lašče", parent: "SI" },
  { code: "SI_135", name: "Videm", parent: "SI" },
  { code: "SI_136", name: "Vipava", parent: "SI" },
  { code: "SI_137", name: "Vitanje", parent: "SI" },
  { code: "SI_138", name: "Vodice", parent: "SI" },
  { code: "SI_139", name: "Vojnik", parent: "SI" },
  { code: "SI_140", name: "Vrhnika", parent: "SI" },
  { code: "SI_141", name: "Vuzenica", parent: "SI" },
  { code: "SI_142", name: "Zagorje ob Savi", parent: "SI" },
  { code: "SI_143", name: "Zavrč", parent: "SI" },
  { code: "SI_144", name: "Zreče", parent: "SI" },
  { code: "SI_146", name: "Železniki", parent: "SI" },
  { code: "SI_147", name: "Žiri", parent: "SI" },
  { code: "SI_148", name: "Benedikt", parent: "SI" },
  { code: "SI_149", name: "Bistrica ob Sotli", parent: "SI" },
  { code: "SI_150", name: "Bloke", parent: "SI" },
  { code: "SI_151", name: "Braslovče", parent: "SI" },
  { code: "SI_152", name: "Cankova", parent: "SI" },
  { code: "SI_153", name: "Cerkvenjak", parent: "SI" },
  { code: "SI_154", name: "Dobje", parent: "SI" },
  { code: "SI_155", name: "Dobrna", parent: "SI" },
  { code: "SI_156", name: "Dobrovnik", parent: "SI" },
  { code: "SI_157", name: "Dolenjske Toplice", parent: "SI" },
  { code: "SI_158", name: "Grad", parent: "SI" },
  { code: "SI_159", name: "Hajdina", parent: "SI" },
  { code: "SI_160", name: "Hoče-Slivnica", parent: "SI" },
  { code: "SI_161", name: "Hodoš", parent: "SI" },
  { code: "SI_162", name: "Horjul", parent: "SI" },
  { code: "SI_163", name: "Jezersko", parent: "SI" },
  { code: "SI_164", name: "Komenda", parent: "SI" },
  { code: "SI_165", name: "Kostel", parent: "SI" },
  { code: "SI_166", name: "Križevci", parent: "SI" },
  { code: "SI_167", name: "Lovrenc na Pohorju", parent: "SI" },
  { code: "SI_168", name: "Markovci", parent: "SI" },
  { code: "SI_169", name: "Miklavž na Dravskem polju", parent: "SI" },
  { code: "SI_170", name: "Mirna Peč", parent: "SI" },
  { code: "SI_171", name: "Oplotnica", parent: "SI" },
  { code: "SI_172", name: "Podlehnik", parent: "SI" },
  { code: "SI_173", name: "Polzela", parent: "SI" },
  { code: "SI_174", name: "Prebold", parent: "SI" },
  { code: "SI_175", name: "Prevalje", parent: "SI" },
  { code: "SI_176", name: "Razkrižje", parent: "SI" },
  { code: "SI_177", name: "Ribnica na Pohorju", parent: "SI" },
  { code: "SI_178", name: "Selnica ob Dravi", parent: "SI" },
  { code: "SI_179", name: "Sodražica", parent: "SI" },
  { code: "SI_180", name: "Solčava", parent: "SI" },
  { code: "SI_181", name: "Sveta Ana", parent: "SI" },
  { code: "SI_182", name: "Sveti Andraž v Slovenskih goricah", parent: "SI" },
  { code: "SI_183", name: "Šempeter-Vrtojba", parent: "SI" },
  { code: "SI_184", name: "Tabor", parent: "SI" },
  { code: "SI_185", name: "Trnovska Vas", parent: "SI" },
  { code: "SI_186", name: "Trzin", parent: "SI" },
  { code: "SI_187", name: "Velika Polana", parent: "SI" },
  { code: "SI_188", name: "Veržej", parent: "SI" },
  { code: "SI_189", name: "Vransko", parent: "SI" },
  { code: "SI_190", name: "Žalec", parent: "SI" },
  { code: "SI_191", name: "Žetale", parent: "SI" },
  { code: "SI_192", name: "Žirovnica", parent: "SI" },
  { code: "SI_193", name: "Žužemberk", parent: "SI" },
  { code: "SI_194", name: "Šmartno pri Litiji", parent: "SI" },
  { code: "SI_195", name: "Apače", parent: "SI" },
  { code: "SI_196", name: "Cirkulane", parent: "SI" },
  { code: "SI_197", name: "Kostanjevica na Krki", parent: "SI" },
  { code: "SI_198", name: "Makole", parent: "SI" },
  { code: "SI_199", name: "Mokronog-Trebelno", parent: "SI" },
  { code: "SI_200", name: "Poljčane", parent: "SI" },
  { code: "SI_201", name: "Renče-Vogrsko", parent: "SI" },
  { code: "SI_202", name: "Središče ob Dravi", parent: "SI" },
  { code: "SI_203", name: "Straža", parent: "SI" },
  { code: "SI_204", name: "Sveta Trojica v Slovenskih goricah", parent: "SI" },
  { code: "SI_205", name: "Sveti Tomaž", parent: "SI" },
  { code: "SI_206", name: "Šmarješke Toplice", parent: "SI" },
  { code: "SI_207", name: "Gorje", parent: "SI" },
  { code: "SI_208", name: "Log-Dragomer", parent: "SI" },
  { code: "SI_209", name: "Rečica ob Savinji", parent: "SI" },
  { code: "SI_210", name: "Sveti Jurij v Slovenskih goricah", parent: "SI" },
  { code: "SI_211", name: "Šentrupert", parent: "SI" },
  { code: "SI_212", name: "Mirna", parent: "SI" },
  { code: "SI_213", name: "Ankaran", parent: "SI" },
  { code: "SK_BC", name: "Banskobystrický kraj", parent: "SK" },
  { code: "SK_BL", name: "Bratislavský kraj", parent: "SK" },
  { code: "SK_KI", name: "Košický kraj", parent: "SK" },
  { code: "SK_NI", name: "Nitriansky kraj", parent: "SK" },
  { code: "SK_PV", name: "Prešovský kraj", parent: "SK" },
  { code: "SK_TA", name: "Trnavský kraj", parent: "SK" },
  { code: "SK_TC", name: "Trenčiansky kraj", parent: "SK" },
  { code: "SK_ZI", name: "Žilinský kraj", parent: "SK" },
  { code: "SL_E", name: "Eastern", parent: "SL" },
  { code: "SL_N", name: "Northern", parent: "SL" },
  { code: "SL_NW", name: "North Western", parent: "SL" },
  { code: "SL_S", name: "Southern", parent: "SL" },
  { code: "SL_W", name: "Western Area (Freetown)", parent: "SL" },
  { code: "SM_01", name: "Acquaviva", parent: "SM" },
  { code: "SM_02", name: "Chiesanuova", parent: "SM" },
  { code: "SM_03", name: "Domagnano", parent: "SM" },
  { code: "SM_04", name: "Faetano", parent: "SM" },
  { code: "SM_05", name: "Fiorentino", parent: "SM" },
  { code: "SM_06", name: "Borgo Maggiore", parent: "SM" },
  { code: "SM_07", name: "Città di San Marino", parent: "SM" },
  { code: "SM_08", name: "Montegiardino", parent: "SM" },
  { code: "SM_09", name: "Serravalle", parent: "SM" },
  { code: "SN_DB", name: "Diourbel", parent: "SN" },
  { code: "SN_DK", name: "Dakar", parent: "SN" },
  { code: "SN_FK", name: "Fatick", parent: "SN" },
  { code: "SN_KA", name: "Kaffrine", parent: "SN" },
  { code: "SN_KD", name: "Kolda", parent: "SN" },
  { code: "SN_KE", name: "Kédougou", parent: "SN" },
  { code: "SN_KL", name: "Kaolack", parent: "SN" },
  { code: "SN_LG", name: "Louga", parent: "SN" },
  { code: "SN_MT", name: "Matam", parent: "SN" },
  { code: "SN_SE", name: "Sédhiou", parent: "SN" },
  { code: "SN_SL", name: "Saint-Louis", parent: "SN" },
  { code: "SN_TC", name: "Tambacounda", parent: "SN" },
  { code: "SN_TH", name: "Thiès", parent: "SN" },
  { code: "SN_ZG", name: "Ziguinchor", parent: "SN" },
  { code: "SO_AW", name: "Awdal", parent: "SO" },
  { code: "SO_BK", name: "Bakool", parent: "SO" },
  { code: "SO_BN", name: "Banaadir", parent: "SO" },
  { code: "SO_BR", name: "Bari", parent: "SO" },
  { code: "SO_BY", name: "Bay", parent: "SO" },
  { code: "SO_GA", name: "Galguduud", parent: "SO" },
  { code: "SO_GE", name: "Gedo", parent: "SO" },
  { code: "SO_HI", name: "Hiiraan", parent: "SO" },
  { code: "SO_JD", name: "Jubbada Dhexe", parent: "SO" },
  { code: "SO_JH", name: "Jubbada Hoose", parent: "SO" },
  { code: "SO_MU", name: "Mudug", parent: "SO" },
  { code: "SO_NU", name: "Nugaal", parent: "SO" },
  { code: "SO_SA", name: "Sanaag", parent: "SO" },
  { code: "SO_SD", name: "Shabeellaha Dhexe", parent: "SO" },
  { code: "SO_SH", name: "Shabeellaha Hoose", parent: "SO" },
  { code: "SO_SO", name: "Sool", parent: "SO" },
  { code: "SO_TO", name: "Togdheer", parent: "SO" },
  { code: "SO_WO", name: "Woqooyi Galbeed", parent: "SO" },
  { code: "SR_BR", name: "Brokopondo", parent: "SR" },
  { code: "SR_CM", name: "Commewijne", parent: "SR" },
  { code: "SR_CR", name: "Coronie", parent: "SR" },
  { code: "SR_MA", name: "Marowijne", parent: "SR" },
  { code: "SR_NI", name: "Nickerie", parent: "SR" },
  { code: "SR_PM", name: "Paramaribo", parent: "SR" },
  { code: "SR_PR", name: "Para", parent: "SR" },
  { code: "SR_SA", name: "Saramacca", parent: "SR" },
  { code: "SR_SI", name: "Sipaliwini", parent: "SR" },
  { code: "SR_WA", name: "Wanica", parent: "SR" },
  { code: "SS_BN", name: "Northern Bahr el Ghazal", parent: "SS" },
  { code: "SS_BW", name: "Western Bahr el Ghazal", parent: "SS" },
  { code: "SS_EC", name: "Central Equatoria", parent: "SS" },
  { code: "SS_EE", name: "Eastern Equatoria", parent: "SS" },
  { code: "SS_EW", name: "Western Equatoria", parent: "SS" },
  { code: "SS_JG", name: "Jonglei", parent: "SS" },
  { code: "SS_LK", name: "Lakes", parent: "SS" },
  { code: "SS_NU", name: "Upper Nile", parent: "SS" },
  { code: "SS_UY", name: "Unity", parent: "SS" },
  { code: "SS_WR", name: "Warrap", parent: "SS" },
  { code: "ST_01", name: "Água Grande", parent: "ST" },
  { code: "ST_02", name: "Cantagalo", parent: "ST" },
  { code: "ST_03", name: "Caué", parent: "ST" },
  { code: "ST_04", name: "Lembá", parent: "ST" },
  { code: "ST_05", name: "Lobata", parent: "ST" },
  { code: "ST_06", name: "Mé-Zóchi", parent: "ST" },
  { code: "ST_P", name: "Príncipe", parent: "ST" },
  { code: "SV_AH", name: "Ahuachapán", parent: "SV" },
  { code: "SV_CA", name: "Cabañas", parent: "SV" },
  { code: "SV_CH", name: "Chalatenango", parent: "SV" },
  { code: "SV_CU", name: "Cuscatlán", parent: "SV" },
  { code: "SV_LI", name: "La Libertad", parent: "SV" },
  { code: "SV_MO", name: "Morazán", parent: "SV" },
  { code: "SV_PA", name: "La Paz", parent: "SV" },
  { code: "SV_SA", name: "Santa Ana", parent: "SV" },
  { code: "SV_SM", name: "San Miguel", parent: "SV" },
  { code: "SV_SO", name: "Sonsonate", parent: "SV" },
  { code: "SV_SS", name: "San Salvador", parent: "SV" },
  { code: "SV_SV", name: "San Vicente", parent: "SV" },
  { code: "SV_UN", name: "La Unión", parent: "SV" },
  { code: "SV_US", name: "Usulután", parent: "SV" },
  { code: "SY_DI", name: "Dimashq", parent: "SY" },
  { code: "SY_DR", name: "Dar'ā", parent: "SY" },
  { code: "SY_DY", name: "Dayr az Zawr", parent: "SY" },
  { code: "SY_HA", name: "Al Ḩasakah", parent: "SY" },
  { code: "SY_HI", name: "Ḩimş", parent: "SY" },
  { code: "SY_HL", name: "Ḩalab", parent: "SY" },
  { code: "SY_HM", name: "Ḩamāh", parent: "SY" },
  { code: "SY_ID", name: "Idlib", parent: "SY" },
  { code: "SY_LA", name: "Al Lādhiqīyah", parent: "SY" },
  { code: "SY_QU", name: "Al Qunayţirah", parent: "SY" },
  { code: "SY_RA", name: "Ar Raqqah", parent: "SY" },
  { code: "SY_RD", name: "Rīf Dimashq", parent: "SY" },
  { code: "SY_SU", name: "As Suwaydā'", parent: "SY" },
  { code: "SY_TA", name: "Ţarţūs", parent: "SY" },
  { code: "SZ_HH", name: "Hhohho", parent: "SZ" },
  { code: "SZ_LU", name: "Lubombo", parent: "SZ" },
  { code: "SZ_MA", name: "Manzini", parent: "SZ" },
  { code: "SZ_SH", name: "Shiselweni", parent: "SZ" },
  { code: "TD_BA", name: "Batha", parent: "TD" },
  { code: "TD_BG", name: "Bahr el Ghazal", parent: "TD" },
  { code: "TD_BO", name: "Borkou", parent: "TD" },
  { code: "TD_CB", name: "Chari-Baguirmi", parent: "TD" },
  { code: "TD_EE", name: "Ennedi-Est", parent: "TD" },
  { code: "TD_EO", name: "Ennedi-Ouest", parent: "TD" },
  { code: "TD_GR", name: "Guéra", parent: "TD" },
  { code: "TD_HL", name: "Hadjer Lamis", parent: "TD" },
  { code: "TD_KA", name: "Kanem", parent: "TD" },
  { code: "TD_LC", name: "Lac", parent: "TD" },
  { code: "TD_LO", name: "Logone-Occidental", parent: "TD" },
  { code: "TD_LR", name: "Logone-Oriental", parent: "TD" },
  { code: "TD_MA", name: "Mandoul", parent: "TD" },
  { code: "TD_MC", name: "Moyen-Chari", parent: "TD" },
  { code: "TD_ME", name: "Mayo-Kebbi-Est", parent: "TD" },
  { code: "TD_MO", name: "Mayo-Kebbi-Ouest", parent: "TD" },
  { code: "TD_ND", name: "Ville de Ndjamena", parent: "TD" },
  { code: "TD_OD", name: "Ouaddaï", parent: "TD" },
  { code: "TD_SA", name: "Salamat", parent: "TD" },
  { code: "TD_SI", name: "Sila", parent: "TD" },
  { code: "TD_TA", name: "Tandjilé", parent: "TD" },
  { code: "TD_TI", name: "Tibesti", parent: "TD" },
  { code: "TD_WF", name: "Wadi Fira", parent: "TD" },
  { code: "TG_C", name: "Centrale", parent: "TG" },
  { code: "TG_K", name: "Kara", parent: "TG" },
  { code: "TG_M", name: "Maritime (Région)", parent: "TG" },
  { code: "TG_P", name: "Plateaux", parent: "TG" },
  { code: "TG_S", name: "Savanes", parent: "TG" },
  { code: "TH_10", name: "Krung Thep Maha Nakhon", parent: "TH" },
  { code: "TH_11", name: "Samut Prakan", parent: "TH" },
  { code: "TH_12", name: "Nonthaburi", parent: "TH" },
  { code: "TH_13", name: "Pathum Thani", parent: "TH" },
  { code: "TH_14", name: "Phra Nakhon Si Ayutthaya", parent: "TH" },
  { code: "TH_15", name: "Ang Thong", parent: "TH" },
  { code: "TH_16", name: "Lop Buri", parent: "TH" },
  { code: "TH_17", name: "Sing Buri", parent: "TH" },
  { code: "TH_18", name: "Chai Nat", parent: "TH" },
  { code: "TH_19", name: "Saraburi", parent: "TH" },
  { code: "TH_20", name: "Chon Buri", parent: "TH" },
  { code: "TH_21", name: "Rayong", parent: "TH" },
  { code: "TH_22", name: "Chanthaburi", parent: "TH" },
  { code: "TH_23", name: "Trat", parent: "TH" },
  { code: "TH_24", name: "Chachoengsao", parent: "TH" },
  { code: "TH_25", name: "Prachin Buri", parent: "TH" },
  { code: "TH_26", name: "Nakhon Nayok", parent: "TH" },
  { code: "TH_27", name: "Sa Kaeo", parent: "TH" },
  { code: "TH_30", name: "Nakhon Ratchasima", parent: "TH" },
  { code: "TH_31", name: "Buri Ram", parent: "TH" },
  { code: "TH_32", name: "Surin", parent: "TH" },
  { code: "TH_33", name: "Si Sa Ket", parent: "TH" },
  { code: "TH_34", name: "Ubon Ratchathani", parent: "TH" },
  { code: "TH_35", name: "Yasothon", parent: "TH" },
  { code: "TH_36", name: "Chaiyaphum", parent: "TH" },
  { code: "TH_37", name: "Amnat Charoen", parent: "TH" },
  { code: "TH_38", name: "Bueng Kan", parent: "TH" },
  { code: "TH_39", name: "Nong Bua Lam Phu", parent: "TH" },
  { code: "TH_40", name: "Khon Kaen", parent: "TH" },
  { code: "TH_41", name: "Udon Thani", parent: "TH" },
  { code: "TH_42", name: "Loei", parent: "TH" },
  { code: "TH_43", name: "Nong Khai", parent: "TH" },
  { code: "TH_44", name: "Maha Sarakham", parent: "TH" },
  { code: "TH_45", name: "Roi Et", parent: "TH" },
  { code: "TH_46", name: "Kalasin", parent: "TH" },
  { code: "TH_47", name: "Sakon Nakhon", parent: "TH" },
  { code: "TH_48", name: "Nakhon Phanom", parent: "TH" },
  { code: "TH_49", name: "Mukdahan", parent: "TH" },
  { code: "TH_50", name: "Chiang Mai", parent: "TH" },
  { code: "TH_51", name: "Lamphun", parent: "TH" },
  { code: "TH_52", name: "Lampang", parent: "TH" },
  { code: "TH_53", name: "Uttaradit", parent: "TH" },
  { code: "TH_54", name: "Phrae", parent: "TH" },
  { code: "TH_55", name: "Nan", parent: "TH" },
  { code: "TH_56", name: "Phayao", parent: "TH" },
  { code: "TH_57", name: "Chiang Rai", parent: "TH" },
  { code: "TH_58", name: "Mae Hong Son", parent: "TH" },
  { code: "TH_60", name: "Nakhon Sawan", parent: "TH" },
  { code: "TH_61", name: "Uthai Thani", parent: "TH" },
  { code: "TH_62", name: "Kamphaeng Phet", parent: "TH" },
  { code: "TH_63", name: "Tak", parent: "TH" },
  { code: "TH_64", name: "Sukhothai", parent: "TH" },
  { code: "TH_65", name: "Phitsanulok", parent: "TH" },
  { code: "TH_66", name: "Phichit", parent: "TH" },
  { code: "TH_67", name: "Phetchabun", parent: "TH" },
  { code: "TH_70", name: "Ratchaburi", parent: "TH" },
  { code: "TH_71", name: "Kanchanaburi", parent: "TH" },
  { code: "TH_72", name: "Suphan Buri", parent: "TH" },
  { code: "TH_73", name: "Nakhon Pathom", parent: "TH" },
  { code: "TH_74", name: "Samut Sakhon", parent: "TH" },
  { code: "TH_75", name: "Samut Songkhram", parent: "TH" },
  { code: "TH_76", name: "Phetchaburi", parent: "TH" },
  { code: "TH_77", name: "Prachuap Khiri Khan", parent: "TH" },
  { code: "TH_80", name: "Nakhon Si Thammarat", parent: "TH" },
  { code: "TH_81", name: "Krabi", parent: "TH" },
  { code: "TH_82", name: "Phangnga", parent: "TH" },
  { code: "TH_83", name: "Phuket", parent: "TH" },
  { code: "TH_84", name: "Surat Thani", parent: "TH" },
  { code: "TH_85", name: "Ranong", parent: "TH" },
  { code: "TH_86", name: "Chumphon", parent: "TH" },
  { code: "TH_90", name: "Songkhla", parent: "TH" },
  { code: "TH_91", name: "Satun", parent: "TH" },
  { code: "TH_92", name: "Trang", parent: "TH" },
  { code: "TH_93", name: "Phatthalung", parent: "TH" },
  { code: "TH_94", name: "Pattani", parent: "TH" },
  { code: "TH_95", name: "Yala", parent: "TH" },
  { code: "TH_96", name: "Narathiwat", parent: "TH" },
  { code: "TH_S", name: "Phatthaya", parent: "TH" },
  { code: "TJ_DU", name: "Dushanbe", parent: "TJ" },
  { code: "TJ_GB", name: "Kŭhistoni Badakhshon", parent: "TJ" },
  { code: "TJ_KT", name: "Khatlon", parent: "TJ" },
  { code: "TJ_RA", name: "nohiyahoi tobei jumhurí", parent: "TJ" },
  { code: "TJ_SU", name: "Sughd", parent: "TJ" },
  { code: "TL_AL", name: "Aileu", parent: "TL" },
  { code: "TL_AN", name: "Ainaro", parent: "TL" },
  { code: "TL_BA", name: "Baucau", parent: "TL" },
  { code: "TL_BO", name: "Bobonaro", parent: "TL" },
  { code: "TL_CO", name: "Cova Lima", parent: "TL" },
  { code: "TL_DI", name: "Díli", parent: "TL" },
  { code: "TL_ER", name: "Ermera", parent: "TL" },
  { code: "TL_LA", name: "Lautém", parent: "TL" },
  { code: "TL_LI", name: "Liquiça", parent: "TL" },
  { code: "TL_MF", name: "Manufahi", parent: "TL" },
  { code: "TL_MT", name: "Manatuto", parent: "TL" },
  { code: "TL_OE", name: "Oé-Cusse Ambeno", parent: "TL" },
  { code: "TL_VI", name: "Viqueque", parent: "TL" },
  { code: "TM_A", name: "Ahal", parent: "TM" },
  { code: "TM_B", name: "Balkan", parent: "TM" },
  { code: "TM_D", name: "Daşoguz", parent: "TM" },
  { code: "TM_L", name: "Lebap", parent: "TM" },
  { code: "TM_M", name: "Mary", parent: "TM" },
  { code: "TM_S", name: "Aşgabat", parent: "TM" },
  { code: "TN_11", name: "Tunis", parent: "TN" },
  { code: "TN_12", name: "L'Ariana", parent: "TN" },
  { code: "TN_13", name: "Ben Arous", parent: "TN" },
  { code: "TN_14", name: "La Manouba", parent: "TN" },
  { code: "TN_21", name: "Nabeul", parent: "TN" },
  { code: "TN_22", name: "Zaghouan", parent: "TN" },
  { code: "TN_23", name: "Bizerte", parent: "TN" },
  { code: "TN_31", name: "Béja", parent: "TN" },
  { code: "TN_32", name: "Jendouba", parent: "TN" },
  { code: "TN_33", name: "Le Kef", parent: "TN" },
  { code: "TN_34", name: "Siliana", parent: "TN" },
  { code: "TN_41", name: "Kairouan", parent: "TN" },
  { code: "TN_42", name: "Kasserine", parent: "TN" },
  { code: "TN_43", name: "Sidi Bouzid", parent: "TN" },
  { code: "TN_51", name: "Sousse", parent: "TN" },
  { code: "TN_52", name: "Monastir", parent: "TN" },
  { code: "TN_53", name: "Mahdia", parent: "TN" },
  { code: "TN_61", name: "Sfax", parent: "TN" },
  { code: "TN_71", name: "Gafsa", parent: "TN" },
  { code: "TN_72", name: "Tozeur", parent: "TN" },
  { code: "TN_73", name: "Kébili", parent: "TN" },
  { code: "TN_81", name: "Gabès", parent: "TN" },
  { code: "TN_82", name: "Médenine", parent: "TN" },
  { code: "TN_83", name: "Tataouine", parent: "TN" },
  { code: "TO_01", name: "'Eua", parent: "TO" },
  { code: "TO_02", name: "Ha'apai", parent: "TO" },
  { code: "TO_03", name: "Niuas", parent: "TO" },
  { code: "TO_04", name: "Tongatapu", parent: "TO" },
  { code: "TO_05", name: "Vava'u", parent: "TO" },
  { code: "TR_01", name: "Adana", parent: "TR" },
  { code: "TR_02", name: "Adıyaman", parent: "TR" },
  { code: "TR_03", name: "Afyonkarahisar", parent: "TR" },
  { code: "TR_04", name: "Ağrı", parent: "TR" },
  { code: "TR_05", name: "Amasya", parent: "TR" },
  { code: "TR_06", name: "Ankara", parent: "TR" },
  { code: "TR_07", name: "Antalya", parent: "TR" },
  { code: "TR_08", name: "Artvin", parent: "TR" },
  { code: "TR_09", name: "Aydın", parent: "TR" },
  { code: "TR_10", name: "Balıkesir", parent: "TR" },
  { code: "TR_11", name: "Bilecik", parent: "TR" },
  { code: "TR_12", name: "Bingöl", parent: "TR" },
  { code: "TR_13", name: "Bitlis", parent: "TR" },
  { code: "TR_14", name: "Bolu", parent: "TR" },
  { code: "TR_15", name: "Burdur", parent: "TR" },
  { code: "TR_16", name: "Bursa", parent: "TR" },
  { code: "TR_17", name: "Çanakkale", parent: "TR" },
  { code: "TR_18", name: "Çankırı", parent: "TR" },
  { code: "TR_19", name: "Çorum", parent: "TR" },
  { code: "TR_20", name: "Denizli", parent: "TR" },
  { code: "TR_21", name: "Diyarbakır", parent: "TR" },
  { code: "TR_22", name: "Edirne", parent: "TR" },
  { code: "TR_23", name: "Elazığ", parent: "TR" },
  { code: "TR_24", name: "Erzincan", parent: "TR" },
  { code: "TR_25", name: "Erzurum", parent: "TR" },
  { code: "TR_26", name: "Eskişehir", parent: "TR" },
  { code: "TR_27", name: "Gaziantep", parent: "TR" },
  { code: "TR_28", name: "Giresun", parent: "TR" },
  { code: "TR_29", name: "Gümüşhane", parent: "TR" },
  { code: "TR_30", name: "Hakkâri", parent: "TR" },
  { code: "TR_31", name: "Hatay", parent: "TR" },
  { code: "TR_32", name: "Isparta", parent: "TR" },
  { code: "TR_33", name: "Mersin", parent: "TR" },
  { code: "TR_34", name: "İstanbul", parent: "TR" },
  { code: "TR_35", name: "İzmir", parent: "TR" },
  { code: "TR_36", name: "Kars", parent: "TR" },
  { code: "TR_37", name: "Kastamonu", parent: "TR" },
  { code: "TR_38", name: "Kayseri", parent: "TR" },
  { code: "TR_39", name: "Kırklareli", parent: "TR" },
  { code: "TR_40", name: "Kırşehir", parent: "TR" },
  { code: "TR_41", name: "Kocaeli", parent: "TR" },
  { code: "TR_42", name: "Konya", parent: "TR" },
  { code: "TR_43", name: "Kütahya", parent: "TR" },
  { code: "TR_44", name: "Malatya", parent: "TR" },
  { code: "TR_45", name: "Manisa", parent: "TR" },
  { code: "TR_46", name: "Kahramanmaraş", parent: "TR" },
  { code: "TR_47", name: "Mardin", parent: "TR" },
  { code: "TR_48", name: "Muğla", parent: "TR" },
  { code: "TR_49", name: "Muş", parent: "TR" },
  { code: "TR_50", name: "Nevşehir", parent: "TR" },
  { code: "TR_51", name: "Niğde", parent: "TR" },
  { code: "TR_52", name: "Ordu", parent: "TR" },
  { code: "TR_53", name: "Rize", parent: "TR" },
  { code: "TR_54", name: "Sakarya", parent: "TR" },
  { code: "TR_55", name: "Samsun", parent: "TR" },
  { code: "TR_56", name: "Siirt", parent: "TR" },
  { code: "TR_57", name: "Sinop", parent: "TR" },
  { code: "TR_58", name: "Sivas", parent: "TR" },
  { code: "TR_59", name: "Tekirdağ", parent: "TR" },
  { code: "TR_60", name: "Tokat", parent: "TR" },
  { code: "TR_61", name: "Trabzon", parent: "TR" },
  { code: "TR_62", name: "Tunceli", parent: "TR" },
  { code: "TR_63", name: "Şanlıurfa", parent: "TR" },
  { code: "TR_64", name: "Uşak", parent: "TR" },
  { code: "TR_65", name: "Van", parent: "TR" },
  { code: "TR_66", name: "Yozgat", parent: "TR" },
  { code: "TR_67", name: "Zonguldak", parent: "TR" },
  { code: "TR_68", name: "Aksaray", parent: "TR" },
  { code: "TR_69", name: "Bayburt", parent: "TR" },
  { code: "TR_70", name: "Karaman", parent: "TR" },
  { code: "TR_71", name: "Kırıkkale", parent: "TR" },
  { code: "TR_72", name: "Batman", parent: "TR" },
  { code: "TR_73", name: "Şırnak", parent: "TR" },
  { code: "TR_74", name: "Bartın", parent: "TR" },
  { code: "TR_75", name: "Ardahan", parent: "TR" },
  { code: "TR_76", name: "Iğdır", parent: "TR" },
  { code: "TR_77", name: "Yalova", parent: "TR" },
  { code: "TR_78", name: "Karabük", parent: "TR" },
  { code: "TR_79", name: "Kilis", parent: "TR" },
  { code: "TR_80", name: "Osmaniye", parent: "TR" },
  { code: "TR_81", name: "Düzce", parent: "TR" },
  { code: "TT_ARI", name: "Arima", parent: "TT" },
  { code: "TT_CHA", name: "Chaguanas", parent: "TT" },
  { code: "TT_CTT", name: "Couva-Tabaquite-Talparo", parent: "TT" },
  { code: "TT_DMN", name: "Diego Martin", parent: "TT" },
  { code: "TT_MRC", name: "Mayaro-Rio Claro", parent: "TT" },
  { code: "TT_PED", name: "Penal-Debe", parent: "TT" },
  { code: "TT_POS", name: "Port of Spain", parent: "TT" },
  { code: "TT_PRT", name: "Princes Town", parent: "TT" },
  { code: "TT_PTF", name: "Point Fortin", parent: "TT" },
  { code: "TT_SFO", name: "San Fernando", parent: "TT" },
  { code: "TT_SGE", name: "Sangre Grande", parent: "TT" },
  { code: "TT_SIP", name: "Siparia", parent: "TT" },
  { code: "TT_SJL", name: "San Juan-Laventille", parent: "TT" },
  { code: "TT_TOB", name: "Tobago", parent: "TT" },
  { code: "TT_TUP", name: "Tunapuna-Piarco", parent: "TT" },
  { code: "TV_FUN", name: "Funafuti", parent: "TV" },
  { code: "TV_NIT", name: "Niutao", parent: "TV" },
  { code: "TV_NKF", name: "Nukufetau", parent: "TV" },
  { code: "TV_NKL", name: "Nukulaelae", parent: "TV" },
  { code: "TV_NMA", name: "Nanumea", parent: "TV" },
  { code: "TV_NMG", name: "Nanumaga", parent: "TV" },
  { code: "TV_NUI", name: "Nui", parent: "TV" },
  { code: "TV_VAI", name: "Vaitupu", parent: "TV" },
  { code: "TW_CHA", name: "Changhua", parent: "TW" },
  { code: "TW_CYI", name: "Chiayi", parent: "TW" },
  { code: "TW_CYQ", name: "Chiayi", parent: "TW" },
  { code: "TW_HSQ", name: "Hsinchu", parent: "TW" },
  { code: "TW_HSZ", name: "Hsinchu", parent: "TW" },
  { code: "TW_HUA", name: "Hualien", parent: "TW" },
  { code: "TW_ILA", name: "Yilan", parent: "TW" },
  { code: "TW_KEE", name: "Keelung", parent: "TW" },
  { code: "TW_KHH", name: "Kaohsiung", parent: "TW" },
  { code: "TW_KIN", name: "Kinmen", parent: "TW" },
  { code: "TW_LIE", name: "Lienchiang", parent: "TW" },
  { code: "TW_MIA", name: "Miaoli", parent: "TW" },
  { code: "TW_NAN", name: "Nantou", parent: "TW" },
  { code: "TW_NWT", name: "New Taipei", parent: "TW" },
  { code: "TW_PEN", name: "Penghu", parent: "TW" },
  { code: "TW_PIF", name: "Pingtung", parent: "TW" },
  { code: "TW_TAO", name: "Taoyuan", parent: "TW" },
  { code: "TW_TNN", name: "Tainan", parent: "TW" },
  { code: "TW_TPE", name: "Taipei", parent: "TW" },
  { code: "TW_TTT", name: "Taitung", parent: "TW" },
  { code: "TW_TXG", name: "Taichung", parent: "TW" },
  { code: "TW_YUN", name: "Yunlin", parent: "TW" },
  { code: "TZ_01", name: "Arusha", parent: "TZ" },
  { code: "TZ_02", name: "Dar es Salaam", parent: "TZ" },
  { code: "TZ_03", name: "Dodoma", parent: "TZ" },
  { code: "TZ_04", name: "Iringa", parent: "TZ" },
  { code: "TZ_05", name: "Kagera", parent: "TZ" },
  { code: "TZ_06", name: "Kaskazini Pemba", parent: "TZ" },
  { code: "TZ_07", name: "Kaskazini Unguja", parent: "TZ" },
  { code: "TZ_08", name: "Kigoma", parent: "TZ" },
  { code: "TZ_09", name: "Kilimanjaro", parent: "TZ" },
  { code: "TZ_10", name: "Kusini Pemba", parent: "TZ" },
  { code: "TZ_11", name: "Kusini Unguja", parent: "TZ" },
  { code: "TZ_12", name: "Lindi", parent: "TZ" },
  { code: "TZ_13", name: "Mara", parent: "TZ" },
  { code: "TZ_14", name: "Mbeya", parent: "TZ" },
  { code: "TZ_15", name: "Mjini Magharibi", parent: "TZ" },
  { code: "TZ_16", name: "Morogoro", parent: "TZ" },
  { code: "TZ_17", name: "Mtwara", parent: "TZ" },
  { code: "TZ_18", name: "Mwanza", parent: "TZ" },
  { code: "TZ_19", name: "Pwani", parent: "TZ" },
  { code: "TZ_20", name: "Rukwa", parent: "TZ" },
  { code: "TZ_21", name: "Ruvuma", parent: "TZ" },
  { code: "TZ_22", name: "Shinyanga", parent: "TZ" },
  { code: "TZ_23", name: "Singida", parent: "TZ" },
  { code: "TZ_24", name: "Tabora", parent: "TZ" },
  { code: "TZ_25", name: "Tanga", parent: "TZ" },
  { code: "TZ_26", name: "Manyara", parent: "TZ" },
  { code: "TZ_27", name: "Geita", parent: "TZ" },
  { code: "TZ_28", name: "Katavi", parent: "TZ" },
  { code: "TZ_29", name: "Njombe", parent: "TZ" },
  { code: "TZ_30", name: "Simiyu", parent: "TZ" },
  { code: "TZ_31", name: "Songwe", parent: "TZ" },
  { code: "UA_05", name: "Vinnytska oblast", parent: "UA" },
  { code: "UA_07", name: "Volynska oblast", parent: "UA" },
  { code: "UA_09", name: "Luhanska oblast", parent: "UA" },
  { code: "UA_12", name: "Dnipropetrovska oblast", parent: "UA" },
  { code: "UA_14", name: "Donetska oblast", parent: "UA" },
  { code: "UA_18", name: "Zhytomyrska oblast", parent: "UA" },
  { code: "UA_21", name: "Zakarpatska oblast", parent: "UA" },
  { code: "UA_23", name: "Zaporizka oblast", parent: "UA" },
  { code: "UA_26", name: "Ivano-Frankivska oblast", parent: "UA" },
  { code: "UA_30", name: "Kyiv", parent: "UA" },
  { code: "UA_32", name: "Kyivska oblast", parent: "UA" },
  { code: "UA_35", name: "Kirovohradska oblast", parent: "UA" },
  { code: "UA_40", name: "Sevastopol", parent: "UA" },
  { code: "UA_43", name: "Avtonomna Respublika Krym", parent: "UA" },
  { code: "UA_46", name: "Lvivska oblast", parent: "UA" },
  { code: "UA_48", name: "Mykolaivska oblast", parent: "UA" },
  { code: "UA_51", name: "Odeska oblast", parent: "UA" },
  { code: "UA_53", name: "Poltavska oblast", parent: "UA" },
  { code: "UA_56", name: "Rivnenska oblast", parent: "UA" },
  { code: "UA_59", name: "Sumska oblast", parent: "UA" },
  { code: "UA_61", name: "Ternopilska oblast", parent: "UA" },
  { code: "UA_63", name: "Kharkivska oblast", parent: "UA" },
  { code: "UA_65", name: "Khersonska oblast", parent: "UA" },
  { code: "UA_68", name: "Khmelnytska oblast", parent: "UA" },
  { code: "UA_71", name: "Cherkaska oblast", parent: "UA" },
  { code: "UA_74", name: "Chernihivska oblast", parent: "UA" },
  { code: "UA_77", name: "Chernivetska oblast", parent: "UA" },
  { code: "UG_101", name: "Kalangala", parent: "UG_C" },
  { code: "UG_102", name: "Kampala", parent: "UG_C" },
  { code: "UG_103", name: "Kiboga", parent: "UG_C" },
  { code: "UG_104", name: "Luwero", parent: "UG_C" },
  { code: "UG_105", name: "Masaka", parent: "UG_C" },
  { code: "UG_106", name: "Mpigi", parent: "UG_C" },
  { code: "UG_107", name: "Mubende", parent: "UG_C" },
  { code: "UG_108", name: "Mukono", parent: "UG_C" },
  { code: "UG_109", name: "Nakasongola", parent: "UG_C" },
  { code: "UG_110", name: "Rakai", parent: "UG_C" },
  { code: "UG_111", name: "Sembabule", parent: "UG_C" },
  { code: "UG_112", name: "Kayunga", parent: "UG_C" },
  { code: "UG_113", name: "Wakiso", parent: "UG_C" },
  { code: "UG_114", name: "Lyantonde", parent: "UG_C" },
  { code: "UG_115", name: "Mityana", parent: "UG_C" },
  { code: "UG_116", name: "Nakaseke", parent: "UG_C" },
  { code: "UG_117", name: "Buikwe", parent: "UG_C" },
  { code: "UG_118", name: "Bukomansibi", parent: "UG_C" },
  { code: "UG_119", name: "Butambala", parent: "UG_C" },
  { code: "UG_120", name: "Buvuma", parent: "UG_C" },
  { code: "UG_121", name: "Gomba", parent: "UG_C" },
  { code: "UG_122", name: "Kalungu", parent: "UG_C" },
  { code: "UG_123", name: "Kyankwanzi", parent: "UG_C" },
  { code: "UG_124", name: "Lwengo", parent: "UG_C" },
  { code: "UG_125", name: "Kyotera", parent: "UG_C" },
  { code: "UG_126", name: "Kasanda", parent: "UG_C" },
  { code: "UG_201", name: "Bugiri", parent: "UG_E" },
  { code: "UG_202", name: "Busia", parent: "UG_E" },
  { code: "UG_203", name: "Iganga", parent: "UG_E" },
  { code: "UG_204", name: "Jinja", parent: "UG_E" },
  { code: "UG_205", name: "Kamuli", parent: "UG_E" },
  { code: "UG_206", name: "Kapchorwa", parent: "UG_E" },
  { code: "UG_207", name: "Katakwi", parent: "UG_E" },
  { code: "UG_208", name: "Kumi", parent: "UG_E" },
  { code: "UG_209", name: "Mbale", parent: "UG_E" },
  { code: "UG_210", name: "Pallisa", parent: "UG_E" },
  { code: "UG_211", name: "Soroti", parent: "UG_E" },
  { code: "UG_212", name: "Tororo", parent: "UG_E" },
  { code: "UG_213", name: "Kaberamaido", parent: "UG_E" },
  { code: "UG_214", name: "Mayuge", parent: "UG_E" },
  { code: "UG_215", name: "Sironko", parent: "UG_E" },
  { code: "UG_216", name: "Amuria", parent: "UG_E" },
  { code: "UG_217", name: "Budaka", parent: "UG_E" },
  { code: "UG_218", name: "Bududa", parent: "UG_E" },
  { code: "UG_219", name: "Bukedea", parent: "UG_E" },
  { code: "UG_220", name: "Bukwo", parent: "UG_E" },
  { code: "UG_221", name: "Butaleja", parent: "UG_E" },
  { code: "UG_222", name: "Kaliro", parent: "UG_E" },
  { code: "UG_223", name: "Manafwa", parent: "UG_E" },
  { code: "UG_224", name: "Namutumba", parent: "UG_E" },
  { code: "UG_225", name: "Bulambuli", parent: "UG_E" },
  { code: "UG_226", name: "Buyende", parent: "UG_E" },
  { code: "UG_227", name: "Kibuku", parent: "UG_E" },
  { code: "UG_228", name: "Kween", parent: "UG_E" },
  { code: "UG_229", name: "Luuka", parent: "UG_E" },
  { code: "UG_230", name: "Namayingo", parent: "UG_E" },
  { code: "UG_231", name: "Ngora", parent: "UG_E" },
  { code: "UG_232", name: "Serere", parent: "UG_E" },
  { code: "UG_233", name: "Butebo", parent: "UG_E" },
  { code: "UG_234", name: "Namisindwa", parent: "UG_E" },
  { code: "UG_235", name: "Bugweri", parent: "UG_E" },
  { code: "UG_236", name: "Kapelebyong", parent: "UG_E" },
  { code: "UG_237", name: "Kalaki", parent: "UG_E" },
  { code: "UG_301", name: "Adjumani", parent: "UG_N" },
  { code: "UG_302", name: "Apac", parent: "UG_N" },
  { code: "UG_303", name: "Arua", parent: "UG_N" },
  { code: "UG_304", name: "Gulu", parent: "UG_N" },
  { code: "UG_305", name: "Kitgum", parent: "UG_N" },
  { code: "UG_306", name: "Kotido", parent: "UG_N" },
  { code: "UG_307", name: "Lira", parent: "UG_N" },
  { code: "UG_308", name: "Moroto", parent: "UG_N" },
  { code: "UG_309", name: "Moyo", parent: "UG_N" },
  { code: "UG_310", name: "Nebbi", parent: "UG_N" },
  { code: "UG_311", name: "Nakapiripirit", parent: "UG_N" },
  { code: "UG_312", name: "Pader", parent: "UG_N" },
  { code: "UG_313", name: "Yumbe", parent: "UG_N" },
  { code: "UG_314", name: "Abim", parent: "UG_N" },
  { code: "UG_315", name: "Amolatar", parent: "UG_N" },
  { code: "UG_316", name: "Amuru", parent: "UG_N" },
  { code: "UG_317", name: "Dokolo", parent: "UG_N" },
  { code: "UG_318", name: "Kaabong", parent: "UG_N" },
  { code: "UG_319", name: "Koboko", parent: "UG_N" },
  { code: "UG_320", name: "Maracha", parent: "UG_N" },
  { code: "UG_321", name: "Oyam", parent: "UG_N" },
  { code: "UG_322", name: "Agago", parent: "UG_N" },
  { code: "UG_323", name: "Alebtong", parent: "UG_N" },
  { code: "UG_324", name: "Amudat", parent: "UG_N" },
  { code: "UG_325", name: "Kole", parent: "UG_N" },
  { code: "UG_326", name: "Lamwo", parent: "UG_N" },
  { code: "UG_327", name: "Napak", parent: "UG_N" },
  { code: "UG_328", name: "Nwoya", parent: "UG_N" },
  { code: "UG_329", name: "Otuke", parent: "UG_N" },
  { code: "UG_330", name: "Zombo", parent: "UG_N" },
  { code: "UG_331", name: "Omoro", parent: "UG_N" },
  { code: "UG_332", name: "Pakwach", parent: "UG_N" },
  { code: "UG_333", name: "Kwania", parent: "UG_N" },
  { code: "UG_334", name: "Nabilatuk", parent: "UG_N" },
  { code: "UG_335", name: "Karenga", parent: "UG_N" },
  { code: "UG_336", name: "Madi-Okollo", parent: "UG_N" },
  { code: "UG_337", name: "Obongi", parent: "UG_N" },
  { code: "UG_401", name: "Bundibugyo", parent: "UG_W" },
  { code: "UG_402", name: "Bushenyi", parent: "UG_W" },
  { code: "UG_403", name: "Hoima", parent: "UG_W" },
  { code: "UG_404", name: "Kabale", parent: "UG_W" },
  { code: "UG_405", name: "Kabarole", parent: "UG_W" },
  { code: "UG_406", name: "Kasese", parent: "UG_W" },
  { code: "UG_407", name: "Kibaale", parent: "UG_W" },
  { code: "UG_408", name: "Kisoro", parent: "UG_W" },
  { code: "UG_409", name: "Masindi", parent: "UG_W" },
  { code: "UG_410", name: "Mbarara", parent: "UG_W" },
  { code: "UG_411", name: "Ntungamo", parent: "UG_W" },
  { code: "UG_412", name: "Rukungiri", parent: "UG_W" },
  { code: "UG_413", name: "Kamwenge", parent: "UG_W" },
  { code: "UG_414", name: "Kanungu", parent: "UG_W" },
  { code: "UG_415", name: "Kyenjojo", parent: "UG_W" },
  { code: "UG_416", name: "Buliisa", parent: "UG_W" },
  { code: "UG_417", name: "Ibanda", parent: "UG_W" },
  { code: "UG_418", name: "Isingiro", parent: "UG_W" },
  { code: "UG_419", name: "Kiruhura", parent: "UG_W" },
  { code: "UG_420", name: "Buhweju", parent: "UG_W" },
  { code: "UG_421", name: "Kiryandongo", parent: "UG_W" },
  { code: "UG_422", name: "Kyegegwa", parent: "UG_W" },
  { code: "UG_423", name: "Mitooma", parent: "UG_W" },
  { code: "UG_424", name: "Ntoroko", parent: "UG_W" },
  { code: "UG_425", name: "Rubirizi", parent: "UG_W" },
  { code: "UG_426", name: "Sheema", parent: "UG_W" },
  { code: "UG_427", name: "Kagadi", parent: "UG_W" },
  { code: "UG_428", name: "Kakumiro", parent: "UG_W" },
  { code: "UG_429", name: "Rubanda", parent: "UG_W" },
  { code: "UG_430", name: "Bunyangabu", parent: "UG_W" },
  { code: "UG_431", name: "Rukiga", parent: "UG_W" },
  { code: "UG_432", name: "Kikuube", parent: "UG_W" },
  { code: "UG_433", name: "Kazo", parent: "UG_W" },
  { code: "UG_434", name: "Kitagwenda", parent: "UG_W" },
  { code: "UG_435", name: "Rwampara", parent: "UG_W" },
  { code: "UG_C", name: "Central", parent: "UG" },
  { code: "UG_E", name: "Eastern", parent: "UG" },
  { code: "UG_N", name: "Northern", parent: "UG" },
  { code: "UG_W", name: "Western", parent: "UG" },
  { code: "UM_67", name: "Johnston Atoll", parent: "UM" },
  { code: "UM_71", name: "Midway Islands", parent: "UM" },
  { code: "UM_76", name: "Navassa Island", parent: "UM" },
  { code: "UM_79", name: "Wake Island", parent: "UM" },
  { code: "UM_81", name: "Baker Island", parent: "UM" },
  { code: "UM_84", name: "Howland Island", parent: "UM" },
  { code: "UM_86", name: "Jarvis Island", parent: "UM" },
  { code: "UM_89", name: "Kingman Reef", parent: "UM" },
  { code: "UM_95", name: "Palmyra Atoll", parent: "UM" },
  { code: "US_AK", name: "Alaska", parent: "US" },
  { code: "US_AL", name: "Alabama", parent: "US" },
  { code: "US_AR", name: "Arkansas", parent: "US" },
  { code: "US_AS", name: "American Samoa", parent: "US" },
  { code: "US_AZ", name: "Arizona", parent: "US" },
  { code: "US_CA", name: "California", parent: "US" },
  { code: "US_CO", name: "Colorado", parent: "US" },
  { code: "US_CT", name: "Connecticut", parent: "US" },
  { code: "US_DC", name: "District of Columbia", parent: "US" },
  { code: "US_DE", name: "Delaware", parent: "US" },
  { code: "US_FL", name: "Florida", parent: "US" },
  { code: "US_GA", name: "Georgia", parent: "US" },
  { code: "US_GU", name: "Guam", parent: "US" },
  { code: "US_HI", name: "Hawaii", parent: "US" },
  { code: "US_IA", name: "Iowa", parent: "US" },
  { code: "US_ID", name: "Idaho", parent: "US" },
  { code: "US_IL", name: "Illinois", parent: "US" },
  { code: "US_IN", name: "Indiana", parent: "US" },
  { code: "US_KS", name: "Kansas", parent: "US" },
  { code: "US_KY", name: "Kentucky", parent: "US" },
  { code: "US_LA", name: "Louisiana", parent: "US" },
  { code: "US_MA", name: "Massachusetts", parent: "US" },
  { code: "US_MD", name: "Maryland", parent: "US" },
  { code: "US_ME", name: "Maine", parent: "US" },
  { code: "US_MI", name: "Michigan", parent: "US" },
  { code: "US_MN", name: "Minnesota", parent: "US" },
  { code: "US_MO", name: "Missouri", parent: "US" },
  { code: "US_MP", name: "Northern Mariana Islands", parent: "US" },
  { code: "US_MS", name: "Mississippi", parent: "US" },
  { code: "US_MT", name: "Montana", parent: "US" },
  { code: "US_NC", name: "North Carolina", parent: "US" },
  { code: "US_ND", name: "North Dakota", parent: "US" },
  { code: "US_NE", name: "Nebraska", parent: "US" },
  { code: "US_NH", name: "New Hampshire", parent: "US" },
  { code: "US_NJ", name: "New Jersey", parent: "US" },
  { code: "US_NM", name: "New Mexico", parent: "US" },
  { code: "US_NV", name: "Nevada", parent: "US" },
  { code: "US_NY", name: "New York", parent: "US" },
  { code: "US_OH", name: "Ohio", parent: "US" },
  { code: "US_OK", name: "Oklahoma", parent: "US" },
  { code: "US_OR", name: "Oregon", parent: "US" },
  { code: "US_PA", name: "Pennsylvania", parent: "US" },
  { code: "US_PR", name: "Puerto Rico", parent: "US" },
  { code: "US_RI", name: "Rhode Island", parent: "US" },
  { code: "US_SC", name: "South Carolina", parent: "US" },
  { code: "US_SD", name: "South Dakota", parent: "US" },
  { code: "US_TN", name: "Tennessee", parent: "US" },
  { code: "US_TX", name: "Texas", parent: "US" },
  { code: "US_UM", name: "United States Minor Outlying Islands", parent: "US" },
  { code: "US_UT", name: "Utah", parent: "US" },
  { code: "US_VA", name: "Virginia", parent: "US" },
  { code: "US_VI", name: "Virgin Islands, U.S.", parent: "US" },
  { code: "US_VT", name: "Vermont", parent: "US" },
  { code: "US_WA", name: "Washington", parent: "US" },
  { code: "US_WI", name: "Wisconsin", parent: "US" },
  { code: "US_WV", name: "West Virginia", parent: "US" },
  { code: "US_WY", name: "Wyoming", parent: "US" },
  { code: "UY_AR", name: "Artigas", parent: "UY" },
  { code: "UY_CA", name: "Canelones", parent: "UY" },
  { code: "UY_CL", name: "Cerro Largo", parent: "UY" },
  { code: "UY_CO", name: "Colonia", parent: "UY" },
  { code: "UY_DU", name: "Durazno", parent: "UY" },
  { code: "UY_FD", name: "Florida", parent: "UY" },
  { code: "UY_FS", name: "Flores", parent: "UY" },
  { code: "UY_LA", name: "Lavalleja", parent: "UY" },
  { code: "UY_MA", name: "Maldonado", parent: "UY" },
  { code: "UY_MO", name: "Montevideo", parent: "UY" },
  { code: "UY_PA", name: "Paysandú", parent: "UY" },
  { code: "UY_RN", name: "Río Negro", parent: "UY" },
  { code: "UY_RO", name: "Rocha", parent: "UY" },
  { code: "UY_RV", name: "Rivera", parent: "UY" },
  { code: "UY_SA", name: "Salto", parent: "UY" },
  { code: "UY_SJ", name: "San José", parent: "UY" },
  { code: "UY_SO", name: "Soriano", parent: "UY" },
  { code: "UY_TA", name: "Tacuarembó", parent: "UY" },
  { code: "UY_TT", name: "Treinta y Tres", parent: "UY" },
  { code: "UZ_AN", name: "Andijon", parent: "UZ" },
  { code: "UZ_BU", name: "Buxoro", parent: "UZ" },
  { code: "UZ_FA", name: "Farg‘ona", parent: "UZ" },
  { code: "UZ_JI", name: "Jizzax", parent: "UZ" },
  { code: "UZ_NG", name: "Namangan", parent: "UZ" },
  { code: "UZ_NW", name: "Navoiy", parent: "UZ" },
  { code: "UZ_QA", name: "Qashqadaryo", parent: "UZ" },
  { code: "UZ_QR", name: "Qoraqalpog‘iston Respublikasi", parent: "UZ" },
  { code: "UZ_SA", name: "Samarqand", parent: "UZ" },
  { code: "UZ_SI", name: "Sirdaryo", parent: "UZ" },
  { code: "UZ_SU", name: "Surxondaryo", parent: "UZ" },
  { code: "UZ_TK", name: "Toshkent", parent: "UZ" },
  { code: "UZ_TO", name: "Toshkent", parent: "UZ" },
  { code: "UZ_XO", name: "Xorazm", parent: "UZ" },
  { code: "VC_01", name: "Charlotte", parent: "VC" },
  { code: "VC_02", name: "Saint Andrew", parent: "VC" },
  { code: "VC_03", name: "Saint David", parent: "VC" },
  { code: "VC_04", name: "Saint George", parent: "VC" },
  { code: "VC_05", name: "Saint Patrick", parent: "VC" },
  { code: "VC_06", name: "Grenadines", parent: "VC" },
  { code: "VE_A", name: "Distrito Capital", parent: "VE" },
  { code: "VE_B", name: "Anzoátegui", parent: "VE" },
  { code: "VE_C", name: "Apure", parent: "VE" },
  { code: "VE_D", name: "Aragua", parent: "VE" },
  { code: "VE_E", name: "Barinas", parent: "VE" },
  { code: "VE_F", name: "Bolívar", parent: "VE" },
  { code: "VE_G", name: "Carabobo", parent: "VE" },
  { code: "VE_H", name: "Cojedes", parent: "VE" },
  { code: "VE_I", name: "Falcón", parent: "VE" },
  { code: "VE_J", name: "Guárico", parent: "VE" },
  { code: "VE_K", name: "Lara", parent: "VE" },
  { code: "VE_L", name: "Mérida", parent: "VE" },
  { code: "VE_M", name: "Miranda", parent: "VE" },
  { code: "VE_N", name: "Monagas", parent: "VE" },
  { code: "VE_O", name: "Nueva Esparta", parent: "VE" },
  { code: "VE_P", name: "Portuguesa", parent: "VE" },
  { code: "VE_R", name: "Sucre", parent: "VE" },
  { code: "VE_S", name: "Táchira", parent: "VE" },
  { code: "VE_T", name: "Trujillo", parent: "VE" },
  { code: "VE_U", name: "Yaracuy", parent: "VE" },
  { code: "VE_V", name: "Zulia", parent: "VE" },
  { code: "VE_W", name: "Dependencias Federales", parent: "VE" },
  { code: "VE_X", name: "La Guaira", parent: "VE" },
  { code: "VE_Y", name: "Delta Amacuro", parent: "VE" },
  { code: "VE_Z", name: "Amazonas", parent: "VE" },
  { code: "VN_01", name: "Lai Châu", parent: "VN" },
  { code: "VN_02", name: "Lào Cai", parent: "VN" },
  { code: "VN_03", name: "Hà Giang", parent: "VN" },
  { code: "VN_04", name: "Cao Bằng", parent: "VN" },
  { code: "VN_05", name: "Sơn La", parent: "VN" },
  { code: "VN_06", name: "Yên Bái", parent: "VN" },
  { code: "VN_07", name: "Tuyên Quang", parent: "VN" },
  { code: "VN_09", name: "Lạng Sơn", parent: "VN" },
  { code: "VN_13", name: "Quảng Ninh", parent: "VN" },
  { code: "VN_14", name: "Hòa Bình", parent: "VN" },
  { code: "VN_18", name: "Ninh Bình", parent: "VN" },
  { code: "VN_20", name: "Thái Bình", parent: "VN" },
  { code: "VN_21", name: "Thanh Hóa", parent: "VN" },
  { code: "VN_22", name: "Nghệ An", parent: "VN" },
  { code: "VN_23", name: "Hà Tĩnh", parent: "VN" },
  { code: "VN_24", name: "Quảng Bình", parent: "VN" },
  { code: "VN_25", name: "Quảng Trị", parent: "VN" },
  { code: "VN_26", name: "Thừa Thiên-Huế", parent: "VN" },
  { code: "VN_27", name: "Quảng Nam", parent: "VN" },
  { code: "VN_28", name: "Kon Tum", parent: "VN" },
  { code: "VN_29", name: "Quảng Ngãi", parent: "VN" },
  { code: "VN_30", name: "Gia Lai", parent: "VN" },
  { code: "VN_31", name: "Bình Định", parent: "VN" },
  { code: "VN_32", name: "Phú Yên", parent: "VN" },
  { code: "VN_33", name: "Đắk Lắk", parent: "VN" },
  { code: "VN_34", name: "Khánh Hòa", parent: "VN" },
  { code: "VN_35", name: "Lâm Đồng", parent: "VN" },
  { code: "VN_36", name: "Ninh Thuận", parent: "VN" },
  { code: "VN_37", name: "Tây Ninh", parent: "VN" },
  { code: "VN_39", name: "Đồng Nai", parent: "VN" },
  { code: "VN_40", name: "Bình Thuận", parent: "VN" },
  { code: "VN_41", name: "Long An", parent: "VN" },
  { code: "VN_43", name: "Bà Rịa - Vũng Tàu", parent: "VN" },
  { code: "VN_44", name: "An Giang", parent: "VN" },
  { code: "VN_45", name: "Đồng Tháp", parent: "VN" },
  { code: "VN_46", name: "Tiền Giang", parent: "VN" },
  { code: "VN_47", name: "Kiến Giang", parent: "VN" },
  { code: "VN_49", name: "Vĩnh Long", parent: "VN" },
  { code: "VN_50", name: "Bến Tre", parent: "VN" },
  { code: "VN_51", name: "Trà Vinh", parent: "VN" },
  { code: "VN_52", name: "Sóc Trăng", parent: "VN" },
  { code: "VN_53", name: "Bắc Kạn", parent: "VN" },
  { code: "VN_54", name: "Bắc Giang", parent: "VN" },
  { code: "VN_55", name: "Bạc Liêu", parent: "VN" },
  { code: "VN_56", name: "Bắc Ninh", parent: "VN" },
  { code: "VN_57", name: "Bình Dương", parent: "VN" },
  { code: "VN_58", name: "Bình Phước", parent: "VN" },
  { code: "VN_59", name: "Cà Mau", parent: "VN" },
  { code: "VN_61", name: "Hải Dương", parent: "VN" },
  { code: "VN_63", name: "Hà Nam", parent: "VN" },
  { code: "VN_66", name: "Hưng Yên", parent: "VN" },
  { code: "VN_67", name: "Nam Định", parent: "VN" },
  { code: "VN_68", name: "Phú Thọ", parent: "VN" },
  { code: "VN_69", name: "Thái Nguyên", parent: "VN" },
  { code: "VN_70", name: "Vĩnh Phúc", parent: "VN" },
  { code: "VN_71", name: "Điện Biên", parent: "VN" },
  { code: "VN_72", name: "Đắk Nông", parent: "VN" },
  { code: "VN_73", name: "Hậu Giang", parent: "VN" },
  { code: "VN_CT", name: "Cần Thơ", parent: "VN" },
  { code: "VN_DN", name: "Đà Nẵng", parent: "VN" },
  { code: "VN_HN", name: "Hà Nội", parent: "VN" },
  { code: "VN_HP", name: "Hải Phòng", parent: "VN" },
  { code: "VN_SG", name: "Hồ Chí Minh", parent: "VN" },
  { code: "VU_MAP", name: "Malampa", parent: "VU" },
  { code: "VU_PAM", name: "Pénama", parent: "VU" },
  { code: "VU_SAM", name: "Sanma", parent: "VU" },
  { code: "VU_SEE", name: "Shéfa", parent: "VU" },
  { code: "VU_TAE", name: "Taféa", parent: "VU" },
  { code: "VU_TOB", name: "Torba", parent: "VU" },
  { code: "WF_AL", name: "Alo", parent: "WF" },
  { code: "WF_SG", name: "Sigave", parent: "WF" },
  { code: "WF_UV", name: "Uvea", parent: "WF" },
  { code: "WS_AA", name: "A'ana", parent: "WS" },
  { code: "WS_AL", name: "Aiga-i-le-Tai", parent: "WS" },
  { code: "WS_AT", name: "Atua", parent: "WS" },
  { code: "WS_FA", name: "Fa'asaleleaga", parent: "WS" },
  { code: "WS_GE", name: "Gaga'emauga", parent: "WS" },
  { code: "WS_GI", name: "Gagaifomauga", parent: "WS" },
  { code: "WS_PA", name: "Palauli", parent: "WS" },
  { code: "WS_SA", name: "Satupa'itea", parent: "WS" },
  { code: "WS_TU", name: "Tuamasaga", parent: "WS" },
  { code: "WS_VF", name: "Va'a-o-Fonoti", parent: "WS" },
  { code: "WS_VS", name: "Vaisigano", parent: "WS" },
  { code: "YE_AB", name: "Abyan", parent: "YE" },
  { code: "YE_AD", name: "‘Adan", parent: "YE" },
  { code: "YE_AM", name: "‘Amrān", parent: "YE" },
  { code: "YE_BA", name: "Al Bayḑā’", parent: "YE" },
  { code: "YE_DA", name: "Aḑ Ḑāli‘", parent: "YE" },
  { code: "YE_DH", name: "Dhamār", parent: "YE" },
  { code: "YE_HD", name: "Ḩaḑramawt", parent: "YE" },
  { code: "YE_HJ", name: "Ḩajjah", parent: "YE" },
  { code: "YE_HU", name: "Al Ḩudaydah", parent: "YE" },
  { code: "YE_IB", name: "Ibb", parent: "YE" },
  { code: "YE_JA", name: "Al Jawf", parent: "YE" },
  { code: "YE_LA", name: "Laḩij", parent: "YE" },
  { code: "YE_MA", name: "Ma’rib", parent: "YE" },
  { code: "YE_MR", name: "Al Mahrah", parent: "YE" },
  { code: "YE_MW", name: "Al Maḩwīt", parent: "YE" },
  { code: "YE_RA", name: "Raymah", parent: "YE" },
  { code: "YE_SA", name: "Amānat al ‘Āşimah", parent: "YE" },
  { code: "YE_SD", name: "Şāʻdah", parent: "YE" },
  { code: "YE_SH", name: "Shabwah", parent: "YE" },
  { code: "YE_SN", name: "Şanʻā’", parent: "YE" },
  { code: "YE_SU", name: "Arkhabīl Suquţrá", parent: "YE" },
  { code: "YE_TA", name: "Tāʻizz", parent: "YE" },
  { code: "ZA_EC", name: "Kapa-Vuxa", parent: "ZA" },
  { code: "ZA_FS", name: "Free State", parent: "ZA" },
  { code: "ZA_GP", name: "Gauteng", parent: "ZA" },
  { code: "ZA_KZN", name: "Kwazulu-Natal", parent: "ZA" },
  { code: "ZA_LP", name: "Limpopo", parent: "ZA" },
  { code: "ZA_MP", name: "Mpumalanga", parent: "ZA" },
  { code: "ZA_NC", name: "Kapa-N'walungu", parent: "ZA" },
  { code: "ZA_NW", name: "N'walungu-Vupeladyambu", parent: "ZA" },
  { code: "ZA_WC", name: "Kapa-Vupeladyambu", parent: "ZA" },
  { code: "ZM_01", name: "Western", parent: "ZM" },
  { code: "ZM_02", name: "Central", parent: "ZM" },
  { code: "ZM_03", name: "Eastern", parent: "ZM" },
  { code: "ZM_04", name: "Luapula", parent: "ZM" },
  { code: "ZM_05", name: "Northern", parent: "ZM" },
  { code: "ZM_06", name: "North-Western", parent: "ZM" },
  { code: "ZM_07", name: "Southern", parent: "ZM" },
  { code: "ZM_08", name: "Copperbelt", parent: "ZM" },
  { code: "ZM_09", name: "Lusaka", parent: "ZM" },
  { code: "ZM_10", name: "Muchinga", parent: "ZM" },
  { code: "ZW_BU", name: "Bulawayo", parent: "ZW" },
  { code: "ZW_HA", name: "Harare", parent: "ZW" },
  { code: "ZW_MA", name: "Manicaland", parent: "ZW" },
  { code: "ZW_MC", name: "Mashonaland Central", parent: "ZW" },
  { code: "ZW_ME", name: "Mashonaland East", parent: "ZW" },
  { code: "ZW_MI", name: "Midlands", parent: "ZW" },
  { code: "ZW_MN", name: "Matabeleland North", parent: "ZW" },
  { code: "ZW_MS", name: "Matabeleland South", parent: "ZW" },
  { code: "ZW_MV", name: "Masvingo", parent: "ZW" },
  { code: "ZW_MW", name: "Mashonaland West", parent: "ZW" },
];
