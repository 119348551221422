/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Event action types that can be tracked for payment
 */
export enum ActionType {
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Indicates how transactions should be grouped for calculating tiers
 */
export enum AgreementTierType {
  CLICK = "CLICK",
  SUBAGREEMENT = "SUBAGREEMENT",
}

/**
 * Statically defined error types
 */
export enum ClickServiceErrorType {
  CSV_ERROR = "CSV_ERROR",
  MONTH_CLOSED = "MONTH_CLOSED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  NOT_FOUND = "NOT_FOUND",
  SUBAGREEMENT_USER_MISMATCH = "SUBAGREEMENT_USER_MISMATCH",
}

/**
 * Event type payments are calculated by
 */
export enum CommissionType {
  CLAWBACK = "CLAWBACK",
  CPA = "CPA",
  HYBRID = "HYBRID",
  REVSHARE = "REVSHARE",
  SPEND = "SPEND",
}

/**
 * ISO-3166-1 country code
 */
export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

/**
 * Currency type payments will be made in
 */
export enum Currency {
  AUD = "AUD",
  BTC = "BTC",
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
  INR = "INR",
  USD = "USD",
}

/**
 * Service the integration connects with
 */
export enum IntegrationType {
  FACEBOOK = "FACEBOOK",
}

/**
 * Two letter language code
 */
export enum LanguageCode {
  AA = "AA",
  AB = "AB",
  AE = "AE",
  AF = "AF",
  AK = "AK",
  AM = "AM",
  AN = "AN",
  AR = "AR",
  AS = "AS",
  AV = "AV",
  AY = "AY",
  AZ = "AZ",
  BA = "BA",
  BE = "BE",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  CA = "CA",
  CE = "CE",
  CH = "CH",
  CO = "CO",
  CR = "CR",
  CS = "CS",
  CU = "CU",
  CV = "CV",
  CY = "CY",
  DA = "DA",
  DE = "DE",
  DV = "DV",
  DZ = "DZ",
  EE = "EE",
  EL = "EL",
  EN = "EN",
  ES = "ES",
  ET = "ET",
  EU = "EU",
  FA = "FA",
  FF = "FF",
  FI = "FI",
  FJ = "FJ",
  FO = "FO",
  FR = "FR",
  FY = "FY",
  GA = "GA",
  GD = "GD",
  GL = "GL",
  GN = "GN",
  GU = "GU",
  GV = "GV",
  HA = "HA",
  HE = "HE",
  HI = "HI",
  HO = "HO",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  HY = "HY",
  HZ = "HZ",
  ID = "ID",
  IG = "IG",
  II = "II",
  IK = "IK",
  IS = "IS",
  IT = "IT",
  IU = "IU",
  JA = "JA",
  JV = "JV",
  KA = "KA",
  KG = "KG",
  KI = "KI",
  KJ = "KJ",
  KK = "KK",
  KL = "KL",
  KM = "KM",
  KN = "KN",
  KO = "KO",
  KR = "KR",
  KS = "KS",
  KU = "KU",
  KV = "KV",
  KW = "KW",
  KY = "KY",
  LA = "LA",
  LB = "LB",
  LG = "LG",
  LI = "LI",
  LN = "LN",
  LO = "LO",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  MG = "MG",
  MH = "MH",
  MI = "MI",
  MK = "MK",
  ML = "ML",
  MN = "MN",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MY = "MY",
  NA = "NA",
  NB = "NB",
  ND = "ND",
  NE = "NE",
  NG = "NG",
  NL = "NL",
  NN = "NN",
  NO = "NO",
  NR = "NR",
  NV = "NV",
  NY = "NY",
  OC = "OC",
  OJ = "OJ",
  OM = "OM",
  OR = "OR",
  OS = "OS",
  PA = "PA",
  PI = "PI",
  PL = "PL",
  PS = "PS",
  PT = "PT",
  QU = "QU",
  RM = "RM",
  RN = "RN",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SI = "SI",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SQ = "SQ",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SU = "SU",
  SV = "SV",
  SW = "SW",
  TA = "TA",
  TE = "TE",
  TG = "TG",
  TH = "TH",
  TI = "TI",
  TK = "TK",
  TL = "TL",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TS = "TS",
  TT = "TT",
  TW = "TW",
  TY = "TY",
  UG = "UG",
  UK = "UK",
  UR = "UR",
  UZ = "UZ",
  VE = "VE",
  VI = "VI",
  WA = "WA",
  WO = "WO",
  XH = "XH",
  YI = "YI",
  YO = "YO",
  ZA = "ZA",
  ZH = "ZH",
  ZU = "ZU",
}

/**
 * Status of payments for specific month
 */
export enum MonthStatusEnum {
  CLOSED = "CLOSED",
  LOCKED = "LOCKED",
}

/**
 * Status for monthly payments being paid to partners
 */
export enum MonthlyPaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING",
  ROLLOVER = "ROLLOVER",
}

/**
 * Revshare types network revshare payments are calculated by
 */
export enum NetworkRevshareType {
  GROSS_GAMING_REVENUE = "GROSS_GAMING_REVENUE",
  NET_REVENUE = "NET_REVENUE",
}

/**
 * Event action types that can be tracked for payment
 */
export enum OfferPoolRequirementType {
  COUNTRY = "COUNTRY",
  STATE = "STATE",
  VERTICAL = "VERTICAL",
}

/**
 * Statically defined error types
 */
export enum OfferServiceErrorType {
  BRAND_INACTIVE = "BRAND_INACTIVE",
  MONTH_CLOSED = "MONTH_CLOSED",
  NOT_FOUND = "NOT_FOUND",
  OFFER_INACTIVE = "OFFER_INACTIVE",
}

/**
 * Indicates whether the tier is pay in (NETWORK) or pay out (PARTNER)
 */
export enum PayType {
  NETWORK = "NETWORK",
  PARTNER = "PARTNER",
}

/**
 * Method of payment for user
 */
export enum PaymentMethod {
  BANKWIRE = "BANKWIRE",
  BTCTRANSFER = "BTCTRANSFER",
  CHECK = "CHECK",
  ECOPAYZ = "ECOPAYZ",
  NETELLER = "NETELLER",
  PAYPAL = "PAYPAL",
  PLAYERACCOUNT = "PLAYERACCOUNT",
  SKRILL = "SKRILL",
}

/**
 * Qualification type to enable payment on clicks
 */
export enum QualificationType {
  DEPOSIT_WINDOW = "DEPOSIT_WINDOW",
  MIN_DEPOSIT = "MIN_DEPOSIT",
  MIN_DEPOSIT_COUNT = "MIN_DEPOSIT_COUNT",
  MIN_FTD_AMOUNT = "MIN_FTD_AMOUNT",
  MIN_WAGER_AMOUNT = "MIN_WAGER_AMOUNT",
  MIN_WAGER_COUNT = "MIN_WAGER_COUNT",
}

/**
 * Relative transaction event type to base DEPOSIT_WINDOW qualifications off of
 */
export enum QualificationWindowType {
  CLICK = "CLICK",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Type of Looker dashboard
 */
export enum ReportType {
  ADMIN_CAMPAIGN = "ADMIN_CAMPAIGN",
  ADMIN_CLICK_DRILLDOWN = "ADMIN_CLICK_DRILLDOWN",
  ADMIN_COHORT = "ADMIN_COHORT",
  ADMIN_CREATIVE = "ADMIN_CREATIVE",
  ADMIN_DAILY = "ADMIN_DAILY",
  ADMIN_DASHBOARD = "ADMIN_DASHBOARD",
  ADMIN_DETAILED_BREAKDOWN = "ADMIN_DETAILED_BREAKDOWN",
  ADMIN_MONTHLY = "ADMIN_MONTHLY",
  ADMIN_OFFER = "ADMIN_OFFER",
  ADMIN_PARTNER = "ADMIN_PARTNER",
  ADMIN_PLAYER = "ADMIN_PLAYER",
  ADMIN_SUBID = "ADMIN_SUBID",
  ADMIN_SUBID_DROPDOWN = "ADMIN_SUBID_DROPDOWN",
  ADMIN_TIERS = "ADMIN_TIERS",
  PARTNER_CLICK_DRILLDOWN = "PARTNER_CLICK_DRILLDOWN",
  PARTNER_CREATIVE = "PARTNER_CREATIVE",
  PARTNER_DAILY = "PARTNER_DAILY",
  PARTNER_DASHBOARD = "PARTNER_DASHBOARD",
  PARTNER_OFFER = "PARTNER_OFFER",
  PARTNER_PLAYER = "PARTNER_PLAYER",
  PARTNER_SUBID = "PARTNER_SUBID",
  PARTNER_SUBID_DROPDOWN = "PARTNER_SUBID_DROPDOWN",
  PARTNER_TIERS = "PARTNER_TIERS",
}

/**
 * Auth0 values used to sort in ascending or descending order
 */
export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

/**
 * ISO-3166-2 state code
 */
export enum StateCode {
  AD_02 = "AD_02",
  AD_03 = "AD_03",
  AD_04 = "AD_04",
  AD_05 = "AD_05",
  AD_06 = "AD_06",
  AD_07 = "AD_07",
  AD_08 = "AD_08",
  AE_AJ = "AE_AJ",
  AE_AZ = "AE_AZ",
  AE_DU = "AE_DU",
  AE_FU = "AE_FU",
  AE_RK = "AE_RK",
  AE_SH = "AE_SH",
  AE_UQ = "AE_UQ",
  AF_BAL = "AF_BAL",
  AF_BAM = "AF_BAM",
  AF_BDG = "AF_BDG",
  AF_BDS = "AF_BDS",
  AF_BGL = "AF_BGL",
  AF_DAY = "AF_DAY",
  AF_FRA = "AF_FRA",
  AF_FYB = "AF_FYB",
  AF_GHA = "AF_GHA",
  AF_GHO = "AF_GHO",
  AF_HEL = "AF_HEL",
  AF_HER = "AF_HER",
  AF_JOW = "AF_JOW",
  AF_KAB = "AF_KAB",
  AF_KAN = "AF_KAN",
  AF_KAP = "AF_KAP",
  AF_KDZ = "AF_KDZ",
  AF_KHO = "AF_KHO",
  AF_KNR = "AF_KNR",
  AF_LAG = "AF_LAG",
  AF_LOG = "AF_LOG",
  AF_NAN = "AF_NAN",
  AF_NIM = "AF_NIM",
  AF_NUR = "AF_NUR",
  AF_PAN = "AF_PAN",
  AF_PAR = "AF_PAR",
  AF_PIA = "AF_PIA",
  AF_PKA = "AF_PKA",
  AF_SAM = "AF_SAM",
  AF_SAR = "AF_SAR",
  AF_TAK = "AF_TAK",
  AF_URU = "AF_URU",
  AF_WAR = "AF_WAR",
  AF_ZAB = "AF_ZAB",
  AG_03 = "AG_03",
  AG_04 = "AG_04",
  AG_05 = "AG_05",
  AG_06 = "AG_06",
  AG_07 = "AG_07",
  AG_08 = "AG_08",
  AG_10 = "AG_10",
  AG_11 = "AG_11",
  AL_01 = "AL_01",
  AL_02 = "AL_02",
  AL_03 = "AL_03",
  AL_04 = "AL_04",
  AL_05 = "AL_05",
  AL_06 = "AL_06",
  AL_07 = "AL_07",
  AL_08 = "AL_08",
  AL_09 = "AL_09",
  AL_10 = "AL_10",
  AL_11 = "AL_11",
  AL_12 = "AL_12",
  AM_AG = "AM_AG",
  AM_AR = "AM_AR",
  AM_AV = "AM_AV",
  AM_ER = "AM_ER",
  AM_GR = "AM_GR",
  AM_KT = "AM_KT",
  AM_LO = "AM_LO",
  AM_SH = "AM_SH",
  AM_SU = "AM_SU",
  AM_TV = "AM_TV",
  AM_VD = "AM_VD",
  AO_BGO = "AO_BGO",
  AO_BGU = "AO_BGU",
  AO_BIE = "AO_BIE",
  AO_CAB = "AO_CAB",
  AO_CCU = "AO_CCU",
  AO_CNN = "AO_CNN",
  AO_CNO = "AO_CNO",
  AO_CUS = "AO_CUS",
  AO_HUA = "AO_HUA",
  AO_HUI = "AO_HUI",
  AO_LNO = "AO_LNO",
  AO_LSU = "AO_LSU",
  AO_LUA = "AO_LUA",
  AO_MAL = "AO_MAL",
  AO_MOX = "AO_MOX",
  AO_NAM = "AO_NAM",
  AO_UIG = "AO_UIG",
  AO_ZAI = "AO_ZAI",
  AR_A = "AR_A",
  AR_B = "AR_B",
  AR_C = "AR_C",
  AR_D = "AR_D",
  AR_E = "AR_E",
  AR_F = "AR_F",
  AR_G = "AR_G",
  AR_H = "AR_H",
  AR_J = "AR_J",
  AR_K = "AR_K",
  AR_L = "AR_L",
  AR_M = "AR_M",
  AR_N = "AR_N",
  AR_P = "AR_P",
  AR_Q = "AR_Q",
  AR_R = "AR_R",
  AR_S = "AR_S",
  AR_T = "AR_T",
  AR_U = "AR_U",
  AR_V = "AR_V",
  AR_W = "AR_W",
  AR_X = "AR_X",
  AR_Y = "AR_Y",
  AR_Z = "AR_Z",
  AT_1 = "AT_1",
  AT_2 = "AT_2",
  AT_3 = "AT_3",
  AT_4 = "AT_4",
  AT_5 = "AT_5",
  AT_6 = "AT_6",
  AT_7 = "AT_7",
  AT_8 = "AT_8",
  AT_9 = "AT_9",
  AU_ACT = "AU_ACT",
  AU_NSW = "AU_NSW",
  AU_NT = "AU_NT",
  AU_QLD = "AU_QLD",
  AU_SA = "AU_SA",
  AU_TAS = "AU_TAS",
  AU_VIC = "AU_VIC",
  AU_WA = "AU_WA",
  AZ_ABS = "AZ_ABS",
  AZ_AGA = "AZ_AGA",
  AZ_AGC = "AZ_AGC",
  AZ_AGM = "AZ_AGM",
  AZ_AGS = "AZ_AGS",
  AZ_AGU = "AZ_AGU",
  AZ_AST = "AZ_AST",
  AZ_BA = "AZ_BA",
  AZ_BAB = "AZ_BAB",
  AZ_BAL = "AZ_BAL",
  AZ_BAR = "AZ_BAR",
  AZ_BEY = "AZ_BEY",
  AZ_BIL = "AZ_BIL",
  AZ_CAB = "AZ_CAB",
  AZ_CAL = "AZ_CAL",
  AZ_CUL = "AZ_CUL",
  AZ_DAS = "AZ_DAS",
  AZ_FUZ = "AZ_FUZ",
  AZ_GA = "AZ_GA",
  AZ_GAD = "AZ_GAD",
  AZ_GOR = "AZ_GOR",
  AZ_GOY = "AZ_GOY",
  AZ_GYG = "AZ_GYG",
  AZ_HAC = "AZ_HAC",
  AZ_IMI = "AZ_IMI",
  AZ_ISM = "AZ_ISM",
  AZ_KAL = "AZ_KAL",
  AZ_KAN = "AZ_KAN",
  AZ_KUR = "AZ_KUR",
  AZ_LA = "AZ_LA",
  AZ_LAC = "AZ_LAC",
  AZ_LAN = "AZ_LAN",
  AZ_LER = "AZ_LER",
  AZ_MAS = "AZ_MAS",
  AZ_MI = "AZ_MI",
  AZ_NA = "AZ_NA",
  AZ_NEF = "AZ_NEF",
  AZ_NV = "AZ_NV",
  AZ_NX = "AZ_NX",
  AZ_OGU = "AZ_OGU",
  AZ_ORD = "AZ_ORD",
  AZ_QAB = "AZ_QAB",
  AZ_QAX = "AZ_QAX",
  AZ_QAZ = "AZ_QAZ",
  AZ_QBA = "AZ_QBA",
  AZ_QBI = "AZ_QBI",
  AZ_QOB = "AZ_QOB",
  AZ_QUS = "AZ_QUS",
  AZ_SA = "AZ_SA",
  AZ_SAB = "AZ_SAB",
  AZ_SAD = "AZ_SAD",
  AZ_SAH = "AZ_SAH",
  AZ_SAK = "AZ_SAK",
  AZ_SAL = "AZ_SAL",
  AZ_SAR = "AZ_SAR",
  AZ_SAT = "AZ_SAT",
  AZ_SBN = "AZ_SBN",
  AZ_SIY = "AZ_SIY",
  AZ_SKR = "AZ_SKR",
  AZ_SM = "AZ_SM",
  AZ_SMI = "AZ_SMI",
  AZ_SMX = "AZ_SMX",
  AZ_SR = "AZ_SR",
  AZ_SUS = "AZ_SUS",
  AZ_TAR = "AZ_TAR",
  AZ_TOV = "AZ_TOV",
  AZ_UCA = "AZ_UCA",
  AZ_XA = "AZ_XA",
  AZ_XAC = "AZ_XAC",
  AZ_XCI = "AZ_XCI",
  AZ_XIZ = "AZ_XIZ",
  AZ_XVD = "AZ_XVD",
  AZ_YAR = "AZ_YAR",
  AZ_YE = "AZ_YE",
  AZ_YEV = "AZ_YEV",
  AZ_ZAN = "AZ_ZAN",
  AZ_ZAQ = "AZ_ZAQ",
  AZ_ZAR = "AZ_ZAR",
  BA_BIH = "BA_BIH",
  BA_BRC = "BA_BRC",
  BA_SRP = "BA_SRP",
  BB_01 = "BB_01",
  BB_02 = "BB_02",
  BB_03 = "BB_03",
  BB_04 = "BB_04",
  BB_05 = "BB_05",
  BB_06 = "BB_06",
  BB_07 = "BB_07",
  BB_08 = "BB_08",
  BB_09 = "BB_09",
  BB_10 = "BB_10",
  BB_11 = "BB_11",
  BD_01 = "BD_01",
  BD_02 = "BD_02",
  BD_03 = "BD_03",
  BD_04 = "BD_04",
  BD_05 = "BD_05",
  BD_06 = "BD_06",
  BD_07 = "BD_07",
  BD_08 = "BD_08",
  BD_09 = "BD_09",
  BD_10 = "BD_10",
  BD_11 = "BD_11",
  BD_12 = "BD_12",
  BD_13 = "BD_13",
  BD_14 = "BD_14",
  BD_15 = "BD_15",
  BD_16 = "BD_16",
  BD_17 = "BD_17",
  BD_18 = "BD_18",
  BD_19 = "BD_19",
  BD_20 = "BD_20",
  BD_21 = "BD_21",
  BD_22 = "BD_22",
  BD_23 = "BD_23",
  BD_24 = "BD_24",
  BD_25 = "BD_25",
  BD_26 = "BD_26",
  BD_27 = "BD_27",
  BD_28 = "BD_28",
  BD_29 = "BD_29",
  BD_30 = "BD_30",
  BD_31 = "BD_31",
  BD_32 = "BD_32",
  BD_33 = "BD_33",
  BD_34 = "BD_34",
  BD_35 = "BD_35",
  BD_36 = "BD_36",
  BD_37 = "BD_37",
  BD_38 = "BD_38",
  BD_39 = "BD_39",
  BD_40 = "BD_40",
  BD_41 = "BD_41",
  BD_42 = "BD_42",
  BD_43 = "BD_43",
  BD_44 = "BD_44",
  BD_45 = "BD_45",
  BD_46 = "BD_46",
  BD_47 = "BD_47",
  BD_48 = "BD_48",
  BD_49 = "BD_49",
  BD_50 = "BD_50",
  BD_51 = "BD_51",
  BD_52 = "BD_52",
  BD_53 = "BD_53",
  BD_54 = "BD_54",
  BD_55 = "BD_55",
  BD_56 = "BD_56",
  BD_57 = "BD_57",
  BD_58 = "BD_58",
  BD_59 = "BD_59",
  BD_60 = "BD_60",
  BD_61 = "BD_61",
  BD_62 = "BD_62",
  BD_63 = "BD_63",
  BD_64 = "BD_64",
  BD_A = "BD_A",
  BD_B = "BD_B",
  BD_C = "BD_C",
  BD_D = "BD_D",
  BD_E = "BD_E",
  BD_F = "BD_F",
  BD_G = "BD_G",
  BD_H = "BD_H",
  BE_BRU = "BE_BRU",
  BE_VAN = "BE_VAN",
  BE_VBR = "BE_VBR",
  BE_VLG = "BE_VLG",
  BE_VLI = "BE_VLI",
  BE_VOV = "BE_VOV",
  BE_VWV = "BE_VWV",
  BE_WAL = "BE_WAL",
  BE_WBR = "BE_WBR",
  BE_WHT = "BE_WHT",
  BE_WLG = "BE_WLG",
  BE_WLX = "BE_WLX",
  BE_WNA = "BE_WNA",
  BF_01 = "BF_01",
  BF_02 = "BF_02",
  BF_03 = "BF_03",
  BF_04 = "BF_04",
  BF_05 = "BF_05",
  BF_06 = "BF_06",
  BF_07 = "BF_07",
  BF_08 = "BF_08",
  BF_09 = "BF_09",
  BF_10 = "BF_10",
  BF_11 = "BF_11",
  BF_12 = "BF_12",
  BF_13 = "BF_13",
  BF_BAL = "BF_BAL",
  BF_BAM = "BF_BAM",
  BF_BAN = "BF_BAN",
  BF_BAZ = "BF_BAZ",
  BF_BGR = "BF_BGR",
  BF_BLG = "BF_BLG",
  BF_BLK = "BF_BLK",
  BF_COM = "BF_COM",
  BF_GAN = "BF_GAN",
  BF_GNA = "BF_GNA",
  BF_GOU = "BF_GOU",
  BF_HOU = "BF_HOU",
  BF_IOB = "BF_IOB",
  BF_KAD = "BF_KAD",
  BF_KEN = "BF_KEN",
  BF_KMD = "BF_KMD",
  BF_KMP = "BF_KMP",
  BF_KOP = "BF_KOP",
  BF_KOS = "BF_KOS",
  BF_KOT = "BF_KOT",
  BF_KOW = "BF_KOW",
  BF_LER = "BF_LER",
  BF_LOR = "BF_LOR",
  BF_MOU = "BF_MOU",
  BF_NAM = "BF_NAM",
  BF_NAO = "BF_NAO",
  BF_NAY = "BF_NAY",
  BF_NOU = "BF_NOU",
  BF_OUB = "BF_OUB",
  BF_OUD = "BF_OUD",
  BF_PAS = "BF_PAS",
  BF_PON = "BF_PON",
  BF_SEN = "BF_SEN",
  BF_SIS = "BF_SIS",
  BF_SMT = "BF_SMT",
  BF_SNG = "BF_SNG",
  BF_SOM = "BF_SOM",
  BF_SOR = "BF_SOR",
  BF_TAP = "BF_TAP",
  BF_TUI = "BF_TUI",
  BF_YAG = "BF_YAG",
  BF_YAT = "BF_YAT",
  BF_ZIR = "BF_ZIR",
  BF_ZON = "BF_ZON",
  BF_ZOU = "BF_ZOU",
  BG_01 = "BG_01",
  BG_02 = "BG_02",
  BG_03 = "BG_03",
  BG_04 = "BG_04",
  BG_05 = "BG_05",
  BG_06 = "BG_06",
  BG_07 = "BG_07",
  BG_08 = "BG_08",
  BG_09 = "BG_09",
  BG_10 = "BG_10",
  BG_11 = "BG_11",
  BG_12 = "BG_12",
  BG_13 = "BG_13",
  BG_14 = "BG_14",
  BG_15 = "BG_15",
  BG_16 = "BG_16",
  BG_17 = "BG_17",
  BG_18 = "BG_18",
  BG_19 = "BG_19",
  BG_20 = "BG_20",
  BG_21 = "BG_21",
  BG_22 = "BG_22",
  BG_23 = "BG_23",
  BG_24 = "BG_24",
  BG_25 = "BG_25",
  BG_26 = "BG_26",
  BG_27 = "BG_27",
  BG_28 = "BG_28",
  BH_13 = "BH_13",
  BH_14 = "BH_14",
  BH_15 = "BH_15",
  BH_17 = "BH_17",
  BI_BB = "BI_BB",
  BI_BL = "BI_BL",
  BI_BM = "BI_BM",
  BI_BR = "BI_BR",
  BI_CA = "BI_CA",
  BI_CI = "BI_CI",
  BI_GI = "BI_GI",
  BI_KI = "BI_KI",
  BI_KR = "BI_KR",
  BI_KY = "BI_KY",
  BI_MA = "BI_MA",
  BI_MU = "BI_MU",
  BI_MW = "BI_MW",
  BI_MY = "BI_MY",
  BI_NG = "BI_NG",
  BI_RM = "BI_RM",
  BI_RT = "BI_RT",
  BI_RY = "BI_RY",
  BJ_AK = "BJ_AK",
  BJ_AL = "BJ_AL",
  BJ_AQ = "BJ_AQ",
  BJ_BO = "BJ_BO",
  BJ_CO = "BJ_CO",
  BJ_DO = "BJ_DO",
  BJ_KO = "BJ_KO",
  BJ_LI = "BJ_LI",
  BJ_MO = "BJ_MO",
  BJ_OU = "BJ_OU",
  BJ_PL = "BJ_PL",
  BJ_ZO = "BJ_ZO",
  BN_BE = "BN_BE",
  BN_BM = "BN_BM",
  BN_TE = "BN_TE",
  BN_TU = "BN_TU",
  BO_B = "BO_B",
  BO_C = "BO_C",
  BO_H = "BO_H",
  BO_L = "BO_L",
  BO_N = "BO_N",
  BO_O = "BO_O",
  BO_P = "BO_P",
  BO_S = "BO_S",
  BO_T = "BO_T",
  BQ_BO = "BQ_BO",
  BQ_SA = "BQ_SA",
  BQ_SE = "BQ_SE",
  BR_AC = "BR_AC",
  BR_AL = "BR_AL",
  BR_AM = "BR_AM",
  BR_AP = "BR_AP",
  BR_BA = "BR_BA",
  BR_CE = "BR_CE",
  BR_DF = "BR_DF",
  BR_ES = "BR_ES",
  BR_GO = "BR_GO",
  BR_MA = "BR_MA",
  BR_MG = "BR_MG",
  BR_MS = "BR_MS",
  BR_MT = "BR_MT",
  BR_PA = "BR_PA",
  BR_PB = "BR_PB",
  BR_PE = "BR_PE",
  BR_PI = "BR_PI",
  BR_PR = "BR_PR",
  BR_RJ = "BR_RJ",
  BR_RN = "BR_RN",
  BR_RO = "BR_RO",
  BR_RR = "BR_RR",
  BR_RS = "BR_RS",
  BR_SC = "BR_SC",
  BR_SE = "BR_SE",
  BR_SP = "BR_SP",
  BR_TO = "BR_TO",
  BS_AK = "BS_AK",
  BS_BI = "BS_BI",
  BS_BP = "BS_BP",
  BS_BY = "BS_BY",
  BS_CE = "BS_CE",
  BS_CI = "BS_CI",
  BS_CK = "BS_CK",
  BS_CO = "BS_CO",
  BS_CS = "BS_CS",
  BS_EG = "BS_EG",
  BS_EX = "BS_EX",
  BS_FP = "BS_FP",
  BS_GC = "BS_GC",
  BS_HI = "BS_HI",
  BS_HT = "BS_HT",
  BS_IN = "BS_IN",
  BS_LI = "BS_LI",
  BS_MC = "BS_MC",
  BS_MG = "BS_MG",
  BS_MI = "BS_MI",
  BS_NE = "BS_NE",
  BS_NO = "BS_NO",
  BS_NP = "BS_NP",
  BS_NS = "BS_NS",
  BS_RC = "BS_RC",
  BS_RI = "BS_RI",
  BS_SA = "BS_SA",
  BS_SE = "BS_SE",
  BS_SO = "BS_SO",
  BS_SS = "BS_SS",
  BS_SW = "BS_SW",
  BS_WG = "BS_WG",
  BT_11 = "BT_11",
  BT_12 = "BT_12",
  BT_13 = "BT_13",
  BT_14 = "BT_14",
  BT_15 = "BT_15",
  BT_21 = "BT_21",
  BT_22 = "BT_22",
  BT_23 = "BT_23",
  BT_24 = "BT_24",
  BT_31 = "BT_31",
  BT_32 = "BT_32",
  BT_33 = "BT_33",
  BT_34 = "BT_34",
  BT_41 = "BT_41",
  BT_42 = "BT_42",
  BT_43 = "BT_43",
  BT_44 = "BT_44",
  BT_45 = "BT_45",
  BT_GA = "BT_GA",
  BT_TY = "BT_TY",
  BW_CE = "BW_CE",
  BW_CH = "BW_CH",
  BW_FR = "BW_FR",
  BW_GA = "BW_GA",
  BW_GH = "BW_GH",
  BW_JW = "BW_JW",
  BW_KG = "BW_KG",
  BW_KL = "BW_KL",
  BW_KW = "BW_KW",
  BW_LO = "BW_LO",
  BW_NE = "BW_NE",
  BW_NW = "BW_NW",
  BW_SE = "BW_SE",
  BW_SO = "BW_SO",
  BW_SP = "BW_SP",
  BW_ST = "BW_ST",
  BY_BR = "BY_BR",
  BY_HM = "BY_HM",
  BY_HO = "BY_HO",
  BY_HR = "BY_HR",
  BY_MA = "BY_MA",
  BY_MI = "BY_MI",
  BY_VI = "BY_VI",
  BZ_BZ = "BZ_BZ",
  BZ_CY = "BZ_CY",
  BZ_CZL = "BZ_CZL",
  BZ_OW = "BZ_OW",
  BZ_SC = "BZ_SC",
  BZ_TOL = "BZ_TOL",
  CA_AB = "CA_AB",
  CA_BC = "CA_BC",
  CA_MB = "CA_MB",
  CA_NB = "CA_NB",
  CA_NL = "CA_NL",
  CA_NS = "CA_NS",
  CA_NT = "CA_NT",
  CA_NU = "CA_NU",
  CA_ON = "CA_ON",
  CA_PE = "CA_PE",
  CA_QC = "CA_QC",
  CA_SK = "CA_SK",
  CA_YT = "CA_YT",
  CD_BC = "CD_BC",
  CD_BU = "CD_BU",
  CD_EQ = "CD_EQ",
  CD_HK = "CD_HK",
  CD_HL = "CD_HL",
  CD_HU = "CD_HU",
  CD_IT = "CD_IT",
  CD_KC = "CD_KC",
  CD_KE = "CD_KE",
  CD_KG = "CD_KG",
  CD_KL = "CD_KL",
  CD_KN = "CD_KN",
  CD_KS = "CD_KS",
  CD_LO = "CD_LO",
  CD_LU = "CD_LU",
  CD_MA = "CD_MA",
  CD_MN = "CD_MN",
  CD_MO = "CD_MO",
  CD_NK = "CD_NK",
  CD_NU = "CD_NU",
  CD_SA = "CD_SA",
  CD_SK = "CD_SK",
  CD_SU = "CD_SU",
  CD_TA = "CD_TA",
  CD_TO = "CD_TO",
  CD_TU = "CD_TU",
  CF_AC = "CF_AC",
  CF_BB = "CF_BB",
  CF_BGF = "CF_BGF",
  CF_BK = "CF_BK",
  CF_HK = "CF_HK",
  CF_HM = "CF_HM",
  CF_HS = "CF_HS",
  CF_KB = "CF_KB",
  CF_KG = "CF_KG",
  CF_LB = "CF_LB",
  CF_MB = "CF_MB",
  CF_MP = "CF_MP",
  CF_NM = "CF_NM",
  CF_OP = "CF_OP",
  CF_SE = "CF_SE",
  CF_UK = "CF_UK",
  CF_VK = "CF_VK",
  CG_11 = "CG_11",
  CG_12 = "CG_12",
  CG_13 = "CG_13",
  CG_14 = "CG_14",
  CG_15 = "CG_15",
  CG_16 = "CG_16",
  CG_2 = "CG_2",
  CG_5 = "CG_5",
  CG_7 = "CG_7",
  CG_8 = "CG_8",
  CG_9 = "CG_9",
  CG_BZV = "CG_BZV",
  CH_AG = "CH_AG",
  CH_AI = "CH_AI",
  CH_AR = "CH_AR",
  CH_BE = "CH_BE",
  CH_BL = "CH_BL",
  CH_BS = "CH_BS",
  CH_FR = "CH_FR",
  CH_GE = "CH_GE",
  CH_GL = "CH_GL",
  CH_GR = "CH_GR",
  CH_JU = "CH_JU",
  CH_LU = "CH_LU",
  CH_NE = "CH_NE",
  CH_NW = "CH_NW",
  CH_OW = "CH_OW",
  CH_SG = "CH_SG",
  CH_SH = "CH_SH",
  CH_SO = "CH_SO",
  CH_SZ = "CH_SZ",
  CH_TG = "CH_TG",
  CH_TI = "CH_TI",
  CH_UR = "CH_UR",
  CH_VD = "CH_VD",
  CH_VS = "CH_VS",
  CH_ZG = "CH_ZG",
  CH_ZH = "CH_ZH",
  CI_AB = "CI_AB",
  CI_BS = "CI_BS",
  CI_CM = "CI_CM",
  CI_DN = "CI_DN",
  CI_GD = "CI_GD",
  CI_LC = "CI_LC",
  CI_LG = "CI_LG",
  CI_MG = "CI_MG",
  CI_SM = "CI_SM",
  CI_SV = "CI_SV",
  CI_VB = "CI_VB",
  CI_WR = "CI_WR",
  CI_YM = "CI_YM",
  CI_ZZ = "CI_ZZ",
  CL_AI = "CL_AI",
  CL_AN = "CL_AN",
  CL_AP = "CL_AP",
  CL_AR = "CL_AR",
  CL_AT = "CL_AT",
  CL_BI = "CL_BI",
  CL_CO = "CL_CO",
  CL_LI = "CL_LI",
  CL_LL = "CL_LL",
  CL_LR = "CL_LR",
  CL_MA = "CL_MA",
  CL_ML = "CL_ML",
  CL_NB = "CL_NB",
  CL_RM = "CL_RM",
  CL_TA = "CL_TA",
  CL_VS = "CL_VS",
  CM_AD = "CM_AD",
  CM_CE = "CM_CE",
  CM_EN = "CM_EN",
  CM_ES = "CM_ES",
  CM_LT = "CM_LT",
  CM_NO = "CM_NO",
  CM_NW = "CM_NW",
  CM_OU = "CM_OU",
  CM_SU = "CM_SU",
  CM_SW = "CM_SW",
  CN_AH = "CN_AH",
  CN_BJ = "CN_BJ",
  CN_CQ = "CN_CQ",
  CN_FJ = "CN_FJ",
  CN_GD = "CN_GD",
  CN_GS = "CN_GS",
  CN_GX = "CN_GX",
  CN_GZ = "CN_GZ",
  CN_HA = "CN_HA",
  CN_HB = "CN_HB",
  CN_HE = "CN_HE",
  CN_HI = "CN_HI",
  CN_HK = "CN_HK",
  CN_HL = "CN_HL",
  CN_HN = "CN_HN",
  CN_JL = "CN_JL",
  CN_JS = "CN_JS",
  CN_JX = "CN_JX",
  CN_LN = "CN_LN",
  CN_MO = "CN_MO",
  CN_NM = "CN_NM",
  CN_NX = "CN_NX",
  CN_QH = "CN_QH",
  CN_SC = "CN_SC",
  CN_SD = "CN_SD",
  CN_SH = "CN_SH",
  CN_SN = "CN_SN",
  CN_SX = "CN_SX",
  CN_TJ = "CN_TJ",
  CN_TW = "CN_TW",
  CN_XJ = "CN_XJ",
  CN_XZ = "CN_XZ",
  CN_YN = "CN_YN",
  CN_ZJ = "CN_ZJ",
  CO_AMA = "CO_AMA",
  CO_ANT = "CO_ANT",
  CO_ARA = "CO_ARA",
  CO_ATL = "CO_ATL",
  CO_BOL = "CO_BOL",
  CO_BOY = "CO_BOY",
  CO_CAL = "CO_CAL",
  CO_CAQ = "CO_CAQ",
  CO_CAS = "CO_CAS",
  CO_CAU = "CO_CAU",
  CO_CES = "CO_CES",
  CO_CHO = "CO_CHO",
  CO_COR = "CO_COR",
  CO_CUN = "CO_CUN",
  CO_DC = "CO_DC",
  CO_GUA = "CO_GUA",
  CO_GUV = "CO_GUV",
  CO_HUI = "CO_HUI",
  CO_LAG = "CO_LAG",
  CO_MAG = "CO_MAG",
  CO_MET = "CO_MET",
  CO_NAR = "CO_NAR",
  CO_NSA = "CO_NSA",
  CO_PUT = "CO_PUT",
  CO_QUI = "CO_QUI",
  CO_RIS = "CO_RIS",
  CO_SAN = "CO_SAN",
  CO_SAP = "CO_SAP",
  CO_SUC = "CO_SUC",
  CO_TOL = "CO_TOL",
  CO_VAC = "CO_VAC",
  CO_VAU = "CO_VAU",
  CO_VID = "CO_VID",
  CR_A = "CR_A",
  CR_C = "CR_C",
  CR_G = "CR_G",
  CR_H = "CR_H",
  CR_L = "CR_L",
  CR_P = "CR_P",
  CR_SJ = "CR_SJ",
  CU_01 = "CU_01",
  CU_03 = "CU_03",
  CU_04 = "CU_04",
  CU_05 = "CU_05",
  CU_06 = "CU_06",
  CU_07 = "CU_07",
  CU_08 = "CU_08",
  CU_09 = "CU_09",
  CU_10 = "CU_10",
  CU_11 = "CU_11",
  CU_12 = "CU_12",
  CU_13 = "CU_13",
  CU_14 = "CU_14",
  CU_15 = "CU_15",
  CU_16 = "CU_16",
  CU_99 = "CU_99",
  CV_B = "CV_B",
  CV_BR = "CV_BR",
  CV_BV = "CV_BV",
  CV_CA = "CV_CA",
  CV_CF = "CV_CF",
  CV_CR = "CV_CR",
  CV_MA = "CV_MA",
  CV_MO = "CV_MO",
  CV_PA = "CV_PA",
  CV_PN = "CV_PN",
  CV_PR = "CV_PR",
  CV_RB = "CV_RB",
  CV_RG = "CV_RG",
  CV_RS = "CV_RS",
  CV_S = "CV_S",
  CV_SD = "CV_SD",
  CV_SF = "CV_SF",
  CV_SL = "CV_SL",
  CV_SM = "CV_SM",
  CV_SO = "CV_SO",
  CV_SS = "CV_SS",
  CV_SV = "CV_SV",
  CV_TA = "CV_TA",
  CV_TS = "CV_TS",
  CY_01 = "CY_01",
  CY_02 = "CY_02",
  CY_03 = "CY_03",
  CY_04 = "CY_04",
  CY_05 = "CY_05",
  CY_06 = "CY_06",
  CZ_10 = "CZ_10",
  CZ_20 = "CZ_20",
  CZ_201 = "CZ_201",
  CZ_202 = "CZ_202",
  CZ_203 = "CZ_203",
  CZ_204 = "CZ_204",
  CZ_205 = "CZ_205",
  CZ_206 = "CZ_206",
  CZ_207 = "CZ_207",
  CZ_208 = "CZ_208",
  CZ_209 = "CZ_209",
  CZ_20A = "CZ_20A",
  CZ_20B = "CZ_20B",
  CZ_20C = "CZ_20C",
  CZ_31 = "CZ_31",
  CZ_311 = "CZ_311",
  CZ_312 = "CZ_312",
  CZ_313 = "CZ_313",
  CZ_314 = "CZ_314",
  CZ_315 = "CZ_315",
  CZ_316 = "CZ_316",
  CZ_317 = "CZ_317",
  CZ_32 = "CZ_32",
  CZ_321 = "CZ_321",
  CZ_322 = "CZ_322",
  CZ_323 = "CZ_323",
  CZ_324 = "CZ_324",
  CZ_325 = "CZ_325",
  CZ_326 = "CZ_326",
  CZ_327 = "CZ_327",
  CZ_41 = "CZ_41",
  CZ_411 = "CZ_411",
  CZ_412 = "CZ_412",
  CZ_413 = "CZ_413",
  CZ_42 = "CZ_42",
  CZ_421 = "CZ_421",
  CZ_422 = "CZ_422",
  CZ_423 = "CZ_423",
  CZ_424 = "CZ_424",
  CZ_425 = "CZ_425",
  CZ_426 = "CZ_426",
  CZ_427 = "CZ_427",
  CZ_51 = "CZ_51",
  CZ_511 = "CZ_511",
  CZ_512 = "CZ_512",
  CZ_513 = "CZ_513",
  CZ_514 = "CZ_514",
  CZ_52 = "CZ_52",
  CZ_521 = "CZ_521",
  CZ_522 = "CZ_522",
  CZ_523 = "CZ_523",
  CZ_524 = "CZ_524",
  CZ_525 = "CZ_525",
  CZ_53 = "CZ_53",
  CZ_531 = "CZ_531",
  CZ_532 = "CZ_532",
  CZ_533 = "CZ_533",
  CZ_534 = "CZ_534",
  CZ_63 = "CZ_63",
  CZ_631 = "CZ_631",
  CZ_632 = "CZ_632",
  CZ_633 = "CZ_633",
  CZ_634 = "CZ_634",
  CZ_635 = "CZ_635",
  CZ_64 = "CZ_64",
  CZ_641 = "CZ_641",
  CZ_642 = "CZ_642",
  CZ_643 = "CZ_643",
  CZ_644 = "CZ_644",
  CZ_645 = "CZ_645",
  CZ_646 = "CZ_646",
  CZ_647 = "CZ_647",
  CZ_71 = "CZ_71",
  CZ_711 = "CZ_711",
  CZ_712 = "CZ_712",
  CZ_713 = "CZ_713",
  CZ_714 = "CZ_714",
  CZ_715 = "CZ_715",
  CZ_72 = "CZ_72",
  CZ_721 = "CZ_721",
  CZ_722 = "CZ_722",
  CZ_723 = "CZ_723",
  CZ_724 = "CZ_724",
  CZ_80 = "CZ_80",
  CZ_801 = "CZ_801",
  CZ_802 = "CZ_802",
  CZ_803 = "CZ_803",
  CZ_804 = "CZ_804",
  CZ_805 = "CZ_805",
  CZ_806 = "CZ_806",
  DE_BB = "DE_BB",
  DE_BE = "DE_BE",
  DE_BW = "DE_BW",
  DE_BY = "DE_BY",
  DE_HB = "DE_HB",
  DE_HE = "DE_HE",
  DE_HH = "DE_HH",
  DE_MV = "DE_MV",
  DE_NI = "DE_NI",
  DE_NW = "DE_NW",
  DE_RP = "DE_RP",
  DE_SH = "DE_SH",
  DE_SL = "DE_SL",
  DE_SN = "DE_SN",
  DE_ST = "DE_ST",
  DE_TH = "DE_TH",
  DJ_AR = "DJ_AR",
  DJ_AS = "DJ_AS",
  DJ_DI = "DJ_DI",
  DJ_DJ = "DJ_DJ",
  DJ_OB = "DJ_OB",
  DJ_TA = "DJ_TA",
  DK_015 = "DK_015",
  DK_020 = "DK_020",
  DK_025 = "DK_025",
  DK_030 = "DK_030",
  DK_035 = "DK_035",
  DK_040 = "DK_040",
  DK_042 = "DK_042",
  DK_050 = "DK_050",
  DK_055 = "DK_055",
  DK_060 = "DK_060",
  DK_065 = "DK_065",
  DK_070 = "DK_070",
  DK_076 = "DK_076",
  DK_080 = "DK_080",
  DK_101 = "DK_101",
  DK_147 = "DK_147",
  DK_81 = "DK_81",
  DK_82 = "DK_82",
  DK_83 = "DK_83",
  DK_84 = "DK_84",
  DK_85 = "DK_85",
  DM_02 = "DM_02",
  DM_03 = "DM_03",
  DM_04 = "DM_04",
  DM_05 = "DM_05",
  DM_06 = "DM_06",
  DM_07 = "DM_07",
  DM_08 = "DM_08",
  DM_09 = "DM_09",
  DM_10 = "DM_10",
  DM_11 = "DM_11",
  DO_01 = "DO_01",
  DO_02 = "DO_02",
  DO_03 = "DO_03",
  DO_04 = "DO_04",
  DO_05 = "DO_05",
  DO_06 = "DO_06",
  DO_07 = "DO_07",
  DO_08 = "DO_08",
  DO_09 = "DO_09",
  DO_10 = "DO_10",
  DO_11 = "DO_11",
  DO_12 = "DO_12",
  DO_13 = "DO_13",
  DO_14 = "DO_14",
  DO_15 = "DO_15",
  DO_16 = "DO_16",
  DO_17 = "DO_17",
  DO_18 = "DO_18",
  DO_19 = "DO_19",
  DO_20 = "DO_20",
  DO_21 = "DO_21",
  DO_22 = "DO_22",
  DO_23 = "DO_23",
  DO_24 = "DO_24",
  DO_25 = "DO_25",
  DO_26 = "DO_26",
  DO_27 = "DO_27",
  DO_28 = "DO_28",
  DO_29 = "DO_29",
  DO_30 = "DO_30",
  DO_31 = "DO_31",
  DO_32 = "DO_32",
  DO_33 = "DO_33",
  DO_34 = "DO_34",
  DO_35 = "DO_35",
  DO_36 = "DO_36",
  DO_37 = "DO_37",
  DO_38 = "DO_38",
  DO_39 = "DO_39",
  DO_40 = "DO_40",
  DO_41 = "DO_41",
  DO_42 = "DO_42",
  DZ_01 = "DZ_01",
  DZ_02 = "DZ_02",
  DZ_03 = "DZ_03",
  DZ_04 = "DZ_04",
  DZ_05 = "DZ_05",
  DZ_06 = "DZ_06",
  DZ_07 = "DZ_07",
  DZ_08 = "DZ_08",
  DZ_09 = "DZ_09",
  DZ_10 = "DZ_10",
  DZ_11 = "DZ_11",
  DZ_12 = "DZ_12",
  DZ_13 = "DZ_13",
  DZ_14 = "DZ_14",
  DZ_15 = "DZ_15",
  DZ_16 = "DZ_16",
  DZ_17 = "DZ_17",
  DZ_18 = "DZ_18",
  DZ_19 = "DZ_19",
  DZ_20 = "DZ_20",
  DZ_21 = "DZ_21",
  DZ_22 = "DZ_22",
  DZ_23 = "DZ_23",
  DZ_24 = "DZ_24",
  DZ_25 = "DZ_25",
  DZ_26 = "DZ_26",
  DZ_27 = "DZ_27",
  DZ_28 = "DZ_28",
  DZ_29 = "DZ_29",
  DZ_30 = "DZ_30",
  DZ_31 = "DZ_31",
  DZ_32 = "DZ_32",
  DZ_33 = "DZ_33",
  DZ_34 = "DZ_34",
  DZ_35 = "DZ_35",
  DZ_36 = "DZ_36",
  DZ_37 = "DZ_37",
  DZ_38 = "DZ_38",
  DZ_39 = "DZ_39",
  DZ_40 = "DZ_40",
  DZ_41 = "DZ_41",
  DZ_42 = "DZ_42",
  DZ_43 = "DZ_43",
  DZ_44 = "DZ_44",
  DZ_45 = "DZ_45",
  DZ_46 = "DZ_46",
  DZ_47 = "DZ_47",
  DZ_48 = "DZ_48",
  DZ_49 = "DZ_49",
  DZ_50 = "DZ_50",
  DZ_51 = "DZ_51",
  DZ_52 = "DZ_52",
  DZ_53 = "DZ_53",
  DZ_54 = "DZ_54",
  DZ_55 = "DZ_55",
  DZ_56 = "DZ_56",
  DZ_57 = "DZ_57",
  DZ_58 = "DZ_58",
  EC_A = "EC_A",
  EC_B = "EC_B",
  EC_C = "EC_C",
  EC_D = "EC_D",
  EC_E = "EC_E",
  EC_F = "EC_F",
  EC_G = "EC_G",
  EC_H = "EC_H",
  EC_I = "EC_I",
  EC_L = "EC_L",
  EC_M = "EC_M",
  EC_N = "EC_N",
  EC_O = "EC_O",
  EC_P = "EC_P",
  EC_R = "EC_R",
  EC_S = "EC_S",
  EC_SD = "EC_SD",
  EC_SE = "EC_SE",
  EC_T = "EC_T",
  EC_U = "EC_U",
  EC_W = "EC_W",
  EC_X = "EC_X",
  EC_Y = "EC_Y",
  EC_Z = "EC_Z",
  EE_130 = "EE_130",
  EE_141 = "EE_141",
  EE_142 = "EE_142",
  EE_171 = "EE_171",
  EE_184 = "EE_184",
  EE_191 = "EE_191",
  EE_198 = "EE_198",
  EE_205 = "EE_205",
  EE_214 = "EE_214",
  EE_245 = "EE_245",
  EE_247 = "EE_247",
  EE_251 = "EE_251",
  EE_255 = "EE_255",
  EE_272 = "EE_272",
  EE_283 = "EE_283",
  EE_284 = "EE_284",
  EE_291 = "EE_291",
  EE_293 = "EE_293",
  EE_296 = "EE_296",
  EE_303 = "EE_303",
  EE_305 = "EE_305",
  EE_317 = "EE_317",
  EE_321 = "EE_321",
  EE_338 = "EE_338",
  EE_353 = "EE_353",
  EE_37 = "EE_37",
  EE_39 = "EE_39",
  EE_424 = "EE_424",
  EE_430 = "EE_430",
  EE_431 = "EE_431",
  EE_432 = "EE_432",
  EE_441 = "EE_441",
  EE_442 = "EE_442",
  EE_446 = "EE_446",
  EE_45 = "EE_45",
  EE_478 = "EE_478",
  EE_480 = "EE_480",
  EE_486 = "EE_486",
  EE_50 = "EE_50",
  EE_503 = "EE_503",
  EE_511 = "EE_511",
  EE_514 = "EE_514",
  EE_52 = "EE_52",
  EE_528 = "EE_528",
  EE_557 = "EE_557",
  EE_56 = "EE_56",
  EE_567 = "EE_567",
  EE_586 = "EE_586",
  EE_60 = "EE_60",
  EE_615 = "EE_615",
  EE_618 = "EE_618",
  EE_622 = "EE_622",
  EE_624 = "EE_624",
  EE_638 = "EE_638",
  EE_64 = "EE_64",
  EE_651 = "EE_651",
  EE_653 = "EE_653",
  EE_661 = "EE_661",
  EE_663 = "EE_663",
  EE_668 = "EE_668",
  EE_68 = "EE_68",
  EE_689 = "EE_689",
  EE_698 = "EE_698",
  EE_708 = "EE_708",
  EE_71 = "EE_71",
  EE_712 = "EE_712",
  EE_714 = "EE_714",
  EE_719 = "EE_719",
  EE_726 = "EE_726",
  EE_732 = "EE_732",
  EE_735 = "EE_735",
  EE_74 = "EE_74",
  EE_784 = "EE_784",
  EE_79 = "EE_79",
  EE_792 = "EE_792",
  EE_793 = "EE_793",
  EE_796 = "EE_796",
  EE_803 = "EE_803",
  EE_809 = "EE_809",
  EE_81 = "EE_81",
  EE_824 = "EE_824",
  EE_834 = "EE_834",
  EE_84 = "EE_84",
  EE_855 = "EE_855",
  EE_87 = "EE_87",
  EE_890 = "EE_890",
  EE_897 = "EE_897",
  EE_899 = "EE_899",
  EE_901 = "EE_901",
  EE_903 = "EE_903",
  EE_907 = "EE_907",
  EE_917 = "EE_917",
  EE_919 = "EE_919",
  EE_928 = "EE_928",
  EG_ALX = "EG_ALX",
  EG_ASN = "EG_ASN",
  EG_AST = "EG_AST",
  EG_BA = "EG_BA",
  EG_BH = "EG_BH",
  EG_BNS = "EG_BNS",
  EG_C = "EG_C",
  EG_DK = "EG_DK",
  EG_DT = "EG_DT",
  EG_FYM = "EG_FYM",
  EG_GH = "EG_GH",
  EG_GZ = "EG_GZ",
  EG_IS = "EG_IS",
  EG_JS = "EG_JS",
  EG_KB = "EG_KB",
  EG_KFS = "EG_KFS",
  EG_KN = "EG_KN",
  EG_LX = "EG_LX",
  EG_MN = "EG_MN",
  EG_MNF = "EG_MNF",
  EG_MT = "EG_MT",
  EG_PTS = "EG_PTS",
  EG_SHG = "EG_SHG",
  EG_SHR = "EG_SHR",
  EG_SIN = "EG_SIN",
  EG_SUZ = "EG_SUZ",
  EG_WAD = "EG_WAD",
  ER_AN = "ER_AN",
  ER_DK = "ER_DK",
  ER_DU = "ER_DU",
  ER_GB = "ER_GB",
  ER_MA = "ER_MA",
  ER_SK = "ER_SK",
  ES_A = "ES_A",
  ES_AB = "ES_AB",
  ES_AL = "ES_AL",
  ES_AN = "ES_AN",
  ES_AR = "ES_AR",
  ES_AS = "ES_AS",
  ES_AV = "ES_AV",
  ES_B = "ES_B",
  ES_BA = "ES_BA",
  ES_BI = "ES_BI",
  ES_BU = "ES_BU",
  ES_C = "ES_C",
  ES_CA = "ES_CA",
  ES_CB = "ES_CB",
  ES_CC = "ES_CC",
  ES_CE = "ES_CE",
  ES_CL = "ES_CL",
  ES_CM = "ES_CM",
  ES_CN = "ES_CN",
  ES_CO = "ES_CO",
  ES_CR = "ES_CR",
  ES_CS = "ES_CS",
  ES_CT = "ES_CT",
  ES_CU = "ES_CU",
  ES_EX = "ES_EX",
  ES_GA = "ES_GA",
  ES_GC = "ES_GC",
  ES_GI = "ES_GI",
  ES_GR = "ES_GR",
  ES_GU = "ES_GU",
  ES_H = "ES_H",
  ES_HU = "ES_HU",
  ES_IB = "ES_IB",
  ES_J = "ES_J",
  ES_L = "ES_L",
  ES_LE = "ES_LE",
  ES_LO = "ES_LO",
  ES_LU = "ES_LU",
  ES_M = "ES_M",
  ES_MA = "ES_MA",
  ES_MC = "ES_MC",
  ES_MD = "ES_MD",
  ES_ML = "ES_ML",
  ES_MU = "ES_MU",
  ES_NA = "ES_NA",
  ES_NC = "ES_NC",
  ES_O = "ES_O",
  ES_OR = "ES_OR",
  ES_P = "ES_P",
  ES_PM = "ES_PM",
  ES_PO = "ES_PO",
  ES_PV = "ES_PV",
  ES_RI = "ES_RI",
  ES_S = "ES_S",
  ES_SA = "ES_SA",
  ES_SE = "ES_SE",
  ES_SG = "ES_SG",
  ES_SO = "ES_SO",
  ES_SS = "ES_SS",
  ES_T = "ES_T",
  ES_TE = "ES_TE",
  ES_TF = "ES_TF",
  ES_TO = "ES_TO",
  ES_V = "ES_V",
  ES_VA = "ES_VA",
  ES_VC = "ES_VC",
  ES_VI = "ES_VI",
  ES_Z = "ES_Z",
  ES_ZA = "ES_ZA",
  ET_AA = "ET_AA",
  ET_AF = "ET_AF",
  ET_AM = "ET_AM",
  ET_BE = "ET_BE",
  ET_DD = "ET_DD",
  ET_GA = "ET_GA",
  ET_HA = "ET_HA",
  ET_OR = "ET_OR",
  ET_SI = "ET_SI",
  ET_SN = "ET_SN",
  ET_SO = "ET_SO",
  ET_SW = "ET_SW",
  ET_TI = "ET_TI",
  FI_01 = "FI_01",
  FI_02 = "FI_02",
  FI_03 = "FI_03",
  FI_04 = "FI_04",
  FI_05 = "FI_05",
  FI_06 = "FI_06",
  FI_07 = "FI_07",
  FI_08 = "FI_08",
  FI_09 = "FI_09",
  FI_10 = "FI_10",
  FI_11 = "FI_11",
  FI_12 = "FI_12",
  FI_13 = "FI_13",
  FI_14 = "FI_14",
  FI_15 = "FI_15",
  FI_16 = "FI_16",
  FI_17 = "FI_17",
  FI_18 = "FI_18",
  FI_19 = "FI_19",
  FJ_01 = "FJ_01",
  FJ_02 = "FJ_02",
  FJ_03 = "FJ_03",
  FJ_04 = "FJ_04",
  FJ_05 = "FJ_05",
  FJ_06 = "FJ_06",
  FJ_07 = "FJ_07",
  FJ_08 = "FJ_08",
  FJ_09 = "FJ_09",
  FJ_10 = "FJ_10",
  FJ_11 = "FJ_11",
  FJ_12 = "FJ_12",
  FJ_13 = "FJ_13",
  FJ_14 = "FJ_14",
  FJ_C = "FJ_C",
  FJ_E = "FJ_E",
  FJ_N = "FJ_N",
  FJ_R = "FJ_R",
  FJ_W = "FJ_W",
  FM_KSA = "FM_KSA",
  FM_PNI = "FM_PNI",
  FM_TRK = "FM_TRK",
  FM_YAP = "FM_YAP",
  FR_01 = "FR_01",
  FR_02 = "FR_02",
  FR_03 = "FR_03",
  FR_04 = "FR_04",
  FR_05 = "FR_05",
  FR_06 = "FR_06",
  FR_07 = "FR_07",
  FR_08 = "FR_08",
  FR_09 = "FR_09",
  FR_10 = "FR_10",
  FR_11 = "FR_11",
  FR_12 = "FR_12",
  FR_13 = "FR_13",
  FR_14 = "FR_14",
  FR_15 = "FR_15",
  FR_16 = "FR_16",
  FR_17 = "FR_17",
  FR_18 = "FR_18",
  FR_19 = "FR_19",
  FR_20R = "FR_20R",
  FR_21 = "FR_21",
  FR_22 = "FR_22",
  FR_23 = "FR_23",
  FR_24 = "FR_24",
  FR_25 = "FR_25",
  FR_26 = "FR_26",
  FR_27 = "FR_27",
  FR_28 = "FR_28",
  FR_29 = "FR_29",
  FR_2A = "FR_2A",
  FR_2B = "FR_2B",
  FR_30 = "FR_30",
  FR_31 = "FR_31",
  FR_32 = "FR_32",
  FR_33 = "FR_33",
  FR_34 = "FR_34",
  FR_35 = "FR_35",
  FR_36 = "FR_36",
  FR_37 = "FR_37",
  FR_38 = "FR_38",
  FR_39 = "FR_39",
  FR_40 = "FR_40",
  FR_41 = "FR_41",
  FR_42 = "FR_42",
  FR_43 = "FR_43",
  FR_44 = "FR_44",
  FR_45 = "FR_45",
  FR_46 = "FR_46",
  FR_47 = "FR_47",
  FR_48 = "FR_48",
  FR_49 = "FR_49",
  FR_50 = "FR_50",
  FR_51 = "FR_51",
  FR_52 = "FR_52",
  FR_53 = "FR_53",
  FR_54 = "FR_54",
  FR_55 = "FR_55",
  FR_56 = "FR_56",
  FR_57 = "FR_57",
  FR_58 = "FR_58",
  FR_59 = "FR_59",
  FR_60 = "FR_60",
  FR_61 = "FR_61",
  FR_62 = "FR_62",
  FR_63 = "FR_63",
  FR_64 = "FR_64",
  FR_65 = "FR_65",
  FR_66 = "FR_66",
  FR_67 = "FR_67",
  FR_68 = "FR_68",
  FR_69 = "FR_69",
  FR_69M = "FR_69M",
  FR_6AE = "FR_6AE",
  FR_70 = "FR_70",
  FR_71 = "FR_71",
  FR_72 = "FR_72",
  FR_73 = "FR_73",
  FR_74 = "FR_74",
  FR_75C = "FR_75C",
  FR_76 = "FR_76",
  FR_77 = "FR_77",
  FR_78 = "FR_78",
  FR_79 = "FR_79",
  FR_80 = "FR_80",
  FR_81 = "FR_81",
  FR_82 = "FR_82",
  FR_83 = "FR_83",
  FR_84 = "FR_84",
  FR_85 = "FR_85",
  FR_86 = "FR_86",
  FR_87 = "FR_87",
  FR_88 = "FR_88",
  FR_89 = "FR_89",
  FR_90 = "FR_90",
  FR_91 = "FR_91",
  FR_92 = "FR_92",
  FR_93 = "FR_93",
  FR_94 = "FR_94",
  FR_95 = "FR_95",
  FR_971 = "FR_971",
  FR_972 = "FR_972",
  FR_973 = "FR_973",
  FR_974 = "FR_974",
  FR_976 = "FR_976",
  FR_ARA = "FR_ARA",
  FR_BFC = "FR_BFC",
  FR_BL = "FR_BL",
  FR_BRE = "FR_BRE",
  FR_CP = "FR_CP",
  FR_CVL = "FR_CVL",
  FR_GES = "FR_GES",
  FR_HDF = "FR_HDF",
  FR_IDF = "FR_IDF",
  FR_MF = "FR_MF",
  FR_NAQ = "FR_NAQ",
  FR_NC = "FR_NC",
  FR_NOR = "FR_NOR",
  FR_OCC = "FR_OCC",
  FR_PAC = "FR_PAC",
  FR_PDL = "FR_PDL",
  FR_PF = "FR_PF",
  FR_PM = "FR_PM",
  FR_TF = "FR_TF",
  FR_WF = "FR_WF",
  GA_1 = "GA_1",
  GA_2 = "GA_2",
  GA_3 = "GA_3",
  GA_4 = "GA_4",
  GA_5 = "GA_5",
  GA_6 = "GA_6",
  GA_7 = "GA_7",
  GA_8 = "GA_8",
  GA_9 = "GA_9",
  GB_ABC = "GB_ABC",
  GB_ABD = "GB_ABD",
  GB_ABE = "GB_ABE",
  GB_AGB = "GB_AGB",
  GB_AGY = "GB_AGY",
  GB_AND = "GB_AND",
  GB_ANN = "GB_ANN",
  GB_ANS = "GB_ANS",
  GB_BAS = "GB_BAS",
  GB_BBD = "GB_BBD",
  GB_BCP = "GB_BCP",
  GB_BDF = "GB_BDF",
  GB_BDG = "GB_BDG",
  GB_BEN = "GB_BEN",
  GB_BEX = "GB_BEX",
  GB_BFS = "GB_BFS",
  GB_BGE = "GB_BGE",
  GB_BGW = "GB_BGW",
  GB_BIR = "GB_BIR",
  GB_BKM = "GB_BKM",
  GB_BNE = "GB_BNE",
  GB_BNH = "GB_BNH",
  GB_BNS = "GB_BNS",
  GB_BOL = "GB_BOL",
  GB_BPL = "GB_BPL",
  GB_BRC = "GB_BRC",
  GB_BRD = "GB_BRD",
  GB_BRY = "GB_BRY",
  GB_BST = "GB_BST",
  GB_BUR = "GB_BUR",
  GB_CAM = "GB_CAM",
  GB_CAY = "GB_CAY",
  GB_CBF = "GB_CBF",
  GB_CCG = "GB_CCG",
  GB_CGN = "GB_CGN",
  GB_CHE = "GB_CHE",
  GB_CHW = "GB_CHW",
  GB_CLD = "GB_CLD",
  GB_CLK = "GB_CLK",
  GB_CMA = "GB_CMA",
  GB_CMD = "GB_CMD",
  GB_CMN = "GB_CMN",
  GB_CON = "GB_CON",
  GB_COV = "GB_COV",
  GB_CRF = "GB_CRF",
  GB_CRY = "GB_CRY",
  GB_CWY = "GB_CWY",
  GB_DAL = "GB_DAL",
  GB_DBY = "GB_DBY",
  GB_DEN = "GB_DEN",
  GB_DER = "GB_DER",
  GB_DEV = "GB_DEV",
  GB_DGY = "GB_DGY",
  GB_DNC = "GB_DNC",
  GB_DND = "GB_DND",
  GB_DOR = "GB_DOR",
  GB_DRS = "GB_DRS",
  GB_DUD = "GB_DUD",
  GB_DUR = "GB_DUR",
  GB_EAL = "GB_EAL",
  GB_EAW = "GB_EAW",
  GB_EAY = "GB_EAY",
  GB_EDH = "GB_EDH",
  GB_EDU = "GB_EDU",
  GB_ELN = "GB_ELN",
  GB_ELS = "GB_ELS",
  GB_ENF = "GB_ENF",
  GB_ENG = "GB_ENG",
  GB_ERW = "GB_ERW",
  GB_ERY = "GB_ERY",
  GB_ESS = "GB_ESS",
  GB_ESX = "GB_ESX",
  GB_FAL = "GB_FAL",
  GB_FIF = "GB_FIF",
  GB_FLN = "GB_FLN",
  GB_FMO = "GB_FMO",
  GB_GAT = "GB_GAT",
  GB_GBN = "GB_GBN",
  GB_GLG = "GB_GLG",
  GB_GLS = "GB_GLS",
  GB_GRE = "GB_GRE",
  GB_GWN = "GB_GWN",
  GB_HAL = "GB_HAL",
  GB_HAM = "GB_HAM",
  GB_HAV = "GB_HAV",
  GB_HCK = "GB_HCK",
  GB_HEF = "GB_HEF",
  GB_HIL = "GB_HIL",
  GB_HLD = "GB_HLD",
  GB_HMF = "GB_HMF",
  GB_HNS = "GB_HNS",
  GB_HPL = "GB_HPL",
  GB_HRT = "GB_HRT",
  GB_HRW = "GB_HRW",
  GB_HRY = "GB_HRY",
  GB_IOS = "GB_IOS",
  GB_IOW = "GB_IOW",
  GB_ISL = "GB_ISL",
  GB_IVC = "GB_IVC",
  GB_KEC = "GB_KEC",
  GB_KEN = "GB_KEN",
  GB_KHL = "GB_KHL",
  GB_KIR = "GB_KIR",
  GB_KTT = "GB_KTT",
  GB_KWL = "GB_KWL",
  GB_LAN = "GB_LAN",
  GB_LBC = "GB_LBC",
  GB_LBH = "GB_LBH",
  GB_LCE = "GB_LCE",
  GB_LDS = "GB_LDS",
  GB_LEC = "GB_LEC",
  GB_LEW = "GB_LEW",
  GB_LIN = "GB_LIN",
  GB_LIV = "GB_LIV",
  GB_LND = "GB_LND",
  GB_LUT = "GB_LUT",
  GB_MAN = "GB_MAN",
  GB_MDB = "GB_MDB",
  GB_MDW = "GB_MDW",
  GB_MEA = "GB_MEA",
  GB_MIK = "GB_MIK",
  GB_MLN = "GB_MLN",
  GB_MON = "GB_MON",
  GB_MRT = "GB_MRT",
  GB_MRY = "GB_MRY",
  GB_MTY = "GB_MTY",
  GB_MUL = "GB_MUL",
  GB_NAY = "GB_NAY",
  GB_NBL = "GB_NBL",
  GB_NEL = "GB_NEL",
  GB_NET = "GB_NET",
  GB_NFK = "GB_NFK",
  GB_NGM = "GB_NGM",
  GB_NIR = "GB_NIR",
  GB_NLK = "GB_NLK",
  GB_NLN = "GB_NLN",
  GB_NMD = "GB_NMD",
  GB_NNH = "GB_NNH",
  GB_NSM = "GB_NSM",
  GB_NTL = "GB_NTL",
  GB_NTT = "GB_NTT",
  GB_NTY = "GB_NTY",
  GB_NWM = "GB_NWM",
  GB_NWP = "GB_NWP",
  GB_NYK = "GB_NYK",
  GB_OLD = "GB_OLD",
  GB_ORK = "GB_ORK",
  GB_OXF = "GB_OXF",
  GB_PEM = "GB_PEM",
  GB_PKN = "GB_PKN",
  GB_PLY = "GB_PLY",
  GB_POR = "GB_POR",
  GB_POW = "GB_POW",
  GB_PTE = "GB_PTE",
  GB_RCC = "GB_RCC",
  GB_RCH = "GB_RCH",
  GB_RCT = "GB_RCT",
  GB_RDB = "GB_RDB",
  GB_RDG = "GB_RDG",
  GB_RFW = "GB_RFW",
  GB_RIC = "GB_RIC",
  GB_ROT = "GB_ROT",
  GB_RUT = "GB_RUT",
  GB_SAW = "GB_SAW",
  GB_SAY = "GB_SAY",
  GB_SCB = "GB_SCB",
  GB_SCT = "GB_SCT",
  GB_SFK = "GB_SFK",
  GB_SFT = "GB_SFT",
  GB_SGC = "GB_SGC",
  GB_SHF = "GB_SHF",
  GB_SHN = "GB_SHN",
  GB_SHR = "GB_SHR",
  GB_SKP = "GB_SKP",
  GB_SLF = "GB_SLF",
  GB_SLG = "GB_SLG",
  GB_SLK = "GB_SLK",
  GB_SND = "GB_SND",
  GB_SOL = "GB_SOL",
  GB_SOM = "GB_SOM",
  GB_SOS = "GB_SOS",
  GB_SRY = "GB_SRY",
  GB_STE = "GB_STE",
  GB_STG = "GB_STG",
  GB_STH = "GB_STH",
  GB_STN = "GB_STN",
  GB_STS = "GB_STS",
  GB_STT = "GB_STT",
  GB_STY = "GB_STY",
  GB_SWA = "GB_SWA",
  GB_SWD = "GB_SWD",
  GB_SWK = "GB_SWK",
  GB_TAM = "GB_TAM",
  GB_TFW = "GB_TFW",
  GB_THR = "GB_THR",
  GB_TOB = "GB_TOB",
  GB_TOF = "GB_TOF",
  GB_TRF = "GB_TRF",
  GB_TWH = "GB_TWH",
  GB_UKM = "GB_UKM",
  GB_VGL = "GB_VGL",
  GB_WAR = "GB_WAR",
  GB_WBK = "GB_WBK",
  GB_WDU = "GB_WDU",
  GB_WFT = "GB_WFT",
  GB_WGN = "GB_WGN",
  GB_WIL = "GB_WIL",
  GB_WKF = "GB_WKF",
  GB_WLL = "GB_WLL",
  GB_WLN = "GB_WLN",
  GB_WLS = "GB_WLS",
  GB_WLV = "GB_WLV",
  GB_WND = "GB_WND",
  GB_WNH = "GB_WNH",
  GB_WNM = "GB_WNM",
  GB_WOK = "GB_WOK",
  GB_WOR = "GB_WOR",
  GB_WRL = "GB_WRL",
  GB_WRT = "GB_WRT",
  GB_WRX = "GB_WRX",
  GB_WSM = "GB_WSM",
  GB_WSX = "GB_WSX",
  GB_YOR = "GB_YOR",
  GB_ZET = "GB_ZET",
  GD_01 = "GD_01",
  GD_02 = "GD_02",
  GD_03 = "GD_03",
  GD_04 = "GD_04",
  GD_05 = "GD_05",
  GD_06 = "GD_06",
  GD_10 = "GD_10",
  GE_AB = "GE_AB",
  GE_AJ = "GE_AJ",
  GE_GU = "GE_GU",
  GE_IM = "GE_IM",
  GE_KA = "GE_KA",
  GE_KK = "GE_KK",
  GE_MM = "GE_MM",
  GE_RL = "GE_RL",
  GE_SJ = "GE_SJ",
  GE_SK = "GE_SK",
  GE_SZ = "GE_SZ",
  GE_TB = "GE_TB",
  GH_AA = "GH_AA",
  GH_AF = "GH_AF",
  GH_AH = "GH_AH",
  GH_BA = "GH_BA",
  GH_BE = "GH_BE",
  GH_BO = "GH_BO",
  GH_CP = "GH_CP",
  GH_EP = "GH_EP",
  GH_NE = "GH_NE",
  GH_NP = "GH_NP",
  GH_OT = "GH_OT",
  GH_SV = "GH_SV",
  GH_TV = "GH_TV",
  GH_UE = "GH_UE",
  GH_UW = "GH_UW",
  GH_WN = "GH_WN",
  GH_WP = "GH_WP",
  GL_AV = "GL_AV",
  GL_KU = "GL_KU",
  GL_QE = "GL_QE",
  GL_QT = "GL_QT",
  GL_SM = "GL_SM",
  GM_B = "GM_B",
  GM_L = "GM_L",
  GM_M = "GM_M",
  GM_N = "GM_N",
  GM_U = "GM_U",
  GM_W = "GM_W",
  GN_B = "GN_B",
  GN_BE = "GN_BE",
  GN_BF = "GN_BF",
  GN_BK = "GN_BK",
  GN_C = "GN_C",
  GN_CO = "GN_CO",
  GN_D = "GN_D",
  GN_DB = "GN_DB",
  GN_DI = "GN_DI",
  GN_DL = "GN_DL",
  GN_DU = "GN_DU",
  GN_F = "GN_F",
  GN_FA = "GN_FA",
  GN_FO = "GN_FO",
  GN_FR = "GN_FR",
  GN_GA = "GN_GA",
  GN_GU = "GN_GU",
  GN_K = "GN_K",
  GN_KA = "GN_KA",
  GN_KB = "GN_KB",
  GN_KD = "GN_KD",
  GN_KE = "GN_KE",
  GN_KN = "GN_KN",
  GN_KO = "GN_KO",
  GN_KS = "GN_KS",
  GN_L = "GN_L",
  GN_LA = "GN_LA",
  GN_LE = "GN_LE",
  GN_LO = "GN_LO",
  GN_M = "GN_M",
  GN_MC = "GN_MC",
  GN_MD = "GN_MD",
  GN_ML = "GN_ML",
  GN_MM = "GN_MM",
  GN_N = "GN_N",
  GN_NZ = "GN_NZ",
  GN_PI = "GN_PI",
  GN_SI = "GN_SI",
  GN_TE = "GN_TE",
  GN_TO = "GN_TO",
  GN_YO = "GN_YO",
  GQ_AN = "GQ_AN",
  GQ_BN = "GQ_BN",
  GQ_BS = "GQ_BS",
  GQ_C = "GQ_C",
  GQ_CS = "GQ_CS",
  GQ_DJ = "GQ_DJ",
  GQ_I = "GQ_I",
  GQ_KN = "GQ_KN",
  GQ_LI = "GQ_LI",
  GQ_WN = "GQ_WN",
  GR_69 = "GR_69",
  GR_A = "GR_A",
  GR_B = "GR_B",
  GR_C = "GR_C",
  GR_D = "GR_D",
  GR_E = "GR_E",
  GR_F = "GR_F",
  GR_G = "GR_G",
  GR_H = "GR_H",
  GR_I = "GR_I",
  GR_J = "GR_J",
  GR_K = "GR_K",
  GR_L = "GR_L",
  GR_M = "GR_M",
  GT_01 = "GT_01",
  GT_02 = "GT_02",
  GT_03 = "GT_03",
  GT_04 = "GT_04",
  GT_05 = "GT_05",
  GT_06 = "GT_06",
  GT_07 = "GT_07",
  GT_08 = "GT_08",
  GT_09 = "GT_09",
  GT_10 = "GT_10",
  GT_11 = "GT_11",
  GT_12 = "GT_12",
  GT_13 = "GT_13",
  GT_14 = "GT_14",
  GT_15 = "GT_15",
  GT_16 = "GT_16",
  GT_17 = "GT_17",
  GT_18 = "GT_18",
  GT_19 = "GT_19",
  GT_20 = "GT_20",
  GT_21 = "GT_21",
  GT_22 = "GT_22",
  GW_BA = "GW_BA",
  GW_BL = "GW_BL",
  GW_BM = "GW_BM",
  GW_BS = "GW_BS",
  GW_CA = "GW_CA",
  GW_GA = "GW_GA",
  GW_L = "GW_L",
  GW_N = "GW_N",
  GW_OI = "GW_OI",
  GW_QU = "GW_QU",
  GW_S = "GW_S",
  GW_TO = "GW_TO",
  GY_BA = "GY_BA",
  GY_CU = "GY_CU",
  GY_DE = "GY_DE",
  GY_EB = "GY_EB",
  GY_ES = "GY_ES",
  GY_MA = "GY_MA",
  GY_PM = "GY_PM",
  GY_PT = "GY_PT",
  GY_UD = "GY_UD",
  GY_UT = "GY_UT",
  HN_AT = "HN_AT",
  HN_CH = "HN_CH",
  HN_CL = "HN_CL",
  HN_CM = "HN_CM",
  HN_CP = "HN_CP",
  HN_CR = "HN_CR",
  HN_EP = "HN_EP",
  HN_FM = "HN_FM",
  HN_GD = "HN_GD",
  HN_IB = "HN_IB",
  HN_IN = "HN_IN",
  HN_LE = "HN_LE",
  HN_LP = "HN_LP",
  HN_OC = "HN_OC",
  HN_OL = "HN_OL",
  HN_SB = "HN_SB",
  HN_VA = "HN_VA",
  HN_YO = "HN_YO",
  HR_01 = "HR_01",
  HR_02 = "HR_02",
  HR_03 = "HR_03",
  HR_04 = "HR_04",
  HR_05 = "HR_05",
  HR_06 = "HR_06",
  HR_07 = "HR_07",
  HR_08 = "HR_08",
  HR_09 = "HR_09",
  HR_10 = "HR_10",
  HR_11 = "HR_11",
  HR_12 = "HR_12",
  HR_13 = "HR_13",
  HR_14 = "HR_14",
  HR_15 = "HR_15",
  HR_16 = "HR_16",
  HR_17 = "HR_17",
  HR_18 = "HR_18",
  HR_19 = "HR_19",
  HR_20 = "HR_20",
  HR_21 = "HR_21",
  HT_AR = "HT_AR",
  HT_CE = "HT_CE",
  HT_GA = "HT_GA",
  HT_ND = "HT_ND",
  HT_NE = "HT_NE",
  HT_NI = "HT_NI",
  HT_NO = "HT_NO",
  HT_OU = "HT_OU",
  HT_SD = "HT_SD",
  HT_SE = "HT_SE",
  HU_BA = "HU_BA",
  HU_BC = "HU_BC",
  HU_BE = "HU_BE",
  HU_BK = "HU_BK",
  HU_BU = "HU_BU",
  HU_BZ = "HU_BZ",
  HU_CS = "HU_CS",
  HU_DE = "HU_DE",
  HU_DU = "HU_DU",
  HU_EG = "HU_EG",
  HU_ER = "HU_ER",
  HU_FE = "HU_FE",
  HU_GS = "HU_GS",
  HU_GY = "HU_GY",
  HU_HB = "HU_HB",
  HU_HE = "HU_HE",
  HU_HV = "HU_HV",
  HU_JN = "HU_JN",
  HU_KE = "HU_KE",
  HU_KM = "HU_KM",
  HU_KV = "HU_KV",
  HU_MI = "HU_MI",
  HU_NK = "HU_NK",
  HU_NO = "HU_NO",
  HU_NY = "HU_NY",
  HU_PE = "HU_PE",
  HU_PS = "HU_PS",
  HU_SD = "HU_SD",
  HU_SF = "HU_SF",
  HU_SH = "HU_SH",
  HU_SK = "HU_SK",
  HU_SN = "HU_SN",
  HU_SO = "HU_SO",
  HU_SS = "HU_SS",
  HU_ST = "HU_ST",
  HU_SZ = "HU_SZ",
  HU_TB = "HU_TB",
  HU_TO = "HU_TO",
  HU_VA = "HU_VA",
  HU_VE = "HU_VE",
  HU_VM = "HU_VM",
  HU_ZA = "HU_ZA",
  HU_ZE = "HU_ZE",
  ID_AC = "ID_AC",
  ID_BA = "ID_BA",
  ID_BB = "ID_BB",
  ID_BE = "ID_BE",
  ID_BT = "ID_BT",
  ID_GO = "ID_GO",
  ID_JA = "ID_JA",
  ID_JB = "ID_JB",
  ID_JI = "ID_JI",
  ID_JK = "ID_JK",
  ID_JT = "ID_JT",
  ID_JW = "ID_JW",
  ID_KA = "ID_KA",
  ID_KB = "ID_KB",
  ID_KI = "ID_KI",
  ID_KR = "ID_KR",
  ID_KS = "ID_KS",
  ID_KT = "ID_KT",
  ID_KU = "ID_KU",
  ID_LA = "ID_LA",
  ID_MA = "ID_MA",
  ID_ML = "ID_ML",
  ID_MU = "ID_MU",
  ID_NB = "ID_NB",
  ID_NT = "ID_NT",
  ID_NU = "ID_NU",
  ID_PA = "ID_PA",
  ID_PB = "ID_PB",
  ID_PE = "ID_PE",
  ID_PP = "ID_PP",
  ID_PS = "ID_PS",
  ID_PT = "ID_PT",
  ID_RI = "ID_RI",
  ID_SA = "ID_SA",
  ID_SB = "ID_SB",
  ID_SG = "ID_SG",
  ID_SL = "ID_SL",
  ID_SM = "ID_SM",
  ID_SN = "ID_SN",
  ID_SR = "ID_SR",
  ID_SS = "ID_SS",
  ID_ST = "ID_ST",
  ID_SU = "ID_SU",
  ID_YO = "ID_YO",
  IE_C = "IE_C",
  IE_CE = "IE_CE",
  IE_CN = "IE_CN",
  IE_CO = "IE_CO",
  IE_CW = "IE_CW",
  IE_D = "IE_D",
  IE_DL = "IE_DL",
  IE_G = "IE_G",
  IE_KE = "IE_KE",
  IE_KK = "IE_KK",
  IE_KY = "IE_KY",
  IE_L = "IE_L",
  IE_LD = "IE_LD",
  IE_LH = "IE_LH",
  IE_LK = "IE_LK",
  IE_LM = "IE_LM",
  IE_LS = "IE_LS",
  IE_M = "IE_M",
  IE_MH = "IE_MH",
  IE_MN = "IE_MN",
  IE_MO = "IE_MO",
  IE_OY = "IE_OY",
  IE_RN = "IE_RN",
  IE_SO = "IE_SO",
  IE_TA = "IE_TA",
  IE_U = "IE_U",
  IE_WD = "IE_WD",
  IE_WH = "IE_WH",
  IE_WW = "IE_WW",
  IE_WX = "IE_WX",
  IL_D = "IL_D",
  IL_HA = "IL_HA",
  IL_JM = "IL_JM",
  IL_M = "IL_M",
  IL_TA = "IL_TA",
  IL_Z = "IL_Z",
  IN_AN = "IN_AN",
  IN_AP = "IN_AP",
  IN_AR = "IN_AR",
  IN_AS = "IN_AS",
  IN_BR = "IN_BR",
  IN_CH = "IN_CH",
  IN_CT = "IN_CT",
  IN_DH = "IN_DH",
  IN_DL = "IN_DL",
  IN_GA = "IN_GA",
  IN_GJ = "IN_GJ",
  IN_HP = "IN_HP",
  IN_HR = "IN_HR",
  IN_JH = "IN_JH",
  IN_JK = "IN_JK",
  IN_KA = "IN_KA",
  IN_KL = "IN_KL",
  IN_LA = "IN_LA",
  IN_LD = "IN_LD",
  IN_MH = "IN_MH",
  IN_ML = "IN_ML",
  IN_MN = "IN_MN",
  IN_MP = "IN_MP",
  IN_MZ = "IN_MZ",
  IN_NL = "IN_NL",
  IN_OR = "IN_OR",
  IN_PB = "IN_PB",
  IN_PY = "IN_PY",
  IN_RJ = "IN_RJ",
  IN_SK = "IN_SK",
  IN_TG = "IN_TG",
  IN_TN = "IN_TN",
  IN_TR = "IN_TR",
  IN_UP = "IN_UP",
  IN_UT = "IN_UT",
  IN_WB = "IN_WB",
  IQ_AN = "IQ_AN",
  IQ_AR = "IQ_AR",
  IQ_BA = "IQ_BA",
  IQ_BB = "IQ_BB",
  IQ_BG = "IQ_BG",
  IQ_DA = "IQ_DA",
  IQ_DI = "IQ_DI",
  IQ_DQ = "IQ_DQ",
  IQ_KA = "IQ_KA",
  IQ_KI = "IQ_KI",
  IQ_KR = "IQ_KR",
  IQ_MA = "IQ_MA",
  IQ_MU = "IQ_MU",
  IQ_NA = "IQ_NA",
  IQ_NI = "IQ_NI",
  IQ_QA = "IQ_QA",
  IQ_SD = "IQ_SD",
  IQ_SU = "IQ_SU",
  IQ_WA = "IQ_WA",
  IR_00 = "IR_00",
  IR_01 = "IR_01",
  IR_02 = "IR_02",
  IR_03 = "IR_03",
  IR_04 = "IR_04",
  IR_05 = "IR_05",
  IR_06 = "IR_06",
  IR_07 = "IR_07",
  IR_08 = "IR_08",
  IR_09 = "IR_09",
  IR_10 = "IR_10",
  IR_11 = "IR_11",
  IR_12 = "IR_12",
  IR_13 = "IR_13",
  IR_14 = "IR_14",
  IR_15 = "IR_15",
  IR_16 = "IR_16",
  IR_17 = "IR_17",
  IR_18 = "IR_18",
  IR_19 = "IR_19",
  IR_20 = "IR_20",
  IR_21 = "IR_21",
  IR_22 = "IR_22",
  IR_23 = "IR_23",
  IR_24 = "IR_24",
  IR_25 = "IR_25",
  IR_26 = "IR_26",
  IR_27 = "IR_27",
  IR_28 = "IR_28",
  IR_29 = "IR_29",
  IR_30 = "IR_30",
  IS_1 = "IS_1",
  IS_2 = "IS_2",
  IS_3 = "IS_3",
  IS_4 = "IS_4",
  IS_5 = "IS_5",
  IS_6 = "IS_6",
  IS_7 = "IS_7",
  IS_8 = "IS_8",
  IS_AKN = "IS_AKN",
  IS_AKU = "IS_AKU",
  IS_ARN = "IS_ARN",
  IS_ASA = "IS_ASA",
  IS_BLA = "IS_BLA",
  IS_BOG = "IS_BOG",
  IS_BOL = "IS_BOL",
  IS_DAB = "IS_DAB",
  IS_DAV = "IS_DAV",
  IS_EOM = "IS_EOM",
  IS_EYF = "IS_EYF",
  IS_FJD = "IS_FJD",
  IS_FJL = "IS_FJL",
  IS_FLA = "IS_FLA",
  IS_FLR = "IS_FLR",
  IS_GAR = "IS_GAR",
  IS_GOG = "IS_GOG",
  IS_GRN = "IS_GRN",
  IS_GRU = "IS_GRU",
  IS_GRY = "IS_GRY",
  IS_HAF = "IS_HAF",
  IS_HRG = "IS_HRG",
  IS_HRU = "IS_HRU",
  IS_HUG = "IS_HUG",
  IS_HUV = "IS_HUV",
  IS_HVA = "IS_HVA",
  IS_HVE = "IS_HVE",
  IS_ISA = "IS_ISA",
  IS_KAL = "IS_KAL",
  IS_KJO = "IS_KJO",
  IS_KOP = "IS_KOP",
  IS_LAN = "IS_LAN",
  IS_MOS = "IS_MOS",
  IS_MUL = "IS_MUL",
  IS_MYR = "IS_MYR",
  IS_NOR = "IS_NOR",
  IS_RGE = "IS_RGE",
  IS_RGY = "IS_RGY",
  IS_RHH = "IS_RHH",
  IS_RKN = "IS_RKN",
  IS_RKV = "IS_RKV",
  IS_SBT = "IS_SBT",
  IS_SDN = "IS_SDN",
  IS_SDV = "IS_SDV",
  IS_SEL = "IS_SEL",
  IS_SFA = "IS_SFA",
  IS_SHF = "IS_SHF",
  IS_SKF = "IS_SKF",
  IS_SKG = "IS_SKG",
  IS_SKO = "IS_SKO",
  IS_SKR = "IS_SKR",
  IS_SNF = "IS_SNF",
  IS_SOG = "IS_SOG",
  IS_SOL = "IS_SOL",
  IS_SSS = "IS_SSS",
  IS_STR = "IS_STR",
  IS_STY = "IS_STY",
  IS_SVG = "IS_SVG",
  IS_TAL = "IS_TAL",
  IS_THG = "IS_THG",
  IS_TJO = "IS_TJO",
  IS_VEM = "IS_VEM",
  IS_VER = "IS_VER",
  IS_VOP = "IS_VOP",
  IT_21 = "IT_21",
  IT_23 = "IT_23",
  IT_25 = "IT_25",
  IT_32 = "IT_32",
  IT_34 = "IT_34",
  IT_36 = "IT_36",
  IT_42 = "IT_42",
  IT_45 = "IT_45",
  IT_52 = "IT_52",
  IT_55 = "IT_55",
  IT_57 = "IT_57",
  IT_62 = "IT_62",
  IT_65 = "IT_65",
  IT_67 = "IT_67",
  IT_72 = "IT_72",
  IT_75 = "IT_75",
  IT_77 = "IT_77",
  IT_78 = "IT_78",
  IT_82 = "IT_82",
  IT_88 = "IT_88",
  IT_AG = "IT_AG",
  IT_AL = "IT_AL",
  IT_AN = "IT_AN",
  IT_AP = "IT_AP",
  IT_AQ = "IT_AQ",
  IT_AR = "IT_AR",
  IT_AT = "IT_AT",
  IT_AV = "IT_AV",
  IT_BA = "IT_BA",
  IT_BG = "IT_BG",
  IT_BI = "IT_BI",
  IT_BL = "IT_BL",
  IT_BN = "IT_BN",
  IT_BO = "IT_BO",
  IT_BR = "IT_BR",
  IT_BS = "IT_BS",
  IT_BT = "IT_BT",
  IT_BZ = "IT_BZ",
  IT_CA = "IT_CA",
  IT_CB = "IT_CB",
  IT_CE = "IT_CE",
  IT_CH = "IT_CH",
  IT_CL = "IT_CL",
  IT_CN = "IT_CN",
  IT_CO = "IT_CO",
  IT_CR = "IT_CR",
  IT_CS = "IT_CS",
  IT_CT = "IT_CT",
  IT_CZ = "IT_CZ",
  IT_EN = "IT_EN",
  IT_FC = "IT_FC",
  IT_FE = "IT_FE",
  IT_FG = "IT_FG",
  IT_FI = "IT_FI",
  IT_FM = "IT_FM",
  IT_FR = "IT_FR",
  IT_GE = "IT_GE",
  IT_GO = "IT_GO",
  IT_GR = "IT_GR",
  IT_IM = "IT_IM",
  IT_IS = "IT_IS",
  IT_KR = "IT_KR",
  IT_LC = "IT_LC",
  IT_LE = "IT_LE",
  IT_LI = "IT_LI",
  IT_LO = "IT_LO",
  IT_LT = "IT_LT",
  IT_LU = "IT_LU",
  IT_MB = "IT_MB",
  IT_MC = "IT_MC",
  IT_ME = "IT_ME",
  IT_MI = "IT_MI",
  IT_MN = "IT_MN",
  IT_MO = "IT_MO",
  IT_MS = "IT_MS",
  IT_MT = "IT_MT",
  IT_NA = "IT_NA",
  IT_NO = "IT_NO",
  IT_NU = "IT_NU",
  IT_OR = "IT_OR",
  IT_PA = "IT_PA",
  IT_PC = "IT_PC",
  IT_PD = "IT_PD",
  IT_PE = "IT_PE",
  IT_PG = "IT_PG",
  IT_PI = "IT_PI",
  IT_PN = "IT_PN",
  IT_PO = "IT_PO",
  IT_PR = "IT_PR",
  IT_PT = "IT_PT",
  IT_PU = "IT_PU",
  IT_PV = "IT_PV",
  IT_PZ = "IT_PZ",
  IT_RA = "IT_RA",
  IT_RC = "IT_RC",
  IT_RE = "IT_RE",
  IT_RG = "IT_RG",
  IT_RI = "IT_RI",
  IT_RM = "IT_RM",
  IT_RN = "IT_RN",
  IT_RO = "IT_RO",
  IT_SA = "IT_SA",
  IT_SI = "IT_SI",
  IT_SO = "IT_SO",
  IT_SP = "IT_SP",
  IT_SR = "IT_SR",
  IT_SS = "IT_SS",
  IT_SU = "IT_SU",
  IT_SV = "IT_SV",
  IT_TA = "IT_TA",
  IT_TE = "IT_TE",
  IT_TN = "IT_TN",
  IT_TO = "IT_TO",
  IT_TP = "IT_TP",
  IT_TR = "IT_TR",
  IT_TS = "IT_TS",
  IT_TV = "IT_TV",
  IT_UD = "IT_UD",
  IT_VA = "IT_VA",
  IT_VB = "IT_VB",
  IT_VC = "IT_VC",
  IT_VE = "IT_VE",
  IT_VI = "IT_VI",
  IT_VR = "IT_VR",
  IT_VT = "IT_VT",
  IT_VV = "IT_VV",
  JM_01 = "JM_01",
  JM_02 = "JM_02",
  JM_03 = "JM_03",
  JM_04 = "JM_04",
  JM_05 = "JM_05",
  JM_06 = "JM_06",
  JM_07 = "JM_07",
  JM_08 = "JM_08",
  JM_09 = "JM_09",
  JM_10 = "JM_10",
  JM_11 = "JM_11",
  JM_12 = "JM_12",
  JM_13 = "JM_13",
  JM_14 = "JM_14",
  JO_AJ = "JO_AJ",
  JO_AM = "JO_AM",
  JO_AQ = "JO_AQ",
  JO_AT = "JO_AT",
  JO_AZ = "JO_AZ",
  JO_BA = "JO_BA",
  JO_IR = "JO_IR",
  JO_JA = "JO_JA",
  JO_KA = "JO_KA",
  JO_MA = "JO_MA",
  JO_MD = "JO_MD",
  JO_MN = "JO_MN",
  JP_01 = "JP_01",
  JP_02 = "JP_02",
  JP_03 = "JP_03",
  JP_04 = "JP_04",
  JP_05 = "JP_05",
  JP_06 = "JP_06",
  JP_07 = "JP_07",
  JP_08 = "JP_08",
  JP_09 = "JP_09",
  JP_10 = "JP_10",
  JP_11 = "JP_11",
  JP_12 = "JP_12",
  JP_13 = "JP_13",
  JP_14 = "JP_14",
  JP_15 = "JP_15",
  JP_16 = "JP_16",
  JP_17 = "JP_17",
  JP_18 = "JP_18",
  JP_19 = "JP_19",
  JP_20 = "JP_20",
  JP_21 = "JP_21",
  JP_22 = "JP_22",
  JP_23 = "JP_23",
  JP_24 = "JP_24",
  JP_25 = "JP_25",
  JP_26 = "JP_26",
  JP_27 = "JP_27",
  JP_28 = "JP_28",
  JP_29 = "JP_29",
  JP_30 = "JP_30",
  JP_31 = "JP_31",
  JP_32 = "JP_32",
  JP_33 = "JP_33",
  JP_34 = "JP_34",
  JP_35 = "JP_35",
  JP_36 = "JP_36",
  JP_37 = "JP_37",
  JP_38 = "JP_38",
  JP_39 = "JP_39",
  JP_40 = "JP_40",
  JP_41 = "JP_41",
  JP_42 = "JP_42",
  JP_43 = "JP_43",
  JP_44 = "JP_44",
  JP_45 = "JP_45",
  JP_46 = "JP_46",
  JP_47 = "JP_47",
  KE_01 = "KE_01",
  KE_02 = "KE_02",
  KE_03 = "KE_03",
  KE_04 = "KE_04",
  KE_05 = "KE_05",
  KE_06 = "KE_06",
  KE_07 = "KE_07",
  KE_08 = "KE_08",
  KE_09 = "KE_09",
  KE_10 = "KE_10",
  KE_11 = "KE_11",
  KE_12 = "KE_12",
  KE_13 = "KE_13",
  KE_14 = "KE_14",
  KE_15 = "KE_15",
  KE_16 = "KE_16",
  KE_17 = "KE_17",
  KE_18 = "KE_18",
  KE_19 = "KE_19",
  KE_20 = "KE_20",
  KE_21 = "KE_21",
  KE_22 = "KE_22",
  KE_23 = "KE_23",
  KE_24 = "KE_24",
  KE_25 = "KE_25",
  KE_26 = "KE_26",
  KE_27 = "KE_27",
  KE_28 = "KE_28",
  KE_29 = "KE_29",
  KE_30 = "KE_30",
  KE_31 = "KE_31",
  KE_32 = "KE_32",
  KE_33 = "KE_33",
  KE_34 = "KE_34",
  KE_35 = "KE_35",
  KE_36 = "KE_36",
  KE_37 = "KE_37",
  KE_38 = "KE_38",
  KE_39 = "KE_39",
  KE_40 = "KE_40",
  KE_41 = "KE_41",
  KE_42 = "KE_42",
  KE_43 = "KE_43",
  KE_44 = "KE_44",
  KE_45 = "KE_45",
  KE_46 = "KE_46",
  KE_47 = "KE_47",
  KG_B = "KG_B",
  KG_C = "KG_C",
  KG_GB = "KG_GB",
  KG_GO = "KG_GO",
  KG_J = "KG_J",
  KG_N = "KG_N",
  KG_O = "KG_O",
  KG_T = "KG_T",
  KG_Y = "KG_Y",
  KH_1 = "KH_1",
  KH_10 = "KH_10",
  KH_11 = "KH_11",
  KH_12 = "KH_12",
  KH_13 = "KH_13",
  KH_14 = "KH_14",
  KH_15 = "KH_15",
  KH_16 = "KH_16",
  KH_17 = "KH_17",
  KH_18 = "KH_18",
  KH_19 = "KH_19",
  KH_2 = "KH_2",
  KH_20 = "KH_20",
  KH_21 = "KH_21",
  KH_22 = "KH_22",
  KH_23 = "KH_23",
  KH_24 = "KH_24",
  KH_25 = "KH_25",
  KH_3 = "KH_3",
  KH_4 = "KH_4",
  KH_5 = "KH_5",
  KH_6 = "KH_6",
  KH_7 = "KH_7",
  KH_8 = "KH_8",
  KH_9 = "KH_9",
  KI_G = "KI_G",
  KI_L = "KI_L",
  KI_P = "KI_P",
  KM_A = "KM_A",
  KM_G = "KM_G",
  KM_M = "KM_M",
  KN_01 = "KN_01",
  KN_02 = "KN_02",
  KN_03 = "KN_03",
  KN_04 = "KN_04",
  KN_05 = "KN_05",
  KN_06 = "KN_06",
  KN_07 = "KN_07",
  KN_08 = "KN_08",
  KN_09 = "KN_09",
  KN_10 = "KN_10",
  KN_11 = "KN_11",
  KN_12 = "KN_12",
  KN_13 = "KN_13",
  KN_15 = "KN_15",
  KN_K = "KN_K",
  KN_N = "KN_N",
  KP_01 = "KP_01",
  KP_02 = "KP_02",
  KP_03 = "KP_03",
  KP_04 = "KP_04",
  KP_05 = "KP_05",
  KP_06 = "KP_06",
  KP_07 = "KP_07",
  KP_08 = "KP_08",
  KP_09 = "KP_09",
  KP_10 = "KP_10",
  KP_13 = "KP_13",
  KP_14 = "KP_14",
  KP_15 = "KP_15",
  KR_11 = "KR_11",
  KR_26 = "KR_26",
  KR_27 = "KR_27",
  KR_28 = "KR_28",
  KR_29 = "KR_29",
  KR_30 = "KR_30",
  KR_31 = "KR_31",
  KR_41 = "KR_41",
  KR_42 = "KR_42",
  KR_43 = "KR_43",
  KR_44 = "KR_44",
  KR_45 = "KR_45",
  KR_46 = "KR_46",
  KR_47 = "KR_47",
  KR_48 = "KR_48",
  KR_49 = "KR_49",
  KR_50 = "KR_50",
  KW_AH = "KW_AH",
  KW_FA = "KW_FA",
  KW_HA = "KW_HA",
  KW_JA = "KW_JA",
  KW_KU = "KW_KU",
  KW_MU = "KW_MU",
  KZ_10 = "KZ_10",
  KZ_11 = "KZ_11",
  KZ_15 = "KZ_15",
  KZ_19 = "KZ_19",
  KZ_23 = "KZ_23",
  KZ_27 = "KZ_27",
  KZ_31 = "KZ_31",
  KZ_33 = "KZ_33",
  KZ_35 = "KZ_35",
  KZ_39 = "KZ_39",
  KZ_43 = "KZ_43",
  KZ_47 = "KZ_47",
  KZ_55 = "KZ_55",
  KZ_59 = "KZ_59",
  KZ_61 = "KZ_61",
  KZ_62 = "KZ_62",
  KZ_63 = "KZ_63",
  KZ_71 = "KZ_71",
  KZ_75 = "KZ_75",
  KZ_79 = "KZ_79",
  LA_AT = "LA_AT",
  LA_BK = "LA_BK",
  LA_BL = "LA_BL",
  LA_CH = "LA_CH",
  LA_HO = "LA_HO",
  LA_KH = "LA_KH",
  LA_LM = "LA_LM",
  LA_LP = "LA_LP",
  LA_OU = "LA_OU",
  LA_PH = "LA_PH",
  LA_SL = "LA_SL",
  LA_SV = "LA_SV",
  LA_VI = "LA_VI",
  LA_VT = "LA_VT",
  LA_XA = "LA_XA",
  LA_XE = "LA_XE",
  LA_XI = "LA_XI",
  LA_XS = "LA_XS",
  LB_AK = "LB_AK",
  LB_AS = "LB_AS",
  LB_BA = "LB_BA",
  LB_BH = "LB_BH",
  LB_BI = "LB_BI",
  LB_JA = "LB_JA",
  LB_JL = "LB_JL",
  LB_NA = "LB_NA",
  LC_01 = "LC_01",
  LC_02 = "LC_02",
  LC_03 = "LC_03",
  LC_05 = "LC_05",
  LC_06 = "LC_06",
  LC_07 = "LC_07",
  LC_08 = "LC_08",
  LC_10 = "LC_10",
  LC_11 = "LC_11",
  LC_12 = "LC_12",
  LI_01 = "LI_01",
  LI_02 = "LI_02",
  LI_03 = "LI_03",
  LI_04 = "LI_04",
  LI_05 = "LI_05",
  LI_06 = "LI_06",
  LI_07 = "LI_07",
  LI_08 = "LI_08",
  LI_09 = "LI_09",
  LI_10 = "LI_10",
  LI_11 = "LI_11",
  LK_1 = "LK_1",
  LK_11 = "LK_11",
  LK_12 = "LK_12",
  LK_13 = "LK_13",
  LK_2 = "LK_2",
  LK_21 = "LK_21",
  LK_22 = "LK_22",
  LK_23 = "LK_23",
  LK_3 = "LK_3",
  LK_31 = "LK_31",
  LK_32 = "LK_32",
  LK_33 = "LK_33",
  LK_4 = "LK_4",
  LK_41 = "LK_41",
  LK_42 = "LK_42",
  LK_43 = "LK_43",
  LK_44 = "LK_44",
  LK_45 = "LK_45",
  LK_5 = "LK_5",
  LK_51 = "LK_51",
  LK_52 = "LK_52",
  LK_53 = "LK_53",
  LK_6 = "LK_6",
  LK_61 = "LK_61",
  LK_62 = "LK_62",
  LK_7 = "LK_7",
  LK_71 = "LK_71",
  LK_72 = "LK_72",
  LK_8 = "LK_8",
  LK_81 = "LK_81",
  LK_82 = "LK_82",
  LK_9 = "LK_9",
  LK_91 = "LK_91",
  LK_92 = "LK_92",
  LR_BG = "LR_BG",
  LR_BM = "LR_BM",
  LR_CM = "LR_CM",
  LR_GB = "LR_GB",
  LR_GG = "LR_GG",
  LR_GK = "LR_GK",
  LR_GP = "LR_GP",
  LR_LO = "LR_LO",
  LR_MG = "LR_MG",
  LR_MO = "LR_MO",
  LR_MY = "LR_MY",
  LR_NI = "LR_NI",
  LR_RG = "LR_RG",
  LR_RI = "LR_RI",
  LR_SI = "LR_SI",
  LS_A = "LS_A",
  LS_B = "LS_B",
  LS_C = "LS_C",
  LS_D = "LS_D",
  LS_E = "LS_E",
  LS_F = "LS_F",
  LS_G = "LS_G",
  LS_H = "LS_H",
  LS_J = "LS_J",
  LS_K = "LS_K",
  LT_01 = "LT_01",
  LT_02 = "LT_02",
  LT_03 = "LT_03",
  LT_04 = "LT_04",
  LT_05 = "LT_05",
  LT_06 = "LT_06",
  LT_07 = "LT_07",
  LT_08 = "LT_08",
  LT_09 = "LT_09",
  LT_10 = "LT_10",
  LT_11 = "LT_11",
  LT_12 = "LT_12",
  LT_13 = "LT_13",
  LT_14 = "LT_14",
  LT_15 = "LT_15",
  LT_16 = "LT_16",
  LT_17 = "LT_17",
  LT_18 = "LT_18",
  LT_19 = "LT_19",
  LT_20 = "LT_20",
  LT_21 = "LT_21",
  LT_22 = "LT_22",
  LT_23 = "LT_23",
  LT_24 = "LT_24",
  LT_25 = "LT_25",
  LT_26 = "LT_26",
  LT_27 = "LT_27",
  LT_28 = "LT_28",
  LT_29 = "LT_29",
  LT_30 = "LT_30",
  LT_31 = "LT_31",
  LT_32 = "LT_32",
  LT_33 = "LT_33",
  LT_34 = "LT_34",
  LT_35 = "LT_35",
  LT_36 = "LT_36",
  LT_37 = "LT_37",
  LT_38 = "LT_38",
  LT_39 = "LT_39",
  LT_40 = "LT_40",
  LT_41 = "LT_41",
  LT_42 = "LT_42",
  LT_43 = "LT_43",
  LT_44 = "LT_44",
  LT_45 = "LT_45",
  LT_46 = "LT_46",
  LT_47 = "LT_47",
  LT_48 = "LT_48",
  LT_49 = "LT_49",
  LT_50 = "LT_50",
  LT_51 = "LT_51",
  LT_52 = "LT_52",
  LT_53 = "LT_53",
  LT_54 = "LT_54",
  LT_55 = "LT_55",
  LT_56 = "LT_56",
  LT_57 = "LT_57",
  LT_58 = "LT_58",
  LT_59 = "LT_59",
  LT_60 = "LT_60",
  LT_AL = "LT_AL",
  LT_KL = "LT_KL",
  LT_KU = "LT_KU",
  LT_MR = "LT_MR",
  LT_PN = "LT_PN",
  LT_SA = "LT_SA",
  LT_TA = "LT_TA",
  LT_TE = "LT_TE",
  LT_UT = "LT_UT",
  LT_VL = "LT_VL",
  LU_CA = "LU_CA",
  LU_CL = "LU_CL",
  LU_D = "LU_D",
  LU_DI = "LU_DI",
  LU_EC = "LU_EC",
  LU_ES = "LU_ES",
  LU_G = "LU_G",
  LU_GR = "LU_GR",
  LU_L = "LU_L",
  LU_LU = "LU_LU",
  LU_ME = "LU_ME",
  LU_RD = "LU_RD",
  LU_RM = "LU_RM",
  LU_VD = "LU_VD",
  LU_WI = "LU_WI",
  LV_002 = "LV_002",
  LV_007 = "LV_007",
  LV_011 = "LV_011",
  LV_015 = "LV_015",
  LV_016 = "LV_016",
  LV_022 = "LV_022",
  LV_026 = "LV_026",
  LV_033 = "LV_033",
  LV_041 = "LV_041",
  LV_042 = "LV_042",
  LV_047 = "LV_047",
  LV_050 = "LV_050",
  LV_052 = "LV_052",
  LV_054 = "LV_054",
  LV_056 = "LV_056",
  LV_058 = "LV_058",
  LV_059 = "LV_059",
  LV_062 = "LV_062",
  LV_067 = "LV_067",
  LV_068 = "LV_068",
  LV_073 = "LV_073",
  LV_077 = "LV_077",
  LV_080 = "LV_080",
  LV_087 = "LV_087",
  LV_088 = "LV_088",
  LV_089 = "LV_089",
  LV_091 = "LV_091",
  LV_094 = "LV_094",
  LV_097 = "LV_097",
  LV_099 = "LV_099",
  LV_101 = "LV_101",
  LV_102 = "LV_102",
  LV_106 = "LV_106",
  LV_111 = "LV_111",
  LV_112 = "LV_112",
  LV_113 = "LV_113",
  LV_DGV = "LV_DGV",
  LV_JEL = "LV_JEL",
  LV_JUR = "LV_JUR",
  LV_LPX = "LV_LPX",
  LV_REZ = "LV_REZ",
  LV_RIX = "LV_RIX",
  LV_VEN = "LV_VEN",
  LY_BA = "LY_BA",
  LY_BU = "LY_BU",
  LY_DR = "LY_DR",
  LY_GT = "LY_GT",
  LY_JA = "LY_JA",
  LY_JG = "LY_JG",
  LY_JI = "LY_JI",
  LY_JU = "LY_JU",
  LY_KF = "LY_KF",
  LY_MB = "LY_MB",
  LY_MI = "LY_MI",
  LY_MJ = "LY_MJ",
  LY_MQ = "LY_MQ",
  LY_NL = "LY_NL",
  LY_NQ = "LY_NQ",
  LY_SB = "LY_SB",
  LY_SR = "LY_SR",
  LY_TB = "LY_TB",
  LY_WA = "LY_WA",
  LY_WD = "LY_WD",
  LY_WS = "LY_WS",
  LY_ZA = "LY_ZA",
  MA_01 = "MA_01",
  MA_02 = "MA_02",
  MA_03 = "MA_03",
  MA_04 = "MA_04",
  MA_05 = "MA_05",
  MA_06 = "MA_06",
  MA_07 = "MA_07",
  MA_08 = "MA_08",
  MA_09 = "MA_09",
  MA_10 = "MA_10",
  MA_11 = "MA_11",
  MA_12 = "MA_12",
  MA_AGD = "MA_AGD",
  MA_AOU = "MA_AOU",
  MA_ASZ = "MA_ASZ",
  MA_AZI = "MA_AZI",
  MA_BEM = "MA_BEM",
  MA_BER = "MA_BER",
  MA_BES = "MA_BES",
  MA_BOD = "MA_BOD",
  MA_BOM = "MA_BOM",
  MA_BRR = "MA_BRR",
  MA_CAS = "MA_CAS",
  MA_CHE = "MA_CHE",
  MA_CHI = "MA_CHI",
  MA_CHT = "MA_CHT",
  MA_DRI = "MA_DRI",
  MA_ERR = "MA_ERR",
  MA_ESI = "MA_ESI",
  MA_ESM = "MA_ESM",
  MA_FAH = "MA_FAH",
  MA_FES = "MA_FES",
  MA_FIG = "MA_FIG",
  MA_FQH = "MA_FQH",
  MA_GUE = "MA_GUE",
  MA_GUF = "MA_GUF",
  MA_HAJ = "MA_HAJ",
  MA_HAO = "MA_HAO",
  MA_HOC = "MA_HOC",
  MA_IFR = "MA_IFR",
  MA_INE = "MA_INE",
  MA_JDI = "MA_JDI",
  MA_JRA = "MA_JRA",
  MA_KEN = "MA_KEN",
  MA_KES = "MA_KES",
  MA_KHE = "MA_KHE",
  MA_KHN = "MA_KHN",
  MA_KHO = "MA_KHO",
  MA_LAA = "MA_LAA",
  MA_LAR = "MA_LAR",
  MA_MAR = "MA_MAR",
  MA_MDF = "MA_MDF",
  MA_MED = "MA_MED",
  MA_MEK = "MA_MEK",
  MA_MID = "MA_MID",
  MA_MOH = "MA_MOH",
  MA_MOU = "MA_MOU",
  MA_NAD = "MA_NAD",
  MA_NOU = "MA_NOU",
  MA_OUA = "MA_OUA",
  MA_OUD = "MA_OUD",
  MA_OUJ = "MA_OUJ",
  MA_OUZ = "MA_OUZ",
  MA_RAB = "MA_RAB",
  MA_REH = "MA_REH",
  MA_SAF = "MA_SAF",
  MA_SAL = "MA_SAL",
  MA_SEF = "MA_SEF",
  MA_SET = "MA_SET",
  MA_SIB = "MA_SIB",
  MA_SIF = "MA_SIF",
  MA_SIK = "MA_SIK",
  MA_SIL = "MA_SIL",
  MA_SKH = "MA_SKH",
  MA_TAF = "MA_TAF",
  MA_TAI = "MA_TAI",
  MA_TAO = "MA_TAO",
  MA_TAR = "MA_TAR",
  MA_TAT = "MA_TAT",
  MA_TAZ = "MA_TAZ",
  MA_TET = "MA_TET",
  MA_TIN = "MA_TIN",
  MA_TIZ = "MA_TIZ",
  MA_TNG = "MA_TNG",
  MA_TNT = "MA_TNT",
  MA_YUS = "MA_YUS",
  MA_ZAG = "MA_ZAG",
  MC_CL = "MC_CL",
  MC_CO = "MC_CO",
  MC_FO = "MC_FO",
  MC_GA = "MC_GA",
  MC_JE = "MC_JE",
  MC_LA = "MC_LA",
  MC_MA = "MC_MA",
  MC_MC = "MC_MC",
  MC_MG = "MC_MG",
  MC_MO = "MC_MO",
  MC_MU = "MC_MU",
  MC_PH = "MC_PH",
  MC_SD = "MC_SD",
  MC_SO = "MC_SO",
  MC_SP = "MC_SP",
  MC_SR = "MC_SR",
  MC_VR = "MC_VR",
  MD_AN = "MD_AN",
  MD_BA = "MD_BA",
  MD_BD = "MD_BD",
  MD_BR = "MD_BR",
  MD_BS = "MD_BS",
  MD_CA = "MD_CA",
  MD_CL = "MD_CL",
  MD_CM = "MD_CM",
  MD_CR = "MD_CR",
  MD_CS = "MD_CS",
  MD_CT = "MD_CT",
  MD_CU = "MD_CU",
  MD_DO = "MD_DO",
  MD_DR = "MD_DR",
  MD_DU = "MD_DU",
  MD_ED = "MD_ED",
  MD_FA = "MD_FA",
  MD_FL = "MD_FL",
  MD_GA = "MD_GA",
  MD_GL = "MD_GL",
  MD_HI = "MD_HI",
  MD_IA = "MD_IA",
  MD_LE = "MD_LE",
  MD_NI = "MD_NI",
  MD_OC = "MD_OC",
  MD_OR = "MD_OR",
  MD_RE = "MD_RE",
  MD_RI = "MD_RI",
  MD_SD = "MD_SD",
  MD_SI = "MD_SI",
  MD_SN = "MD_SN",
  MD_SO = "MD_SO",
  MD_ST = "MD_ST",
  MD_SV = "MD_SV",
  MD_TA = "MD_TA",
  MD_TE = "MD_TE",
  MD_UN = "MD_UN",
  ME_01 = "ME_01",
  ME_02 = "ME_02",
  ME_03 = "ME_03",
  ME_04 = "ME_04",
  ME_05 = "ME_05",
  ME_06 = "ME_06",
  ME_07 = "ME_07",
  ME_08 = "ME_08",
  ME_09 = "ME_09",
  ME_10 = "ME_10",
  ME_11 = "ME_11",
  ME_12 = "ME_12",
  ME_13 = "ME_13",
  ME_14 = "ME_14",
  ME_15 = "ME_15",
  ME_16 = "ME_16",
  ME_17 = "ME_17",
  ME_18 = "ME_18",
  ME_19 = "ME_19",
  ME_20 = "ME_20",
  ME_21 = "ME_21",
  ME_22 = "ME_22",
  ME_23 = "ME_23",
  ME_24 = "ME_24",
  MG_A = "MG_A",
  MG_D = "MG_D",
  MG_F = "MG_F",
  MG_M = "MG_M",
  MG_T = "MG_T",
  MG_U = "MG_U",
  MH_ALK = "MH_ALK",
  MH_ALL = "MH_ALL",
  MH_ARN = "MH_ARN",
  MH_AUR = "MH_AUR",
  MH_EBO = "MH_EBO",
  MH_ENI = "MH_ENI",
  MH_JAB = "MH_JAB",
  MH_JAL = "MH_JAL",
  MH_KIL = "MH_KIL",
  MH_KWA = "MH_KWA",
  MH_L = "MH_L",
  MH_LAE = "MH_LAE",
  MH_LIB = "MH_LIB",
  MH_LIK = "MH_LIK",
  MH_MAJ = "MH_MAJ",
  MH_MAL = "MH_MAL",
  MH_MEJ = "MH_MEJ",
  MH_MIL = "MH_MIL",
  MH_NMK = "MH_NMK",
  MH_NMU = "MH_NMU",
  MH_RON = "MH_RON",
  MH_T = "MH_T",
  MH_UJA = "MH_UJA",
  MH_UTI = "MH_UTI",
  MH_WTH = "MH_WTH",
  MH_WTJ = "MH_WTJ",
  MK_101 = "MK_101",
  MK_102 = "MK_102",
  MK_103 = "MK_103",
  MK_104 = "MK_104",
  MK_105 = "MK_105",
  MK_106 = "MK_106",
  MK_107 = "MK_107",
  MK_108 = "MK_108",
  MK_109 = "MK_109",
  MK_201 = "MK_201",
  MK_202 = "MK_202",
  MK_203 = "MK_203",
  MK_204 = "MK_204",
  MK_205 = "MK_205",
  MK_206 = "MK_206",
  MK_207 = "MK_207",
  MK_208 = "MK_208",
  MK_209 = "MK_209",
  MK_210 = "MK_210",
  MK_211 = "MK_211",
  MK_301 = "MK_301",
  MK_303 = "MK_303",
  MK_304 = "MK_304",
  MK_307 = "MK_307",
  MK_308 = "MK_308",
  MK_310 = "MK_310",
  MK_311 = "MK_311",
  MK_312 = "MK_312",
  MK_313 = "MK_313",
  MK_401 = "MK_401",
  MK_402 = "MK_402",
  MK_403 = "MK_403",
  MK_404 = "MK_404",
  MK_405 = "MK_405",
  MK_406 = "MK_406",
  MK_407 = "MK_407",
  MK_408 = "MK_408",
  MK_409 = "MK_409",
  MK_410 = "MK_410",
  MK_501 = "MK_501",
  MK_502 = "MK_502",
  MK_503 = "MK_503",
  MK_504 = "MK_504",
  MK_505 = "MK_505",
  MK_506 = "MK_506",
  MK_507 = "MK_507",
  MK_508 = "MK_508",
  MK_509 = "MK_509",
  MK_601 = "MK_601",
  MK_602 = "MK_602",
  MK_603 = "MK_603",
  MK_604 = "MK_604",
  MK_605 = "MK_605",
  MK_606 = "MK_606",
  MK_607 = "MK_607",
  MK_608 = "MK_608",
  MK_609 = "MK_609",
  MK_701 = "MK_701",
  MK_702 = "MK_702",
  MK_703 = "MK_703",
  MK_704 = "MK_704",
  MK_705 = "MK_705",
  MK_706 = "MK_706",
  MK_801 = "MK_801",
  MK_802 = "MK_802",
  MK_803 = "MK_803",
  MK_804 = "MK_804",
  MK_805 = "MK_805",
  MK_806 = "MK_806",
  MK_807 = "MK_807",
  MK_808 = "MK_808",
  MK_809 = "MK_809",
  MK_810 = "MK_810",
  MK_811 = "MK_811",
  MK_812 = "MK_812",
  MK_813 = "MK_813",
  MK_814 = "MK_814",
  MK_815 = "MK_815",
  MK_816 = "MK_816",
  MK_817 = "MK_817",
  ML_1 = "ML_1",
  ML_10 = "ML_10",
  ML_2 = "ML_2",
  ML_3 = "ML_3",
  ML_4 = "ML_4",
  ML_5 = "ML_5",
  ML_6 = "ML_6",
  ML_7 = "ML_7",
  ML_8 = "ML_8",
  ML_9 = "ML_9",
  ML_BKO = "ML_BKO",
  MM_01 = "MM_01",
  MM_02 = "MM_02",
  MM_03 = "MM_03",
  MM_04 = "MM_04",
  MM_05 = "MM_05",
  MM_06 = "MM_06",
  MM_07 = "MM_07",
  MM_11 = "MM_11",
  MM_12 = "MM_12",
  MM_13 = "MM_13",
  MM_14 = "MM_14",
  MM_15 = "MM_15",
  MM_16 = "MM_16",
  MM_17 = "MM_17",
  MM_18 = "MM_18",
  MN_035 = "MN_035",
  MN_037 = "MN_037",
  MN_039 = "MN_039",
  MN_041 = "MN_041",
  MN_043 = "MN_043",
  MN_046 = "MN_046",
  MN_047 = "MN_047",
  MN_049 = "MN_049",
  MN_051 = "MN_051",
  MN_053 = "MN_053",
  MN_055 = "MN_055",
  MN_057 = "MN_057",
  MN_059 = "MN_059",
  MN_061 = "MN_061",
  MN_063 = "MN_063",
  MN_064 = "MN_064",
  MN_065 = "MN_065",
  MN_067 = "MN_067",
  MN_069 = "MN_069",
  MN_071 = "MN_071",
  MN_073 = "MN_073",
  MN_1 = "MN_1",
  MR_01 = "MR_01",
  MR_02 = "MR_02",
  MR_03 = "MR_03",
  MR_04 = "MR_04",
  MR_05 = "MR_05",
  MR_06 = "MR_06",
  MR_07 = "MR_07",
  MR_08 = "MR_08",
  MR_09 = "MR_09",
  MR_10 = "MR_10",
  MR_11 = "MR_11",
  MR_12 = "MR_12",
  MR_13 = "MR_13",
  MR_14 = "MR_14",
  MR_15 = "MR_15",
  MT_01 = "MT_01",
  MT_02 = "MT_02",
  MT_03 = "MT_03",
  MT_04 = "MT_04",
  MT_05 = "MT_05",
  MT_06 = "MT_06",
  MT_07 = "MT_07",
  MT_08 = "MT_08",
  MT_09 = "MT_09",
  MT_10 = "MT_10",
  MT_11 = "MT_11",
  MT_12 = "MT_12",
  MT_13 = "MT_13",
  MT_14 = "MT_14",
  MT_15 = "MT_15",
  MT_16 = "MT_16",
  MT_17 = "MT_17",
  MT_18 = "MT_18",
  MT_19 = "MT_19",
  MT_20 = "MT_20",
  MT_21 = "MT_21",
  MT_22 = "MT_22",
  MT_23 = "MT_23",
  MT_24 = "MT_24",
  MT_25 = "MT_25",
  MT_26 = "MT_26",
  MT_27 = "MT_27",
  MT_28 = "MT_28",
  MT_29 = "MT_29",
  MT_30 = "MT_30",
  MT_31 = "MT_31",
  MT_32 = "MT_32",
  MT_33 = "MT_33",
  MT_34 = "MT_34",
  MT_35 = "MT_35",
  MT_36 = "MT_36",
  MT_37 = "MT_37",
  MT_38 = "MT_38",
  MT_39 = "MT_39",
  MT_40 = "MT_40",
  MT_41 = "MT_41",
  MT_42 = "MT_42",
  MT_43 = "MT_43",
  MT_44 = "MT_44",
  MT_45 = "MT_45",
  MT_46 = "MT_46",
  MT_47 = "MT_47",
  MT_48 = "MT_48",
  MT_49 = "MT_49",
  MT_50 = "MT_50",
  MT_51 = "MT_51",
  MT_52 = "MT_52",
  MT_53 = "MT_53",
  MT_54 = "MT_54",
  MT_55 = "MT_55",
  MT_56 = "MT_56",
  MT_57 = "MT_57",
  MT_58 = "MT_58",
  MT_59 = "MT_59",
  MT_60 = "MT_60",
  MT_61 = "MT_61",
  MT_62 = "MT_62",
  MT_63 = "MT_63",
  MT_64 = "MT_64",
  MT_65 = "MT_65",
  MT_66 = "MT_66",
  MT_67 = "MT_67",
  MT_68 = "MT_68",
  MU_AG = "MU_AG",
  MU_BL = "MU_BL",
  MU_CC = "MU_CC",
  MU_FL = "MU_FL",
  MU_GP = "MU_GP",
  MU_MO = "MU_MO",
  MU_PA = "MU_PA",
  MU_PL = "MU_PL",
  MU_PW = "MU_PW",
  MU_RO = "MU_RO",
  MU_RR = "MU_RR",
  MU_SA = "MU_SA",
  MV_00 = "MV_00",
  MV_01 = "MV_01",
  MV_02 = "MV_02",
  MV_03 = "MV_03",
  MV_04 = "MV_04",
  MV_05 = "MV_05",
  MV_07 = "MV_07",
  MV_08 = "MV_08",
  MV_12 = "MV_12",
  MV_13 = "MV_13",
  MV_14 = "MV_14",
  MV_17 = "MV_17",
  MV_20 = "MV_20",
  MV_23 = "MV_23",
  MV_24 = "MV_24",
  MV_25 = "MV_25",
  MV_26 = "MV_26",
  MV_27 = "MV_27",
  MV_28 = "MV_28",
  MV_29 = "MV_29",
  MV_MLE = "MV_MLE",
  MW_BA = "MW_BA",
  MW_BL = "MW_BL",
  MW_C = "MW_C",
  MW_CK = "MW_CK",
  MW_CR = "MW_CR",
  MW_CT = "MW_CT",
  MW_DE = "MW_DE",
  MW_DO = "MW_DO",
  MW_KR = "MW_KR",
  MW_KS = "MW_KS",
  MW_LI = "MW_LI",
  MW_LK = "MW_LK",
  MW_MC = "MW_MC",
  MW_MG = "MW_MG",
  MW_MH = "MW_MH",
  MW_MU = "MW_MU",
  MW_MW = "MW_MW",
  MW_MZ = "MW_MZ",
  MW_N = "MW_N",
  MW_NB = "MW_NB",
  MW_NE = "MW_NE",
  MW_NI = "MW_NI",
  MW_NK = "MW_NK",
  MW_NS = "MW_NS",
  MW_NU = "MW_NU",
  MW_PH = "MW_PH",
  MW_RU = "MW_RU",
  MW_S = "MW_S",
  MW_SA = "MW_SA",
  MW_TH = "MW_TH",
  MW_ZO = "MW_ZO",
  MX_AGU = "MX_AGU",
  MX_BCN = "MX_BCN",
  MX_BCS = "MX_BCS",
  MX_CAM = "MX_CAM",
  MX_CHH = "MX_CHH",
  MX_CHP = "MX_CHP",
  MX_CMX = "MX_CMX",
  MX_COA = "MX_COA",
  MX_COL = "MX_COL",
  MX_DUR = "MX_DUR",
  MX_GRO = "MX_GRO",
  MX_GUA = "MX_GUA",
  MX_HID = "MX_HID",
  MX_JAL = "MX_JAL",
  MX_MEX = "MX_MEX",
  MX_MIC = "MX_MIC",
  MX_MOR = "MX_MOR",
  MX_NAY = "MX_NAY",
  MX_NLE = "MX_NLE",
  MX_OAX = "MX_OAX",
  MX_PUE = "MX_PUE",
  MX_QUE = "MX_QUE",
  MX_ROO = "MX_ROO",
  MX_SIN = "MX_SIN",
  MX_SLP = "MX_SLP",
  MX_SON = "MX_SON",
  MX_TAB = "MX_TAB",
  MX_TAM = "MX_TAM",
  MX_TLA = "MX_TLA",
  MX_VER = "MX_VER",
  MX_YUC = "MX_YUC",
  MX_ZAC = "MX_ZAC",
  MY_01 = "MY_01",
  MY_02 = "MY_02",
  MY_03 = "MY_03",
  MY_04 = "MY_04",
  MY_05 = "MY_05",
  MY_06 = "MY_06",
  MY_07 = "MY_07",
  MY_08 = "MY_08",
  MY_09 = "MY_09",
  MY_10 = "MY_10",
  MY_11 = "MY_11",
  MY_12 = "MY_12",
  MY_13 = "MY_13",
  MY_14 = "MY_14",
  MY_15 = "MY_15",
  MY_16 = "MY_16",
  MZ_A = "MZ_A",
  MZ_B = "MZ_B",
  MZ_G = "MZ_G",
  MZ_I = "MZ_I",
  MZ_L = "MZ_L",
  MZ_MPM = "MZ_MPM",
  MZ_N = "MZ_N",
  MZ_P = "MZ_P",
  MZ_Q = "MZ_Q",
  MZ_S = "MZ_S",
  MZ_T = "MZ_T",
  NA_CA = "NA_CA",
  NA_ER = "NA_ER",
  NA_HA = "NA_HA",
  NA_KA = "NA_KA",
  NA_KE = "NA_KE",
  NA_KH = "NA_KH",
  NA_KU = "NA_KU",
  NA_KW = "NA_KW",
  NA_OD = "NA_OD",
  NA_OH = "NA_OH",
  NA_ON = "NA_ON",
  NA_OS = "NA_OS",
  NA_OT = "NA_OT",
  NA_OW = "NA_OW",
  NE_1 = "NE_1",
  NE_2 = "NE_2",
  NE_3 = "NE_3",
  NE_4 = "NE_4",
  NE_5 = "NE_5",
  NE_6 = "NE_6",
  NE_7 = "NE_7",
  NE_8 = "NE_8",
  NG_AB = "NG_AB",
  NG_AD = "NG_AD",
  NG_AK = "NG_AK",
  NG_AN = "NG_AN",
  NG_BA = "NG_BA",
  NG_BE = "NG_BE",
  NG_BO = "NG_BO",
  NG_BY = "NG_BY",
  NG_CR = "NG_CR",
  NG_DE = "NG_DE",
  NG_EB = "NG_EB",
  NG_ED = "NG_ED",
  NG_EK = "NG_EK",
  NG_EN = "NG_EN",
  NG_FC = "NG_FC",
  NG_GO = "NG_GO",
  NG_IM = "NG_IM",
  NG_JI = "NG_JI",
  NG_KD = "NG_KD",
  NG_KE = "NG_KE",
  NG_KN = "NG_KN",
  NG_KO = "NG_KO",
  NG_KT = "NG_KT",
  NG_KW = "NG_KW",
  NG_LA = "NG_LA",
  NG_NA = "NG_NA",
  NG_NI = "NG_NI",
  NG_OG = "NG_OG",
  NG_ON = "NG_ON",
  NG_OS = "NG_OS",
  NG_OY = "NG_OY",
  NG_PL = "NG_PL",
  NG_RI = "NG_RI",
  NG_SO = "NG_SO",
  NG_TA = "NG_TA",
  NG_YO = "NG_YO",
  NG_ZA = "NG_ZA",
  NI_AN = "NI_AN",
  NI_AS = "NI_AS",
  NI_BO = "NI_BO",
  NI_CA = "NI_CA",
  NI_CI = "NI_CI",
  NI_CO = "NI_CO",
  NI_ES = "NI_ES",
  NI_GR = "NI_GR",
  NI_JI = "NI_JI",
  NI_LE = "NI_LE",
  NI_MD = "NI_MD",
  NI_MN = "NI_MN",
  NI_MS = "NI_MS",
  NI_MT = "NI_MT",
  NI_NS = "NI_NS",
  NI_RI = "NI_RI",
  NI_SJ = "NI_SJ",
  NL_AW = "NL_AW",
  NL_BQ1 = "NL_BQ1",
  NL_BQ2 = "NL_BQ2",
  NL_BQ3 = "NL_BQ3",
  NL_CW = "NL_CW",
  NL_DR = "NL_DR",
  NL_FL = "NL_FL",
  NL_FR = "NL_FR",
  NL_GE = "NL_GE",
  NL_GR = "NL_GR",
  NL_LI = "NL_LI",
  NL_NB = "NL_NB",
  NL_NH = "NL_NH",
  NL_OV = "NL_OV",
  NL_SX = "NL_SX",
  NL_UT = "NL_UT",
  NL_ZE = "NL_ZE",
  NL_ZH = "NL_ZH",
  NO_03 = "NO_03",
  NO_11 = "NO_11",
  NO_15 = "NO_15",
  NO_18 = "NO_18",
  NO_21 = "NO_21",
  NO_22 = "NO_22",
  NO_30 = "NO_30",
  NO_34 = "NO_34",
  NO_38 = "NO_38",
  NO_42 = "NO_42",
  NO_46 = "NO_46",
  NO_50 = "NO_50",
  NO_54 = "NO_54",
  NP_P1 = "NP_P1",
  NP_P2 = "NP_P2",
  NP_P3 = "NP_P3",
  NP_P4 = "NP_P4",
  NP_P5 = "NP_P5",
  NP_P6 = "NP_P6",
  NP_P7 = "NP_P7",
  NR_01 = "NR_01",
  NR_02 = "NR_02",
  NR_03 = "NR_03",
  NR_04 = "NR_04",
  NR_05 = "NR_05",
  NR_06 = "NR_06",
  NR_07 = "NR_07",
  NR_08 = "NR_08",
  NR_09 = "NR_09",
  NR_10 = "NR_10",
  NR_11 = "NR_11",
  NR_12 = "NR_12",
  NR_13 = "NR_13",
  NR_14 = "NR_14",
  NZ_AUK = "NZ_AUK",
  NZ_BOP = "NZ_BOP",
  NZ_CAN = "NZ_CAN",
  NZ_CIT = "NZ_CIT",
  NZ_GIS = "NZ_GIS",
  NZ_HKB = "NZ_HKB",
  NZ_MBH = "NZ_MBH",
  NZ_MWT = "NZ_MWT",
  NZ_NSN = "NZ_NSN",
  NZ_NTL = "NZ_NTL",
  NZ_OTA = "NZ_OTA",
  NZ_STL = "NZ_STL",
  NZ_TAS = "NZ_TAS",
  NZ_TKI = "NZ_TKI",
  NZ_WGN = "NZ_WGN",
  NZ_WKO = "NZ_WKO",
  NZ_WTC = "NZ_WTC",
  OM_BJ = "OM_BJ",
  OM_BS = "OM_BS",
  OM_BU = "OM_BU",
  OM_DA = "OM_DA",
  OM_MA = "OM_MA",
  OM_MU = "OM_MU",
  OM_SJ = "OM_SJ",
  OM_SS = "OM_SS",
  OM_WU = "OM_WU",
  OM_ZA = "OM_ZA",
  OM_ZU = "OM_ZU",
  PA_1 = "PA_1",
  PA_10 = "PA_10",
  PA_2 = "PA_2",
  PA_3 = "PA_3",
  PA_4 = "PA_4",
  PA_5 = "PA_5",
  PA_6 = "PA_6",
  PA_7 = "PA_7",
  PA_8 = "PA_8",
  PA_9 = "PA_9",
  PA_EM = "PA_EM",
  PA_KY = "PA_KY",
  PA_NB = "PA_NB",
  PA_NT = "PA_NT",
  PE_AMA = "PE_AMA",
  PE_ANC = "PE_ANC",
  PE_APU = "PE_APU",
  PE_ARE = "PE_ARE",
  PE_AYA = "PE_AYA",
  PE_CAJ = "PE_CAJ",
  PE_CAL = "PE_CAL",
  PE_CUS = "PE_CUS",
  PE_HUC = "PE_HUC",
  PE_HUV = "PE_HUV",
  PE_ICA = "PE_ICA",
  PE_JUN = "PE_JUN",
  PE_LAL = "PE_LAL",
  PE_LAM = "PE_LAM",
  PE_LIM = "PE_LIM",
  PE_LMA = "PE_LMA",
  PE_LOR = "PE_LOR",
  PE_MDD = "PE_MDD",
  PE_MOQ = "PE_MOQ",
  PE_PAS = "PE_PAS",
  PE_PIU = "PE_PIU",
  PE_PUN = "PE_PUN",
  PE_SAM = "PE_SAM",
  PE_TAC = "PE_TAC",
  PE_TUM = "PE_TUM",
  PE_UCA = "PE_UCA",
  PG_CPK = "PG_CPK",
  PG_CPM = "PG_CPM",
  PG_EBR = "PG_EBR",
  PG_EHG = "PG_EHG",
  PG_EPW = "PG_EPW",
  PG_ESW = "PG_ESW",
  PG_GPK = "PG_GPK",
  PG_HLA = "PG_HLA",
  PG_JWK = "PG_JWK",
  PG_MBA = "PG_MBA",
  PG_MPL = "PG_MPL",
  PG_MPM = "PG_MPM",
  PG_MRL = "PG_MRL",
  PG_NCD = "PG_NCD",
  PG_NIK = "PG_NIK",
  PG_NPP = "PG_NPP",
  PG_NSB = "PG_NSB",
  PG_SAN = "PG_SAN",
  PG_SHM = "PG_SHM",
  PG_WBK = "PG_WBK",
  PG_WHM = "PG_WHM",
  PG_WPD = "PG_WPD",
  PH_00 = "PH_00",
  PH_01 = "PH_01",
  PH_02 = "PH_02",
  PH_03 = "PH_03",
  PH_05 = "PH_05",
  PH_06 = "PH_06",
  PH_07 = "PH_07",
  PH_08 = "PH_08",
  PH_09 = "PH_09",
  PH_10 = "PH_10",
  PH_11 = "PH_11",
  PH_12 = "PH_12",
  PH_13 = "PH_13",
  PH_14 = "PH_14",
  PH_15 = "PH_15",
  PH_40 = "PH_40",
  PH_41 = "PH_41",
  PH_ABR = "PH_ABR",
  PH_AGN = "PH_AGN",
  PH_AGS = "PH_AGS",
  PH_AKL = "PH_AKL",
  PH_ALB = "PH_ALB",
  PH_ANT = "PH_ANT",
  PH_APA = "PH_APA",
  PH_AUR = "PH_AUR",
  PH_BAN = "PH_BAN",
  PH_BAS = "PH_BAS",
  PH_BEN = "PH_BEN",
  PH_BIL = "PH_BIL",
  PH_BOH = "PH_BOH",
  PH_BTG = "PH_BTG",
  PH_BTN = "PH_BTN",
  PH_BUK = "PH_BUK",
  PH_BUL = "PH_BUL",
  PH_CAG = "PH_CAG",
  PH_CAM = "PH_CAM",
  PH_CAN = "PH_CAN",
  PH_CAP = "PH_CAP",
  PH_CAS = "PH_CAS",
  PH_CAT = "PH_CAT",
  PH_CAV = "PH_CAV",
  PH_CEB = "PH_CEB",
  PH_COM = "PH_COM",
  PH_DAO = "PH_DAO",
  PH_DAS = "PH_DAS",
  PH_DAV = "PH_DAV",
  PH_DIN = "PH_DIN",
  PH_DVO = "PH_DVO",
  PH_EAS = "PH_EAS",
  PH_GUI = "PH_GUI",
  PH_IFU = "PH_IFU",
  PH_ILI = "PH_ILI",
  PH_ILN = "PH_ILN",
  PH_ILS = "PH_ILS",
  PH_ISA = "PH_ISA",
  PH_KAL = "PH_KAL",
  PH_LAG = "PH_LAG",
  PH_LAN = "PH_LAN",
  PH_LAS = "PH_LAS",
  PH_LEY = "PH_LEY",
  PH_LUN = "PH_LUN",
  PH_MAD = "PH_MAD",
  PH_MAG = "PH_MAG",
  PH_MAS = "PH_MAS",
  PH_MDC = "PH_MDC",
  PH_MDR = "PH_MDR",
  PH_MOU = "PH_MOU",
  PH_MSC = "PH_MSC",
  PH_MSR = "PH_MSR",
  PH_NCO = "PH_NCO",
  PH_NEC = "PH_NEC",
  PH_NER = "PH_NER",
  PH_NSA = "PH_NSA",
  PH_NUE = "PH_NUE",
  PH_NUV = "PH_NUV",
  PH_PAM = "PH_PAM",
  PH_PAN = "PH_PAN",
  PH_PLW = "PH_PLW",
  PH_QUE = "PH_QUE",
  PH_QUI = "PH_QUI",
  PH_RIZ = "PH_RIZ",
  PH_ROM = "PH_ROM",
  PH_SAR = "PH_SAR",
  PH_SCO = "PH_SCO",
  PH_SIG = "PH_SIG",
  PH_SLE = "PH_SLE",
  PH_SLU = "PH_SLU",
  PH_SOR = "PH_SOR",
  PH_SUK = "PH_SUK",
  PH_SUN = "PH_SUN",
  PH_SUR = "PH_SUR",
  PH_TAR = "PH_TAR",
  PH_TAW = "PH_TAW",
  PH_WSA = "PH_WSA",
  PH_ZAN = "PH_ZAN",
  PH_ZAS = "PH_ZAS",
  PH_ZMB = "PH_ZMB",
  PH_ZSI = "PH_ZSI",
  PK_BA = "PK_BA",
  PK_GB = "PK_GB",
  PK_IS = "PK_IS",
  PK_JK = "PK_JK",
  PK_KP = "PK_KP",
  PK_PB = "PK_PB",
  PK_SD = "PK_SD",
  PK_TA = "PK_TA",
  PL_02 = "PL_02",
  PL_04 = "PL_04",
  PL_06 = "PL_06",
  PL_08 = "PL_08",
  PL_10 = "PL_10",
  PL_12 = "PL_12",
  PL_14 = "PL_14",
  PL_16 = "PL_16",
  PL_18 = "PL_18",
  PL_20 = "PL_20",
  PL_22 = "PL_22",
  PL_24 = "PL_24",
  PL_26 = "PL_26",
  PL_28 = "PL_28",
  PL_30 = "PL_30",
  PL_32 = "PL_32",
  PS_BTH = "PS_BTH",
  PS_DEB = "PS_DEB",
  PS_GZA = "PS_GZA",
  PS_HBN = "PS_HBN",
  PS_JEM = "PS_JEM",
  PS_JEN = "PS_JEN",
  PS_JRH = "PS_JRH",
  PS_KYS = "PS_KYS",
  PS_NBS = "PS_NBS",
  PS_NGZ = "PS_NGZ",
  PS_QQA = "PS_QQA",
  PS_RBH = "PS_RBH",
  PS_RFH = "PS_RFH",
  PS_SLT = "PS_SLT",
  PS_TBS = "PS_TBS",
  PS_TKM = "PS_TKM",
  PT_01 = "PT_01",
  PT_02 = "PT_02",
  PT_03 = "PT_03",
  PT_04 = "PT_04",
  PT_05 = "PT_05",
  PT_06 = "PT_06",
  PT_07 = "PT_07",
  PT_08 = "PT_08",
  PT_09 = "PT_09",
  PT_10 = "PT_10",
  PT_11 = "PT_11",
  PT_12 = "PT_12",
  PT_13 = "PT_13",
  PT_14 = "PT_14",
  PT_15 = "PT_15",
  PT_16 = "PT_16",
  PT_17 = "PT_17",
  PT_18 = "PT_18",
  PT_20 = "PT_20",
  PT_30 = "PT_30",
  PW_002 = "PW_002",
  PW_004 = "PW_004",
  PW_010 = "PW_010",
  PW_050 = "PW_050",
  PW_100 = "PW_100",
  PW_150 = "PW_150",
  PW_212 = "PW_212",
  PW_214 = "PW_214",
  PW_218 = "PW_218",
  PW_222 = "PW_222",
  PW_224 = "PW_224",
  PW_226 = "PW_226",
  PW_227 = "PW_227",
  PW_228 = "PW_228",
  PW_350 = "PW_350",
  PW_370 = "PW_370",
  PY_1 = "PY_1",
  PY_10 = "PY_10",
  PY_11 = "PY_11",
  PY_12 = "PY_12",
  PY_13 = "PY_13",
  PY_14 = "PY_14",
  PY_15 = "PY_15",
  PY_16 = "PY_16",
  PY_19 = "PY_19",
  PY_2 = "PY_2",
  PY_3 = "PY_3",
  PY_4 = "PY_4",
  PY_5 = "PY_5",
  PY_6 = "PY_6",
  PY_7 = "PY_7",
  PY_8 = "PY_8",
  PY_9 = "PY_9",
  PY_ASU = "PY_ASU",
  QA_DA = "QA_DA",
  QA_KH = "QA_KH",
  QA_MS = "QA_MS",
  QA_RA = "QA_RA",
  QA_SH = "QA_SH",
  QA_US = "QA_US",
  QA_WA = "QA_WA",
  QA_ZA = "QA_ZA",
  RO_AB = "RO_AB",
  RO_AG = "RO_AG",
  RO_AR = "RO_AR",
  RO_B = "RO_B",
  RO_BC = "RO_BC",
  RO_BH = "RO_BH",
  RO_BN = "RO_BN",
  RO_BR = "RO_BR",
  RO_BT = "RO_BT",
  RO_BV = "RO_BV",
  RO_BZ = "RO_BZ",
  RO_CJ = "RO_CJ",
  RO_CL = "RO_CL",
  RO_CS = "RO_CS",
  RO_CT = "RO_CT",
  RO_CV = "RO_CV",
  RO_DB = "RO_DB",
  RO_DJ = "RO_DJ",
  RO_GJ = "RO_GJ",
  RO_GL = "RO_GL",
  RO_GR = "RO_GR",
  RO_HD = "RO_HD",
  RO_HR = "RO_HR",
  RO_IF = "RO_IF",
  RO_IL = "RO_IL",
  RO_IS = "RO_IS",
  RO_MH = "RO_MH",
  RO_MM = "RO_MM",
  RO_MS = "RO_MS",
  RO_NT = "RO_NT",
  RO_OT = "RO_OT",
  RO_PH = "RO_PH",
  RO_SB = "RO_SB",
  RO_SJ = "RO_SJ",
  RO_SM = "RO_SM",
  RO_SV = "RO_SV",
  RO_TL = "RO_TL",
  RO_TM = "RO_TM",
  RO_TR = "RO_TR",
  RO_VL = "RO_VL",
  RO_VN = "RO_VN",
  RO_VS = "RO_VS",
  RS_00 = "RS_00",
  RS_01 = "RS_01",
  RS_02 = "RS_02",
  RS_03 = "RS_03",
  RS_04 = "RS_04",
  RS_05 = "RS_05",
  RS_06 = "RS_06",
  RS_07 = "RS_07",
  RS_08 = "RS_08",
  RS_09 = "RS_09",
  RS_10 = "RS_10",
  RS_11 = "RS_11",
  RS_12 = "RS_12",
  RS_13 = "RS_13",
  RS_14 = "RS_14",
  RS_15 = "RS_15",
  RS_16 = "RS_16",
  RS_17 = "RS_17",
  RS_18 = "RS_18",
  RS_19 = "RS_19",
  RS_20 = "RS_20",
  RS_21 = "RS_21",
  RS_22 = "RS_22",
  RS_23 = "RS_23",
  RS_24 = "RS_24",
  RS_25 = "RS_25",
  RS_26 = "RS_26",
  RS_27 = "RS_27",
  RS_28 = "RS_28",
  RS_29 = "RS_29",
  RS_KM = "RS_KM",
  RS_VO = "RS_VO",
  RU_AD = "RU_AD",
  RU_AL = "RU_AL",
  RU_ALT = "RU_ALT",
  RU_AMU = "RU_AMU",
  RU_ARK = "RU_ARK",
  RU_AST = "RU_AST",
  RU_BA = "RU_BA",
  RU_BEL = "RU_BEL",
  RU_BRY = "RU_BRY",
  RU_BU = "RU_BU",
  RU_CE = "RU_CE",
  RU_CHE = "RU_CHE",
  RU_CHU = "RU_CHU",
  RU_CU = "RU_CU",
  RU_DA = "RU_DA",
  RU_IN = "RU_IN",
  RU_IRK = "RU_IRK",
  RU_IVA = "RU_IVA",
  RU_KAM = "RU_KAM",
  RU_KB = "RU_KB",
  RU_KC = "RU_KC",
  RU_KDA = "RU_KDA",
  RU_KEM = "RU_KEM",
  RU_KGD = "RU_KGD",
  RU_KGN = "RU_KGN",
  RU_KHA = "RU_KHA",
  RU_KHM = "RU_KHM",
  RU_KIR = "RU_KIR",
  RU_KK = "RU_KK",
  RU_KL = "RU_KL",
  RU_KLU = "RU_KLU",
  RU_KO = "RU_KO",
  RU_KOS = "RU_KOS",
  RU_KR = "RU_KR",
  RU_KRS = "RU_KRS",
  RU_KYA = "RU_KYA",
  RU_LEN = "RU_LEN",
  RU_LIP = "RU_LIP",
  RU_MAG = "RU_MAG",
  RU_ME = "RU_ME",
  RU_MO = "RU_MO",
  RU_MOS = "RU_MOS",
  RU_MOW = "RU_MOW",
  RU_MUR = "RU_MUR",
  RU_NEN = "RU_NEN",
  RU_NGR = "RU_NGR",
  RU_NIZ = "RU_NIZ",
  RU_NVS = "RU_NVS",
  RU_OMS = "RU_OMS",
  RU_ORE = "RU_ORE",
  RU_ORL = "RU_ORL",
  RU_PER = "RU_PER",
  RU_PNZ = "RU_PNZ",
  RU_PRI = "RU_PRI",
  RU_PSK = "RU_PSK",
  RU_ROS = "RU_ROS",
  RU_RYA = "RU_RYA",
  RU_SA = "RU_SA",
  RU_SAK = "RU_SAK",
  RU_SAM = "RU_SAM",
  RU_SAR = "RU_SAR",
  RU_SE = "RU_SE",
  RU_SMO = "RU_SMO",
  RU_SPE = "RU_SPE",
  RU_STA = "RU_STA",
  RU_SVE = "RU_SVE",
  RU_TA = "RU_TA",
  RU_TAM = "RU_TAM",
  RU_TOM = "RU_TOM",
  RU_TUL = "RU_TUL",
  RU_TVE = "RU_TVE",
  RU_TY = "RU_TY",
  RU_TYU = "RU_TYU",
  RU_UD = "RU_UD",
  RU_ULY = "RU_ULY",
  RU_VGG = "RU_VGG",
  RU_VLA = "RU_VLA",
  RU_VLG = "RU_VLG",
  RU_VOR = "RU_VOR",
  RU_YAN = "RU_YAN",
  RU_YAR = "RU_YAR",
  RU_YEV = "RU_YEV",
  RU_ZAB = "RU_ZAB",
  RW_01 = "RW_01",
  RW_02 = "RW_02",
  RW_03 = "RW_03",
  RW_04 = "RW_04",
  RW_05 = "RW_05",
  SA_01 = "SA_01",
  SA_02 = "SA_02",
  SA_03 = "SA_03",
  SA_04 = "SA_04",
  SA_05 = "SA_05",
  SA_06 = "SA_06",
  SA_07 = "SA_07",
  SA_08 = "SA_08",
  SA_09 = "SA_09",
  SA_10 = "SA_10",
  SA_11 = "SA_11",
  SA_12 = "SA_12",
  SA_14 = "SA_14",
  SB_CE = "SB_CE",
  SB_CH = "SB_CH",
  SB_CT = "SB_CT",
  SB_GU = "SB_GU",
  SB_IS = "SB_IS",
  SB_MK = "SB_MK",
  SB_ML = "SB_ML",
  SB_RB = "SB_RB",
  SB_TE = "SB_TE",
  SB_WE = "SB_WE",
  SC_01 = "SC_01",
  SC_02 = "SC_02",
  SC_03 = "SC_03",
  SC_04 = "SC_04",
  SC_05 = "SC_05",
  SC_06 = "SC_06",
  SC_07 = "SC_07",
  SC_08 = "SC_08",
  SC_09 = "SC_09",
  SC_10 = "SC_10",
  SC_11 = "SC_11",
  SC_12 = "SC_12",
  SC_13 = "SC_13",
  SC_14 = "SC_14",
  SC_15 = "SC_15",
  SC_16 = "SC_16",
  SC_17 = "SC_17",
  SC_18 = "SC_18",
  SC_19 = "SC_19",
  SC_20 = "SC_20",
  SC_21 = "SC_21",
  SC_22 = "SC_22",
  SC_23 = "SC_23",
  SC_24 = "SC_24",
  SC_25 = "SC_25",
  SC_26 = "SC_26",
  SC_27 = "SC_27",
  SD_DC = "SD_DC",
  SD_DE = "SD_DE",
  SD_DN = "SD_DN",
  SD_DS = "SD_DS",
  SD_DW = "SD_DW",
  SD_GD = "SD_GD",
  SD_GK = "SD_GK",
  SD_GZ = "SD_GZ",
  SD_KA = "SD_KA",
  SD_KH = "SD_KH",
  SD_KN = "SD_KN",
  SD_KS = "SD_KS",
  SD_NB = "SD_NB",
  SD_NO = "SD_NO",
  SD_NR = "SD_NR",
  SD_NW = "SD_NW",
  SD_RS = "SD_RS",
  SD_SI = "SD_SI",
  SE_AB = "SE_AB",
  SE_AC = "SE_AC",
  SE_BD = "SE_BD",
  SE_C = "SE_C",
  SE_D = "SE_D",
  SE_E = "SE_E",
  SE_F = "SE_F",
  SE_G = "SE_G",
  SE_H = "SE_H",
  SE_I = "SE_I",
  SE_K = "SE_K",
  SE_M = "SE_M",
  SE_N = "SE_N",
  SE_O = "SE_O",
  SE_S = "SE_S",
  SE_T = "SE_T",
  SE_U = "SE_U",
  SE_W = "SE_W",
  SE_X = "SE_X",
  SE_Y = "SE_Y",
  SE_Z = "SE_Z",
  SG_01 = "SG_01",
  SG_02 = "SG_02",
  SG_03 = "SG_03",
  SG_04 = "SG_04",
  SG_05 = "SG_05",
  SH_AC = "SH_AC",
  SH_HL = "SH_HL",
  SH_TA = "SH_TA",
  SI_001 = "SI_001",
  SI_002 = "SI_002",
  SI_003 = "SI_003",
  SI_004 = "SI_004",
  SI_005 = "SI_005",
  SI_006 = "SI_006",
  SI_007 = "SI_007",
  SI_008 = "SI_008",
  SI_009 = "SI_009",
  SI_010 = "SI_010",
  SI_011 = "SI_011",
  SI_012 = "SI_012",
  SI_013 = "SI_013",
  SI_014 = "SI_014",
  SI_015 = "SI_015",
  SI_016 = "SI_016",
  SI_017 = "SI_017",
  SI_018 = "SI_018",
  SI_019 = "SI_019",
  SI_020 = "SI_020",
  SI_021 = "SI_021",
  SI_022 = "SI_022",
  SI_023 = "SI_023",
  SI_024 = "SI_024",
  SI_025 = "SI_025",
  SI_026 = "SI_026",
  SI_027 = "SI_027",
  SI_028 = "SI_028",
  SI_029 = "SI_029",
  SI_030 = "SI_030",
  SI_031 = "SI_031",
  SI_032 = "SI_032",
  SI_033 = "SI_033",
  SI_034 = "SI_034",
  SI_035 = "SI_035",
  SI_036 = "SI_036",
  SI_037 = "SI_037",
  SI_038 = "SI_038",
  SI_039 = "SI_039",
  SI_040 = "SI_040",
  SI_041 = "SI_041",
  SI_042 = "SI_042",
  SI_043 = "SI_043",
  SI_044 = "SI_044",
  SI_045 = "SI_045",
  SI_046 = "SI_046",
  SI_047 = "SI_047",
  SI_048 = "SI_048",
  SI_049 = "SI_049",
  SI_050 = "SI_050",
  SI_051 = "SI_051",
  SI_052 = "SI_052",
  SI_053 = "SI_053",
  SI_054 = "SI_054",
  SI_055 = "SI_055",
  SI_056 = "SI_056",
  SI_057 = "SI_057",
  SI_058 = "SI_058",
  SI_059 = "SI_059",
  SI_060 = "SI_060",
  SI_061 = "SI_061",
  SI_062 = "SI_062",
  SI_063 = "SI_063",
  SI_064 = "SI_064",
  SI_065 = "SI_065",
  SI_066 = "SI_066",
  SI_067 = "SI_067",
  SI_068 = "SI_068",
  SI_069 = "SI_069",
  SI_070 = "SI_070",
  SI_071 = "SI_071",
  SI_072 = "SI_072",
  SI_073 = "SI_073",
  SI_074 = "SI_074",
  SI_075 = "SI_075",
  SI_076 = "SI_076",
  SI_077 = "SI_077",
  SI_078 = "SI_078",
  SI_079 = "SI_079",
  SI_080 = "SI_080",
  SI_081 = "SI_081",
  SI_082 = "SI_082",
  SI_083 = "SI_083",
  SI_084 = "SI_084",
  SI_085 = "SI_085",
  SI_086 = "SI_086",
  SI_087 = "SI_087",
  SI_088 = "SI_088",
  SI_089 = "SI_089",
  SI_090 = "SI_090",
  SI_091 = "SI_091",
  SI_092 = "SI_092",
  SI_093 = "SI_093",
  SI_094 = "SI_094",
  SI_095 = "SI_095",
  SI_096 = "SI_096",
  SI_097 = "SI_097",
  SI_098 = "SI_098",
  SI_099 = "SI_099",
  SI_100 = "SI_100",
  SI_101 = "SI_101",
  SI_102 = "SI_102",
  SI_103 = "SI_103",
  SI_104 = "SI_104",
  SI_105 = "SI_105",
  SI_106 = "SI_106",
  SI_107 = "SI_107",
  SI_108 = "SI_108",
  SI_109 = "SI_109",
  SI_110 = "SI_110",
  SI_111 = "SI_111",
  SI_112 = "SI_112",
  SI_113 = "SI_113",
  SI_114 = "SI_114",
  SI_115 = "SI_115",
  SI_116 = "SI_116",
  SI_117 = "SI_117",
  SI_118 = "SI_118",
  SI_119 = "SI_119",
  SI_120 = "SI_120",
  SI_121 = "SI_121",
  SI_122 = "SI_122",
  SI_123 = "SI_123",
  SI_124 = "SI_124",
  SI_125 = "SI_125",
  SI_126 = "SI_126",
  SI_127 = "SI_127",
  SI_128 = "SI_128",
  SI_129 = "SI_129",
  SI_130 = "SI_130",
  SI_131 = "SI_131",
  SI_132 = "SI_132",
  SI_133 = "SI_133",
  SI_134 = "SI_134",
  SI_135 = "SI_135",
  SI_136 = "SI_136",
  SI_137 = "SI_137",
  SI_138 = "SI_138",
  SI_139 = "SI_139",
  SI_140 = "SI_140",
  SI_141 = "SI_141",
  SI_142 = "SI_142",
  SI_143 = "SI_143",
  SI_144 = "SI_144",
  SI_146 = "SI_146",
  SI_147 = "SI_147",
  SI_148 = "SI_148",
  SI_149 = "SI_149",
  SI_150 = "SI_150",
  SI_151 = "SI_151",
  SI_152 = "SI_152",
  SI_153 = "SI_153",
  SI_154 = "SI_154",
  SI_155 = "SI_155",
  SI_156 = "SI_156",
  SI_157 = "SI_157",
  SI_158 = "SI_158",
  SI_159 = "SI_159",
  SI_160 = "SI_160",
  SI_161 = "SI_161",
  SI_162 = "SI_162",
  SI_163 = "SI_163",
  SI_164 = "SI_164",
  SI_165 = "SI_165",
  SI_166 = "SI_166",
  SI_167 = "SI_167",
  SI_168 = "SI_168",
  SI_169 = "SI_169",
  SI_170 = "SI_170",
  SI_171 = "SI_171",
  SI_172 = "SI_172",
  SI_173 = "SI_173",
  SI_174 = "SI_174",
  SI_175 = "SI_175",
  SI_176 = "SI_176",
  SI_177 = "SI_177",
  SI_178 = "SI_178",
  SI_179 = "SI_179",
  SI_180 = "SI_180",
  SI_181 = "SI_181",
  SI_182 = "SI_182",
  SI_183 = "SI_183",
  SI_184 = "SI_184",
  SI_185 = "SI_185",
  SI_186 = "SI_186",
  SI_187 = "SI_187",
  SI_188 = "SI_188",
  SI_189 = "SI_189",
  SI_190 = "SI_190",
  SI_191 = "SI_191",
  SI_192 = "SI_192",
  SI_193 = "SI_193",
  SI_194 = "SI_194",
  SI_195 = "SI_195",
  SI_196 = "SI_196",
  SI_197 = "SI_197",
  SI_198 = "SI_198",
  SI_199 = "SI_199",
  SI_200 = "SI_200",
  SI_201 = "SI_201",
  SI_202 = "SI_202",
  SI_203 = "SI_203",
  SI_204 = "SI_204",
  SI_205 = "SI_205",
  SI_206 = "SI_206",
  SI_207 = "SI_207",
  SI_208 = "SI_208",
  SI_209 = "SI_209",
  SI_210 = "SI_210",
  SI_211 = "SI_211",
  SI_212 = "SI_212",
  SI_213 = "SI_213",
  SK_BC = "SK_BC",
  SK_BL = "SK_BL",
  SK_KI = "SK_KI",
  SK_NI = "SK_NI",
  SK_PV = "SK_PV",
  SK_TA = "SK_TA",
  SK_TC = "SK_TC",
  SK_ZI = "SK_ZI",
  SL_E = "SL_E",
  SL_N = "SL_N",
  SL_NW = "SL_NW",
  SL_S = "SL_S",
  SL_W = "SL_W",
  SM_01 = "SM_01",
  SM_02 = "SM_02",
  SM_03 = "SM_03",
  SM_04 = "SM_04",
  SM_05 = "SM_05",
  SM_06 = "SM_06",
  SM_07 = "SM_07",
  SM_08 = "SM_08",
  SM_09 = "SM_09",
  SN_DB = "SN_DB",
  SN_DK = "SN_DK",
  SN_FK = "SN_FK",
  SN_KA = "SN_KA",
  SN_KD = "SN_KD",
  SN_KE = "SN_KE",
  SN_KL = "SN_KL",
  SN_LG = "SN_LG",
  SN_MT = "SN_MT",
  SN_SE = "SN_SE",
  SN_SL = "SN_SL",
  SN_TC = "SN_TC",
  SN_TH = "SN_TH",
  SN_ZG = "SN_ZG",
  SO_AW = "SO_AW",
  SO_BK = "SO_BK",
  SO_BN = "SO_BN",
  SO_BR = "SO_BR",
  SO_BY = "SO_BY",
  SO_GA = "SO_GA",
  SO_GE = "SO_GE",
  SO_HI = "SO_HI",
  SO_JD = "SO_JD",
  SO_JH = "SO_JH",
  SO_MU = "SO_MU",
  SO_NU = "SO_NU",
  SO_SA = "SO_SA",
  SO_SD = "SO_SD",
  SO_SH = "SO_SH",
  SO_SO = "SO_SO",
  SO_TO = "SO_TO",
  SO_WO = "SO_WO",
  SR_BR = "SR_BR",
  SR_CM = "SR_CM",
  SR_CR = "SR_CR",
  SR_MA = "SR_MA",
  SR_NI = "SR_NI",
  SR_PM = "SR_PM",
  SR_PR = "SR_PR",
  SR_SA = "SR_SA",
  SR_SI = "SR_SI",
  SR_WA = "SR_WA",
  SS_BN = "SS_BN",
  SS_BW = "SS_BW",
  SS_EC = "SS_EC",
  SS_EE = "SS_EE",
  SS_EW = "SS_EW",
  SS_JG = "SS_JG",
  SS_LK = "SS_LK",
  SS_NU = "SS_NU",
  SS_UY = "SS_UY",
  SS_WR = "SS_WR",
  ST_01 = "ST_01",
  ST_02 = "ST_02",
  ST_03 = "ST_03",
  ST_04 = "ST_04",
  ST_05 = "ST_05",
  ST_06 = "ST_06",
  ST_P = "ST_P",
  SV_AH = "SV_AH",
  SV_CA = "SV_CA",
  SV_CH = "SV_CH",
  SV_CU = "SV_CU",
  SV_LI = "SV_LI",
  SV_MO = "SV_MO",
  SV_PA = "SV_PA",
  SV_SA = "SV_SA",
  SV_SM = "SV_SM",
  SV_SO = "SV_SO",
  SV_SS = "SV_SS",
  SV_SV = "SV_SV",
  SV_UN = "SV_UN",
  SV_US = "SV_US",
  SY_DI = "SY_DI",
  SY_DR = "SY_DR",
  SY_DY = "SY_DY",
  SY_HA = "SY_HA",
  SY_HI = "SY_HI",
  SY_HL = "SY_HL",
  SY_HM = "SY_HM",
  SY_ID = "SY_ID",
  SY_LA = "SY_LA",
  SY_QU = "SY_QU",
  SY_RA = "SY_RA",
  SY_RD = "SY_RD",
  SY_SU = "SY_SU",
  SY_TA = "SY_TA",
  SZ_HH = "SZ_HH",
  SZ_LU = "SZ_LU",
  SZ_MA = "SZ_MA",
  SZ_SH = "SZ_SH",
  TD_BA = "TD_BA",
  TD_BG = "TD_BG",
  TD_BO = "TD_BO",
  TD_CB = "TD_CB",
  TD_EE = "TD_EE",
  TD_EO = "TD_EO",
  TD_GR = "TD_GR",
  TD_HL = "TD_HL",
  TD_KA = "TD_KA",
  TD_LC = "TD_LC",
  TD_LO = "TD_LO",
  TD_LR = "TD_LR",
  TD_MA = "TD_MA",
  TD_MC = "TD_MC",
  TD_ME = "TD_ME",
  TD_MO = "TD_MO",
  TD_ND = "TD_ND",
  TD_OD = "TD_OD",
  TD_SA = "TD_SA",
  TD_SI = "TD_SI",
  TD_TA = "TD_TA",
  TD_TI = "TD_TI",
  TD_WF = "TD_WF",
  TG_C = "TG_C",
  TG_K = "TG_K",
  TG_M = "TG_M",
  TG_P = "TG_P",
  TG_S = "TG_S",
  TH_10 = "TH_10",
  TH_11 = "TH_11",
  TH_12 = "TH_12",
  TH_13 = "TH_13",
  TH_14 = "TH_14",
  TH_15 = "TH_15",
  TH_16 = "TH_16",
  TH_17 = "TH_17",
  TH_18 = "TH_18",
  TH_19 = "TH_19",
  TH_20 = "TH_20",
  TH_21 = "TH_21",
  TH_22 = "TH_22",
  TH_23 = "TH_23",
  TH_24 = "TH_24",
  TH_25 = "TH_25",
  TH_26 = "TH_26",
  TH_27 = "TH_27",
  TH_30 = "TH_30",
  TH_31 = "TH_31",
  TH_32 = "TH_32",
  TH_33 = "TH_33",
  TH_34 = "TH_34",
  TH_35 = "TH_35",
  TH_36 = "TH_36",
  TH_37 = "TH_37",
  TH_38 = "TH_38",
  TH_39 = "TH_39",
  TH_40 = "TH_40",
  TH_41 = "TH_41",
  TH_42 = "TH_42",
  TH_43 = "TH_43",
  TH_44 = "TH_44",
  TH_45 = "TH_45",
  TH_46 = "TH_46",
  TH_47 = "TH_47",
  TH_48 = "TH_48",
  TH_49 = "TH_49",
  TH_50 = "TH_50",
  TH_51 = "TH_51",
  TH_52 = "TH_52",
  TH_53 = "TH_53",
  TH_54 = "TH_54",
  TH_55 = "TH_55",
  TH_56 = "TH_56",
  TH_57 = "TH_57",
  TH_58 = "TH_58",
  TH_60 = "TH_60",
  TH_61 = "TH_61",
  TH_62 = "TH_62",
  TH_63 = "TH_63",
  TH_64 = "TH_64",
  TH_65 = "TH_65",
  TH_66 = "TH_66",
  TH_67 = "TH_67",
  TH_70 = "TH_70",
  TH_71 = "TH_71",
  TH_72 = "TH_72",
  TH_73 = "TH_73",
  TH_74 = "TH_74",
  TH_75 = "TH_75",
  TH_76 = "TH_76",
  TH_77 = "TH_77",
  TH_80 = "TH_80",
  TH_81 = "TH_81",
  TH_82 = "TH_82",
  TH_83 = "TH_83",
  TH_84 = "TH_84",
  TH_85 = "TH_85",
  TH_86 = "TH_86",
  TH_90 = "TH_90",
  TH_91 = "TH_91",
  TH_92 = "TH_92",
  TH_93 = "TH_93",
  TH_94 = "TH_94",
  TH_95 = "TH_95",
  TH_96 = "TH_96",
  TH_S = "TH_S",
  TJ_DU = "TJ_DU",
  TJ_GB = "TJ_GB",
  TJ_KT = "TJ_KT",
  TJ_RA = "TJ_RA",
  TJ_SU = "TJ_SU",
  TL_AL = "TL_AL",
  TL_AN = "TL_AN",
  TL_BA = "TL_BA",
  TL_BO = "TL_BO",
  TL_CO = "TL_CO",
  TL_DI = "TL_DI",
  TL_ER = "TL_ER",
  TL_LA = "TL_LA",
  TL_LI = "TL_LI",
  TL_MF = "TL_MF",
  TL_MT = "TL_MT",
  TL_OE = "TL_OE",
  TL_VI = "TL_VI",
  TM_A = "TM_A",
  TM_B = "TM_B",
  TM_D = "TM_D",
  TM_L = "TM_L",
  TM_M = "TM_M",
  TM_S = "TM_S",
  TN_11 = "TN_11",
  TN_12 = "TN_12",
  TN_13 = "TN_13",
  TN_14 = "TN_14",
  TN_21 = "TN_21",
  TN_22 = "TN_22",
  TN_23 = "TN_23",
  TN_31 = "TN_31",
  TN_32 = "TN_32",
  TN_33 = "TN_33",
  TN_34 = "TN_34",
  TN_41 = "TN_41",
  TN_42 = "TN_42",
  TN_43 = "TN_43",
  TN_51 = "TN_51",
  TN_52 = "TN_52",
  TN_53 = "TN_53",
  TN_61 = "TN_61",
  TN_71 = "TN_71",
  TN_72 = "TN_72",
  TN_73 = "TN_73",
  TN_81 = "TN_81",
  TN_82 = "TN_82",
  TN_83 = "TN_83",
  TO_01 = "TO_01",
  TO_02 = "TO_02",
  TO_03 = "TO_03",
  TO_04 = "TO_04",
  TO_05 = "TO_05",
  TR_01 = "TR_01",
  TR_02 = "TR_02",
  TR_03 = "TR_03",
  TR_04 = "TR_04",
  TR_05 = "TR_05",
  TR_06 = "TR_06",
  TR_07 = "TR_07",
  TR_08 = "TR_08",
  TR_09 = "TR_09",
  TR_10 = "TR_10",
  TR_11 = "TR_11",
  TR_12 = "TR_12",
  TR_13 = "TR_13",
  TR_14 = "TR_14",
  TR_15 = "TR_15",
  TR_16 = "TR_16",
  TR_17 = "TR_17",
  TR_18 = "TR_18",
  TR_19 = "TR_19",
  TR_20 = "TR_20",
  TR_21 = "TR_21",
  TR_22 = "TR_22",
  TR_23 = "TR_23",
  TR_24 = "TR_24",
  TR_25 = "TR_25",
  TR_26 = "TR_26",
  TR_27 = "TR_27",
  TR_28 = "TR_28",
  TR_29 = "TR_29",
  TR_30 = "TR_30",
  TR_31 = "TR_31",
  TR_32 = "TR_32",
  TR_33 = "TR_33",
  TR_34 = "TR_34",
  TR_35 = "TR_35",
  TR_36 = "TR_36",
  TR_37 = "TR_37",
  TR_38 = "TR_38",
  TR_39 = "TR_39",
  TR_40 = "TR_40",
  TR_41 = "TR_41",
  TR_42 = "TR_42",
  TR_43 = "TR_43",
  TR_44 = "TR_44",
  TR_45 = "TR_45",
  TR_46 = "TR_46",
  TR_47 = "TR_47",
  TR_48 = "TR_48",
  TR_49 = "TR_49",
  TR_50 = "TR_50",
  TR_51 = "TR_51",
  TR_52 = "TR_52",
  TR_53 = "TR_53",
  TR_54 = "TR_54",
  TR_55 = "TR_55",
  TR_56 = "TR_56",
  TR_57 = "TR_57",
  TR_58 = "TR_58",
  TR_59 = "TR_59",
  TR_60 = "TR_60",
  TR_61 = "TR_61",
  TR_62 = "TR_62",
  TR_63 = "TR_63",
  TR_64 = "TR_64",
  TR_65 = "TR_65",
  TR_66 = "TR_66",
  TR_67 = "TR_67",
  TR_68 = "TR_68",
  TR_69 = "TR_69",
  TR_70 = "TR_70",
  TR_71 = "TR_71",
  TR_72 = "TR_72",
  TR_73 = "TR_73",
  TR_74 = "TR_74",
  TR_75 = "TR_75",
  TR_76 = "TR_76",
  TR_77 = "TR_77",
  TR_78 = "TR_78",
  TR_79 = "TR_79",
  TR_80 = "TR_80",
  TR_81 = "TR_81",
  TT_ARI = "TT_ARI",
  TT_CHA = "TT_CHA",
  TT_CTT = "TT_CTT",
  TT_DMN = "TT_DMN",
  TT_MRC = "TT_MRC",
  TT_PED = "TT_PED",
  TT_POS = "TT_POS",
  TT_PRT = "TT_PRT",
  TT_PTF = "TT_PTF",
  TT_SFO = "TT_SFO",
  TT_SGE = "TT_SGE",
  TT_SIP = "TT_SIP",
  TT_SJL = "TT_SJL",
  TT_TOB = "TT_TOB",
  TT_TUP = "TT_TUP",
  TV_FUN = "TV_FUN",
  TV_NIT = "TV_NIT",
  TV_NKF = "TV_NKF",
  TV_NKL = "TV_NKL",
  TV_NMA = "TV_NMA",
  TV_NMG = "TV_NMG",
  TV_NUI = "TV_NUI",
  TV_VAI = "TV_VAI",
  TW_CHA = "TW_CHA",
  TW_CYI = "TW_CYI",
  TW_CYQ = "TW_CYQ",
  TW_HSQ = "TW_HSQ",
  TW_HSZ = "TW_HSZ",
  TW_HUA = "TW_HUA",
  TW_ILA = "TW_ILA",
  TW_KEE = "TW_KEE",
  TW_KHH = "TW_KHH",
  TW_KIN = "TW_KIN",
  TW_LIE = "TW_LIE",
  TW_MIA = "TW_MIA",
  TW_NAN = "TW_NAN",
  TW_NWT = "TW_NWT",
  TW_PEN = "TW_PEN",
  TW_PIF = "TW_PIF",
  TW_TAO = "TW_TAO",
  TW_TNN = "TW_TNN",
  TW_TPE = "TW_TPE",
  TW_TTT = "TW_TTT",
  TW_TXG = "TW_TXG",
  TW_YUN = "TW_YUN",
  TZ_01 = "TZ_01",
  TZ_02 = "TZ_02",
  TZ_03 = "TZ_03",
  TZ_04 = "TZ_04",
  TZ_05 = "TZ_05",
  TZ_06 = "TZ_06",
  TZ_07 = "TZ_07",
  TZ_08 = "TZ_08",
  TZ_09 = "TZ_09",
  TZ_10 = "TZ_10",
  TZ_11 = "TZ_11",
  TZ_12 = "TZ_12",
  TZ_13 = "TZ_13",
  TZ_14 = "TZ_14",
  TZ_15 = "TZ_15",
  TZ_16 = "TZ_16",
  TZ_17 = "TZ_17",
  TZ_18 = "TZ_18",
  TZ_19 = "TZ_19",
  TZ_20 = "TZ_20",
  TZ_21 = "TZ_21",
  TZ_22 = "TZ_22",
  TZ_23 = "TZ_23",
  TZ_24 = "TZ_24",
  TZ_25 = "TZ_25",
  TZ_26 = "TZ_26",
  TZ_27 = "TZ_27",
  TZ_28 = "TZ_28",
  TZ_29 = "TZ_29",
  TZ_30 = "TZ_30",
  TZ_31 = "TZ_31",
  UA_05 = "UA_05",
  UA_07 = "UA_07",
  UA_09 = "UA_09",
  UA_12 = "UA_12",
  UA_14 = "UA_14",
  UA_18 = "UA_18",
  UA_21 = "UA_21",
  UA_23 = "UA_23",
  UA_26 = "UA_26",
  UA_30 = "UA_30",
  UA_32 = "UA_32",
  UA_35 = "UA_35",
  UA_40 = "UA_40",
  UA_43 = "UA_43",
  UA_46 = "UA_46",
  UA_48 = "UA_48",
  UA_51 = "UA_51",
  UA_53 = "UA_53",
  UA_56 = "UA_56",
  UA_59 = "UA_59",
  UA_61 = "UA_61",
  UA_63 = "UA_63",
  UA_65 = "UA_65",
  UA_68 = "UA_68",
  UA_71 = "UA_71",
  UA_74 = "UA_74",
  UA_77 = "UA_77",
  UG_101 = "UG_101",
  UG_102 = "UG_102",
  UG_103 = "UG_103",
  UG_104 = "UG_104",
  UG_105 = "UG_105",
  UG_106 = "UG_106",
  UG_107 = "UG_107",
  UG_108 = "UG_108",
  UG_109 = "UG_109",
  UG_110 = "UG_110",
  UG_111 = "UG_111",
  UG_112 = "UG_112",
  UG_113 = "UG_113",
  UG_114 = "UG_114",
  UG_115 = "UG_115",
  UG_116 = "UG_116",
  UG_117 = "UG_117",
  UG_118 = "UG_118",
  UG_119 = "UG_119",
  UG_120 = "UG_120",
  UG_121 = "UG_121",
  UG_122 = "UG_122",
  UG_123 = "UG_123",
  UG_124 = "UG_124",
  UG_125 = "UG_125",
  UG_126 = "UG_126",
  UG_201 = "UG_201",
  UG_202 = "UG_202",
  UG_203 = "UG_203",
  UG_204 = "UG_204",
  UG_205 = "UG_205",
  UG_206 = "UG_206",
  UG_207 = "UG_207",
  UG_208 = "UG_208",
  UG_209 = "UG_209",
  UG_210 = "UG_210",
  UG_211 = "UG_211",
  UG_212 = "UG_212",
  UG_213 = "UG_213",
  UG_214 = "UG_214",
  UG_215 = "UG_215",
  UG_216 = "UG_216",
  UG_217 = "UG_217",
  UG_218 = "UG_218",
  UG_219 = "UG_219",
  UG_220 = "UG_220",
  UG_221 = "UG_221",
  UG_222 = "UG_222",
  UG_223 = "UG_223",
  UG_224 = "UG_224",
  UG_225 = "UG_225",
  UG_226 = "UG_226",
  UG_227 = "UG_227",
  UG_228 = "UG_228",
  UG_229 = "UG_229",
  UG_230 = "UG_230",
  UG_231 = "UG_231",
  UG_232 = "UG_232",
  UG_233 = "UG_233",
  UG_234 = "UG_234",
  UG_235 = "UG_235",
  UG_236 = "UG_236",
  UG_237 = "UG_237",
  UG_301 = "UG_301",
  UG_302 = "UG_302",
  UG_303 = "UG_303",
  UG_304 = "UG_304",
  UG_305 = "UG_305",
  UG_306 = "UG_306",
  UG_307 = "UG_307",
  UG_308 = "UG_308",
  UG_309 = "UG_309",
  UG_310 = "UG_310",
  UG_311 = "UG_311",
  UG_312 = "UG_312",
  UG_313 = "UG_313",
  UG_314 = "UG_314",
  UG_315 = "UG_315",
  UG_316 = "UG_316",
  UG_317 = "UG_317",
  UG_318 = "UG_318",
  UG_319 = "UG_319",
  UG_320 = "UG_320",
  UG_321 = "UG_321",
  UG_322 = "UG_322",
  UG_323 = "UG_323",
  UG_324 = "UG_324",
  UG_325 = "UG_325",
  UG_326 = "UG_326",
  UG_327 = "UG_327",
  UG_328 = "UG_328",
  UG_329 = "UG_329",
  UG_330 = "UG_330",
  UG_331 = "UG_331",
  UG_332 = "UG_332",
  UG_333 = "UG_333",
  UG_334 = "UG_334",
  UG_335 = "UG_335",
  UG_336 = "UG_336",
  UG_337 = "UG_337",
  UG_401 = "UG_401",
  UG_402 = "UG_402",
  UG_403 = "UG_403",
  UG_404 = "UG_404",
  UG_405 = "UG_405",
  UG_406 = "UG_406",
  UG_407 = "UG_407",
  UG_408 = "UG_408",
  UG_409 = "UG_409",
  UG_410 = "UG_410",
  UG_411 = "UG_411",
  UG_412 = "UG_412",
  UG_413 = "UG_413",
  UG_414 = "UG_414",
  UG_415 = "UG_415",
  UG_416 = "UG_416",
  UG_417 = "UG_417",
  UG_418 = "UG_418",
  UG_419 = "UG_419",
  UG_420 = "UG_420",
  UG_421 = "UG_421",
  UG_422 = "UG_422",
  UG_423 = "UG_423",
  UG_424 = "UG_424",
  UG_425 = "UG_425",
  UG_426 = "UG_426",
  UG_427 = "UG_427",
  UG_428 = "UG_428",
  UG_429 = "UG_429",
  UG_430 = "UG_430",
  UG_431 = "UG_431",
  UG_432 = "UG_432",
  UG_433 = "UG_433",
  UG_434 = "UG_434",
  UG_435 = "UG_435",
  UG_C = "UG_C",
  UG_E = "UG_E",
  UG_N = "UG_N",
  UG_W = "UG_W",
  UM_67 = "UM_67",
  UM_71 = "UM_71",
  UM_76 = "UM_76",
  UM_79 = "UM_79",
  UM_81 = "UM_81",
  UM_84 = "UM_84",
  UM_86 = "UM_86",
  UM_89 = "UM_89",
  UM_95 = "UM_95",
  US_AK = "US_AK",
  US_AL = "US_AL",
  US_AR = "US_AR",
  US_AS = "US_AS",
  US_AZ = "US_AZ",
  US_CA = "US_CA",
  US_CO = "US_CO",
  US_CT = "US_CT",
  US_DC = "US_DC",
  US_DE = "US_DE",
  US_FL = "US_FL",
  US_GA = "US_GA",
  US_GU = "US_GU",
  US_HI = "US_HI",
  US_IA = "US_IA",
  US_ID = "US_ID",
  US_IL = "US_IL",
  US_IN = "US_IN",
  US_KS = "US_KS",
  US_KY = "US_KY",
  US_LA = "US_LA",
  US_MA = "US_MA",
  US_MD = "US_MD",
  US_ME = "US_ME",
  US_MI = "US_MI",
  US_MN = "US_MN",
  US_MO = "US_MO",
  US_MP = "US_MP",
  US_MS = "US_MS",
  US_MT = "US_MT",
  US_NC = "US_NC",
  US_ND = "US_ND",
  US_NE = "US_NE",
  US_NH = "US_NH",
  US_NJ = "US_NJ",
  US_NM = "US_NM",
  US_NV = "US_NV",
  US_NY = "US_NY",
  US_OH = "US_OH",
  US_OK = "US_OK",
  US_OR = "US_OR",
  US_PA = "US_PA",
  US_PR = "US_PR",
  US_RI = "US_RI",
  US_SC = "US_SC",
  US_SD = "US_SD",
  US_TN = "US_TN",
  US_TX = "US_TX",
  US_UM = "US_UM",
  US_UT = "US_UT",
  US_VA = "US_VA",
  US_VI = "US_VI",
  US_VT = "US_VT",
  US_WA = "US_WA",
  US_WI = "US_WI",
  US_WV = "US_WV",
  US_WY = "US_WY",
  UY_AR = "UY_AR",
  UY_CA = "UY_CA",
  UY_CL = "UY_CL",
  UY_CO = "UY_CO",
  UY_DU = "UY_DU",
  UY_FD = "UY_FD",
  UY_FS = "UY_FS",
  UY_LA = "UY_LA",
  UY_MA = "UY_MA",
  UY_MO = "UY_MO",
  UY_PA = "UY_PA",
  UY_RN = "UY_RN",
  UY_RO = "UY_RO",
  UY_RV = "UY_RV",
  UY_SA = "UY_SA",
  UY_SJ = "UY_SJ",
  UY_SO = "UY_SO",
  UY_TA = "UY_TA",
  UY_TT = "UY_TT",
  UZ_AN = "UZ_AN",
  UZ_BU = "UZ_BU",
  UZ_FA = "UZ_FA",
  UZ_JI = "UZ_JI",
  UZ_NG = "UZ_NG",
  UZ_NW = "UZ_NW",
  UZ_QA = "UZ_QA",
  UZ_QR = "UZ_QR",
  UZ_SA = "UZ_SA",
  UZ_SI = "UZ_SI",
  UZ_SU = "UZ_SU",
  UZ_TK = "UZ_TK",
  UZ_TO = "UZ_TO",
  UZ_XO = "UZ_XO",
  VC_01 = "VC_01",
  VC_02 = "VC_02",
  VC_03 = "VC_03",
  VC_04 = "VC_04",
  VC_05 = "VC_05",
  VC_06 = "VC_06",
  VE_A = "VE_A",
  VE_B = "VE_B",
  VE_C = "VE_C",
  VE_D = "VE_D",
  VE_E = "VE_E",
  VE_F = "VE_F",
  VE_G = "VE_G",
  VE_H = "VE_H",
  VE_I = "VE_I",
  VE_J = "VE_J",
  VE_K = "VE_K",
  VE_L = "VE_L",
  VE_M = "VE_M",
  VE_N = "VE_N",
  VE_O = "VE_O",
  VE_P = "VE_P",
  VE_R = "VE_R",
  VE_S = "VE_S",
  VE_T = "VE_T",
  VE_U = "VE_U",
  VE_V = "VE_V",
  VE_W = "VE_W",
  VE_X = "VE_X",
  VE_Y = "VE_Y",
  VE_Z = "VE_Z",
  VN_01 = "VN_01",
  VN_02 = "VN_02",
  VN_03 = "VN_03",
  VN_04 = "VN_04",
  VN_05 = "VN_05",
  VN_06 = "VN_06",
  VN_07 = "VN_07",
  VN_09 = "VN_09",
  VN_13 = "VN_13",
  VN_14 = "VN_14",
  VN_18 = "VN_18",
  VN_20 = "VN_20",
  VN_21 = "VN_21",
  VN_22 = "VN_22",
  VN_23 = "VN_23",
  VN_24 = "VN_24",
  VN_25 = "VN_25",
  VN_26 = "VN_26",
  VN_27 = "VN_27",
  VN_28 = "VN_28",
  VN_29 = "VN_29",
  VN_30 = "VN_30",
  VN_31 = "VN_31",
  VN_32 = "VN_32",
  VN_33 = "VN_33",
  VN_34 = "VN_34",
  VN_35 = "VN_35",
  VN_36 = "VN_36",
  VN_37 = "VN_37",
  VN_39 = "VN_39",
  VN_40 = "VN_40",
  VN_41 = "VN_41",
  VN_43 = "VN_43",
  VN_44 = "VN_44",
  VN_45 = "VN_45",
  VN_46 = "VN_46",
  VN_47 = "VN_47",
  VN_49 = "VN_49",
  VN_50 = "VN_50",
  VN_51 = "VN_51",
  VN_52 = "VN_52",
  VN_53 = "VN_53",
  VN_54 = "VN_54",
  VN_55 = "VN_55",
  VN_56 = "VN_56",
  VN_57 = "VN_57",
  VN_58 = "VN_58",
  VN_59 = "VN_59",
  VN_61 = "VN_61",
  VN_63 = "VN_63",
  VN_66 = "VN_66",
  VN_67 = "VN_67",
  VN_68 = "VN_68",
  VN_69 = "VN_69",
  VN_70 = "VN_70",
  VN_71 = "VN_71",
  VN_72 = "VN_72",
  VN_73 = "VN_73",
  VN_CT = "VN_CT",
  VN_DN = "VN_DN",
  VN_HN = "VN_HN",
  VN_HP = "VN_HP",
  VN_SG = "VN_SG",
  VU_MAP = "VU_MAP",
  VU_PAM = "VU_PAM",
  VU_SAM = "VU_SAM",
  VU_SEE = "VU_SEE",
  VU_TAE = "VU_TAE",
  VU_TOB = "VU_TOB",
  WF_AL = "WF_AL",
  WF_SG = "WF_SG",
  WF_UV = "WF_UV",
  WS_AA = "WS_AA",
  WS_AL = "WS_AL",
  WS_AT = "WS_AT",
  WS_FA = "WS_FA",
  WS_GE = "WS_GE",
  WS_GI = "WS_GI",
  WS_PA = "WS_PA",
  WS_SA = "WS_SA",
  WS_TU = "WS_TU",
  WS_VF = "WS_VF",
  WS_VS = "WS_VS",
  YE_AB = "YE_AB",
  YE_AD = "YE_AD",
  YE_AM = "YE_AM",
  YE_BA = "YE_BA",
  YE_DA = "YE_DA",
  YE_DH = "YE_DH",
  YE_HD = "YE_HD",
  YE_HJ = "YE_HJ",
  YE_HU = "YE_HU",
  YE_IB = "YE_IB",
  YE_JA = "YE_JA",
  YE_LA = "YE_LA",
  YE_MA = "YE_MA",
  YE_MR = "YE_MR",
  YE_MW = "YE_MW",
  YE_RA = "YE_RA",
  YE_SA = "YE_SA",
  YE_SD = "YE_SD",
  YE_SH = "YE_SH",
  YE_SN = "YE_SN",
  YE_SU = "YE_SU",
  YE_TA = "YE_TA",
  ZA_EC = "ZA_EC",
  ZA_FS = "ZA_FS",
  ZA_GP = "ZA_GP",
  ZA_KZN = "ZA_KZN",
  ZA_LP = "ZA_LP",
  ZA_MP = "ZA_MP",
  ZA_NC = "ZA_NC",
  ZA_NW = "ZA_NW",
  ZA_WC = "ZA_WC",
  ZM_01 = "ZM_01",
  ZM_02 = "ZM_02",
  ZM_03 = "ZM_03",
  ZM_04 = "ZM_04",
  ZM_05 = "ZM_05",
  ZM_06 = "ZM_06",
  ZM_07 = "ZM_07",
  ZM_08 = "ZM_08",
  ZM_09 = "ZM_09",
  ZM_10 = "ZM_10",
  ZW_BU = "ZW_BU",
  ZW_HA = "ZW_HA",
  ZW_MA = "ZW_MA",
  ZW_MC = "ZW_MC",
  ZW_ME = "ZW_ME",
  ZW_MI = "ZW_MI",
  ZW_MN = "ZW_MN",
  ZW_MS = "ZW_MS",
  ZW_MV = "ZW_MV",
  ZW_MW = "ZW_MW",
}

/**
 * Active status of the subagreement
 */
export enum SubagreementStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

/**
 * The action or event the tier will be based on
 */
export enum ThresholdType {
  DEPOSIT_AMOUNT = "DEPOSIT_AMOUNT",
  DEPOSIT_COUNT = "DEPOSIT_COUNT",
  DEPOSIT_WINDOW = "DEPOSIT_WINDOW",
  FIRST_TIME_DEPOSIT = "FIRST_TIME_DEPOSIT",
  NET_REVENUE = "NET_REVENUE",
  QUALIFIED_DEPOSIT = "QUALIFIED_DEPOSIT",
  WAGER_AMOUNT = "WAGER_AMOUNT",
  WAGER_COUNT = "WAGER_COUNT",
  WAGER_WINDOW = "WAGER_WINDOW",
}

/**
 * The type of transaction to start the tier window
 */
export enum TierWindowType {
  CLICK = "CLICK",
  FIRST_TIME_DEPOSIT = "FIRST_TIME_DEPOSIT",
  FIRST_TIME_WAGER = "FIRST_TIME_WAGER",
  INSTALL = "INSTALL",
  REGISTRATION = "REGISTRATION",
}

/**
 * Currency type transactions were made in
 */
export enum TransactionCurrency {
  AUD = "AUD",
  BTC = "BTC",
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
  INR = "INR",
  USD = "USD",
}

/**
 * Type of transaction source
 */
export enum TransactionSource {
  CSV = "CSV",
  DATA_PROVIDER_GIG = "DATA_PROVIDER_GIG",
  FTP = "FTP",
  MANUAL = "MANUAL",
  S2S = "S2S",
}

/**
 * Type of transaction event in a click's lifecycle
 */
export enum TransactionType {
  ADJUSTMENT = "ADJUSTMENT",
  ADMIN_FEE = "ADMIN_FEE",
  BONUS = "BONUS",
  CHARGEBACK = "CHARGEBACK",
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  EXTRA_COST = "EXTRA_COST",
  GROSS_GAMING_REVENUE = "GROSS_GAMING_REVENUE",
  INSTALL = "INSTALL",
  NET_REVENUE = "NET_REVENUE",
  PROVIDER_NET_REVENUE = "PROVIDER_NET_REVENUE",
  REGISTRATION = "REGISTRATION",
  TAX = "TAX",
  WAGER = "WAGER",
  WINNINGS_PAID = "WINNINGS_PAID",
  WITHDRAWAL = "WITHDRAWAL",
}

/**
 * Available default display currencies
 */
export enum UserCurrency {
  AUD = "AUD",
  BTC = "BTC",
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
  INR = "INR",
  USD = "USD",
}

/**
 * Statically defined error types
 */
export enum UserServiceErrorType {
  INVALID_INPUT = "INVALID_INPUT",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  NOT_FOUND = "NOT_FOUND",
}

/**
 * User fields available to be sorted by
 */
export enum UserSortFields {
  CREATED_AT = "CREATED_AT",
  EMAIL = "EMAIL",
  NAME = "NAME",
}

/**
 * Statically defined verticals types
 */
export enum VerticalType {
  BETTING_EXCHANGE = "BETTING_EXCHANGE",
  BINGO = "BINGO",
  CASINO = "CASINO",
  CRYPTO = "CRYPTO",
  DEFAULT = "DEFAULT",
  ESPORTS = "ESPORTS",
  FANTASY = "FANTASY",
  FOREX = "FOREX",
  HORSE_RACING = "HORSE_RACING",
  LIVE_DEALER = "LIVE_DEALER",
  LOTTERY = "LOTTERY",
  OTHER = "OTHER",
  POKER = "POKER",
  SLOTS = "SLOTS",
  SPORTSBOOK = "SPORTSBOOK",
  SWEEPSTAKES = "SWEEPSTAKES",
  TABLE_GAMES = "TABLE_GAMES",
  VIRTUAL = "VIRTUAL",
  default = "default",
}

/**
 * Event action types that can be tracked for payment
 */
export enum WebhookActionType {
  CLICK = "CLICK",
  DEPOSIT = "DEPOSIT",
  DOWNLOAD = "DOWNLOAD",
  QUALIFIED_DEPOSIT = "QUALIFIED_DEPOSIT",
  REGISTRATION = "REGISTRATION",
}

/**
 * HTTP method to use for webhook requests
 */
export enum WebhookHttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export interface AgreementDetailsInput {
  actionType?: ActionType | null;
  cpaAmount?: number | null;
  commissionType: CommissionType;
  currency: Currency;
  revsharePercentages?: any | null;
  revshareType?: NetworkRevshareType | null;
  tierType?: AgreementTierType | null;
  spendPercentage?: number | null;
  spendAccessToken?: string | null;
}

export interface CreateAccountPoolVariableInput {
  description?: string | null;
  name: string;
}

export interface CreateAccountVariableInput {
  name: string;
  value: string;
}

export interface CreateQualificationInput {
  amount: number;
  qualificationType: QualificationType;
  windowType?: QualificationWindowType | null;
}

export interface CreateTierInput {
  cpa?: number | null;
  payType?: PayType | null;
  revsharePercentages?: any | null;
  level: number;
  threshold: number;
  thresholdType: ThresholdType;
  windowType?: TierWindowType | null;
}

export interface MonthlyPaymentTotalsPartnerIdMonth {
  partnerId: string;
  month: any;
  externalComment?: string | null;
  internalComment?: string | null;
}

export interface OfferPoolRequirementInput {
  offerId: number;
  requirementType: OfferPoolRequirementType;
  requirementValue: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
