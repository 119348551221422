import React from 'react';
import IntegrationsList from '../../components/IntegrationsList/IntegrationsList';
import { useQuery } from '@apollo/client';
import { Integrations as IIntegrationsQuery } from '../../generated/Integrations';
import IntegrationsQuery from '../../queries/IntegrationsQuery';

const IntegrationsListContainer = () => {
  const { data, loading } = useQuery<IIntegrationsQuery>(IntegrationsQuery);
  const integrationsData = (() => {
    if (data && data.integrations) {
      return data.integrations;
    }
    const defaultData = {
      __typename: 'IntegrationsConnection',
      edges: [],
      pageInfo: {
        __typename: 'PageInfo',
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: null,
      },
      totalCount: 0,
    };
    return defaultData;
  })();

  return (
    <IntegrationsList
      integrations={integrationsData.edges.map((edge) => edge.node)}
      loading={loading}
    />
  )
}

export default IntegrationsListContainer;