import { gql } from "@apollo/client";

const DeactivateIntegrationMutation = gql`
  mutation DeactivateIntegration($integrationId: ID!) {
    deactivateIntegration(integrationId: $integrationId) {
      integration {
        id
        active
      }
    }
  }
`;

export default DeactivateIntegrationMutation;
