
import { List, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AllowedPaymentMethods_allowedPaymentMethods } from '../../generated/AllowedPaymentMethods';
import { Currency } from '../../generated/globalTypes';
import CurrencySettingsListItem from '../CurrencySettingsListItem/CurrencySettingsListItem';

export interface ICurrencySettingsList {
    allowedPaymentMethods: AllowedPaymentMethods_allowedPaymentMethods[];
  }
const CurrencySettingList = ({
    allowedPaymentMethods
  }: ICurrencySettingsList) => {
  const classes = useStyles();
  return (
    <List>
      <div className={classes.header}>
        <Typography variant="h5">Allowed Payment Options</Typography>
      </div>
      {
        Object.values(Currency).map((currency: Currency, index) => {
        return (
          <CurrencySettingsListItem
            key={index}
            label={currency}
            methods={
              allowedPaymentMethods
                .filter(method => method.currency === currency)
                .sort((a, b) => a.paymentMethod.localeCompare(b.paymentMethod))
            }
          />
        );
      })
      }
    </List>
)};

const useStyles = makeStyles(({ spacing }) => ({
    formGroup: {
      padding: `0 ${spacing(2)}px`
    },
    header: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: spacing()
    },
    lineItemHeader: {
      justifyContent: "space-between"
    }
  }));
export default CurrencySettingList;