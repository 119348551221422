import { gql } from "@apollo/client";

const UpdateClickMutation = gql`
  mutation UpdateClick(
    $clickId: ID!
    $playerId: String
    $playerCity: String
    $playerCountryCode: CountryCode
    $playerStateCode: StateCode
    $playerPostalCode: String
    $subagreementId: ID
  ) {
    updateClick(
      clickId: $clickId
      input: {
        playerId: $playerId
        playerCity: $playerCity
        playerCountryCode: $playerCountryCode
        playerStateCode: $playerStateCode
        playerPostalCode: $playerPostalCode
        subagreementId: $subagreementId
      }
    ) {
      click {
        id
      }
    }
  }
`;

export default UpdateClickMutation;
