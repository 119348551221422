import { gql } from "@apollo/client";

const UpdateInstanceSettingsRequireConversionKeyMutation = gql`
  mutation UpdateInstanceSettingsRequireConversionKey(
    $requireConversionKey: Boolean!
  ) {
    updateInstanceSettings(
      input: {
        requireConversionKey: $requireConversionKey
      }
    ) {
      requireConversionKey
    }
  }
`;

export default UpdateInstanceSettingsRequireConversionKeyMutation;
