const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "AUD":
      return "A$";
    case "BTC":
      return "₿";
    case "CAD":
      return "C$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "INR":
      return "₹";
    case "USD":
      return "$";
  }
};

export default getCurrencySymbol;
