import { gql } from "@apollo/client";

const DeactivateOfferPoolMutation = gql`
  mutation DeactivateOfferPool ($offerPoolId: Int!) {
    deactivateOfferPool(offerPoolId: $offerPoolId) {
      offerPool {
        id
        active
      }
      error {
        errorType
        message
      }
  }
}
`;

export default DeactivateOfferPoolMutation;