import { gql } from "@apollo/client";

const IntegrationsQuery = gql`
  query Integrations {
    integrations {
      edges {
        cursor
        node {
          id
          accountId
          active
          errorMessage
          externalId
          integrationType
          createdAt
          updatedAt
          createdBy {
            id
            displayId
            name
          }
        }
      }
    }
  }
`;

export default IntegrationsQuery;