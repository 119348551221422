import { gql } from "@apollo/client";

const ConversionKeysQuery = gql`
  query ConversionKeys(
    $page: Int! = 1
    $limit: Int! = 50
  ) {
    conversionKeys(
      limit: $limit,
      page: $page
    ) {
      edges {
        cursor
        node {
          id
          lastUsedAt
          name
          createdBy {
            displayId
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      totalCount
    }
  }
`;

export default ConversionKeysQuery;
