import React, { MouseEvent, useState } from "react";
import OfferPoolForm from "../OfferPoolForm/OfferPoolForm";
import { format } from "date-fns";
import {
  TableRow,
  TableCell,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { OfferPools_offerPools_edges_node } from "../../generated/OfferPools";
import { Edit as EditIcon } from "@material-ui/icons";
import OfferPoolStatusChip from "../OfferPoolStatusChip/OfferPoolStatusChip";
import { useAuth0 } from "../../utils/auth0Provider";

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export interface IOfferPoolListItemProps {
  offerPool: OfferPools_offerPools_edges_node;
}

const OfferPoolListItem = ({
  offerPool
}: IOfferPoolListItemProps) => {
  const styles = useStyles();
  const [offerPoolUpdateOpen, setOfferPoolUpdateOpen] = useState(false);

  const { hasPermission } = useAuth0();
  const manageOfferPools = hasPermission("manage:offer_pools");

  const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOfferPoolUpdateOpen(true);
  };

  const offerRequirementCount = () =>
    offerPool.requirements.reduce((offers, offerRequirement) => {
      if (!offers.includes(offerRequirement.offer.id)) {
        offers.push(offerRequirement.offer.id);
      }
      return offers;
    }, [] as number[]).length;

  const offerRequirementTypes = () =>
    offerPool.requirements.length === 0 ? (
      <Typography variant="body2">&mdash;</Typography>
    ) : (
      offerPool.requirements
        .reduce((requirementTypes, offerRequirement) => {
          if (!requirementTypes.includes(offerRequirement.requirementType)) {
            requirementTypes.push(offerRequirement.requirementType);
          }
          return requirementTypes;
        }, [] as string[])
        .map((label) => label.charAt(0).toUpperCase() + label.toLowerCase().substring(1)).join(", ")
    );

  return (
    <>
      <TableRow
        className={styles.tableRow}
        component={Link}
        hover
        to={`/offer-pools/${offerPool.id}`}
      >
        <TableCell>
          <Typography
            variant="body2"
          >
            {offerPool.name}
          </Typography>
        </TableCell>
        <TableCell width={90} align="center">
          <Typography variant="body2">{offerRequirementCount()}</Typography>
        </TableCell>
        <TableCell>{offerRequirementTypes()}</TableCell>
        <TableCell width={150} align="right">
          <Typography variant="body2">
            {format(new Date(offerPool.updatedAt), "MM/dd/yyyy")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {format(new Date(offerPool.updatedAt), "HH:mmaa")}
          </Typography>
        </TableCell>
        <TableCell width={80} align="right">
          {manageOfferPools && <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>}
        </TableCell>
        <TableCell width={100} align="right">
          <OfferPoolStatusChip
            offerPoolId={offerPool.id}
            active={offerPool.active}
          />
        </TableCell>

        {manageOfferPools && <OfferPoolForm
          type="update"
          onClose={() => setOfferPoolUpdateOpen(false)}
          open={offerPoolUpdateOpen}
          id={parseInt(offerPool.id, 10)}
          name={offerPool.name}
          active={offerPool.active}
          archived={offerPool.archived}
          requirements={offerPool.requirements}
        />}
      </TableRow>
    </>
  );
};

export default OfferPoolListItem;
