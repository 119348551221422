import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";

import ConfirmationAlert from "../ConfirmationAlert";
import CreateConversionKeyMutation from "../../mutations/CreateConversionKeyMutation";
import { CreateConversionKey } from "../../generated/CreateConversionKey";

interface IConversionKeyFormProps {
  onClose: () => void;
  open: boolean;
}

const ConversionKeyForm = ({ onClose, open }: IConversionKeyFormProps) => {
  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [nameState, setName] = useState<string | null>();

  const [createConversionKey, { loading }] = useMutation<CreateConversionKey>(CreateConversionKeyMutation);

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setName("");
    setDirty(false);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    createConversionKey(
      { variables: { name: nameState }, refetchQueries: ["ConversionKeys"] }
    ).then(
      () => { onCloseReset() }
    );
  };

  const setInputState = (setFunction: (value?: string | null) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>Create Conversion Key</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Create a new conversion key for use in authenticating S2S endpoint conversion requests
            </DialogContentText>

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default ConversionKeyForm;
