import { gql } from '@apollo/client';

const ReactivateIntegrationMutation = gql`
  mutation ReactivateIntegration($integrationId: ID!) {
    reactivateIntegration(integrationId: $integrationId) {
      integration {
        id
        active
      }
    }
  }
`;

export default ReactivateIntegrationMutation;