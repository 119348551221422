import { useQuery } from "@apollo/client";
import React, { ChangeEvent } from "react";
import { useDebouncedCallback } from "use-debounce";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
  Brands as IBrandsQuery,
  Brands_brands_edges_node
} from "../../generated/Brands";
import { Offers_offers_edges_node_brand } from "../../generated/Offers";
import BrandsQuery from "../../queries/BrandsQuery";

export interface IInputBrandSearch {
  defaultValue?: Offers_offers_edges_node_brand;
  disabled: boolean;
  label: string;
  onSelect: (brand: Brands_brands_edges_node | undefined) => void;
  className: string;
  required?: boolean;
}

const InputBrandSearch = ({
  defaultValue,
  disabled,
  label,
  onSelect,
  className,
  required
}: IInputBrandSearch) => {
  const { data, loading, refetch } = useQuery<IBrandsQuery>(BrandsQuery);

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value: string) => {
    debouncedRefetch({ search: value || undefined });
  };

  const handleSelect = (brand?: Brands_brands_edges_node | undefined) => {
    if (brand) {
      onSelect(brand);
    } else {
      onSelect(undefined);
      handleSearch("");
    }
  };

  const options = data?.brands?.edges.map(edge => edge.node) || [];

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      defaultValue={defaultValue as Brands_brands_edges_node}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      loading={loading}
      onChange={(_: ChangeEvent<{}>, brand: Brands_brands_edges_node | null) =>
        handleSelect(brand || undefined)
      }
      onInputChange={(_, search) => handleSearch(search)}
      options={options}
      renderInput={params => (
        <TextField {...params} fullWidth label={label} required={required} />
      )}
    />
  );
};

export default InputBrandSearch;
