import { useQuery } from "@apollo/client";
import React, { ChangeEvent } from "react";
import { useDebouncedCallback } from "use-debounce";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
  Advertisers as IAdvertisersQuery,
  Advertisers_advertisers_edges_node
} from "../../generated/Advertisers";
import { Brand_brand_brand_advertiser } from "../../generated/Brand";
import AdvertisersQuery from "../../queries/AdvertisersQuery";

interface IInputAdvertiserSearch {
  className: string;
  defaultValue?: Brand_brand_brand_advertiser;
  disabled: boolean;
  label: string;
  onSelect: (id: number | undefined) => void;
}

const InputAdvertiserSearch = ({
  className,
  defaultValue,
  disabled,
  label,
  onSelect
}: IInputAdvertiserSearch) => {
  const { data, loading, refetch } = useQuery<IAdvertisersQuery>(
    AdvertisersQuery
  );

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value: string) => {
    debouncedRefetch({ search: value || undefined });
  };

  const handleSelect = (advertiser?: any) => {
    if (advertiser) {
      onSelect(advertiser.id);
    } else {
      onSelect(undefined);
      handleSearch("");
    }
  };

  const options = data?.advertisers?.edges.map(edge => edge.node) || [];

  return (
    <Autocomplete
      className={className}
      defaultValue={defaultValue as Advertisers_advertisers_edges_node}
      disabled={disabled}
      getOptionLabel={option => option.name}
      loading={loading}
      onChange={(_: ChangeEvent<{}>, advertiser: any | null) =>
        handleSelect(advertiser || undefined)
      }
      onInputChange={(_, search) => handleSearch(search)}
      options={options}
      renderInput={params => (
        <TextField {...params} fullWidth label={label} required />
      )}
      getOptionSelected={(_, advertiser) => advertiser?.id === defaultValue?.id}
    />
  );
};

export default InputAdvertiserSearch;
