import React, { useState } from "react";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  ListItem,
  makeStyles,
  Typography
} from "@material-ui/core";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import {
  useMutation
} from "@apollo/client";
import UpdateAllowedPaymentMethodMutation from "../../mutations/UpdateAllowedPaymentMethodMutation";
import {
  UpdateAllowedPaymentMethod, UpdateAllowedPaymentMethodVariables
} from "../../generated/UpdateAllowedPaymentMethod";
import {
  AllowedPaymentMethods_allowedPaymentMethods
} from "../../generated/AllowedPaymentMethods";

interface ICurrencySettingsListItem {
  label?: string;
  methods: AllowedPaymentMethods_allowedPaymentMethods[];
}

const CurrencySettingsListItem = ({
  label,
  methods,
}: ICurrencySettingsListItem) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [updateAllowedPaymentMethod] = useMutation<
    UpdateAllowedPaymentMethod,
    UpdateAllowedPaymentMethodVariables
  >(UpdateAllowedPaymentMethodMutation);

  return (
    <>
      <ListItem
        className={classes.itemHeader}
        button
        divider
        onClick={() => setOpen(!open)}
      >
        {label}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open}>
        <div className={classes.item}>
          <FormGroup className={classes.formGroup}>
            {
              methods.length > 0 ?methods.map(method => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={method.active}
                      onChange={() =>
                        updateAllowedPaymentMethod({
                          variables: {
                            active: !method.active,
                            allowedPaymentMethodId: method.id
                          }
                        })
                      }
                    />
                  }
                  key={method.id}
                  label={method.paymentMethod}
                />
              )) : (
                <Typography variant="body2" color="textSecondary">
                    No Payment Methods Available
                </Typography>
              )
            }
            </FormGroup>
          </div>
      </Collapse>
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  formGroup: {
    padding: spacing(0, 2)
  },
  itemHeader: {
    justifyContent: "space-between"
  },
  item: {
    padding: spacing(1, 0)
  }
}));

export default CurrencySettingsListItem;