import React from "react";
import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { OfferPool as IOfferPoolQuery, OfferPool_offerPool_offerPool } from "../../generated/OfferPool";
import OfferPoolQuery from "../../queries/OfferPoolQuery";
import OfferPoolShow from "../../components/OfferPoolShow/OfferPoolShow";

const OfferPoolShowContainer = () => {
  const { offerPoolId: rawOfferPoolId } = useParams<{
    offerPoolId: string;
  }>();
  const classes = useStyles();
  const offerPoolId = parseInt(rawOfferPoolId as string, 10);
  const { loading, error, data } = useQuery<IOfferPoolQuery>(OfferPoolQuery, {
    variables: { offerPoolId }
  });

  if (loading) {
    return <LinearProgress/>
  }

  if (error || !data || !data.offerPool || data.offerPool.error) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    )
  }

  return <OfferPoolShow
    offerPool={data?.offerPool.offerPool as OfferPool_offerPool_offerPool}
  />
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default OfferPoolShowContainer;