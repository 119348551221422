import { Chip, Tooltip } from "@material-ui/core";
import { FiberManualRecordTwoTone } from "@material-ui/icons";
import React, { useState } from "react";
import { useAuth0 } from "../../utils/auth0Provider";
import { useMutation } from "@apollo/client";
import { DeactivateIntegration as IDeactivateIntegration } from "../../generated/DeactivateIntegration";
import DeactivateIntegrationMutation from "../../mutations/DeactivateIntegrationMutation";
import { ReactivateIntegration as IReactivateIntegration } from "../../generated/ReactivateIntegration";
import ReactivateIntegrationMutation from "../../mutations/ReactivateIntegrationMutation";
import ConfirmationAlert from "../ConfirmationAlert";

interface IIntegrationsStatusChipProps {
  integrationId?: string | number;
  active?: boolean;
}

const IntegrationsStatusChip = ({
  integrationId: rawIntegrationId,
  active,
}: IIntegrationsStatusChipProps) => {
  const integrationId = parseInt(rawIntegrationId as string, 10);
  const { hasPermission } = useAuth0();
  const manageIntegrations = hasPermission("manage:integrations");
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(
    false
  );
  const [deactivateIntegration, { loading: deactivateLoading }] = useMutation<
    IDeactivateIntegration
  >(DeactivateIntegrationMutation, {
    variables: {
      integrationId,
    },
  });
  const [reactivateIntegration, { loading: reactivateLoading }] = useMutation<
    IReactivateIntegration
  >(ReactivateIntegrationMutation, {
    variables: {
      integrationId,
    },
  });
  const loading = deactivateLoading || reactivateLoading;
  const tooltipTitle = manageIntegrations
    ? `${active ? "Deactivate" : "Activate"} this Integration`
    : "";
  
  const onConfirmationNegative = () => {
    setDeactivateConfirmationOpen(false);
  };
  const onConfirmationPositive = () => {
    setDeactivateConfirmationOpen(false);
    deactivateIntegration();
  };

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Chip
          variant={active ? "outlined" : "default"}
          size="small"
          label={active ? "Active" : "Inactive"}
          color={active ? "primary" : "default"}
          icon={<FiberManualRecordTwoTone />}
          disabled={!manageIntegrations || loading}
          onClick={() =>
            active
              ? setDeactivateConfirmationOpen(true)
              : reactivateIntegration()
          }
        />
      </Tooltip>

      <ConfirmationAlert
        title="Deactivate Integration"
        content="Are you sure you want to deactivate this integration?"
        open={deactivateConfirmationOpen}
        onNegative={onConfirmationNegative}
        onPositive={onConfirmationPositive}
        positiveAction="Deactivate"
      />
    </>
  );
};

export default IntegrationsStatusChip;
