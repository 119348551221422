import { gql } from "@apollo/client";

const TransactionsQuery = gql`
  query Transactions(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $clickId: ID!
    $removed: Boolean
  ) {
    transactions(
      after: $after
      before: $before
      limit: $limit
      clickId: $clickId
      removed: $removed
    ) {
      edges {
        cursor
        node {
          amount
          currency
          externalId
          id
          source
          transactionAt
          transactionType
          removed
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default TransactionsQuery;
