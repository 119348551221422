import { gql } from "@apollo/client";

const DeleteConversionKeyMutation = gql`
  mutation DeleteConversionKey($conversionKeyId: ID!) {
    deleteConversionKey(conversionKeyId: $conversionKeyId) {
      success
    }
  }
`;

export default DeleteConversionKeyMutation;
