import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { OfferPoolRequirementType } from "../../generated/globalTypes";
import { CheckBox, CheckBoxOutlineBlank, Remove } from "@material-ui/icons";
import {
  ISO31661 as ISOCountryCodes,
  ISO31662 as ISOStateCodes,
} from "../../utils/ISO3166";
import { verticalsMap } from "../../utils/verticalsMap";
import { Autocomplete } from "@material-ui/lab";
import { IOfferPoolRequirement } from "../OfferPoolForm/OfferPoolForm";

interface IOfferPoolRequirementValueOption {
  label: string;
  value: string;
  group?: string;
}

interface IOfferPoolFormOfferRequirementSetProps extends IOfferPoolRequirement {
  index?: string;
  onDelete: () => void;
  onChange: (values: IOfferPoolRequirement) => void;
  disabled?: boolean;
  disabledTypes?: OfferPoolRequirementType[];
  offerPoolCountries?: string[];
}

const OfferPoolFormOfferRequirementSet = ({
  index,
  requirementType,
  requirementValue,
  onChange,
  onDelete,
  disabled,
  disabledTypes,
  offerPoolCountries,
}: IOfferPoolFormOfferRequirementSetProps) => {
  const classes = useStyles();
  // Internal State
  const [requirementValueLabel, setRequirementValueLabel] = useState<string>(
    "Requirement Value"
  );
  const [requirementValueOptions, setRequirementValueOptions] = useState<
    IOfferPoolRequirementValueOption[]
  >([]);

  // Offer Pool Data
  const [offerPoolRequirementType, setOfferPoolRequirementType] = useState<
    OfferPoolRequirementType
  >(requirementType as OfferPoolRequirementType);
  const [offerPoolRequirementValue, setOfferPoolRequirementValue] = useState<
    string[]
  >(requirementValue as string[]);

  const emitOnChange = ({
    requirementType,
    requirementValue,
  }: {
    requirementType?: OfferPoolRequirementType;
    requirementValue?: string[];
  }) =>
    onChange({
      requirementType:
        requirementType ||
        (offerPoolRequirementType as OfferPoolRequirementType),
      requirementValue:
        requirementValue || (offerPoolRequirementValue as string[]),
    });

  const updateOptions = useCallback((requirementType: string | null = null) => {
    if (!requirementType) {
      requirementType = offerPoolRequirementType as string;
    }

    switch (requirementType) {
      case OfferPoolRequirementType.COUNTRY:
        setRequirementValueLabel("Countries");
        setRequirementValueOptions(
          [...ISOCountryCodes].map((countryCode) => ({
            label: countryCode.name,
            value: countryCode.alpha2,
          }))
        );
        break;
      case OfferPoolRequirementType.STATE:
        setRequirementValueLabel("States");
        setRequirementValueOptions(
          [...ISOStateCodes]
            .filter((stateCode) =>
              offerPoolCountries && offerPoolCountries.length > 0
                ? offerPoolCountries.includes(stateCode.parent)
                : true
            )
            .map((stateCode) => ({
              group: ISOCountryCodes.find(
                (countryCode) => countryCode.alpha2 === stateCode.parent
              )?.name,
              label: stateCode.name,
              value: stateCode.code,
            }))
            .sort((a, b) => (a.group || "").localeCompare(b.group || ""))
        );
        break;
      case OfferPoolRequirementType.VERTICAL:
        setRequirementValueLabel("Verticals");
        setRequirementValueOptions(
          [...Object.entries(verticalsMap)].map(([label, value]) => ({
            label,
            value,
          }))
        );
        break;
      default:
        setRequirementValueOptions([]);
        break;
    }
  }, [offerPoolRequirementType, offerPoolCountries]);

  const handleTypeChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const requirementType = event.target.value as OfferPoolRequirementType;
    setOfferPoolRequirementType(requirementType);
    updateOptions(requirementType);
    emitOnChange({ requirementType });
  };

  const handleValueChange = (
    _: ChangeEvent<{}>,
    value: IOfferPoolRequirementValueOption[]
  ) => {
    const requirementValue = [...value.map((v) => v.value)];
    setOfferPoolRequirementValue(requirementValue);
    emitOnChange({ requirementValue });
  };

  useEffect(() => {
    updateOptions();
  }, [offerPoolCountries, updateOptions]);

  useCallback(() => {
    updateOptions(requirementType);
    setOfferPoolRequirementType(requirementType as OfferPoolRequirementType);
    setOfferPoolRequirementValue(requirementValue as string[]);
  }, [requirementType, requirementValue, updateOptions]);

  return (
    <div className={classes.root}>
      <FormControl variant="standard">
        <InputLabel id={`requirement-${index}-type-label`}>Type</InputLabel>
        <Select
          label="Requirement Type"
          labelId={`requirement-${index}-type-label`}
          value={offerPoolRequirementType as OfferPoolRequirementType}
          onChange={handleTypeChange}
          disabled={disabled}
        >
          <MenuItem value={undefined} disabled={true}>
            Requirement Type
          </MenuItem>
          {Object.keys(OfferPoolRequirementType).map((requirementType, ri) => (
            <MenuItem
              value={requirementType}
              key={ri}
              disabled={
                disabledTypes?.includes(
                  requirementType as OfferPoolRequirementType
                ) || false
              }
            >
              {requirementType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        multiple
        size="small"
        options={requirementValueOptions}
        getOptionLabel={(option) => option ? option.label as string : ''}
        getOptionSelected={(option, value) => option.value === value.value}
        groupBy={(option) => option.group as string}
        noOptionsText={"Select a requirement type first"}
        onChange={handleValueChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={requirementValueLabel}
            variant="standard"
          />
        )}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={selected}
            />
            {option.label}
          </>
        )}
        disabled={disabled || requirementValueOptions.length === 0}
        value={[
          ...(requirementValue?.map((v) =>
            requirementValueOptions.find((o) => o.value === v)
          ) as IOfferPoolRequirementValueOption[]),
        ]}
      />
      <div className={classes.lastColumn}>
        <IconButton
          title="Remove this requirement"
          size="small"
          disabled={disabled}
          onClick={onDelete}
        >
          <Remove />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "calc(30% - 1em) calc(70% - 48px) 48px",
    gap: "0.5em",
    margin: "1em 0 0.5em",
    alignItems: "flex-start",

    // Fixes the issue with the autocomplete input being misaligned
    // when size="small"
    //
    "& .MuiAutocomplete-inputRoot": {
      marginTop: 19,
    },
  },
  lastColumn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: spacing(1),
  },
}));

export default OfferPoolFormOfferRequirementSet;
