import { gql } from "@apollo/client";

const UpdateOfferPoolMutation = gql`
  mutation UpdateOfferPool(
    $id: Int!
    $name: String!
    $requirements: [OfferPoolRequirementInput!]
  ) {
    updateOfferPool(
      offerPoolId: $id
      input: {
        name: $name,
        requirements: $requirements
      }
    ) {
      offerPool {
        id
        active
        archived
        name
        createdAt
        updatedAt
        requirements {
          id
          requirementType
          requirementValue
          offer {
            id
            name
          }
        }
      }
    }
  }
`;

export default UpdateOfferPoolMutation