import { gql } from "@apollo/client";
const OfferPoolQuery = gql`
  query OfferPool(
    $offerPoolId: Int!
  ) {
    offerPool(offerPoolId: $offerPoolId) {
      offerPool {
        id
        active
        archived
        name
        createdAt
        updatedAt
        requirements {
          id
          requirementType
          requirementValue
          offer {
            id
            name
            brand {
              id
              name
              advertiser {
                id
                name
              }
            }
          }
        }
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default OfferPoolQuery;