import React from "react";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useAuth0 } from "../../utils/auth0Provider";
import { Add as AddIcon } from "@material-ui/icons";
import { Integrations_integrations_edges_node } from "../../generated/Integrations";
import IntegrationsListItem from "../IntegrationsListItem/IntegrationsListItem";
import { useHistory } from "react-router-dom";

interface IIntegrationsListProps {
  loading: boolean;
  integrations: Integrations_integrations_edges_node[];
}

const IntegrationsList = ({
  loading,
  integrations,
}: IIntegrationsListProps) => {
  const styles = useStyles();
  const history = useHistory();
  const { hasPermission } = useAuth0();
  const manageIntegrations = hasPermission("manage:integrations");

  return (
    <>
      <Paper className={styles.paper}>
        <div className={styles.header}>
          <Typography variant="h5">Integrations</Typography>
          {manageIntegrations && (
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                history.push("/tech/integrations/new");
              }}
            >
              <AddIcon />
              New
            </Button>
          )}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Platform</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Features</TableCell>
              <TableCell>Last Sync</TableCell>
              <TableCell>Connected At</TableCell>
              <TableCell>Connected By</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.length ? (
              integrations.map((integration) => (
                <IntegrationsListItem
                  integration={integration}
                  key={integration.id}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {loading ? (
                    <CircularProgress thickness={6} color="primary" />
                  ) : (
                    "No Integrations, add a new one!"
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing(1),
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0,
  },
}));

export default IntegrationsList;
