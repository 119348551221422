import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import ClickShow from "../../components/ClickShow";
import { Click as IClickQuery } from "../../generated/Click";
import { ClickServiceErrorType } from "../../generated/globalTypes";
import { Transactions as ITransactionsQuery, Transactions_transactions } from "../../generated/Transactions";
import ClickQuery from "../../queries/ClickQuery";
import TransactionsQuery from "../../queries/TransactionsQuery";

const ClickShowContainer = () => {
  const { clickId } = useParams<{ clickId: string }>();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const classes = useStyles();
  const limit = 100;

  let after: string | undefined, before: string | undefined, removed: number | boolean | undefined;

  const { loading, error, data, refetch } = useQuery<IClickQuery>(ClickQuery, {
    variables: { clickId }
  });

  if (history.location.search.length > 0) {
    after = queryParams.get("after") || undefined;
  }
  
  if (history.location.search.length > 0) {
    before = queryParams.get("before") || undefined;
  }
  
  if (history.location.search.length > 0) {
    if (!queryParams.get("removed")) {
      removed = false;
    } else {
      removed = queryParams.get("removed") === "1" ? undefined : false;
    }
  } else {
    if (!queryParams.get("removed")) {
      removed = false;
    } else {
      removed = queryParams.get("removed") === "1" ? undefined : false;
    }
  }

  const { data: tData } = useQuery<ITransactionsQuery>(
    TransactionsQuery, 
    { variables: { clickId, limit, before, after, removed } }
  );

  const { data: firstTransactionData } = useQuery<ITransactionsQuery>(
    TransactionsQuery,
    { variables: { clickId, limit: 1, removed: false } }
  );
  
  const onNextPage = () => {
    const newAfter = transactionsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history
        .push({
          pathname: history.location.pathname,
          search: `?after=${newAfter}&removed=${removed === undefined ? "1" : "0"}`
        });
    }
  };

  const onPreviousPage = () => {
    const newBefore = transactionsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history
        .push({
          pathname: history.location.pathname,
          search: `?before=${newBefore}&removed=${removed === undefined ? "1" : "0"}`
        });
    }
  };

  const onRemoved = () => {
    if (removed === undefined) {
      removed = false;
    } else {
      removed = undefined
    }

    if (queryParams.get("before") != null) {
      history
      .push({
        pathname: history.location.pathname,
        search: `?before=${queryParams.get("before")}&removed=${removed === undefined ? "1" : "0"}`
      });
    } else if (queryParams.get("after") != null) {
      history
        .push({
          pathname: history.location.pathname,
          search: `?after=${queryParams.get("after")}&removed=${removed === undefined ? "1" : "0"}`
        });
    } else {
      history
        .push({
          pathname: history.location.pathname,
          search: `?removed=${removed === undefined ? "1" : "0"}`
        });
    }
    };
  
  if (loading) {
    return <LinearProgress />;
  }

  const transactionsData = (() => {
    if (tData && tData.transactions) {
      return tData.transactions;
    } else {
      const defaultData: Transactions_transactions = {
        __typename: "TransactionsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        },
      };
      return defaultData;
    }
  })();

  if (data?.click?.error?.errorType === ClickServiceErrorType.NOT_FOUND) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Click ID does not exist.
        </Typography>
      </>
    );
  }

  if (error || !data || !data.click || !data.click.click) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }
  
  const clickedAt = firstTransactionData?.transactions?.edges[0].node.transactionAt;

  return (<ClickShow
    {...data.click.click}
    transactions={transactionsData.edges}
    clickedAt={clickedAt}
    clickRefetch={refetch}
    removed={removed}
    hasNextPage={transactionsData.pageInfo.hasNextPage}
    hasPreviousPage={transactionsData.pageInfo.hasPreviousPage}
    onNextPage={onNextPage}
    onPreviousPage={onPreviousPage}
    onRemoved={onRemoved} />);
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default ClickShowContainer;
