import { gql } from "@apollo/client";

const CreateOfferPoolMutation = gql`
  mutation CreateOfferPool(
    $name: String!
    $requirements: [OfferPoolRequirementInput!]!
  ) {
    createOfferPool(
      input: {
        name: $name
        requirements: $requirements
      }
    ) {
      offerPool {
        id
        active
        archived
        name
        createdAt
        updatedAt
        requirements {
          id
          requirementType
          requirementValue
          offer {
            id
            name
          }
        }
      }
      error {
        errorType
        message
      }
    }
  } 
`;

export default CreateOfferPoolMutation;