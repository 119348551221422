import { useMutation } from "@apollo/client";
import React, { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

import {
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TableCell,
  TableRow,
  Theme
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { useAuth0 } from "../../utils/auth0Provider";

import {
  Offers_offers_edges_node_brand,
  Offers_offers_edges_node_brand_advertiser
} from "../../generated/Offers";

import { DeactivateOffer } from "../../generated/DeactivateOffer";
import { ReactivateOffer } from "../../generated/ReactivateOffer";
import DeactivateOfferMutation from "../../mutations/DeactivateOfferMutation";
import ReactivateOfferMutation from "../../mutations/ReactivateOfferMutation";

import ConfirmationAlert from "../ConfirmationAlert";
import GeneralPopover from "../GeneralPopover";

import { VerticalType } from "../../generated/globalTypes";
import verticalFormatter from "../../utils/verticalFormatter";
import { ChevronRight } from "@material-ui/icons";

export interface IOfferListItemProps {
  active: boolean;
  advertiser: Offers_offers_edges_node_brand_advertiser;
  brand: Offers_offers_edges_node_brand;
  id: number;
  name: string;
  onOfferUpdate: () => void;
  verticals: VerticalType[] | null;
}

const OfferListItem = ({
  active,
  advertiser,
  brand,
  id,
  name,
  onOfferUpdate,
  verticals
}: IOfferListItemProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const updateOffers = hasPermission("manage:offers");
  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [deactivateOffer, { loading: deactivateLoading }] = useMutation<
    DeactivateOffer
  >(DeactivateOfferMutation, { variables: { offerId: id } });

  const [reactivateOffer, { loading: reactivateLoading }] = useMutation<
    ReactivateOffer
  >(ReactivateOfferMutation, { variables: { offerId: id } });
  
  const onActiveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveConfirmationOpen(true);
  };

  const onConfirmationNegative = () => {
    setActiveConfirmationOpen(false);
  };

  const onConfirmationPositive = () => {
    setActiveConfirmationOpen(false);
    active ? deactivateOffer() : reactivateOffer();
  };

  const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onOfferUpdate();
  };

  const displayVerticals = (verts: VerticalType[]) => {
    if (verts.length > 1) {
      return (
        <>
          <GeneralPopover message={"Multi"}>
            <List dense>
              {verts.map((vert, index) => {
                return (
                  <>
                    <ListItem key={index}>
                      <ListItemText primary={verticalFormatter(vert)} />
                    </ListItem>
                  </>
                );
              })}
            </List>
          </GeneralPopover>
        </>
      );
    } else if (verts.length === 1) {
      const vertical = verts[0];
      return verticalFormatter(vertical);
    } else {
      return "";
    }
  };

  const activeConfirmationContent = active
    ? "Deactivating an offer will deactivate all creatives and prevent click tracking."
    : "Reactivating an offer will also reactivate the parent brand.";
  const activeConfirmationPositiveAction = active ? "Deactivate" : "Reactivate";
  const activeConfirmationTitle = active
    ? `Deactivate Offer: ${name}`
    : `Reactivate Offer: ${name}`;

  return (
    <>
      <TableRow
        className={classes.tableRow}
        component={Link}
        hover
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        key={id}
        to={`/offers/${id}/details`}
      >
        
        <TableCell >
        <div className={classes.overflow}>
          <Chip
          size="small"
          className="chip"
          color={active ? "primary" : "default"}
          />
         {name + " (" + id + ")"}</div>
          </TableCell>
        <TableCell>{displayVerticals(verticals as [])}</TableCell>
        <TableCell>{brand?.name}</TableCell>
        {updateOffers && <TableCell>{advertiser?.name}</TableCell>}
        <TableCell>
          <Checkbox
            checked={active}
            disabled={!updateOffers || deactivateLoading || reactivateLoading}
            onClick={onActiveClick}
          />
        </TableCell>
        {updateOffers && (
          <TableCell className="actonItems">
            <IconButton style={{visibility: hover ? "visible" : "hidden" }} onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
        <TableCell className={classes.chevronCell}>
          <ChevronRight />
        </TableCell>
      </TableRow>

      {updateOffers && (
        <ConfirmationAlert
          content={activeConfirmationContent}
          onNegative={onConfirmationNegative}
          onPositive={onConfirmationPositive}
          open={activeConfirmationOpen}
          positiveAction={activeConfirmationPositiveAction}
          title={activeConfirmationTitle}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  overflow:{
    maxWidth: "90ch",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  paper: {
    padding: theme.spacing(1)
  },
  popover: {
    pointerEvents: "none"
  },
  chevronCell: {
    width: 10,
    paddingRight: 0, 
  },
  tableRow: {
    cursor: "pointer", 
    textDecoration: "none",
    "& .MuiTableCell-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiChip-root": {
      height: 16,
      marginRight: 10,
    }
  },
  chip: {
    marginRight: 1,
  }
}));

export default OfferListItem;
