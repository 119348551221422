import { gql } from "@apollo/client";

const LightweightUsersQuery = gql`
  query LightweightUsers(
    $search: String
  ) {
    users(
      search: $search
    ) {
      total
      users {
        displayId
        email
        id
        name
      }
    }
  }
`;

export default LightweightUsersQuery;
