import { gql } from "@apollo/client";

const OfferPoolsQuery = gql`
  query OfferPools(
    $limit: Int! = 50
    $page: Int! = 1
  ) {
    offerPools(
      limit: $limit
      page: $page
    ) {
      edges {
        cursor
        node {
          id
          active
          archived
          name
          createdAt
          updatedAt
          requirements {
            id
            requirementType
            requirementValue
            offer {
              id
              name
              brand {
                id
                name
                advertiser {
                  id
                  name
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      totalCount
    }
  }
`;

export default OfferPoolsQuery;