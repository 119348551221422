import { useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import {
  createMuiTheme,
  CssBaseline,
  makeStyles,
  MuiThemeProvider
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import Auth0Redirect from "../../components/Auth0Redirect";
import AccountPoolListContainer from "../../containers/AccountPoolListContainer";
import AccountPoolShowContainer from "../../containers/AccountPoolShowContainer/AccountPoolShowContainer";
import AdjustmentListContainer from "../../containers/AdjustmentListContainer";
import AdvertiserListContainer from "../../containers/AdvertiserListContainer";
import AdvertiserShowContainer from "../../containers/AdvertiserShowContainer";
import BrandListContainer from "../../containers/BrandListContainer";
import BrandShowContainer from "../../containers/BrandShowContainer";
import ClickShowContainer from "../../containers/ClickShowContainer/ClickShowContainer";
import InstanceSettingsListContainer from "../../containers/InstanceSettingsListContainer";
import MonthlyPaymentsListContainer from "../../containers/MonthlyPaymentsListContainer";
import OfferListContainer from "../../containers/OfferListContainer";
import OfferShowContainer from "../../containers/OfferShowContainer";
import OfferPoolListContainer from "../../containers/OfferPoolListContainer";
import SettingsContainer from "../../containers/SettingsContainer";
import SubagreementLogsListContainer from "../../containers/SubagreementLogsListContainer";
import UserListContainer from "../../containers/UserListContainer";
import UserShowContainer from "../../containers/UserShowContainer";
import { InstanceSettings } from "../../generated/InstanceSettings";
import InstanceSettingsQuery from "../../queries/InstanceSettingsQuery";
import ConversionManagement from "../ConversionManagement";
import CsvForm from "../CsvForm";
import ErrorPage from "../ErrorPage";
import Header from "../Header";
import Landing from "../Landing";
import Loading from "../Loading";
import PrivacyPolicy from "../PrivacyPolicy";
import RedirectRoute from "../RedirectRoute";
import Report from "../Report";
import SmartPixelGenerator from "../SmartPixelGenerator";
import TermsOfService from "../TermsOfService";
import OfferPoolShowContainer from "../../containers/OfferPoolShowContainer/OfferPoolShowContainer";
import IntegrationsListContainer from "../../containers/IntegrationsListContainer/IntegrationsListContainer";
import IntegrationsShowContainer from "../../containers/IntegrationsShowContainer/IntegrationsShowContainer";
import IntegrationsFormContainer from "../../containers/IntegrationsFormContainer/IntegrationsFormContainer";

const Layout = () => {
  const classes = useStyles();

  const { hasPermission, isAuthenticated, loading, user } = useAuth0();
  const { boot } = useIntercom();

  const [animationComplete, setAnimationComplete] = useState(false);
  const [animationTimeout, setAnimationTimeout] = useState<number | undefined>(
    undefined
  );
  const [userApproved, setUserApproved] = useState(false);

  const { data, loading: instanceSettingsLoading } = useQuery<InstanceSettings>(
    InstanceSettingsQuery
  );

  const [favi, setFavi] = useState("../../../public/favicon.ico");

  if (userApproved && hasPermission("boot:intercom")) {
    boot({
      customAttributes: {
        instance: process.env.REACT_APP_INSTANCE
      },
      email: user?.email,
      name: `${user?.name} - ${process.env.REACT_APP_INSTANCE}`
    });
  }

  useEffect(() => {
    if (
      isAuthenticated &&
      !!user &&
      user["https://intelitics.com/app_metadata"].approved
    ) {
      setUserApproved(true);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (!animationComplete && animationTimeout === undefined) {
      const timeoutId = window.setTimeout(() => {
        setAnimationComplete(true);
        setAnimationTimeout(undefined);
      }, 1000);
      setAnimationTimeout(timeoutId);
    }
  }, [animationComplete, animationTimeout]);

  useEffect(() => {
    if (data?.instanceSettings.favicon) {
      setFavi(data?.instanceSettings.favicon);
    }
  }, [loading, data]);

  if (loading || instanceSettingsLoading || !animationComplete) {
    return <Loading />;
  }

  const appTheme = createMuiTheme({
    palette: {
      primary: {
        contrastText: data?.instanceSettings.themePrimaryTextColor || "#fff",
        main: data?.instanceSettings.themePrimaryColor || "#5cc384"
      },
      secondary: {
        contrastText: data?.instanceSettings.themeSecondaryTextColor || "#fff",
        main: data?.instanceSettings.themeSecondaryColor || "#f50057"
      },
      type: "light"
    }
  });

  const faviconError = () => {
    setFavi("../../../public/favicon.ico");
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />

      <Helmet>
        <title>{data?.instanceSettings.title}</title>
        <link
          rel="icon"
          type="image/png"
          href={favi}
          onError={() => faviconError()}
        />
      </Helmet>

      <BrowserRouter>
        <Switch>
          <RedirectRoute
            component={Landing}
            exact
            path="/"
            redirectTo="/dashboard"
            shouldRedirect={userApproved}
          />

          <Route component={Auth0Redirect} path={["/login", "/signup"]} />
          <Route component={PrivacyPolicy} path="/privacy" />
          <Route component={TermsOfService} path="/terms" />
          <Route component={ErrorPage} path="/error" />

          {/* Header grouped with routes that should display it always */}
          <Fragment>
            <Header logo={data?.instanceSettings.logo} />

            <div className={classes.container}>
              <RedirectRoute
                component={AccountPoolShowContainer}
                exact
                path="/account-pools/:accountPoolId"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:account_pools")}
              />

              <RedirectRoute
                component={AccountPoolListContainer}
                exact
                path="/account-pools"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:account_pools")}
              />

              <RedirectRoute
                component={AdjustmentListContainer}
                exact
                path="/adjustments"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:adjustments")}
              />

              <RedirectRoute
                component={MonthlyPaymentsListContainer}
                exact
                path="/payments"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:monthly_payments")}
              />

              <RedirectRoute
                component={AdvertiserListContainer}
                exact
                path="/advertisers"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:advertisers")}
              />

              <RedirectRoute
                component={AdvertiserShowContainer}
                path="/advertisers/:advertiserId"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:advertisers")}
              />

              <RedirectRoute
                component={BrandListContainer}
                exact
                path="/brands"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={BrandShowContainer}
                path="/brands/:brandId/:tabId"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={SmartPixelGenerator}
                exact
                path="/generator"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:smart_pixels")}
              />

              <RedirectRoute
                component={OfferListContainer}
                exact
                path="/offers"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={OfferShowContainer}
                exact
                path="/offers/:offerId/:tabId"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={SubagreementLogsListContainer}
                exact
                path="/offers/:offerId/subagreements/:userId"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={OfferPoolListContainer}
                exact
                path="/offer-pools"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={OfferPoolShowContainer}
                exact
                path="/offer-pools/:offerPoolId"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={Report}
                exact
                path="/dashboard"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={Report}
                exact
                path="/reports/:reportName"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={SettingsContainer}
                exact
                path="/settings"
                redirectTo="/"
                shouldRedirect={!userApproved}
              />

              <RedirectRoute
                component={UserListContainer}
                exact
                path="/users"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:users")}
              />

              <RedirectRoute
                component={ConversionManagement}
                exact
                path="/tech/conversion-management"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:clicks")}
              />

              <RedirectRoute
                component={ClickShowContainer}
                exact
                path="/tech/conversion-management/:clickId"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:clicks")}
              />

              <RedirectRoute
                component={CsvForm}
                exact
                path="/tech/import-csv"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:clicks")}
              />

              <RedirectRoute
                component={InstanceSettingsListContainer}
                exact
                path="/tech/instance-settings"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:instance_settings")}
              />

              <RedirectRoute
                component={IntegrationsListContainer}
                exact
                path="/tech/integrations"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:integrations")}
              />

              <RedirectRoute
                component={IntegrationsFormContainer}
                exact
                path="/tech/integrations/new"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:integrations")}
              />

              <RedirectRoute
                component={IntegrationsFormContainer}
                exact
                path="/tech/integrations/:integrationId([0-9]*)/edit"
                redirectTo="/"
                shouldRedirect={!hasPermission("manage:integrations")}
              />

              <RedirectRoute
                component={IntegrationsShowContainer}
                exact
                path="/tech/integrations/:integrationId([0-9]*)"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:integrations")}
              />

              <RedirectRoute
                component={UserShowContainer}
                exact
                path="/users/:userId"
                redirectTo="/"
                shouldRedirect={!hasPermission("read:users")}
              />
            </div>
          </Fragment>
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    marginBottom: 100,
    marginTop: 64
  }
});

export default Layout;
