import { gql } from "@apollo/client";

const CreateIntegrationMutation = gql`
  mutation CreateIntegration(
    $accessToken: String!
    $accountId: String!
    $externalId: String!
    $integrationType: IntegrationType!
  ) {
    createIntegration(
      input: {
        accessToken: $accessToken
        accountId: $accountId
        externalId: $externalId
        integrationType: $integrationType
      }
    ) {
      integration {
        id
        accountId
        active
        errorMessage
        externalId
        integrationType
        createdAt
        updatedAt
        createdBy {
          id
          displayId
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export default CreateIntegrationMutation;
