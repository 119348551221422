import { gql } from "@apollo/client";

const ReactivateOfferPoolMutation = gql`
  mutation ReactivateOfferPool ($offerPoolId: Int!) {
    reactivateOfferPool(offerPoolId: $offerPoolId) {
      offerPool {
        id
        active
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default ReactivateOfferPoolMutation;