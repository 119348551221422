import React from 'react';
import IntegrationsShow from '../../components/IntegrationsShow/IntegrationsShow';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { integration as IIntegration } from '../../generated/Integration';
import IntegrationQuery from '../../queries/IntegrationQuery';
import { Typography, makeStyles } from '@material-ui/core';

const IntegrationsShowContainer = () => {
  const classes = useStyles();
  const { integrationId } = useParams<{
    integrationId: string;
  }>();
  
  const { loading, error, data } = useQuery<IIntegration>(IntegrationQuery, {
    variables: { integrationId },
  });

  if (error) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return <IntegrationsShow
    loading={loading}
    integration={data?.integration.integration}
  />;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default IntegrationsShowContainer;