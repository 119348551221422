import React, { useRef, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  ChevronLeft
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import OfferPoolForm, {
  groupRequirementsByOffer,
  IOfferPoolRequirement,
  IOfferPoolRequirementsGroup,
} from "../OfferPoolForm/OfferPoolForm";
import { OfferPool_offerPool_offerPool as IOfferPool } from "../../generated/OfferPool";
import { format } from "date-fns";
import { ISO31661, ISO31662 } from "../../utils/ISO3166";
import { OfferPoolRequirementType } from "../../generated/globalTypes";
import OfferPoolStatusChip from "../OfferPoolStatusChip/OfferPoolStatusChip";
import { useAuth0 } from "../../utils/auth0Provider";

export interface IOfferPoolShowProps {
  offerPool: IOfferPool;
}

const OfferPoolShow = ({ offerPool }: IOfferPoolShowProps) => {
  const cardDetails = useRef<HTMLDivElement>(null);
  const cardOffers = useRef<HTMLDivElement>(null);
  const cardStatus = useRef<HTMLDivElement>(null);
  let scrollTID: NodeJS.Timeout;
  const scrollAndHighlightCard = (cardRef: React.RefObject<HTMLDivElement>) => {
    clearTimeout(scrollTID);
    [cardDetails, cardOffers, cardStatus].forEach((card) =>
      cardRef.current?.classList.remove("highlight")
    );
    if (cardRef.current) {
      window.scrollTo(0, cardRef.current.offsetTop);
      cardRef.current.scrollIntoView({ behavior: "smooth" });
      cardRef.current.classList.add("highlight");
      scrollTID = setTimeout(
        () => cardRef.current?.classList.remove("highlight"),
        2000
      );
    }
  };

  const history = useHistory();
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const manageOfferPools = hasPermission("manage:offer_pools");

  const [offerPoolEditOpen, setOfferPoolEditOpen] = useState(false);
  const requirementsGroupedByOffer = groupRequirementsByOffer(offerPool.requirements);

  const renderOfferListIem = (label: string, value: any) => {
    return (
      <div className={classes.cardListItem}>
        <Typography component="dt" variant="body1">
          {label}
        </Typography>
        <Typography component="dd" variant="body1">
          {value}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Link
                underline="hover"
                color="inherit"
                onClick={(e) => history.push("/offer-pools")}
              >
                <ChevronLeft />
                Offer Pools
              </Link>
              <Typography color="textPrimary">{offerPool?.name}</Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.headerItem}>
            <OfferPoolStatusChip
              offerPoolId={offerPool?.id}
              active={offerPool?.active}
            />
            {manageOfferPools && <Button
              color="primary"
              variant="contained"
              onClick={() => setOfferPoolEditOpen(true)}
            >
              <AddIcon />
              Edit
            </Button>}
          </div>
        </div>
        <Divider />
        <div className={classes.gRoot}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={2}>
              <List>
                <ListItem
                  button
                  onClick={() => scrollAndHighlightCard(cardDetails)}
                >
                  <ListItemText primary="Details" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => scrollAndHighlightCard(cardOffers)}
                >
                  <ListItemText primary="Offers" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => scrollAndHighlightCard(cardStatus)}
                >
                  <ListItemText primary="Status" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={10}>
              <div className={classes.cardContainer}>
                <Card
                  ref={cardDetails}
                  variant="outlined"
                  className={classes.card}
                >
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Details
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderOfferListIem("Name", offerPool?.name)}
                      {renderOfferListIem(
                        "Advertiser",
                        requirementsGroupedByOffer[0]?.offer?.brand?.advertiser
                          ?.name
                      )}
                      {renderOfferListIem(
                        "Brand",
                        requirementsGroupedByOffer[0]?.offer?.brand?.name
                      )}
                    </dl>
                  </CardContent>
                </Card>

                <Card
                  ref={cardOffers}
                  variant="outlined"
                  className={classes.card}
                >
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Offers
                    </Typography>
                    {offerPool?.requirements.length > 0 ? (
                      <Table className={classes.cardListItemTable} size="small">
                        <TableBody>
                          {[...Object.values(requirementsGroupedByOffer)].map(
                            (group: IOfferPoolRequirementsGroup) => (
                              <TableRow key={group?.offer?.id}>
                                <TableCell valign="top">
                                  <Typography
                                    component="strong"
                                    variant="inherit"
                                  >
                                    {group?.offer?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {group.requirements
                                    .reduce(
                                      (acc, req: IOfferPoolRequirement) => {
                                        if (
                                          !acc.includes(
                                            req.requirementType as string
                                          )
                                        ) {
                                          acc.push(
                                            req.requirementType as string
                                          );
                                        }
                                        return acc;
                                      },
                                      [] as string[]
                                    )
                                    .map(
                                      (label) =>
                                        label.charAt(0).toUpperCase() +
                                        label.toLowerCase().substring(1)
                                    )
                                    .map((label) => (
                                      <div>&mdash; {label}</div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                  {group.requirements.map((req) => {
                                    let value = req?.requirementValue;
                                    if (
                                      req.requirementType ===
                                      OfferPoolRequirementType.COUNTRY
                                    ) {
                                      value = ISO31661.filter((c) =>
                                        req?.requirementValue?.includes(
                                          c.alpha2
                                        )
                                      ).map(({ name }) => name);
                                    }
                                    if (
                                      req.requirementType ===
                                      OfferPoolRequirementType.STATE
                                    ) {
                                      value = ISO31662.filter((c) =>
                                        req?.requirementValue?.includes(c.code)
                                      ).map(({ name }) => name);
                                    }
                                    return (
                                      <div key={req.requirementType}>
                                        &mdash; {value?.join(", ")}
                                      </div>
                                    );
                                  })}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        No offers added
                      </Typography>
                    )}
                  </CardContent>
                </Card>

                <Card
                  ref={cardStatus}
                  variant="outlined"
                  className={classes.card}
                >
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Status
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderOfferListIem(
                        "Created",
                        format(
                          new Date(offerPool.createdAt),
                          "MM/dd/yyyy HH:mmaa"
                        )
                      )}
                      {renderOfferListIem(
                        "Last Updated",
                        format(
                          new Date(offerPool.updatedAt),
                          "MM/dd/yyyy HH:mmaa"
                        )
                      )}
                      {renderOfferListIem(
                        "Status",
                        <OfferPoolStatusChip
                          offerPoolId={offerPool?.id}
                          active={offerPool?.active}
                        />
                      )}
                    </dl>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
      {manageOfferPools && <OfferPoolForm
        type="update"
        onClose={() => setOfferPoolEditOpen(false)}
        open={offerPoolEditOpen}
        id={parseInt(offerPool.id, 10)}
        name={offerPool.name}
        active={offerPool.active}
        archived={offerPool.archived}
        requirements={offerPool.requirements}
      />}
    </>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  "@keyframes highlight": {
    "0%": {
      backgroundColor: palette.grey[50],
    },
    "100%": {
      backgroundColor: "inherit",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(2),
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    paddingBottom: spacing(1),
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  gRoot: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: spacing(2),
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: palette.grey[200],
  },
  card: {
    marginBottom: spacing(2),
    "&.highlight": {
      animation: `$highlight .5s ease-in-out`,
    },
  },
  cardTitle: {
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  cardList: {
    width: "100%",
  },
  cardListItem: {
    display: "flex",
    gap: spacing(1),
    marginBottom: spacing(1),
    "& > dt": {
      fontWeight: "bold",
      minWidth: "10%",
    },
  },
  cardListItemTable: {
    width: "inherit",
  },
}));

export default OfferPoolShow;
