import React, { useState } from "react";
import { Chip, Tooltip } from "@material-ui/core";
import { FiberManualRecordTwoTone } from "@material-ui/icons";
import ConfirmationAlert from "../ConfirmationAlert";
import { useMutation } from "@apollo/client";
import { DeactivateOfferPool } from "../../generated/DeactivateOfferPool";
import DeactivateOfferPoolMutation from "../../mutations/DeactivateOfferPoolMutation";
import { ReactivateOfferPool } from "../../generated/ReactivateOfferPool";
import ReactivateOfferPoolMutation from "../../mutations/ReactivateOfferPoolMutation";
import { useAuth0 } from "../../utils/auth0Provider";

export interface IOfferPoolStatusChipProps {
  offerPoolId?: string|number;
  active?: boolean;
}

const OfferPoolStatusChip = ({
  offerPoolId: rawOfferPoolId,
  active,
}: IOfferPoolStatusChipProps) => {
  const offerPoolId = parseInt(rawOfferPoolId as string, 10);

  const { hasPermission } = useAuth0();
  const manageOfferPools = hasPermission("manage:offer_pools");

  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [deactivateOfferPool, { loading: deactivateLoading }] = useMutation<DeactivateOfferPool>(DeactivateOfferPoolMutation, {
    variables: {
      offerPoolId,
    }
  });
  const onConfirmationNegative = () => {
    setDeactivateConfirmationOpen(false);
  }
  const onConfirmationPositive = () => {
    setDeactivateConfirmationOpen(false);
    deactivateOfferPool();
  }

  const [reactivateOfferPool, { loading: reactivateLoading }] = useMutation<
    ReactivateOfferPool
  >(ReactivateOfferPoolMutation, {
    variables: {
      offerPoolId,
    },
  });
  const loading = deactivateLoading || reactivateLoading;

  const tooltipTitle = manageOfferPools ? `${active ? "Deactivate" : "Activate"} this Offer Pool` : "";
  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Chip
          variant={active ? "outlined" : "default"}
          size="small"
          label={active ? "Live" : "Inactive"}
          color={active ? "primary" : "default"}
          icon={<FiberManualRecordTwoTone />}
          disabled={!manageOfferPools || loading}
          onClick={() =>
            active ? setDeactivateConfirmationOpen(true) : reactivateOfferPool()
          }
        />
      </Tooltip>

      <ConfirmationAlert
        title="Deactivate Offer Pool"
        content="Are you sure you want to deactivate this offer pool?"
        open={deactivateConfirmationOpen}
        onNegative={onConfirmationNegative}
        onPositive={onConfirmationPositive}
        positiveAction="Deactivate"
      />
    </>
  );
};

export default OfferPoolStatusChip;
